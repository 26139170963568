import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { ProjectService } from "../../../services/projects/projectService";
import { DashboardProps } from "../DashboardPage";
import { CardTitle } from "../../../components/bootstrap/Card";
import { FixNumber } from "../../../utils/fixNumber";

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const formattedStartDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`;
const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

const DashBoardProfits: React.FC<DashboardProps> = ({ filters, dashboardDates }) => {

  const projectService = new ProjectService();

  const [data] = useFetch(useCallback(async () => {
    const currentProfitsFilters = {
      filter_filters: {
        between_dates: {
          start_date: dashboardDates.startDate ? dashboardDates.startDate : formattedStartDate,
          end_date: dashboardDates.endDate ? dashboardDates.endDate : formattedEndDate,
        },
        path: filters?.path || "dashboard_profit_values",
      },
      filter_order: filters?.filter_order || [],
      limit: filters?.limit || 0,
      page: filters?.page || 0,
    };
    const response = await projectService.getProfitsValues(currentProfitsFilters);
    return response.getResponseData();
  }, [filters]));

  if (!data) return null;

  return (
    <div className="h-100 d-flex flex-column justify-content-center pe-2">
      <div className="text-center">
        <CardTitle className="m-0 fs-6">Total:</CardTitle>
        <span className="text-end fs-6">{FixNumber(data[0][0].currentProfits - data[0][0].currentCosts)}€</span>
      </div>

      <div className="text-center mt-3">
        <CardTitle className="m-0 fs-6">Prevista:</CardTitle>
        <span className="text-center fs-6">{FixNumber(data[0][0].expectedProfits - data[0][0].expectedCosts)}€</span>
      </div>
    </div>
  )
};

export default DashBoardProfits;