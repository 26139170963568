import { Fragment, useCallback, useContext, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import Page from "../../../layout/Page/Page";
import { DocumentService } from "../../../services/documents/documentService";
import useTruncateString from "../../../hooks/useTruncateString";
import DocumentTypeForm from "../DocumentTypeForm";
import DocumentsFilters from "./documents-options/DocumentsFilters";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

export interface ITypesFilters {
    parents?: boolean | null;
    company?: string;
    user?: string;
};

const typesFilters: ITypesFilters = {
    parents: null,
};

const DocumentsList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const { truncateString } = useTruncateString();

    const docService = new DocumentService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(typesFilters);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await docService.listDocumentTypes(filters);
        return response.getResponseData();
    }, [filters]));

    const handleDelete = async (id: string) => {
        try {
            const response = await (await docService.deleteDocumentType(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Tarea eliminada correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar la tarea");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            setSelectedItem(null);
            setShow(false);
            setModalOpen(false);
            refetch();
            toast.success(message);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage error={error} />;

        if (data) {
            let apiData = data as any;

            return (
                <CustomTable
                    title="Tipos de documentos"
                    data={apiData ? apiData.projectDocumentTypes : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as any ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-center" onClick={() => {
                                        setSelectedItem(element);
                                        setModalOpen(true);
                                        setShow(true);
                                    }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Padre',
                            keyValue: 'parent',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>{element.parent ? element.parent.name : '-'}</>
                                )
                            }
                        },
                        {
                            name: 'Descripción',
                            keyValue: 'description',
                            render: (element: any) => {
                                return (
                                    <>{element.description ? truncateString(element.description, 10) : '-'}</>
                                )
                            }
                        },
                        {
                            name: 'Orden',
                            keyValue: 'documentOrder',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>{element.documentOrder ? element.documentOrder : '-'}</>
                                )
                            }
                        },
                        /* {
                            name: 'Creado por',
                            keyValue: 'user',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.user.name}
                                    </div>
                                )
                            }
                        }, */
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Editar",
                                buttonType: 'icon',
                                icon: "Edit",
                                additionalClasses: `text-primary ${userCan("edit", "project_document_type") ? "" : "d-none"}`,
                                description: "Editar tipo de documento",
                                callback: (item: any) => {
                                    if (!userCan("edit", "project_document_type")) return;
                                    setSelectedItem(item);
                                    setModalOpen(true);
                                    setShow(false);
                                },
                            },
                            {
                                title: "Eliminar",
                                buttonType: 'icon',
                                icon: "Delete",
                                additionalClasses: `text-danger ${userCan("delete", "project_document_type") ? "" : "d-none"}`,
                                description: "Eliminar tipo de documento",
                                callback: (item: any) => {
                                    if (!userCan("delete", "project_document_type")) return;
                                    handleConfirmationAlert({
                                        title: "Eliminar tipo de documento",
                                        text: "¿Está seguro que desea eliminar el tipo de documento?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        }
                                    })
                                },
                            },
                            {
                                title: "Sin permisos",
                                buttonType: 'icon',
                                icon: "Block",
                                additionalClasses: `text-danger ${(userCan('edit', 'project_document_type') || userCan('delete', 'project_document_type')) ? 'd-none' : ''}`,
                                description: "No tiene permisos para editar ni eliminar",
                                callback: (item: any) => { },
                            }
                        ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Tipos de Documentos</CardTitle>
                    <SubheaderSeparator />

                    <Button
                        color="primary"
                        title="Crear tipo"
                        icon="Add"
                        isLight
                        className={`${userCan("create", "project_document_type") ? "" : "d-none"}`}
                        onClick={() => {
                            if (!userCan("create", "project_document_type")) return;
                            setModalOpen(true);
                            setSelectedItem(null);
                            setShow(false);
                        }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <DocumentsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} data={data} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <>{getContent()}</>
                    </CardBody>

                    {modalOpen && (
                        <DocumentTypeForm
                            isOpen={modalOpen} setIsOpen={setModalOpen}
                            onClose={_onCloseModal}
                            data={selectedItem} show={show}
                        />
                    )}
                </Card>
            </Page>
        </Fragment>
    );
};

export default DocumentsList;