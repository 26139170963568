import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoginService } from '../services/auth/loginService';
import { saveUserToLocalStorage, removeUserFromLocalStorage, loadUserFromLocalStorage } from '../utils/jwt';
import { toast } from 'react-toastify';

export type Device = {
  id: string;
  token: string;
  device_type: string;
}

export interface User {
  token: string;
  id: string;
  name: string;
  lastName: string;
  email: string;
  profilePictureId: string;
  roles: string[];
  company: string;
  companyName: string;
  devices: Device[];
}

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: loadUserFromLocalStorage() !== null,
  user: loadUserFromLocalStorage(),
  loading: false,
  error: null,
};

export const login = createAsyncThunk('auth/login', async (credentials: { email: string; password: string }) => {
  const response = await (await (new LoginService()).authUserCredentials(credentials.email, credentials.password)).getResponseData();
  if (response.success) {

  } else {
    setTimeout(() => {
      toast.error(response.message || 'Credenciales incorrectas');
    }, 100);
    // initialState.error = response.message;
  }

  const user: User = {
    token: response.data.token,
    id: response.data.user.id,
    name: response.data.user.name,
    lastName: response.data.user.lastName,
    email: response.data.user.email,
    profilePictureId: response.data.user.imgProfile,
    roles: response.data.user.roles,
    company: response.data.user.company,
    companyName: response.data.user.companyName,
    devices: response.data.user.devices,
  };

  saveUserToLocalStorage(user);
  return user;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      removeUserFromLocalStorage();
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
      state.user = null;
    },
    addDevice: (state, action) => {
      state.user?.devices.push(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error desconocido';
      });
  },
});

export const { logout, addDevice } = authSlice.actions;

export default authSlice.reducer;