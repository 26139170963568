import { useEffect } from "react";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import { useBugdetForm } from "../providers/BudgetProvider";
import { conceptsNames } from "../../../utils/concepts";


const BudgetConcepts = () => {

    const { formik, formikConcepts, formikStages, conceptsData, setConceptsData, taxesList } = useBugdetForm();

    const newConcepts = [...conceptsData];

    // Delete the concept from the formik when it is deleted from the component
    const _handleDeleteConceptFromStage = (newConceptsData: any) => {
        // Get the ids of the concepts of newConceptsData
        const newConceptsDataIDs = newConceptsData.map((concept: any) => concept.id);

        // Get the ids of the concepts of formik
        const formikConceptsIDs = formikStages.values.concepts.map((concept: any) => concept.id);

        // Compare the ids of the concepts of formik with the ids of the concepts of newConceptsData
        const conceptToDelete = formikConceptsIDs.filter((id: any) => !newConceptsDataIDs.includes(id));

        // Search in the formik the concept that matches the id of conceptToDelete and delete it
        const newFormikConcepts = formikStages.values.concepts.filter((concept: any) => concept.id !== conceptToDelete[0]);

        // Update the formik
        formikStages.setFieldValue("concepts", newFormikConcepts);
    };

    const getConceptsNames = () => {
        return conceptsNames.map((concept: string) => { return { value: concept, label: concept } });
    };

    // Get the name of the concept by its id
    const getTaxValueById = (id: string) => {
        const tax = taxesList().filter((tax: any) => tax.value === id);
        return tax[0]?.label;
    };

    // Update the formik when the conceptsData changes
    useEffect(() => {
        formik.setFieldValue("concepts", conceptsData);
        formikConcepts.resetForm();
    }, [conceptsData]);

    return (
        <>
            {conceptsData.map((concept: any, index: number) => {
                const nameSelected = getConceptsNames().filter((name: any) => name.value === concept.name);
                const defaultNameValue = nameSelected[0] ? { value: nameSelected[0].value, label: nameSelected[0].label.toString() } : null;

                return (
                    <div className="row mb-3" key={concept.id}>
                        <div className="col-md-1">
                            <Input
                                id={concept.quantity.toString()}
                                value={concept.quantity}
                                onChange={(e: any) => {
                                    newConcepts[index].quantity = e.target.value;
                                    setConceptsData(newConcepts);
                                }}
                                type="number"
                            //className={verify("quantity")}
                            />
                            {/* {showErrors("quantity")} */}
                        </div>

                        <div className="col-md-3">
                            <SearchableSelect
                                isSearchable
                                name={concept.name}
                                defaultValue={defaultNameValue}
                                options={getConceptsNames()}
                                onChange={(e: any) => {
                                    newConcepts[index].name = e.value;
                                    setConceptsData(newConcepts);
                                }}
                            />
                        </div>

                        <div className="col-md-4">
                            <Input
                                id={concept.textField}
                                value={concept.textField}
                                onChange={(e: any) => {
                                    newConcepts[index].textField = e.target.value;
                                    setConceptsData(newConcepts);
                                }}
                            //className={verify("textField")}
                            />
                            {/* {showErrors("textField")} */}
                        </div>

                        <div style={{ width: '10%' }}>
                            <Input
                                id={concept.amount.toString()}
                                value={concept.amount}
                                onChange={(e: any) => {
                                    newConcepts[index].amount = e.target.value;
                                    setConceptsData(newConcepts);
                                }}
                                type="number"
                            //className={verify("amount")}
                            />
                            {/* {showErrors("amount")} */}
                        </div>

                        <div style={{ width: '10%' }}>
                            <Input id={concept.tax + 'tax'} value={getTaxValueById(concept.tax !== 0 ? concept.tax : concept.taxes)} disabled />
                        </div>

                        <div style={{ width: '10%' }}>
                            <Input id={concept.withholdings + 'withholding'} value={concept.withholdings} disabled />
                        </div>

                        <div className="d-flex justify-content-end p-0" style={{ width: '3%' }} >
                            <Button
                                color='dark'
                                isLight
                                icon='Delete'
                                title="Eliminar concepto"
                                onClick={() => {
                                    handleConfirmationAlert({
                                        title: "Eliminar concepto",
                                        text: "¿Está seguro que desea eliminar el concepto?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            const newConceptsData = conceptsData.filter((concept: any) => concept !== conceptsData[index]);
                                            setConceptsData(newConceptsData);
                                            _handleDeleteConceptFromStage(newConceptsData);
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                )
            })}
        </>
    );
}

export default BudgetConcepts;