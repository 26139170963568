import { useEffect, useState } from 'react';
import { useDirectoryNavigator } from './DirectoryNavigatorProvider';
import { DocumentService } from '../../services/documents/documentService';
import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup';
import Icon from '../icon/Icon';
import moment from 'moment';
import 'moment/locale/es';
import { handleConfirmationAlert } from '../../utils/ConfirmationAlert';
import { ProjectService } from '../../services/projects/projectService';
import { toast } from 'react-toastify';
import useGetIcon from '../../hooks/useGetIcon';
import useHandleDownloadDocument from '../../hooks/useDownloadDocument';
import useHandleErrors from '../../hooks/useHandleErrors';
import Checks from '../bootstrap/forms/Checks';

export const DirectoryNavigatorList = () => {

    const { selectedDocumentType, updateList, setUpdateList, filters } = useDirectoryNavigator();
    const { handleDownloadDocument } = useHandleDownloadDocument();
    const { handleErrors } = useHandleErrors();
    const { getIcon } = useGetIcon();
    const documentService = new DocumentService();
    const projectService = new ProjectService();

    const [documents, setDocuments] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState<any>();

    const getDocumentsList = async () => {
        if (!selectedDocumentType || selectedDocumentType.id === "") return;
        const response = await documentService.listProjectDocuments({ ...filters, parentId: selectedDocumentType.id });
        const documentTypesResponse = response.getResponseData();
        handleErrors(response);
        const data = documentTypesResponse.data.projectDocuments;
        setDocuments(data);
    };

    const _handleDelete = async (id: string) => {
        try {
            const response = await (await projectService.deleteDocument(id)).getResponseData();
            if (response.success) {
                setUpdateList(true);
                toast.success('Documento eliminado');
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _handleDeleteAll = async () => {
        try {
            const response = await (await documentService.multiDeleteDocuments(selectedDocuments)).getResponseData();
            if (response.success) {
                setUpdateList(true);
                setSelectedDocuments([]);
                toast.success('Documentos eliminados');
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        if (selectedDocumentType?.id !== "") {
            getDocumentsList();
            setUpdateList(false);
        }
    }, [selectedDocumentType, filters, updateList]);

    if (!selectedDocumentType || selectedDocumentType.id === "") return null;

    return (
        <>
            {(documents.length > 0) && (
                <ListGroup isFlush style={{ borderBottom: '1px solid lightgrey' }}>
                    {documents.map((document: any) => (
                        <ListGroupItem key={document.id} tag="button" className='rounded btnHoverColor'>
                            <div className="row">
                                <div className="col-md-1 d-flex align-items-center">
                                    <Checks
                                        id={document.document.id}
                                        checked={selectedDocuments?.includes(document.document.id)}
                                        onChange={() => {
                                            setSelectedDocuments(selectedDocuments?.includes(document.document.id)
                                                ? selectedDocuments?.filter((id: any) => id !== document.document.id)
                                                : [...(selectedDocuments || []), document.document.id]
                                            )
                                        }}
                                    />
                                </div>
                                <div
                                    className="col-md-9 d-flex align-items-center"
                                    onClick={() => handleDownloadDocument(projectService.downloadDocument, document.document.id, document.document.originalName)}
                                >
                                    <Icon icon={getIcon(document.document.extension)} size='2x' className="me-3" />
                                    <span>{document.document.originalName}</span>
                                </div>
                                <div className="col-md-2 d-flex justify-content-end align-items-center" style={{ cursor: 'default' }}>
                                    <div>{moment(document.document.updatedAt.date).format('DD MMM YYYY')}</div>
                                    <Icon
                                        icon='Delete'
                                        size='lg'
                                        title="Eliminar documento"
                                        onClick={() => handleConfirmationAlert({
                                            title: "Eliminar documento",
                                            text: "¿Está seguro que desea eliminar el documento?",
                                            icon: "warning",
                                            onConfirm: () => { _handleDelete(document.document.id) },
                                            focusCancel: true,
                                        })}
                                        className="ms-3"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            )}

            {selectedDocuments?.length > 0 && (
                <div className="d-flex justify-content-end mt-3">
                    <button
                        className="btn btn-secondary"
                        onClick={() => handleConfirmationAlert({
                            title: "Eliminar documentos",
                            text: "¿Está seguro que desea eliminar los documentos seleccionados?",
                            icon: "warning",
                            onConfirm: () => { _handleDeleteAll() },
                            focusCancel: true,
                        })}
                    >
                        Eliminar selección
                    </button>
                </div>
            )}
        </>
    );
};