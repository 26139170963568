import { FC } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";

interface StageConceptsModalProps {
    concepts: any[];
    openModal: boolean;
    setOpenModal: any;
}

const StageConceptsModal: FC<StageConceptsModalProps> = ({ concepts, openModal, setOpenModal }) => {

    if (!concepts) return null;

    return (
        <Modal isOpen={openModal} setIsOpen={setOpenModal} size='md' titleId='Conceptos de la fase'>
            <ModalHeader setIsOpen={setOpenModal} className='p-4'>
                <ModalTitle id='stageConcepts'>Conceptos de la fase</ModalTitle>
            </ModalHeader>

            <ModalBody className="mt-5">
                {concepts.map((concept: any) => {
                    return (
                        <div className="row mb-3 d-flex justify-content-center align-items-center">
                            <div className="col-10 fw-bold">
                                {concept.concepts?.name}
                            </div>
                            <div className="col-2">
                                {concept.concepts?.priceTotal} €
                            </div>
                        </div>
                    )
                })}
            </ModalBody>
        </Modal>
    )
}

export default StageConceptsModal;