import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import Textarea from "../../../../components/bootstrap/forms/Textarea";
import Button from "../../../../components/bootstrap/Button";
import Spinner from "../../../../components/bootstrap/Spinner";
import { IncomeService } from "../../../../services/incomes/incomeService";
import FormLabel from "../../../../components/FormLabel";
import { SketchPicker } from "react-color";

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data?: any;
    onClose: (type: number, message?: string, errors?: any) => void;
    show?: boolean;
}

const CreateModalIncomeType: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');
    const incomeService = new IncomeService();

    const [modal, setModal] = useState(false);
    const [incomeTypeList, setIncomeTypeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

    const getIncomeTypes = async () => {
        const incomeTypeId = data?.id || '';
        const response = await (await incomeService.getIncomeTypes({
            filter_filters: {
                not_income_type: incomeTypeId
            },
            filter_order: [
                {
                    "field": "name",
                    "order": "asc"
                }
            ],
            limit: 999999
        })).getResponseData();
        if (response.success) {
            const incomeTypes = response.data.incomeTypes;
            setIncomeTypeList(incomeTypes);
        }
    }

    const getIncomeTypeList = () => {
        if (incomeTypeList as any) {
            let incomeTypeOptions = incomeTypeList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return [{ id: '', text: 'Ninguno' }, ...incomeTypeOptions]
        }
        return [];
    }

    useEffect(() => {
        getIncomeTypes();
    }, [isSuperAdmin])

    const create = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await (await incomeService.createIncomeType(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
            setIsLoading(false);
        } catch (e) {
            toast.error('Error al crear tipo de ingreso');
        }
    }

    const update = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await (await incomeService.editIncomeType(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
            setIsLoading(false);
        } catch (e) {
            toast.error('Error al editar el tipo de ingreso');
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            incomeTypeId: data?.id || null,
            name: data?.name || '',
            description: data?.description || '',
            parent: data?.parent?.id || '',
            color: data?.color || '#000000',
        },
        onSubmit: (values) => {
            if (data != null) {
                update(values)
            } else {
                create(values);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'md' : 'sm'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{show ? 'Ver Tipo de Ingreso' : data ? 'Editar Tipo de Ingreso' : 'Crear Tipo de Ingreso'}</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className={isSuperAdmin ? 'col-md-6' : "col-md-12"}>
                                <Input value={formik.values.name || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup requiredInputLabel id='parent' label='Categoria Padre' className={isSuperAdmin ? 'col-md-6' : "col-md-12"}>
                                <Select id="parent" disabled={show ? true : false} list={getIncomeTypeList()} onChange={formik.handleChange} value={formik.values.parent || ''} ariaLabel='Select Income Types' placeholder='Elegir Tipo de Ingreso...' />
                            </FormGroup>
                            <FormGroup id='description' label='Descripción:' className={"col-md-12"}>
                                <Textarea value={formik.values.description || ''} disabled={show ? true : false} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                            <div className="col-md-3">
                                <FormLabel label="Color" required />
                                <div
                                    key={formik.values.incomeTypeId + formik.values.color}
                                    onClick={() => setShowColorPicker(!showColorPicker)}
                                    style={{
                                        backgroundColor: formik.values.color,
                                        border: "1px solid rgba(0, 0, 0, 0.1)",
                                        cursor: "pointer",
                                        width: "25px",
                                        height: "25px",
                                    }}
                                    className={'rounded-circle mr-3 p-3'}
                                >
                                    <div className="rounded-circle" style={{ backgroundColor: formik.values.color, width: "5px", height: "5px" }} />
                                </div>
                                {showColorPicker && <SketchPicker color={formik.values.color} onChange={(color) => { formik.setFieldValue('color', color.hex) }} />}
                                <></>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'} isDisable={isLoading}>
                            {isLoading ? <Spinner /> : data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (
                <div className="d-flex justify-content-center p-5">
                    <Spinner />
                </div>
            )}
        </Modal>
    )
}

export default CreateModalIncomeType;