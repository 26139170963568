import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../redux/store';

interface ProtectedRouteProps {
  component: React.ComponentType;
  role: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, role }) => {

  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  if (user && user.roles && user.roles.includes(role)) {
    if (isAuthenticated) return <Component />;

    return <Navigate to="/" />;
  }

  return <Navigate to="/login" />
};

export default ProtectedRoute;