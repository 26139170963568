import { useState } from 'react';
import { Dropdown, FormGroup } from 'react-bootstrap';
import { useCosts } from '../provider/CostsProvider';
import CustomDateRangePicker, { RangeDate } from '../../../../components/CustomDateRangePicker';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { changeCostFilters } from '../../../../redux/schedulerSlice';
import Button from '../../../../components/bootstrap/Button';

const CostsFilters = () => {

    //  Redux connection
    const dispatch = useDispatch();
    const costFilters = useSelector((state: RootState) => state.scheduler.costFilters);
    const { filters, updateFilters, viewMode, changeViewMode } = useCosts();

    const [fromDate, setFromDate] = useState<string>(filters.filter_filters?.estimated_entry_date?.from || costFilters.estimated_entry_date.from);
    const [toDate, setToDate] = useState<string>(filters.filter_filters?.estimated_entry_date?.to || costFilters.estimated_entry_date.to);
    const [selectedOption, setSelectedOption] = useState(viewMode);
    const options = ['Mes', 'Semana', 'Día'];

    const handleViewModeChange = (eventKey: any) => {
        setSelectedOption(eventKey);
        changeViewMode(eventKey);
        switch (eventKey) {
            case 'Mes':
                updateFilters({ range_mode_view: 'month' });
                break;
            case 'Semana':
                updateFilters({ range_mode_view: 'week' });
                break;
            case 'Día':
                updateFilters({ range_mode_view: 'day' });
                break;
        }
    };

    /**
    * Update the date range filter
    */
    const onChangeDateUpdate = (range: RangeDate) => {
        if (range.startDate && range.endDate) {
            // Update the calendar
            setFromDate(moment(range.startDate).format('YYYY-MM-DD'));
            setToDate(moment(range.endDate).format('YYYY-MM-DD'));

            // Update filters
            updateFilters({
                estimated_entry_date: {
                    from: moment(range.startDate).format('YYYY-MM-DD'),
                    to: moment(range.endDate).format('YYYY-MM-DD')
                }
            });

            // Update redux
            dispatch(changeCostFilters({
                estimated_entry_date: {
                    from: moment(range.startDate).format('YYYY-MM-DD'),
                    to: moment(range.endDate).format('YYYY-MM-DD')
                }
            }));
        }
    };

    return (
        <div className='row w-100 d-flex justify-content-end'>
            <FormGroup className='d-flex align-items-center col-5'>
                <CustomDateRangePicker
                    defaultSelection='custom'
                    showStaticRanges={true}
                    onChangeDate={onChangeDateUpdate}
                    customSelection={{
                        startDate: fromDate ? moment(fromDate).toDate() : moment().startOf('month').toDate(),
                        endDate: toDate ? moment(toDate).toDate() : moment().endOf('day').toDate()
                    }}
                />
            </FormGroup>

            <FormGroup className='d-flex align-items-center col-2'>
                <Dropdown onSelect={handleViewModeChange}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ paddingRight: '10px', paddingLeft: '10px', marginLeft: 'auto', marginRight: '-60px' }}>
                        {selectedOption}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {options.map((option) => (
                            <Dropdown.Item key={option} eventKey={option}>
                                {option}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </FormGroup>

            <div className='d-flex align-items-center col-2'>
                <Button
                    //isLight
                    color='brand'
                    onClick={() => {
                        updateFilters({ estimated_entry_date: { from: moment().subtract(2, 'months').format('YYYY-MM-DD'), to: moment().add(2, 'months').format('YYYY-MM-DD') } });
                        dispatch(changeCostFilters({ estimated_entry_date: { from: moment().subtract(2, 'months').format('YYYY-MM-DD'), to: moment().add(2, 'months').format('YYYY-MM-DD') } }));
                    }}
                >
                    Limpiar
                </Button>
            </div>
        </div>
    );
}

export default CostsFilters;