import { FC, Fragment, useCallback, useState } from "react";
import Select from "../../../components/bootstrap/forms/Select";
import { toast } from "react-toastify";
import Accordion, { AccordionItem } from "../../../components/bootstrap/Accordion";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { PermissionService } from "../../../services/auth/permissionService";
import { RoleService } from "../../../services/auth/roleService";
import { CompanyService } from "../../../services/companies/organizationService";
import { Companies, CompaniesApiResponse } from "../../../type/company-type";
import { NewRole, Permission, PermissionGroup, PermissionsApiResponse } from "../../../type/role-type";
import { useFormik } from "formik";
import Checks from "../../../components/bootstrap/forms/Checks";
import { UserService } from "../../../services/users/userService";
import { loadUserFromLocalStorage } from "../../../utils/jwt";
import ErrorMessage from "../../../components/ErrorMessage";

interface RoleCreateModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	refetch: () => void;
}

const RoleCreateModal: FC<RoleCreateModalProps> = ({ isOpen, setIsOpen, refetch }) => {

	const rol = loadUserFromLocalStorage()?.roles[0];

	const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState<number[]>([]);

	const [permissions, fetchingPermissions, permissionError] = useFetch(useCallback(async () => {
		const permissionService = new PermissionService();
		const response = await permissionService.getPermissions();
		return response.getResponseData() as PermissionsApiResponse;
	}, []));

	const [companies, fetchingCompanies, companyError] = useFetch(useCallback(async () => {
		const companyService = new CompanyService();
		const response = await companyService.getOrganizations();
		if (response.getResponseData() === undefined) {
			const result = ((await new UserService().getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData());
			formik.values.company = result?.data.userRoles[0].company.id;
			return result.data.userRoles[0].company as CompaniesApiResponse;
		};
		return response.getResponseData() as CompaniesApiResponse;
	}, []));

	const getCompanyList = () => {
		if (companies as Companies) {
			return companies.companies.map((productType: { id: string; name: string }) => {
				return {
					value: productType.id,
					label: productType.name,
				};
			});
		}
		return [];
	};

	const handleCreateRole = async (values: any) => {
		values.permissions = selectedPermissions;

		try {
			const response = await (await new RoleService().createRole(values)).getResponseData();

			if (response.success) {
				setIsOpen(false);
				/* navigate('/roles'); */
				setTimeout(() => {
					toast.success('Rol creado correctamente');
				}, 100);

				refetch();
			} else {
				throw new Error(response.message);
			}
		} catch (e: any) {
			toast.error(e.message || 'Error al crear el rol');
			companyError(e.message);
			setTimeout(() => {
				companyError('');
			}, 3000);
		}
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			description: "",
			company: "",
			permissions: [],
		},
		onSubmit: (values: NewRole) => { handleCreateRole(values); },
	});

	const getPermissions = () => {
		if (fetchingPermissions || fetchingCompanies) return <div className="text-center"><Spinner /></div>;

		if (permissionError || companyError) return <ErrorMessage />;

		return (
			<Fragment>
				<div className="row g-4">
					<FormGroup requiredInputLabel id="name" label="Nombre" className={rol === 'Superadministrador' ? 'col-md-3' : 'col-md-6'} >
						<Input required value={formik.values.name} onChange={formik.handleChange} />
					</FormGroup>
					<FormGroup
						id="description" label="Breve descripción" className="col-md-6">
						<Input id="description" value={formik.values.description} onChange={formik.handleChange} />
					</FormGroup>
					{rol === 'Superadministrador' && (
						<FormGroup requiredInputLabel id="company" label="Organización" className="col-md-3"          >
							<Select
								required id="company"
								onChange={formik.handleChange}
								value={formik.values.company}
								ariaLabel="Default select example"
								placeholder="Elegir organización..."
								list={getCompanyList()}
							/>
						</FormGroup>
					)}
				</div>

				<div className="row mt-5">
					{permissions?.map((group: PermissionGroup, index: number) => {
						return (
							<div className="col-md-3 mb-5" key={group.id}>
								<Accordion id={group.name} isFlush activeItemId={group.id}>
									<AccordionItem id={group.id} title={group.label}>
										<>
											<Checks
												label="Seleccionar todos"
												value="all"
												checked={selectAll.includes(group.id)}
												onChange={() => {
													const list = group.permissions.map((item: Permission) => item.id);
													if (selectAll.includes(group.id)) {
														setSelectAll(selectAll.filter((id: number) => id !== group.id));
														setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
													} else {
														setSelectAll([...selectAll, group.id]);
														setSelectedPermissions([...selectedPermissions.concat(list)]);
													}
												}}
											/>
										</>
										{group.permissions.map((permission: Permission, index: number) => {
											return (
												<div key={permission.id}>
													<Checks
														label={permission.label}
														value={permission.id}
														checked={selectedPermissions.includes(
															permission.id
														)}
														onChange={() => {
															selectedPermissions.includes(permission.id)
																? setSelectedPermissions(
																	selectedPermissions.filter(
																		(id: number) => id !== permission.id
																	)
																)
																: setSelectedPermissions([
																	...selectedPermissions,
																	permission.id,
																]);
														}}
													/>
												</div>
											);
										}
										)}
									</AccordionItem>
								</Accordion>
							</div>
						);
					})}
				</div>
			</Fragment>
		);
	};

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xl" titleId="Nuevo Rol">
			<ModalHeader setIsOpen={setIsOpen} className="p-4">
				<ModalTitle id="new_role">Nuevo Rol</ModalTitle>
			</ModalHeader>
			<form onSubmit={formik.handleSubmit} autoComplete="off">
				<ModalBody className="px-4">{getPermissions()}</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button icon="Save" color="primary" type="submit">
						Guardar Rol
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	)
}

export default RoleCreateModal;