import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import UserForm from "../UserForm";
import { UserService } from "../../../services/users/userService";
import { User } from "../../../type/user-type";
import useFetch from "../../../hooks/useFetch";
import UserEditPermissions from "./UserEditPermissions";
import { RolePermission } from "../../../type/role-type";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

const UserEdit: FC = () => {

  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { userCan } = usePrivilege();

  const [editPermissions, setEditPermissions] = useState<boolean>(false);
  const [userPermissions, setUserPermissions] = useState<RolePermission[] | null>(null);

  const [data, loading, error] = useFetch(useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUserById(id as string);
    return response.getResponseData() as User;
  }, [id]));

  const handleUpdate = async (values: any) => {
    const formData = new FormData();

    for (let value in values) {
      if (values[value]) {
        formData.append(value, values[value]);
      }
    }

    formData.append("user", id as string);

    try {
      const userService = new UserService();
      if (values.password !== "" && values.passwordConfirm !== "") {
        formData.delete("password");
        formData.delete("passwordConfirm");
        const result = await userService.changePassword(id, values.password, values.passwordConfirm);

        if (result.getResponseData().success) {
          toast.success("Contraseña cambiada correctamente");
        } else {
          toast.error("Error al cambiar la contraseña");
        }
      }
      const response = await userService.editUser(formData);
      const responseData = response.getResponseData();

      if (responseData.success) {
        navigate(-1);
        toast.success("Usuario editado correctamente");
      } else {
        responseData.data.errors.forEach((error: any) => {
          toast.error(error.message);
        });
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const getContent = () => {
    if (loading) return <Spinner />;

    if (error) return <ErrorMessage message={error.message} />;

    if (data !== null) {
      const userData = {
        name: data.name !== null ? data.name : "",
        last_name: data.lastName !== null ? data.lastName : "",
        email: data.email !== null ? data.email : "",
        dni: data.dni !== null ? data.dni : "",
        address: data.address !== null ? data.address : "",
        telephone: data.telephone !== null ? data.telephone : "",
        role: data.userRoles[0].role !== null ? data.userRoles[0].role.id : "",
        roleName: data.userRoles[0].role !== null ? data.userRoles[0].role.name : "",
        password: "",
        passwordConfirm: "",
        company: data.userRoles[0].company !== null ? data.userRoles[0].company.id : "",
        companyName: data.userRoles[0].company !== null ? data.userRoles[0].company.name : "",
        birthday_date: data.birthdayDate !== null ? data.birthdayDate.date.slice(0, 10) : "",
        signatureText: data.signatureText !== null ? data.signatureText : "",
        color: data.color !== null ? data.color : "#607d8b",
      };

      return <UserForm isLoading={false} submit={handleUpdate} userData={userData} profileImg={data.profileImg} signatureImg={data.signatureImg} />;
    }
  };

  return (
    <Fragment>
      {
        userCan('edit_permissions', 'user') && (
          <Button
            color="secondary" isLight icon="Security"
            onClick={() => { setEditPermissions(true) }}
          >
            Editar Permisos
          </Button>
        )
      }

      {getContent()}

      {editPermissions && data && (<UserEditPermissions companyId={data.userRoles[0].company?.id} isOpen={editPermissions} setIsOpen={setEditPermissions} userPermissions={data.userPermissions} userId={id} newPermissions={userPermissions} setNewPermissions={setUserPermissions} />)}
    </Fragment>
  );
};

export default UserEdit;