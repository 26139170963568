import { FC, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import Page from "../../layout/Page/Page";
import { useFormik } from "formik";
import * as yup from "yup";
import { Project } from "../../type/project-type";
import useFetch from "../../hooks/useFetch";
import { BudgetService } from "../../services/budgets/budgetService";
import { Budget } from "../../type/budgets-type";
import GeneralProjectInfo from "./components/GeneralProjectInfo";
import moment from "moment";
import LiftForm from "./components/LiftForm";
import { validateCadRef } from "../../utils/ValidationCadRef";
import { validateCif } from "../../utils/ValidationCifNif";

interface ProjectFormProps {
    budgetId: string;
    projectData?: any;
    submit: (values: any) => void;
    isLoading: boolean;
}

const projectInitialValues: Project = {
    company: "",
    budget: "",
    sponsor: null,
    representative: null,
    client: null,
    surveyor: "",
    constructionManager: "",
    planner: "",
    subsidy: "",
    code: null,
    project: {
        type: null,
        cif: "",
        description: "",
        address: "",
        population: "",
        province: "",
        pc: 0,
        constructionYear: moment().year(),
        cad_ref: "",
        pem: 0,
        codeIdentifier: null,
        year: null,
        startDate: "",
        deliveryDate: "",
        enumeration: null,
        gmu: "",
        licenseNumber: "",
        use: "",
        bailNumberRefund: "",
    },
    projectAddress: "",
    projectPopulation: "",
    projectProvince: "",
    projectPC: 0,
    projectConstructionYear: moment().year(),
    projectCadRef: "",
    projectPEM: 0,
    projectStartDate: "",
    projectDeliveryDate: "",
    projectGMU: "",
    projectLicenseNumber: "",
    projectUse: "",
    projectBailNumberRefund: "",
};

const projectValidationSchema = yup.object({
    company: yup.string(),
    budget: yup.string(),
    surveyor: yup.string(),
    constructionManager: yup.string(),
    planner: yup.string().required("Este campo es obligatorio"),
    subsidy: yup.string(),
    project: yup.object().shape({
        cif: yup.string().test('valid-nif', 'El CIF no es válido', (value) => value ? validateCif(value) : true),
        description: yup.string(),
        address: yup.string().required("Este campo es obligatorio"),
        population: yup.string().required("Este campo es obligatorio"),
        province: yup.string().required("Este campo es obligatorio"),
        pc: yup.number().required("Este campo es obligatorio"),
        constructionYear: yup.number().required("Este campo es obligatorio"),
        cad_ref: yup.string(),
        pem: yup.number().required("Este campo es obligatorio"),
        startDate: yup.string().required("Este campo es obligatorio"),
        deliveryDate: yup.string().required("Este campo es obligatorio"),
    }),
    cif: yup.string().test('valid-nif', 'El CIF no es válido', (value) => value ? validateCif(value) : true),
    projectAddress: yup.string().required("Este campo es obligatorio"),
    projectPopulation: yup.string().required("Este campo es obligatorio"),
    projectProvince: yup.string().required("Este campo es obligatorio"),
    projectPC: yup.number().required("Este campo es obligatorio").min(0, 'El mínimo es 0').max(99999, 'El máximo es 99999'),
    projectConstructionYear: yup.number().required("Este campo es obligatorio").min(moment().year() - 100, 'El año mínimo no puede ser anterior a 100 años').max(moment().year() + 100, 'El año máximo no puede ser posterior a 100 años'),
    projectCadRef: yup.string().required("Este campo es obligatorio")/* .test(
        'validateCadRef',
        'El campo debe cumplir el formato específico',
        async function (value: string) {
            const isValid = await validateCadRef(value);
            return isValid;
        }
    ) */,
    projectPEM: yup.number().required("Este campo es obligatorio").min(0).max(999999999),
    projectStartDate: yup.string().required("Este campo es obligatorio"),
    projectDeliveryDate: yup.string().required("Este campo es obligatorio"),
    projectGMU: yup.string(),
    projectLicenseNumber: yup.string(),
    projectUse: yup.string(),
    projectBailNumberRefund: yup.string(),
});

const ProjectForm: FC<ProjectFormProps> = ({ budgetId, projectData, submit, isLoading }) => {

    const navigate = useNavigate();
    const mode = projectData ? "Editar" : "Crear";

    const [budgetData] = useFetch(useCallback(async () => {
        const response = (await (new BudgetService).getBudget(budgetId as string));
        return response.getResponseData() as Budget;
    }, []));

    // Función para cargar el formulario de cada tipo de proyecto
    const getProjectType = () => {
        if (budgetData && budgetData.code.acronym) {
            switch (budgetData.code.acronym) {
                case "IN":
                    return <></>;
                case "AS":
                    //return <LiftForm projectExtraData={projectExtraData} setProjectExtraData={setProjectExtraData} />;
                    return <></>;
                case "RR":
                    return <></>;
                case "AC":
                    return <></>;
                case "AFO":
                    return <></>;
                case "CE":
                    return <></>;
                case "VI":
                    return <></>;
                case "DE":
                    return <></>;
                case "DO":
                    return <></>;
                case "CT":
                    return <></>;
                default:
                    return (<div className="d-flex justify-content-center mt-5">
                        <span className="text-center text-muted fs-3">Este tipo de proyecto no tiene datos extra</span>
                    </div>);
            }
        }
    };

    const verifyClass = (inputFieldID: keyof Project) => { return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : ""; };

    const showErrors = (inputFieldID: keyof Project) => {
        // @ts-ignore
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    const formik = useFormik({
        initialValues: projectData ? projectData : projectInitialValues,
        validationSchema: projectValidationSchema,
        onSubmit: (values) => submit(values),
    });

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Proyecto</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color="primary" onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner /> : `${mode} Proyecto`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <CardBody isScrollable={false}>
                            <GeneralProjectInfo budgetData={budgetData} formik={formik} verifyClass={verifyClass} showErrors={showErrors} mode={mode} />

                            {getProjectType()}
                        </CardBody>
                    </form>
                </Card>
            </Page>
        </Fragment>
    )
}

export default ProjectForm;