export const SuperAdminMenu = {
	title: {
		id: 'title-super-admin',
		text: 'Super Admin',
		icon: 'Extension',
	},
	companies: {
		id: 'companies',
		text: 'Organizaciones',
		path: '/organizaciones',
		icon: 'Business',
		access: {
			group: 'companies',
			action: 'list',
		},
		subMenu: null,
	},
};

export const authMenu = {
	title: {
		id: 'title-admin',
		text: 'Administración',
		icon: 'Extension',
	},
	configuration: {
		id: 'configuration',
		text: 'Configuración',
		icon: 'Settings',
		path: '/configuracion',
		access: {
			group: 'companies',
			action: 'admin_my_company',
		},
		subMenu: null,
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		path: '/roles',
		icon: 'AdminPanelSettings',
		access: {
			group: 'roles',
			action: 'list',
		},
		subMenu: null,
	},
	users: {
		id: 'users',
		text: 'Usuarios',
		path: '/usuarios',
		icon: 'Person',
		access: {
			group: 'user',
			action: 'list',
		},
	},
	tasks: {
		id: 'tasks',
		text: 'Tareas',
		path: '/tareas',
		icon: 'Assignment',
		access: {
			group: 'tasks',
			action: 'list',
		},
		subMenu: null,
	},
	documents: {
		id: 'documents',
		text: 'Documentos',
		path: '/documentos',
		icon: 'Description',
		access: {
			group: 'project_document_type',
			action: 'list',
		},
		subMenu: null,
	},
};

export const ClientMenu = {
	title: {
		id: 'title-client',
		text: 'Clientes',
		icon: 'Extension',
	},
	contacts: {
		id: 'contacts',
		text: 'Contactos',
		path: '/contactos',
		icon: 'ContactPage',
		access: {
			group: 'clients',
			action: 'list',
		},
	},
	clients: {
		id: 'clients',
		text: 'Clientes',
		path: '/clientes',
		icon: 'People',
		access: {
			group: 'clients',
			action: 'list',
		},
	},
	categories: {
		id: 'Category',
		text: 'Categorias',
		path: '/categorias',
		icon: 'Category',
		access: {
			group: 'categories',
			action: 'list',
		},
	},
	strategy: {
		id: 'strategy',
		text: 'Estrategias',
		path: '/estrategias',
		icon: 'EmojiObjects',
		access: {
			group: 'origin',
			action: 'list',
		},
	},
};

export const FinancesMenu = {
	title: {
		id: 'finances-title',
		text: 'Finanzas',
		icon: 'Extension',
	},
	finances: {
		id: 'finances',
		text: 'Finanzas',
		path: '/finanzas',
		icon: 'Extension',
		access: {
			group: 'finances',
			action: 'list',
		},
	},
	budget: {
		id: 'budgets',
		text: 'Presupuestos',
		path: '/finanzas/presupuestos',
		icon: 'CalendarToday',
		access: {
			group: 'budget',
			action: 'list',
		},
	},
	project: {
		id: 'projects',
		text: 'Proyectos',
		path: '/finanzas/proyectos',
		icon: 'Work',
		access: {
			group: 'project',
			action: 'list',
		},
	},
	subsidy: {
		id: 'subsidy',
		text: 'Subvenciones',
		path: '/finanzas/subvenciones',
		icon: 'MonetizationOn',
		access: {
			group: 'subsidy',
			action: 'list',
		},
	},
	status: {
		id: 'status',
		text: 'Estados',
		path: '/finanzas/estados',
		icon: 'AssignmentTurnedIn',
		access: {
			group: 'statuses',
			action: 'list',
		},
	},
	codes: {
		id: 'codes',
		text: 'Códigos',
		path: '/finanzas/codigos',
		icon: 'Code',
		access: {
			group: 'budget_code',
			action: 'list',
		},
	},
	seriesTaxes: {
		id: 'series',
		text: 'Series e impuestos',
		path: '/finanzas/series-impuestos',
		icon: 'Receipt',
		access: {
			group: 'budget_series' || 'budget_taxes',
			action: 'list',
		},
	},
};

export const AccountingPagesMenu = {
	title: {
		id: 'title-accounting',
		text: 'Contabilidad',
		icon: 'Extension',
	},
	income: {
		id: 'income',
		text: 'Ingresos',
		path: '/ingresos',
		icon: 'Payments',
		access: {
			group: 'income',
			action: 'admin_income',
		},
	},
	income_type: {
		id: 'income_type',
		text: 'Tipos de ingresos',
		path: '/tipo-ingresos',
		icon: 'FormatListBulleted',
		access: {
			group: 'income',
			action: 'admin_income',
		},
	},
	cost: {
		id: 'cost',
		text: 'Costes',
		path: '/costes',
		icon: 'RequestQuote',
		access: {
			group: 'costs',
			action: 'admin_costs',
		},
	},
	cost_fixed: {
		id: 'cost_fixed',
		text: 'Costes Fijos',
		path: '/listado-costes-fijos',
		icon: 'ReceiptLong',
		access: {
			group: 'costs_fixed',
			action: 'admin_costs_fixed',
		},
	},
	cost_type: {
		id: 'cost_type',
		text: 'Tipos de costes',
		path: '/tipo-costes',
		icon: 'FormatListBulleted',
		access: {
			group: 'costs',
			action: 'admin_costs',
		},
	},
}

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Panel de control',
		path: '/',
		icon: 'Dashboard',
		access: {
			group: 'companies',
			action: 'show_dashboard',
		},
		subMenu: null,
	},
};

export const calendarMenu = {
	calendar: {
		id: 'calendar',
		text: 'Calendario de tareas',
		path: '/calendario',
		icon: 'CalendarViewDay',
		access: {
			group: 'tasks',
			action: 'list',
		},
		subMenu: null,
	},
};

export const schedulerMenu = {
	scheduler: {
		id: 'scheduler',
		text: 'Planificador',
	},
	cashFlowSchedule: {
		id: 'cash_flow_scheduler',
		text: 'Flujo de efectivo',
		icon: 'Savings',
		path: '/flujo-efectivo',
		access: {
			group: 'cash_flow',
			action: 'admin_cash_flow',
		},
		/* [
			{
				group: 'cash_flow',
				action: 'admin_cash_flow',
			},
			{
				group: 'cash_flow',
				action: 'list',
			},
		], */
		subMenu: null,
	},
	invoicingSchedule: {
		id: 'incomes_scheduler',
		text: 'Plan Ingresos',
		path: '/planificador-ingresos',
		icon: 'CalendarToday',
		access:
		{
			group: 'income',
			action: 'admin_income',
		},
		/* [
			{
				group: 'income',
				action: 'admin_income',
			},
			{
				group: 'income',
				action: 'list',
			},
		], */
		subMenu: null,
	},
	costsSchedule: {
		id: 'costs_scheduler',
		text: 'Plan Costes',
		path: '/plan-costes',
		icon: 'CalendarToday',
		access:
		{
			group: 'costs',
			action: 'admin_costs',
		},
		/* [
			{
				group: 'costs',
				action: 'admin_costs',
			},
			{
				group: 'costs',
				action: 'list',
			},
		], */
		subMenu: null,
	},
};