import React, { useEffect, useState } from "react";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../components/bootstrap/OffCanvas";
import { TasksCalendarFilters } from "./TasksCalendarFilters";
import './TaskCanvas.css';

interface TasksFiltersCanvasProps {
    setOpen: (status: boolean) => void;
    isOpen?: boolean;
}

export const TasksFiltersCanvas: React.FC<TasksFiltersCanvasProps> = ({ setOpen, isOpen }) => {

    const [isOpened, setIsOpened] = useState<boolean>(isOpen ? isOpen : false);

    useEffect(() => {
        if (isOpen === undefined) return;
        setIsOpened(isOpen);
    }, [isOpen]);

    return (
        <>
            <OffCanvas
                id="task-filters-canvas"
                setOpen={setOpen}
                isOpen={isOpened}
                titleId='task-filters-canvas-title'>
                <OffCanvasHeader
                    setOpen={setOpen}
                    className='p-4'>
                    <OffCanvasTitle id='task-filters-canvas-title'>
                        Filtros de tareas
                    </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody className='ps-3 pe-3'>
                    <TasksCalendarFilters />
                </OffCanvasBody>
            </OffCanvas>
        </>
    )
}