import { FC, useEffect, useState } from "react";
import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { BudgetService } from "../../../services/budgets/budgetService";
import { CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import { FinancesMenu } from "../../../menu";
import moment from "moment";
import { CustomTable } from "../../../components/table/CustomTable";
import Icon from "../../../components/icon/Icon";
import StageConceptsModal from "./StageConceptsModal";
import { toast } from "react-toastify";
import Button from "../../../components/bootstrap/Button";
import { IconBrandGmail } from "@tabler/icons-react";
import Checks from "../../../components/bootstrap/forms/Checks";
import IconWithTooltip from "../../../components/IconWithTooltip";

interface StagesInvoicesProps {
    id: string;
}

const StagesInvoices: FC<StagesInvoicesProps> = ({ id }) => {

    const budgetService = new BudgetService();

    const [budgetId, setBudgetId] = useState<string>('');
    const [budgetName, setBudgetName] = useState<string>('');
    const [budgetConcepts, setBudgetConcepts] = useState<any[]>([]);
    const [openConceptsModal, setOpenConceptsModal] = useState<boolean>(false);
    const [spinnerMail, setSpinnerMail] = useState<boolean>(false);
    const [paidStages, setPaidStages] = useState<{ id: string, isPaid: boolean }[]>([]);

    const [invoices, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await budgetService.getBudgetStages(id);
        return response.getResponseData();
    }, [id]));

    const sendMailToClient = async (budgetId: string, stageId: string) => {
        try {
            const response = (await (new BudgetService).sendInvoiceToClient(budgetId, stageId)).getResponseData();
            if (response.message && response.message !== 'El cliente no tiene email') {
                setSpinnerMail(false);
                toast.success('Correo enviado correctamente');
                refetch();
            } else {
                setSpinnerMail(false);
                toast.error(response.message || 'Error al enviar el correo');
            }
        } catch (error: any) {
            setSpinnerMail(false);
            toast.error('Error al enviar el correo');
        }
    };

    const downloadInvoice = async (budgetId: string, stageId: string, address: string, name: string) => {
        try {
            const response = (await (new BudgetService).downloadInvoice(budgetId, stageId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name + '_' + address + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar la factura');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al descargar la factura');
        }
    };

    // Cambia el estado de la fase
    const _handleIsPaid = async (stageId: string) => {
        try {
            const response = await (await (new BudgetService).togglePaidStage(stageId)).getResponseData();
            if (response.success) {
                const stage = paidStages.find((stage) => stage.id === stageId);

                // Si existe la fase en el array de paidStages, se cambia el estado
                if (stage) {
                    const newPaidStages = paidStages.map((stage) => {
                        if (stage.id === stageId) {
                            return { id: stageId, isPaid: !stage.isPaid };
                        } else {
                            return stage;
                        }
                    });
                    setPaidStages(newPaidStages);
                } else {
                    const newPaidStages = [...paidStages, { id: stageId, isPaid: true }];
                    setPaidStages(newPaidStages);
                }

                refetch();
                toast.success('Estado de la fase cambiado correctamente');
            } else {
                toast.error(response.message || "Error al cambiar el estado de la fase");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Comprueba si la fase está pagada
    const stageIsPaid = (stageId: string) => {
        const stage = paidStages.find((stage: any) => stage.id === stageId);
        return stage ? stage.isPaid : false;
    };

    // Comprueba si la fecha de la fase ha pasado para ocultar el botón de descargar factura
    function hasDatePassed(targetDate: string): boolean {
        // Convierte la cadena de fecha en un objeto Date
        const targetDateTime = new Date(targetDate);

        // Obtiene la fecha y hora actual
        const currentDateTime = new Date();

        // Compara las fechas
        return targetDateTime < currentDateTime;
    };

    useEffect(() => {
        // Si hay facturas, se guarda el id y el nombre del presupuesto
        if (invoices && invoices[0].budget && invoices[0].stageHasConcept) {
            setBudgetId(invoices[0].budget.id);
            setBudgetName(invoices[0].budget.codeIdentifier);
        }

        // Si hay facturas, se recorre el array y se guarda en el estado si está pagada o no
        if (invoices) {
            let newPaidStages: { id: string, isPaid: boolean }[] = [];

            invoices?.forEach((stage: any) => {
                newPaidStages.push({ id: stage.id, isPaid: stage.isPaid });
            });

            setPaidStages(newPaidStages);
        }
    }, [invoices]);

    return (
        <>
            {error && <ErrorMessage />}

            <>
                <CardHeader>
                    <CardTitle className="cursor-pointer">
                        <a href={`${FinancesMenu.finances.path}/${budgetId}/vista`}>Presupuesto {budgetName}</a>
                    </CardTitle>
                </CardHeader>
                <CustomTable
                    title="Fases"
                    loading={loading}
                    data={invoices || null}
                    pagination={false}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: 'Nombre',
                            keyValue: 'name',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Conceptos',
                            keyValue: 'concepts',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    element.stageHasConcept.length > 0
                                        ? (<div className="text-center cursor-pointer"
                                            onClick={() => {
                                                setBudgetConcepts(element.stageHasConcept);
                                                setOpenConceptsModal(true);
                                            }}
                                        >
                                            <Icon icon="RemoveRedEye" />
                                        </div>)
                                        : <div className="text-center">-</div>
                                )
                            },
                        },
                        {
                            name: 'Fecha de subida',
                            keyValue: 'createdAt',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {moment(element.createdAt.date).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Fecha fin de fase',
                            keyValue: 'endStageDate',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.endStageDate ? moment(element.endStageDate.date).format('DD/MM/YYYY HH:mm') : "-"}
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Correo enviado',
                            keyValue: 'isSent',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {
                                            element.isSent
                                                ? moment(element.sendDate.date).format('DD/MM/YYY HH:mm')
                                                : hasDatePassed(invoices?.endStageDate?.date)
                                                    ? (
                                                        <Button
                                                            color='dark' isLight className="p-2" title="Enviar al cliente"
                                                            onClick={() => {
                                                                setSpinnerMail(true);
                                                                sendMailToClient(element.budget.id, element.id);
                                                            }}
                                                        >
                                                            {spinnerMail ? <Spinner size={18} key={element.id} /> : <IconBrandGmail size={18} />}
                                                        </Button>
                                                    )
                                                    : (
                                                        <IconWithTooltip label="La fecha de la fase no ha pasado" icon="Info" />
                                                    )
                                        }
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Pagado',
                            keyValue: 'isPaid',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <Checks
                                            type="switch"
                                            checked={stageIsPaid(element.id)}
                                            onChange={() => { _handleIsPaid(element.id) }}
                                        />
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Factura',
                            keyValue: 'invoice',
                            isVisible: /* invoices.budgetStatus?.finalStatus &&  */hasDatePassed(invoices?.endStageDate?.date),
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {
                                            hasDatePassed(invoices?.endStageDate?.date)
                                                ? (
                                                    <Button
                                                        color='dark' isLight className="p-2" title="Descargar factura de la fase"
                                                        onClick={() => { downloadInvoice(element.budget.id, element.id, element.budget.address, element?.invoices[0]?.invoiceIdentifier) }}
                                                    >
                                                        <Icon icon='SimCardDownload' size={'lg'} />
                                                    </Button>
                                                )
                                                : <></>
                                        }
                                    </div>
                                )
                            }
                        },
                    ]}
                />
                {openConceptsModal && <StageConceptsModal concepts={budgetConcepts} openModal={openConceptsModal} setOpenModal={setOpenConceptsModal} />}
            </>
        </>
    )
}

export default StagesInvoices;