import React, { useCallback, useContext, useEffect, useState } from "react";
import useFilters, { FilterOptions, FilterOrders } from "../../../../hooks/useFilters";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { changeStorageViewMode } from "../../../../redux/schedulerSlice";
import useFetch from "../../../../hooks/useFetch";
import { CostService } from "../../../../services/costs/costService";
import moment from "moment";

type CostsProviderProps = {
    children: React.ReactNode,
    defaultFilters?: any,
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
}

type CostsContextData = {
    viewMode: "Mes" | "Semana" | "Día",
    changeViewMode: (view: "Mes" | "Semana" | "Día") => void,
    filters: FilterOptions | any,
    updateFilters: (filters: any) => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    isLoading: boolean,
    setIsLoading: (loading: boolean) => void,
    fromDate: string,
    setFromDate: (date: string) => void,
    toDate: string,
    setToDate: (date: string) => void,
    costsList: any,
    costsTypesList: any,
    refetchCosts: () => void,
}

const CostsContext: React.Context<CostsContextData> = React.createContext<CostsContextData>({
    viewMode: 'Día',
    changeViewMode: (view: "Mes" | "Semana" | "Día") => { },
    filters: {} as FilterOptions | any,
    updateFilters: (filters: any) => { },
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    isLoading: false,
    setIsLoading: (loading: boolean) => { },
    fromDate: '',
    setFromDate: (date: string) => { },
    toDate: '',
    setToDate: (date: string) => { },
    costsList: null,
    costsTypesList: null,
    refetchCosts: () => { },
});

const CostsProvider: React.FC<CostsProviderProps> = ({ children, defaultFilters, defaultOrders = [] as FilterOrders, defaultPage = 1, defaultPageSize = 999999 }) => {

    //  Redux connection
    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.scheduler.viewMode);
    const costFilters = useSelector((state: RootState) => state.scheduler.costFilters);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [costsList, setCostsList] = useState<any>(null);
    const [fromDate, setFromDate] = useState<string>(costFilters?.estimated_entry_date?.from || moment().subtract(2, 'months').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<string>(costFilters?.estimated_entry_date?.to || moment().add(2, 'months').format('YYYY-MM-DD'));

    const [regularCostsList, loadingCosts, costsError, refetchCosts] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) return null;
        setIsLoading(true);
        const response = await (new CostService).getCosts(filters);
        setIsLoading(false);
        return response.getResponseData() as any;
    }, [filters]));

    const [costsTypesList, loadingCostsTypes, costsTypesError] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) return null;
        setIsLoading(true);
        const response = await (new CostService).getCostTypes(filters);
        setIsLoading(false);
        return response.getResponseData() as any;
    }, [filters]));

    const changeViewMode = (view: "Mes" | "Semana" | "Día") => {
        dispatch(changeStorageViewMode(view));
    };

    /**
     * Update costs list when filters change
     */
    useEffect(() => {
        if (regularCostsList && regularCostsList.costs) {
            setCostsList({ costs: regularCostsList.costs });
        }
    }, [regularCostsList]);

    useEffect(() => {
        if (viewMode) {
            switch (viewMode) {
                case 'Mes':
                    updateFilters({ range_mode_view: 'month' });
                    break;
                case 'Semana':
                    updateFilters({ range_mode_view: 'week' });
                    break;
                case 'Día':
                    updateFilters({ range_mode_view: 'day' });
                    break;
                default:
                    break;
            }
        }
    }, [viewMode]);

    useEffect(() => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) {
            updateFilters({ estimated_entry_date: { from: fromDate, to: toDate } });
        }
    }, [costFilters]);

    return (
        <CostsContext.Provider value={{
            viewMode,
            changeViewMode,
            filters,
            updateFilters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,
            isLoading,
            setIsLoading,
            fromDate,
            setFromDate,
            toDate,
            setToDate,
            costsList,
            costsTypesList,
            refetchCosts,
        }}>
            {children}
        </CostsContext.Provider>
    );
}

export { CostsProvider, CostsContext };

export function useCosts() {
    return useContext(CostsContext);
}