import { Fragment, useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { StatusService } from "../../../services/status/statusService";
import { StatusesApiResponse } from "../../../type/status-type";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import { toast } from "react-toastify";
import StatusModalForm from "../StatusModalForm";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../components/table/CustomTable";
import ErrorMessage from "../../../components/ErrorMessage";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import Tooltips from "../../../components/bootstrap/Tooltips";
import Icon from "../../../components/icon/Icon";
import classNames from "classnames";
import ColorPicker from "../../../components/ColorPicker";
import Page from "../../../layout/Page/Page";
import StatusFilters from "./status-options/StatusFilters";
import useHandleErrors from "../../../hooks/useHandleErrors";

export interface IStatusFilters {
    company?: string;
    oriented_to?: string;
    minOrder?: number;
    maxOrder?: number;
}

const statusFilters: IStatusFilters = {
    company: '',
    oriented_to: 'project',
    minOrder: 0,
    maxOrder: 100,
};

const StatusList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const statusService = new StatusService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(statusFilters);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await statusService.listStatuses(filters);
        return response.getResponseData() as StatusesApiResponse;
    }, [filters]));

    const getOrientedTo = (orientedTo: string) => {
        switch (orientedTo) {
            case 'project':
                return 'Proyecto';
            case 'budget':
                return 'Presupuesto';
            default:
                return '';
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            setSelectedItem(null);
            setShow(false);
            setModalOpen(false);
            refetch();
            toast.success(message);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    const _setOrder = async (id: string, order: number) => {
        const response = await (await (new StatusService()).setOrderStatus(id, order)).getResponseData();

        if (response.success) {
            toast.success('Orden actualizado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const _setMainStatus = async (id: string) => {
        const response = await (await (new StatusService()).setMainStatus(id)).getResponseData();

        if (response.success) {
            toast.success('Estado establecido como inicial');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const _setFinalStatus = async (id: string) => {
        const response = await (await (new StatusService()).setFinalStatus(id)).getResponseData();

        if (response.success) {
            toast.success('Estado establecido como final');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const _setHasGMU = async (id: string) => {
        const response = await (await (new StatusService()).setHasGMU(id)).getResponseData();

        if (response.success) {
            toast.success('Estado establecido como generador de GMU');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const _setHasLicenseNum = async (id: string) => {
        const response = await (await (new StatusService()).setHasLicenseNum(id)).getResponseData();

        if (response.success) {
            toast.success('Estado establecido como generador de número de licencia');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const _setHasFileNumberUse = async (id: string) => {
        const response = await (await (new StatusService()).setHasFileNumberUse(id)).getResponseData();

        if (response.success) {
            toast.success('Estado establecido como generador de expediente de utilización');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const _setHasBailNumberRefund = async (id: string) => {
        const response = await (await (new StatusService()).setHasBailNumberRefund(id)).getResponseData();

        if (response.success) {
            toast.success('Estado establecido como generador de número de devolución de fianza');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const _setStatusColor = async (values: any) => {
        const response = await (await (new StatusService()).editStatus(values)).getResponseData();

        if (response.success) {
            toast.success('Color actualizado correctamente');
        } else {
            handleErrors(response);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await statusService.deleteStatus(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Estado eliminado correctamente');
                }, 100);
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage />;

        if (data) {
            let apiData = data as StatusesApiResponse;

            return (
                <CustomTable
                    title="Estados"
                    data={apiData ? apiData.statuses : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as StatusesApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: '',
                            keyValue: 'setOrder',
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center w-50">
                                        <Tooltips title="Subir de posición">
                                            <Button
                                                type={"button"}
                                                isLight isLink
                                                onClick={() => {
                                                    if (item.orderStatus === 1) return;
                                                    _setOrder(item.id, item.orderStatus - 1);
                                                }}
                                                className={classNames({ 'cursor-na': item.orderStatus === 1 }, 'w-auto')}
                                            >
                                                {(item.orderStatus > 1) && <Icon icon='KeyboardArrowUp' color="dark" size='2x' />}
                                            </Button>
                                        </Tooltips>
                                        <Tooltips title="Bajar de posición">
                                            <Button
                                                type={"button"}
                                                isLight isLink
                                                onClick={() => {
                                                    if (item.orderStatus === data?.totalRegisters) return;
                                                    _setOrder(item.id, item.orderStatus + 1);
                                                }}
                                                className={classNames({ 'cursor-na': item.orderStatus === data?.totalRegisters }, 'w-auto')}
                                            >
                                                {(item.orderStatus < data?.totalRegisters) && <Icon icon='KeyboardArrowDown' color="dark" size='2x' />}
                                            </Button>
                                        </Tooltips>
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Orden',
                            keyValue: 'orderStatus',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {item.orderStatus}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Nombre",
                            keyValue: "name",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-center" onClick={() => {
                                        setSelectedItem(element);
                                        setModalOpen(true);
                                        setShow(false);
                                    }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: userCan('list', 'companies', true) ? "text-center" : "d-none",
                            isVisible: userCan('list', 'companies', true),
                            render: (item: any) => {
                                return (
                                    <div key={item.id} className="d-flex justify-content-center">
                                        {item.company.name}
                                    </div>
                                )
                            }
                        },
                        {
                            name: 'Perteneciente a',
                            keyValue: 'oriented_to',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {getOrientedTo(item.orientedTo)}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Inicial",
                            keyValue: "main_status",
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <Tooltips title={item.mainStatus ? `Es el estado inicial de ${getOrientedTo(item.orientedTo)}` : "Establecer este estado como inicial"}>
                                            <Button
                                                type={"button"}
                                                isLight isLink
                                                onClick={() => {
                                                    if (item.mainStatus) return;
                                                    _setMainStatus(item.id);
                                                }}
                                            //className={classNames({ 'cursor-na': item.mainStatus }, 'w-auto')}
                                            >
                                                {
                                                    item.mainStatus
                                                        ? <Icon icon={'star'} color={"secondary"} size={'2x'} />
                                                        : <Icon icon={'starOutline'} color={"light"} size={'2x'} />
                                                }
                                            </Button>
                                        </Tooltips>

                                    </div>
                                );
                            },
                        },
                        {
                            name: "Final",
                            keyValue: "final_status",
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <Tooltips title={item.finalStatus ? `Es el estado final de ${getOrientedTo(item.orientedTo)}` : "Establecer este estado como final"}>
                                            <Button
                                                type={"button"}
                                                isLight isLink
                                                onClick={() => {
                                                    if (item.finalStatus) return;
                                                    _setFinalStatus(item.id);
                                                }}
                                            //className={classNames({ 'cursor-na': item.mainStatus }, 'w-auto')}
                                            >
                                                {
                                                    item.finalStatus
                                                        ? <Icon icon={'SportsScore'} color={"secondary"} size={'2x'} />
                                                        : <Icon icon={'SportsScore'} color={"light"} size={'2x'} />
                                                }
                                            </Button>
                                        </Tooltips>

                                    </div>
                                );
                            },
                        },
                        {
                            name: "GMU",
                            keyValue: "file_number_gmu",
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <Button
                                            type={"button"} isLight isLink
                                            onClick={() => {
                                                if (item.generateFileNumberGMU) return;
                                                _setHasGMU(item.id);
                                            }}
                                        >
                                            {item.generateFileNumberGMU
                                                ? <Icon icon={'CheckBox'} color={"secondary"} size={'2x'} />
                                                : <Icon icon={'CheckBoxOutlineBlank'} color={"light"} size={'2x'} />
                                            }
                                        </Button>

                                    </div>
                                );
                            },
                        },
                        {
                            name: "Nº de licencia",
                            keyValue: 'license_number',
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <Button
                                            type={"button"} isLight isLink
                                            onClick={() => {
                                                if (item.generateLicenseNumber) return;
                                                _setHasLicenseNum(item.id);
                                            }}
                                        >
                                            {item.generateLicenseNumber
                                                ? <Icon icon={'CheckBox'} color={"secondary"} size={'2x'} />
                                                : <Icon icon={'CheckBoxOutlineBlank'} color={"light"} size={'2x'} />
                                            }
                                        </Button>

                                    </div>
                                );
                            },
                        },
                        {
                            name: "Nº devolución fianza",
                            keyValue: 'bail_number_refund',
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <Button
                                            type={"button"} isLight isLink
                                            onClick={() => {
                                                if (item.generateBailNumberRefund) return;
                                                _setHasBailNumberRefund(item.id);
                                            }}
                                        >
                                            {item.generateBailNumberRefund
                                                ? <Icon icon={'CheckBox'} color={"secondary"} size={'2x'} />
                                                : <Icon icon={'CheckBoxOutlineBlank'} color={"light"} size={'2x'} />
                                            }
                                        </Button>

                                    </div>
                                );
                            },
                        },
                        {
                            name: "Expdte. utilización",
                            keyValue: 'file_number_use',
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <Button
                                            type={"button"} isLight isLink
                                            onClick={() => {
                                                if (item.generateFileNumberUse) return;
                                                _setHasFileNumberUse(item.id);
                                            }}
                                        >
                                            {item.generateFileNumberUse
                                                ? <Icon icon={'CheckBox'} color={"secondary"} size={'2x'} />
                                                : <Icon icon={'CheckBoxOutlineBlank'} color={"light"} size={'2x'} />
                                            }
                                        </Button>

                                    </div>
                                );
                            },
                        },
                        {
                            name: "Color",
                            keyValue: "color",
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <ColorPicker item={item} color={item.color} handleSave={(e: any) => { _setStatusColor(e) }} disabled={false} filters={filters} />
                                    </div>
                                );
                            },
                        },
                        /* {
                            name: 'Descripción',
                            keyValue: 'description',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        }, */
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Editar",
                                buttonType: 'icon',
                                icon: "Edit",
                                additionalClasses: `text-primary ${userCan('edit', 'statuses') ? '' : 'd-none'}`,
                                description: "Editar estado",
                                callback: (item: any) => {
                                    if (!userCan('edit', 'statuses')) return;
                                    setSelectedItem(item);
                                    setModalOpen(true);
                                    setShow(false);
                                },
                            },
                            {
                                title: "Eliminar",
                                buttonType: 'icon',
                                icon: "Delete",
                                additionalClasses: `text-danger ${userCan('delete', 'statuses') ? '' : 'd-none'}`,
                                description: "Eliminar estado",
                                callback: (item: any) => {
                                    if (!userCan('delete', 'statuses')) return;
                                    handleConfirmationAlert({
                                        title: "Eliminar estado",
                                        text: "¿Está seguro que desea eliminar el estado?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        }
                                    })
                                },
                            },
                            {
                                title: "Sin permisos",
                                buttonType: 'icon',
                                icon: "Block",
                                additionalClasses: `text-danger ${(userCan('edit', 'statuses') || userCan('delete', 'statuses')) ? 'd-none' : ''}`,
                                description: "No tiene permisos para editar ni eliminar",
                                callback: (item: any) => { },
                            }
                        ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Estados</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="primary"
                        title="Crear estado"
                        icon="Add"
                        isLight
                        className={userCan('create', 'statuses') ? '' : 'd-none'}
                        onClick={() => {
                            if (!userCan('create', 'statuses')) return;
                            setModalOpen(true);
                            setSelectedItem(null);
                            setShow(false);
                        }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <StatusFilters filters={filters} updateFilters={updateFilters} updatePageSize={updatePageSize} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch>
                    <CardBody className="table-responsive">
                        <>{getContent()}</>
                    </CardBody>
                </Card>
            </Page>

            {modalOpen && (<StatusModalForm isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />)}
        </Fragment>
    )
}

export default StatusList;