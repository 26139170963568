import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import ProjectList from "./projects-list/ProjectList";

const ProjectsListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <ToastContainer />
                <ProjectList />
            </FiltersProvider>
        </Fragment>
    )
}

export default ProjectsListWrapper;