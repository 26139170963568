import React, { useEffect, useState } from 'react'

type Props = {
    placeholder: string,
    onSearch(value: string): void,
    value?: string,
    onChange?: (value: string) => void
}

const CustomSearchInput: React.FC<Props> = ({ placeholder, onSearch, value, onChange }) => {

    const [searchValue, setSearchValue] = useState<string>("");
    const [isMounted, setIsMounted] = useState<boolean>(false);

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
            return;
        }
        const delaySearch = setTimeout(() => {
            onSearch(searchValue);
        }, 800);

        return () => clearTimeout(delaySearch);
    }, [searchValue])


    return (
        <div>
            <input className='form-control form-control-solid' placeholder={placeholder} onChange={(e) => {
                onChange && onChange(e.target.value);
                setSearchValue(e.target.value);
            }} value={value} />
        </div>
    )
}

CustomSearchInput.defaultProps = {

}

export default CustomSearchInput;