import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const TASK_ENDPOINT = '/tasks';

export class TaskService extends RestServiceConnection {

    listTasks = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TASK_ENDPOINT + '/list',
            data: filters
        }, true) as AxiosResponse;
        return this;
    }

    createTask = async (task: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TASK_ENDPOINT + '/create',
            data: task
        }, true) as AxiosResponse;
        return this;
    }

    editTask = async (task: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TASK_ENDPOINT + '/edit',
            data: task
        }, true) as AxiosResponse;
        return this;
    }

    deleteTask = async (task: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TASK_ENDPOINT + '/delete',
            data: { task }
        }, true) as AxiosResponse;
        return this;
    }

    updateTaskStatus = async (task: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TASK_ENDPOINT + '/toggle',
            data: { task }
        }, true) as AxiosResponse;
        return this;
    }

}