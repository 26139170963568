import { toast } from "react-toastify";

export default function useHandleDownloadDocument() {

    const handleDownloadDocument = async (service: any, documentId: string, documentName: string) => {
        try {
            const response = (await service(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    return { handleDownloadDocument };
}