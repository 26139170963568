import { useFormik } from "formik";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button from "../../components/bootstrap/Button";
import { LoginContainer } from "./LoginContainer";
import { LoginService } from "../../services/auth/loginService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('La contraseña es obligatoria').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
    repeatPassword: Yup.string().required('Repita la contraseña').oneOf([Yup.ref('password'), ''], 'Las contraseñas no coinciden'),
});

const ResetPassword = () => {
    const navigate = useNavigate();

    // Controlar el envío del formulario
    const handleSubmit = async (password: string, repeatPassword: string) => {
        if (password === repeatPassword) {
            const urlString = window.location.href.split('/').pop() || '';
            const token = urlString.substring(urlString.indexOf("token=") + 6);

            try {
                const response = await (new LoginService()).resetPassword(password, repeatPassword, token);

                if (response.data.success) {
                    navigate('/login');
                    setTimeout(() => {
                        toast.success(response.data.message);
                    }, 100);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error('Error al cambiar la contraseña: ' + error);
            }
        } else {
            toast.error('Las contraseñas no coinciden');
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: (values) => {
            handleSubmit(values.password, values.repeatPassword);
        },
    });

    return (
        <LoginContainer>
            <form onSubmit={formik.handleSubmit} className='row g-4' autoComplete="off">

                <div className='col-12'>
                    <FormGroup id='password' isFloating label='Nueva Contraseña'>
                        <Input
                            type='password'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={() => { formik.setErrors({}) }}
                            isTouched={formik.touched.password}
                            invalidFeedback={formik.errors.password}
                            isValid={formik.isValid}
                        />
                    </FormGroup>
                </div>
                <div className='col-12'>
                    <FormGroup id='repeatPassword' isFloating label='Repetir Contraseña' className='mt-3'>
                        <Input
                            type='password'
                            value={formik.values.repeatPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isTouched={formik.touched.repeatPassword}
                            invalidFeedback={formik.errors.repeatPassword}
                            isValid={formik.isValid}
                        />
                    </FormGroup>
                </div>
                <div className='col-12 mt-5'>
                    <Button color='primary' className='w-100 py-3' type='submit'>
                        CAMBIAR CONTRASEÑA
                    </Button>
                </div>
            </form>
        </LoginContainer>
    );
}

export default ResetPassword;