import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import StatusDropdown from "../../../components/StatusDropdown";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import { UserService } from "../../../services/users/userService";
import UsersFilters from "./users-options/UsersFilters";
import useFetch from "../../../hooks/useFetch";
import { UsersApiResponse } from "../../../type/user-type";
import useFilters from "../../../hooks/useFilters";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import AsyncImg from "../../../components/AsyncImg";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import ColorPicker from "../../../components/ColorPicker";
import useHandleErrors from "../../../hooks/useHandleErrors";

export interface IUserFilters {
  role?: any;
  active?: number;
}

const userFilters: IUserFilters = {
  role: '',
  active: 1,
};

const UsersList = () => {

  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const { handleErrors } = useHandleErrors();
  const userService = new UserService();

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(userFilters);

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await userService.getUsers(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]));

  const toggleUserStatus = async (id: string, status: boolean, toggleStatus: Function) => {
    try {
      setChangingStatus([...changingStatus, id]);
      const response = (await userService.toggleUserStatus(id, status)).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success(status ? 'Se ha activado el usuario' : 'Se ha desactivado el usuario');
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toast.error(response.message);
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
      toast.error(error.message);
    }
  };

  const _setStatusColor = async (values: any) => {
    const response = await (await userService.editUser(values)).getResponseData();

    if (response.success) {
      toast.success('Color actualizado correctamente');
    } else {
      handleErrors(response);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await (await (new UserService()).deleteUser(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success('Usuario eliminado');
        }, 100);
      } else {
        toast.error(response.message || "Error al eliminar el usuario");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data) {
      let apiData = data as UsersApiResponse;

      return (
        <CustomTable
          title="Usuarios"
          loading={loading}
          data={apiData.users ? apiData.users : null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: apiData as UsersApiResponse ? apiData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "",
              keyValue: "profileImg",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className='user-avatar'>
                      <AsyncImg id={element.profileImg ? element.profileImg.id : null} />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="text-primary cursor-pointer" onClick={() => { navigate(element.id + '/perfil') }}>
                    {element.name}
                  </div>
                )
              },
            },
            {
              name: "Apellidos",
              keyValue: "lastName",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>{element.lastName || "-"}</>
                );
              },
            },
            {
              name: "Rol",
              keyValue: "role",
              className: "text-center",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>{element.userRoles[0]?.role?.name || "-"}</>
                );
              },
            },
            {
              name: "Correo electrónico",
              keyValue: "email",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>{element.email || "-"}</>
                );
              }
            },
            {
              name: "DNI",
              keyValue: "dni",
              className: "text-center",
              render: (element: any) => {
                return (
                  <>{element.dni || "-"}</>
                );
              }
            },
            {
              name: "Teléfono",
              keyValue: "telephone",
              className: "text-center",
              render: (element: any) => {
                return (
                  <>{element.telephone || "-"}</>
                );
              }
            },
            {
              name: "Dirección",
              keyValue: "address",
              className: "text-center",
              render: (element: any) => {
                return (
                  <>{element.address || "-"}</>
                );
              },
            },
            {
              name: "Color",
              keyValue: "color",
              className: "text-center",
              render: (item: any) => {
                return (
                  <div className="d-flex justify-content-center" key={item.id}>
                    <ColorPicker user={item} color={item.color} handleSave={(e: any) => { _setStatusColor(e) }} disabled={false} filters={filters} />
                  </div>
                );
              },
            },
            {
              name: "Organización",
              keyValue: "organization",
              className: (userCan("list", "companies") ? "text-center" : "d-none"),
              isVisible: (userCan("list", "companies") ? true : false),
              render: (element: any) => {
                return (
                  <>
                    {element.userRoles[0]?.company?.name || "-"}
                  </>
                );
              },
            },
            {
              name: "Estado",
              keyValue: "active",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center" key={element.id}>
                    <StatusDropdown
                      disabled={changingStatus.includes(element.id)}
                      itemId={element.id}
                      status={element.active}
                      change={toggleUserStatus}
                    />
                  </div>
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={[
            {
              title: "Editar",
              buttonType: 'icon',
              icon: "Edit",
              additionalClasses: `text-primary ${userCan("edit", "user") ? "" : "d-none"}`,
              description: "Editar perfil de usuario",
              callback: (item: any) => {
                if (userCan("edit", "user")) return navigate(`${item.id}/edicion`);
              },
            },
            {
              title: "Eliminar",
              buttonType: 'icon',
              icon: "Delete",
              additionalClasses: `text-danger ${userCan("delete", "user") ? "" : "d-none"}`,
              description: "Eliminar usuario",
              callback: (item: any) => {
                if (!userCan("delete", "user")) return;
                handleConfirmationAlert({
                  title: "Eliminar usuario",
                  text: "¿Está seguro que desea eliminar el usuario?",
                  icon: "warning",
                  onConfirm: () => {
                    handleDelete(item.id);
                  }
                })
              },
            },
            {
              title: "Sin permisos",
              buttonType: 'icon',
              icon: "Block",
              additionalClasses: `text-danger ${(userCan('edit', 'user') || userCan('delete', 'user')) ? 'd-none' : ''}`,
              description: "No tiene permisos para editar ni eliminar",
              callback: (item: any) => { },
            }
          ]}
        />
      );
    }
  };

  return (
    <>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Usuarios</CardTitle>
          <SubheaderSeparator />
          <Button
            color="primary" icon="Add" isLight title="Crear usuario"
            className={userCan("create", "user") ? "" : "d-none"}
            onClick={() => {
              if (!userCan("create", "user")) return;
              navigate("creacion");
            }}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <UsersFilters updateFilters={updateFilters} updatePageSize={updatePageSize} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card>
          <CardBody className="table-responsive">
            <>{getContent()}</>
          </CardBody>
        </Card>
      </Page>
    </>
  );
};

export default UsersList;