/*  eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import ReactSelect from 'react-select'
import { KTSVG } from '../KTSVG';
import './Pagination.css';

type Props = {
    pageCount: number,
    currentPage: number,
    rangeDisplayed?: number,
    handlePagination: Function,
    handlePerPage: Function
    defaultPerPage?: number,
}

const Pagination: React.FC<Props> = ({ pageCount, currentPage, rangeDisplayed, handlePagination, handlePerPage, defaultPerPage }) => {

    const pageSizeOptions = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 500, label: '500' },
    ];

    useEffect(() => {
        if (defaultPerPage) {
            handlePerPage(defaultPerPage);
        }
    }, [defaultPerPage]);

    return (
        <div className='d-flex flex-stack flex-wrap justify-content-end mt-4'>
            <div className='d-flex align-self-center'>
                <ReactPaginate
                    key={pageCount + '-pagination'}
                    pageCount={pageCount}
                    pageRangeDisplayed={rangeDisplayed}
                    initialPage={(pageCount === 0) ? 0 : (currentPage - 1)}
                    onPageChange={page => handlePagination(page)}
                    activeLinkClassName={'active'}
                    nextClassName={'page-item'}
                    previousClassName={'page-item'}
                    className={'pagination me-4'}
                    pageClassName={'page-item'}
                    activeClassName={'active'}
                    nextLabel={<KTSVG path={'/duotune/arrows/arr023.svg'} />}
                    previousLabel={<KTSVG path={'/duotune/arrows/arr022.svg'} />}
                    nextLinkClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    breakLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                />

                <ReactSelect
                    menuPlacement='top'
                    onChange={e => handlePerPage(e?.value)}
                    options={pageSizeOptions}
                    defaultValue={defaultPerPage ? pageSizeOptions.find(e => e.value === defaultPerPage) : pageSizeOptions[0]}
                    className="sm"
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            borderRadius: '10px',
                            boxShadow: state.isFocused ? '0 0 0 3px rgba(215, 64, 86, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                            border: '0px !important',
                            backgroundColor: '#f8f9fa',
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#d74056' : 'white',
                            color: state.isFocused ? 'white' : 'black',
                            '&:hover': {
                                backgroundColor: '#d74056',
                                color: 'white',
                                borderColor: '#d74056 !important'
                            }
                        })
                    }}
                    placeholder='Registros ...'
                />
            </div>
        </div>
    );
}

Pagination.defaultProps = {
    rangeDisplayed: 2
};

export { Pagination };