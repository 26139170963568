import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const COMPANY_ENDPOINT = '/companies';

export class CompanyService extends RestServiceConnection {

    getCompanies = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/list',
            data: filters ? filters : {},
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    getOrganizations = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/list',
            data: filters ? filters : {},
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getCompanyById = async (company?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/get',
            data: { company },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getMyCompany = async (company: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/get-my-company',
            data: { company },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleCompanyStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/toggle',
            data: {
                company: id,
                active: status
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    createCompany = async (company: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/create',
            data: company,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    editCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => (companyObj[key] = value));
        companyObj['logo'] = null;

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/edit',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

    editMyCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => (companyObj[key] = value));
        companyObj['logo'] = null;

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/edit-my-company',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

    editOrganizationImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('company', id);
        formData.append('logo', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteOrganizationImg = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/delete-image',
            data: {
                company: id
            }
        }, true);
        return this;
    }

    deleteCompany = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/delete',
            data: {
                company: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    listDocuments = async (company: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/list-documents',
            data: { company },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    downloadDocument = async (document?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/download-document',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: { document }
        }, true);
        return this;
    }

    uploadDocument = async (company: string, document: File) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/add-document',
            data: {
                company: company,
                document: document,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }


    deleteDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANY_ENDPOINT + '/delete-document',
            data: { document: id }
        }, true) as AxiosResponse;
        return this;
    }

}