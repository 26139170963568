import { useNavigate } from "react-router-dom";
import { CardTitle } from "react-bootstrap";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import { ContactInfo } from "./components/ContactInfo";

export const ContactProfile = () => {

    const navigate = useNavigate();

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>Perfil del contacto</CardTitle>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='xxl'>
                <Card>
                    <CardBody>
                        <ContactInfo />
                    </CardBody>
                </Card>
            </Page>
        </>
    )
}