import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const DOCUMENTS_ENDPOINT = '/documents';
const DOCUMENTS_TYPES_ENDPOINT = '/project_document';

export class DocumentService extends RestServiceConnection {

    getDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_ENDPOINT + '/get',
            data: {
                document: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    renderDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            responseType: 'blob',
            url: DOCUMENTS_ENDPOINT + '/render',
            data: {
                document: id
            },
            headers: {
                "Content-Type": "application/json",
                "responseType": "blob"
            }
        }, true);
        return this;
    }

    renderDocumentURL = (id?: string) => {
        if (!id) return '';
        return `${process.env.REACT_APP_API_URL}${DOCUMENTS_ENDPOINT}/render-document/${id}`
    }

    listProjectDocuments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_TYPES_ENDPOINT + '/list-project-documents',
            data: filters ? filters : {},
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    listDocumentTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_TYPES_ENDPOINT + '/list-document-types',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getDocumentType = async (documentType: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_TYPES_ENDPOINT + '/get-document-type',
            data: { documentType },
        }, true) as AxiosResponse;
        return this;
    }

    createDocumentType = async (documentType: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_TYPES_ENDPOINT + '/create-document-type',
            data: documentType,
        }, true) as AxiosResponse;
        return this;
    }

    editDocumentType = async (document: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_TYPES_ENDPOINT + '/edit-document-type',
            data: document,
        }, true) as AxiosResponse;
        return this;
    }

    downloadDocument = async (documentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_ENDPOINT + '/render',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                document: documentId
            }
        }, true);
        return this;
    }

    uploadDocumentIncomeCost = async (entityId: string, entityName: 'income'|'costs', document: File) => {
        const formData = new FormData();
        formData.append('entityId', entityId);
        formData.append('entityName', entityName); 
        formData.append('document', document);

        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_ENDPOINT + '/upload',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteDocumentType = async (documentType: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_TYPES_ENDPOINT + '/delete-document-type',
            data: { documentType },
        }, true) as AxiosResponse;
        return this;
    }

    multiDeleteDocuments = async (documentIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_ENDPOINT + '/multi-delete',
            data: { documentIds }
        }, true) as AxiosResponse;
        return this;
    }

}