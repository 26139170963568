import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { File } from 'buffer';

const PROJECT_ENDPOINT = '/project';

export class ProjectService extends RestServiceConnection {

    listProjects = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/list-projects',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    listStatusCountProject = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/count-project-statuses',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getProfitsValues = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/profits-values',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getProject = async (project: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/get-project',
            data: { project },
        }, true) as AxiosResponse;
        return this;
    }

    getProjectPersons = async (project: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/get-project-authorized-persons-images',
            data: { project },
        }, true) as AxiosResponse;
        return this;
    }

    createProject = async (project: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/create-project',
            data: project,
        }, true) as AxiosResponse;
        return this;
    }

    editProject = async (project: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/edit-project',
            data: project,
        }, true) as AxiosResponse;
        return this;
    }

    deleteProject = async (project: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/delete-project',
            data: { project },
        }, true) as AxiosResponse;
        return this;
    }

    toggleProjectStatus = async (id: string, status: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/toggle-project-status',
            data: {
                project: id,
                status: status
            }
        }, true) as AxiosResponse;
        return this;
    }

    generateControlSheet = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '_document/generate-control-sheet',
            data: {
                project: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    getSubsidies = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/list-subsidy',
            data: filters ? filters : {},
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    getSubsidy = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/get-subsidy',
            data: {
                subsidy: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    createSubsidy = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/create-subsidy',
            data: {
                convocatoryName: data.convocatoryName,
                company: data.company,
                description: data.description
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    editSubsidy = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/edit-subsidy',
            data: {
                subsidy: data.subsidy,
                convocatoryName: data.convocatoryName,
                company: data.company,
                description: data.description
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    deleteSubsidy = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/delete-subsidy',
            data: {
                subsidy: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    toggleSubsidyStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/toggle-subsidy-status',
            data: {
                subsidy: id,
                active: status
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    listStatusChanges = async (project: string, startDate?: string, endDate?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/list-status-changes',
            data: {
                project,
                startDate,
                endDate
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    listProjectDocuments = async (project: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/list-project-documents',
            data: { project },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    downloadDocument = async (document?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/download-project-document',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: { document }
        }, true);
        return this;
    }

    uploadDocument = async (project: string, documents: File[], documentType: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/add-project-document',
            data: {
                project: project,
                documents: documents,
                documentType: documentType,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/delete-project-document',
            data: { document: id }
        }, true) as AxiosResponse;
        return this;
    }

    deleteDocuments = async (documents: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/multi-delete',
            data: { documents }
        }, true) as AxiosResponse;
        return this;
    }

    getProjectParticipants = async (project: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/get-project-authorized-persons-images',
            data: { project },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    addParticipant = async (project: string, user: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/add-project-participant',
            data: { project, user },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    removeParticipant = async (project: string, user: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/delete-project-participant',
            data: { project, user },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

    validateCadRef = async (reference: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PROJECT_ENDPOINT + '/validate-cadastre-reference',
            data: { reference },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

}