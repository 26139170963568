import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { CostsProvider } from "./provider/CostsProvider";
import CostsSchedulerPage from "./CostsSchedulerPage";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

export const CostsWrapper = () => {

    const costFilters = useSelector((state: RootState) => state.scheduler.costFilters);

    const defaultFilter = {
        estimated_entry_date: {
            from: costFilters?.estimated_entry_date.from,
            to: costFilters?.estimated_entry_date.to,
        },
        range_mode_view: "day",
        path: null,
    };

    return (
        <Fragment>
            <ToastContainer />
            <CostsProvider defaultFilters={defaultFilter}>
                <CostsSchedulerPage />
            </CostsProvider>
        </Fragment>
    );
}