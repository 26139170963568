import { useEffect, useState } from 'react';
import { useDirectoryNavigator } from './DirectoryNavigatorProvider';
import Icon from '../icon/Icon';
import classNames from 'classnames';

type BreadcrumbItem = {
    id: string,
    title: string,
    children?: any,
    tag: 'li',
}

export const DirectoryNavigatorBreadcrumb = () => {

    const { selectedDocumentType, setSelectedDocumentType, updateFilters } = useDirectoryNavigator();

    const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

    // Update breadcrumbItems when selectedDocumentType changes or is reset
    useEffect(() => {
        if (selectedDocumentType.id !== "") {
            breadcrumbItems && breadcrumbItems.length > 0
                ? breadcrumbItems.find(item => item.id === selectedDocumentType.id)
                    ? setBreadcrumbItems(breadcrumbItems.slice(0, breadcrumbItems.findIndex(item => item.id === selectedDocumentType.id) + 1))
                    : setBreadcrumbItems([...breadcrumbItems, { id: selectedDocumentType.id, title: selectedDocumentType.title, tag: 'li' }])
                : setBreadcrumbItems([...breadcrumbItems, { id: selectedDocumentType.id, title: selectedDocumentType.title, tag: 'li' }]);
            ;
        } else {
            setBreadcrumbItems([]);
        }
    }, [selectedDocumentType]);

    return (
        <div className="d-flex align-items-center mt-2 mb-2 flex-wrap">
            <a
                href='#!'
                onClick={() => {
                    setSelectedDocumentType({ id: "", title: "", children: [] });
                    updateFilters({ parent: '', documentType: "" });
                }}
                className="cursor-pointer"
                style={{ fontSize: '1.1rem' }}
            >
                Tipos de documentos
            </a>

            {breadcrumbItems.map((item, index) => (
                <div key={`breadcrumb-${index}`} className="d-flex align-items-center">
                    <Icon icon="ChevronRight" size='lg' className="mx-2" />
                    <a
                        href='#!'
                        onClick={() => {
                            if (index !== breadcrumbItems.length - 1) {
                                setSelectedDocumentType({ id: item.id, title: item.title, children: item.children });
                                updateFilters({ parent: item.id, documentType: item.id });
                            }
                        }}
                        className={classNames({
                            "text-muted": index === breadcrumbItems.length - 1,
                            "cursor-pointer": index !== breadcrumbItems.length - 1
                        })}
                        style={{ fontSize: '1.1rem' }}
                    >
                        {item.title}
                    </a>
                </div>
            ))}
        </div>
    );
};