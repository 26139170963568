import FormLabel from "../../../components/FormLabel";
import Button from "../../../components/bootstrap/Button";
import { CardTitle } from "../../../components/bootstrap/Card";
import Input from "../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import BudgetConcepts from "./BudgetConcepts";
import { useBugdetForm } from "../providers/BudgetProvider";
import { conceptsNames } from "../../../utils/concepts";

const BudgetConceptForm = () => {

    const {
        formik, formikConcepts, verifyClassConcepts, showErrorsConcepts,
        taxesApiData, conceptsData, nameSelected, setNameSelected
    } = useBugdetForm();

    const getConceptsNames = () => {
        return conceptsNames.map((concept: string) => { return { value: concept, label: concept } })
    };

    const getTaxValueById = (id: string) => {
        if (taxesApiData) {
            const tax = taxesApiData.taxes.find((tax: any) => tax.id === id);
            return tax?.value;
        }
        return '';
    };

    return (
        <form onSubmit={formikConcepts.handleSubmit} autoComplete="off">
            <CardTitle className="mt-5 mb-4">Conceptos</CardTitle>

            <div className="row">
                <FormLabel label='Cantidad' cols={1} required />
                <FormLabel label='Nombre' cols={3} required />
                <FormLabel label='Descripción' cols={4} />
                <FormLabel label='Precio (€)' style={{ width: '10%' }} required />
                <FormLabel label='Impuesto (%)' style={{ width: '10%' }} />
                <FormLabel label="Retención (%)" style={{ width: '10%' }} />
            </div>

            {/* List of concepts */}
            {conceptsData?.length > 0 && <BudgetConcepts />}

            {/* Form to add a concept to the budget */}
            <div className="row mt-2">
                <div className="col-md-1">
                    <Input type="number" id='quantity' onChange={formikConcepts.handleChange} value={formikConcepts.values.quantity} className={verifyClassConcepts('quantity')} />
                    {showErrorsConcepts('quantity')}
                </div>
                <div className="col-md-3">
                    <SearchableSelect
                        isSearchable
                        options={getConceptsNames()}
                        onChange={(e: any) => {
                            setNameSelected(e);
                            formikConcepts.handleChange(e.value)
                            formikConcepts.setFieldValue('name', e.value);
                        }}
                        value={nameSelected}
                        name='name-select'
                        placeholder=''
                        classname={verifyClassConcepts('name')}
                    />
                    {showErrorsConcepts('name')}
                </div>
                <div className="col-md-4">
                    <Input id='textField' onChange={formikConcepts.handleChange} value={formikConcepts.values.textField} className={verifyClassConcepts('textField')} />
                    {showErrorsConcepts('textField')}
                </div>
                <div style={{ width: '10%' }}>
                    <Input type="number" id='amount' onChange={formikConcepts.handleChange} value={formikConcepts.values.amount} className={verifyClassConcepts('amount')} />
                    {showErrorsConcepts('amount')}
                </div>
                <div style={{ width: '10%' }}>
                    <Input value={getTaxValueById(formik.values.taxes)} disabled />
                </div>
                <div style={{ width: '10%' }}>
                    <Input type="number" value={formik.values.withholdings} disabled />
                </div>
                <div className="col-md-12 d-flex justify-content-center mt-4">
                    <Button type="submit" color='secondary' isLight onClick={formikConcepts.handleSubmit}>Guardar y añadir otro concepto</Button>
                </div>
            </div>
        </form>
    )
}

export default BudgetConceptForm;