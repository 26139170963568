import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';

const INCOME_ENDPOINT = '/income';

export class IncomeService extends RestServiceConnection {

    // ------------------------------------------------------------
    //  ALL TYPES FOR ENTITY INCOME
    // ------------------------------------------------------------

    getIncomes = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/list',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getIncomeById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/get',
            data: {
                incomeId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createIncome = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/create',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    editIncome = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/edit',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    deleteIncome = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/delete',
            data: {
                incomeId: id
            }
        }, true) as AxiosResponse;
        return this;
    }


    // ------------------------------------------------------------
    //  ALL TYPES FOR ENTITY INCOME-TYPE
    // ------------------------------------------------------------
    getIncomeTypes = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/list-types',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getIncomeTypeById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/get-type',
            data: {
                incomeTypeId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createIncomeType = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/create-type',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    editIncomeType = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/edit-type',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    deleteIncomeType = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/delete-type',
            data: {
                incomeTypeId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    changeCostTypeColor = async (typeId: string, color: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INCOME_ENDPOINT + '/edit-type-color',
            data: { typeId, color }
        }, true) as AxiosResponse;
        return this;
    }

}