import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const PERMISSIONS_ENDPOINT = '/permissions';

export class PermissionService extends RestServiceConnection {

    getPermissions = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PERMISSIONS_ENDPOINT + '/get-all',
            data: {

            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }
}