import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Page from "../../layout/Page/Page";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import BudgetGeneralForm from "./components/BudgetGeneralForm";
import BudgetConceptForm from "./components/BudgetConceptForm";
import BudgetStageForm from "./components/BudgetStageForm";
import { useBugdetForm } from "./providers/BudgetProvider";
import '../../styles/stylesEdited.scss';
import { Spinner } from "react-bootstrap";

export interface Concept {
    id: string;
    name: string;
    textField: string;
    amount: number;
    quantity: number;
    tax: number;
    withholdings: number;
}

export interface Stage {
    id: string;
    name: string;
    textField: string;
    duration: number;
    concepts: [];
}

interface BudgetFormProps {
    isLoading?: boolean;
}

const BudgetsForm: FC<BudgetFormProps> = ({ isLoading }) => {

    const { formik, mode, isSubmitting } = useBugdetForm();
    const navigate = useNavigate();

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => {
                        if (formik.values !== undefined) {
                            handleConfirmationAlert({
                                title: "Salir sin guardar",
                                text: "¿Está seguro que desea salir sin guardar los cambios?",
                                icon: "warning",
                                onConfirm: () => { navigate(-1) },
                                focusCancel: true,
                            })
                        }
                    }} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Presupuesto</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading || isSubmitting}>
                        {mode === 'Crear'
                            ? isSubmitting ? <Spinner animation="border" size="sm" /> : 'Crear'
                            : isLoading ? <Spinner animation="border" size="sm" /> : 'Guardar'
                        }
                    </Button>
                </SubHeaderRight>
            </SubHeader>

            <Page container='xxl'>
                <Card stretch={false}>
                    <CardBody>
                        <CardTitle className="mb-4">Datos del presupuesto</CardTitle>

                        <BudgetGeneralForm />

                        <BudgetConceptForm />

                        <BudgetStageForm />
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    )
}

export default BudgetsForm;