import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const SERIES_ENDPOINT = '/financial';

export class SerieService extends RestServiceConnection {

    listSeries = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERIES_ENDPOINT + '/list-budget-series',
            data: filters
        }, true) as AxiosResponse;
        return this;
    }

    createSerie = async (serie: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERIES_ENDPOINT + '/create-budget-series',
            data: serie
        }, true) as AxiosResponse;
        return this;
    }

    editSerie = async (serie: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERIES_ENDPOINT + '/edit-budget-series',
            data: serie
        }, true) as AxiosResponse;
        return this;
    }

    deleteSerie = async (series: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERIES_ENDPOINT + '/delete-budget-series',
            data: { series }
        }, true) as AxiosResponse;
        return this;
    }

}