// axiosConfig.js
import axios from 'axios';

const instance = axios.create();

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Redirigir al usuario a la página de inicio de sesión si se recibe un 401
      window.location.replace('/login');
    }
    return Promise.reject(error);
  }
);

export default instance;