import { FC, Fragment } from 'react'
import Icon from '../../../../components/icon/Icon'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput';

interface ICodeFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: () => void;
    filters: any;
}

const CodeFilters: FC<ICodeFiltersProps> = ({ updateFilters }) => {

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                }}
                placeholder='Buscar...'
            />
        </Fragment>
    )
}

export default CodeFilters;