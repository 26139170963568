import { useFormik } from "formik";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import { UserService } from "../../services/users/userService";
import { loadUserFromLocalStorage } from "../../utils/jwt";
import { CompanyService } from "../../services/companies/organizationService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../components/bootstrap/Modal";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Button from "../../components/bootstrap/Button";
import * as yup from 'yup';
import useFetch from "../../hooks/useFetch";
import SearchableSelect from "../../components/selector/SearchableSelect";
import { DocumentService } from "../../services/documents/documentService";
import Checks from "../../components/bootstrap/forms/Checks";
import useFilters from "../../hooks/useFilters";
import { ITypesFilters } from "./documents-list/DocumentsList";


const typesFilters: ITypesFilters = {
    parents: null,
};
interface CreateModalProps {
    data?: any;
    isOpen: boolean;
    show?: boolean;
    setIsOpen(...args: unknown[]): unknown;
    onClose: (type: number, message?: string, errors?: any) => void;
}

interface DocumentType {
    documentType: string;
    name: string;
    company: any;
    parent: string;
    description: string;
    documentOrder?: number;
}

const validationSchema = yup.object({
    company: yup.string().required('La organización es obligatoria'),
    name: yup.string().required('El nombre es obligatorio'),
    parent: yup.string().notRequired(),
    description: yup.string().notRequired(),
});

const editSchema = yup.object({
    company: yup.string().required('La organización es obligatoria'),
    name: yup.string().required('El nombre es obligatorio'),
    parent: yup.string().notRequired(),
    description: yup.string().notRequired(),
    documentOrder: yup.number().min(1, 'No puedes introducir un valor inferior a 1').required('El orden del documento es obligatorio'),
});

const DocumentTypeForm: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const documentService = new DocumentService();

    const { filters } = useFilters(typesFilters, [], 0, 999999999);

    const [company, setCompany] = useState<string>("");
    const [companyList, setCompanyList] = useState<any[]>([]);
    const [isSubtype, setIsSubtype] = useState<boolean>(false);
    const [parentSelected, setParentSelected] = useState<{ value: string, label: string }>({ value: '', label: '' });

    const [parents] = useFetch(useCallback(async () => {
        const response = await documentService.listDocumentTypes(filters);
        return response.getResponseData();
    }, [filters]));

    const getCompany = async () => {
        const userService = new UserService;
        const response = (await userService.getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData()
        if (response.success) {
            setCompany(response.data.userRoles[0].company.id)
        }
    };

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    };

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const getParentsList = () => {
        if (parents) {
            return parents.projectDocumentTypes?.map((parents: any) => {
                return {
                    value: parents.id,
                    label: parents.name,
                    isSelected: parents.id === data?.project?.id,
                }
            })
        }
        return [];
    };

    const _handleCreateType = async (values: any) => {
        try {
            const response = await (await documentService.createDocumentType(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear el tipo');
        }
    };

    const _handleEditType = async (values: any) => {
        try {
            const response = await (await documentService.editDocumentType(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar el tipo');
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            documentType: data?.id || '',
            company: data?.company?.id || company,
            name: data?.name || '',
            parent: data?.parent?.id || '',
            description: data?.description || '',
            documentOrder: data?.documentOrder || null,
        },
        validationSchema: data ? editSchema : validationSchema,
        onSubmit: (values) => {
            if (isSubtype && values.parent === '') {
                toast.error('El tipo padre es obligatorio');
                return;
            }
            if (!isSubtype) values.parent = '';
            if (values.company != '') {
                if (data != null) {
                    _handleEditType(values);
                } else {
                    _handleCreateType(values);
                }
            }
        },
    });

    const verifyClass = (inputFieldID: keyof DocumentType) => { return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : "" };

    const showErrors = (inputFieldID: keyof DocumentType) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    useEffect(() => {
        if (!isSuperAdmin) {
            getCompany();
        } else {
            getCompanies();
        }
    }, [isSuperAdmin]);

    // Si está editando un tipo y es hijo, se setea el valor del padre
    useEffect(() => {
        if (data) {
            setIsSubtype(data?.parent ? true : false);
            setParentSelected({ value: data?.parent?.id, label: data?.parent?.name });
        }
    }, [data]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size='md' titleId='Crear tipo'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_type'>{show ? 'Ver tipo' : data ? 'Editar tipo' : 'Crear tipo'}</ModalTitle>
            </ModalHeader>

            {
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <ModalBody className='row px-4 g-4'>
                        {
                            isSuperAdmin && (
                                <FormGroup requiredInputLabel={!show} id='company' label='Organización' className='col-md-12'>
                                    <Select id="company" disabled={show ? true : false} list={getCompanyList()} onChange={formik.handleChange} value={formik.values.company || ''} ariaLabel='Select Companies' placeholder='Elegir Organización...' />
                                </FormGroup>
                            )
                        }

                        <Checks
                            type="switch"
                            id="type"
                            label="¿Es un subtipo?"
                            checked={isSubtype}
                            onChange={() => {
                                setIsSubtype(!isSubtype);
                                if (!isSubtype) {
                                    setParentSelected({ value: '', label: '' });
                                    formik.setFieldValue('parent', '');
                                }
                            }}
                            disabled={show ? true : false}
                        />

                        {
                            isSubtype && (
                                <FormGroup requiredInputLabel={!show} id='parent' label='Padre' className='col-md-12'>
                                    <SearchableSelect
                                        name="parent"
                                        isDisabled={show ? true : false}
                                        options={getParentsList()}
                                        onChange={(e: any) => {
                                            formik.handleChange(e.value);
                                            setParentSelected({ value: e.value, label: e.label });
                                            formik.setFieldValue('parent', e.value);
                                        }}
                                        value={parentSelected}
                                        placeholder='tipo padre '
                                    />
                                </FormGroup>
                            )
                        }

                        {
                            data && (
                                <FormGroup requiredInputLabel={!show} id='documentOrder' label='Orden:' className="col-md-4">
                                    <Input id='documentOrder' type="number" value={formik.values.documentOrder} disabled={show ? true : false} onChange={(e: any) => formik.setFieldValue('documentOrder', e.target.value)} className={verifyClass('documentOrder')} />
                                    {showErrors('documentOrder')}
                                </FormGroup>
                            )
                        }

                        <FormGroup requiredInputLabel={!show} id='name' label='Nombre:' className="col-md-12">
                            <Input id='name' value={formik.values.name} disabled={show ? true : false} onChange={(e: any) => formik.setFieldValue('name', e.target.value)} className={verifyClass('name')} />
                            {showErrors('name')}
                        </FormGroup>

                        <FormGroup label="Descripción:">
                            <textarea
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                                style={{
                                    borderRadius: '10px',
                                    boxShadow: show ? '0 0 0 3px #e9ecef' : '0 0 0 3px rgb(249, 230, 236)',
                                    backgroundColor: show ? '#e9ecef' : '#f8f9fa',
                                    width: '100%',
                                    padding: '15px',
                                    border: '0'
                                }}
                                disabled={show ? true : false}
                                className={verifyClass('description')}
                            />
                            {showErrors('description')}
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'}>
                            {data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>
            }
        </Modal>
    );
}

export default DocumentTypeForm;