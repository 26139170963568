import { AxiosResponse } from "axios";
import { Role } from "../../type/apiResponse-type";
import { RestServiceConnection } from "../restServiceConnection";

const ROLES_ENDPOINT = "/roles";

export class RoleService extends RestServiceConnection {

  listRoles = async (filters: any) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/list',
      data: filters,
      headers: {
        "Content-Type": "application/json"
      }
    }, true) as AxiosResponse;
    return this;
  }

  getRoles = async () => {
    this.response = await this.makeRequest(
      {
        method: "POST",
        url: ROLES_ENDPOINT + "/get-all",
        //data: ,
        headers: {
          "Content-Type": "application/json",
        },
      },
      true
    );
    return this;
  };

  getRoleById = async (id: string) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/get',
      data: {
        role: id
      },
      headers: {
        "Content-Type": "application/json"
      }
    }, true);
    return this;
  }

  getRolesByOrganization = async (organizationId: string) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/company',
      data: {
        company: organizationId
      },
      headers: {
        "Content-Type": "application/json"
      }
    }, true);
    return this;
  }

  createRole = async (role: Role) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/create',
      data: role,
      headers: {
        "Content-Type": "application/json"
      }
    }, true);
    return this;
  }

  editRole = async (roleData: FormData) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/edit',
      data: roleData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }, true);
    return this;
  }

  deleteRole = async (id: string, company: string) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/delete',
      data: {
        role: id,
        company: company
      },
      headers: {
        "Content-Type": "application/json"
      }
    }, true);
    return this;
  }
}
