import { FC } from "react";

interface EmptyTableProps {
    row: string;
}

const EmptyTable: FC<EmptyTableProps> = ({ row }) => {
    return (
        <div className="text-center">
            <h5 className="text-muted">No se han encontrado {row}</h5>
        </div>
    )
}

export default EmptyTable;