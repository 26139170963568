import { useEffect, useState } from "react";
import { DocumentService } from "../../services/documents/documentService";
import ListGroup, { ListGroupItem } from "../bootstrap/ListGroup";
import { useDirectoryNavigator } from "./DirectoryNavigatorProvider";
import Icon from "../icon/Icon";
import useHandleErrors from "../../hooks/useHandleErrors";

export const DirectoryNavigatorGroups = () => {
    const { selectedDocumentType, setSelectedDocumentType, filters } = useDirectoryNavigator();
    const { handleErrors } = useHandleErrors();
    const documentService = new DocumentService();

    const [folders, setFolders] = useState([]);

    const getDocumentTypes = async () => {
        const response = await documentService.listDocumentTypes(filters);
        const documentTypesResponse = response.getResponseData();
        handleErrors(response);
        const data = documentTypesResponse.data?.projectDocumentTypes;
        classificateFolders(data);
    };

    /**
     * Classificate folders by parent or root folders
     */
    const classificateFolders = (data: any) => {
        let selectedType = selectedDocumentType?.id || null;
        const parentFolders = data.filter((item: any) => {
            if (selectedType === null && item.parent === null) {
                return item;
            }
            if (selectedType !== null && item.parent?.id === selectedType) {
                return item;
            }
            return false;
        });
        setFolders(parentFolders);
    };

    useEffect(() => {
        getDocumentTypes();
    }, [filters]);

    //console.log('folders', folders)

    return (
        <ListGroup isFlush style={{ borderBottom: '1px solid lightgrey' }}>
            {folders.map((folder: any, index) => {
                const childrenCount = folder.children.length > 0 ? `(${folder.children.length})` : '';
                return (
                    <ListGroupItem
                        key={`folder-${index}`}
                        tag="button"
                        onClick={() => setSelectedDocumentType({ id: folder.id, title: folder.name, children: folder.children })}
                    >
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content align-items-center">
                                <Icon icon="Folder" size='2x' className="me-3" />
                                <div>{folder.name} {childrenCount}</div>
                            </div>
                        </div>
                    </ListGroupItem>
                );
            })}
        </ListGroup>
    );
};