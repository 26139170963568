import React, { useCallback, useContext, useEffect, useState } from "react";
import { TaskService } from "../../../services/tasks/taskService";
import { Task } from "../../../type/calendar-type";
import useFilters, { FilterOptions, FilterOrders } from "../../../hooks/useFilters";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { changeStorageDate, changeStorageViewMode } from "../../../redux/calendarSlice";
import moment from "moment";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { useParams } from "react-router-dom";

type TasksCalendarContextData = {
    tasks?: Task[],
    refetchTasks: () => void,
    viewMode: "month" | "week" | "work_week" | "day",
    changeViewMode: (view: "month" | "week" | "work_week" | "day") => void,
    currentDate: Date,
    setCalendarDate: (date: Date) => void,
    changeFinishTask: (id: string) => void,
    deleteTask: (id: string) => void,
    taskSelected: any | null,
    isTaskSelected: boolean,
    setIsTaskSelected: (isTaskSelected: boolean) => void,
    selectTask: (task: any | null) => void,
    updateFilters: (filters: any) => void,
    filters: FilterOptions,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    isLoading?: boolean,
    totalResults?: number,
    lastPage?: number,
    totalAmount?: number,
    projectView?: boolean,
}

type TasksCalendarProviderProps = {
    children: React.ReactNode
    defaultFilters?: FilterOptions
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
    projectView?: boolean,
}

const TasksCalendarContext: React.Context<TasksCalendarContextData> = React.createContext<TasksCalendarContextData>({
    tasks: [],
    refetchTasks: () => { },
    viewMode: 'month',
    changeViewMode: (view: "month" | "week" | "work_week" | "day") => { },
    currentDate: new Date(),
    setCalendarDate: (date: Date) => { },
    changeFinishTask: (id: string) => { },
    deleteTask: (id: string) => { },
    taskSelected: null,
    isTaskSelected: false,
    setIsTaskSelected: (isTaskSelected: boolean) => { },
    selectTask: (task: any | null) => { },
    updateFilters: (filters: any) => { },
    filters: {} as FilterOptions,
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    isLoading: false,
    totalResults: 0,
    lastPage: 1,
    totalAmount: 0,
    projectView: false,
});

const TasksCalendarProvider: React.FC<TasksCalendarProviderProps> = ({ children, defaultFilters, defaultOrders, defaultPage, defaultPageSize, projectView }) => {

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.calendar.viewMode);
    const currentDate = useSelector((state: RootState) => moment(state.calendar.date).toDate());

    const [tasks, setTasks] = useState<Task[]>([]);
    const [taskSelected, setTaskSelected] = useState<any | null>(null);
    const [isTaskSelected, setIsTaskSelected] = useState<boolean>(false);

    // Calendar tasks fetch
    const [tasksResponse, isLoading, errors, refetchTask] = useFetch(useCallback(async () => {
        //if (filters.filter_filters?.project !== id) return;
        const response = (await ((new TaskService()).listTasks(filters))).getResponseData();
        if (!response.success) {
            handleErrors(response);
            return null;
        }
        setTasks(response.data.tasks);
        return response;
    }, [filters]));

    const changeViewMode = (view: "month" | "week" | "work_week" | "day") => {
        dispatch(changeStorageViewMode(view));
    };

    const refetchTasks = () => {
        refetchTask();
    };

    const setCalendarDate = (date: Date) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        dispatch(changeStorageDate(dateStr));
    };

    const changeFinishTask = (id: string) => {
        handleConfirmationAlert({
            title: "Cambiar estado de la tarea",
            icon: "info",
            onConfirm: async () => {
                const response = (await ((new TaskService()).updateTaskStatus(id))).getResponseData();
                if (response.success) {
                    const newTasks = tasks.map(task => {
                        if (task.id === id) {
                            task.isFinished = !task.isFinished;
                        }
                        return task;
                    });
                    setTasks(newTasks);
                    refetchTasks();
                    return response;
                } else {
                    handleErrors(response);
                    return null;
                }
            },
            focusCancel: true,
        });
    };

    const deleteTask = async (id: string) => {
        const response = (await ((new TaskService()).deleteTask(id))).getResponseData();
        if (!response.success) {
            handleErrors(response);
            return null;
        }
        refetchTasks();

        return response;
    };

    const selectTask = (task: any | null) => {
        setTaskSelected(task);
        setIsTaskSelected(true);
    };

    useEffect(() => {
        if (projectView) {
            updateFilters({ project: id });
        }
    }, [projectView]);

    return (
        <TasksCalendarContext.Provider value={{
            tasks,
            refetchTasks,
            viewMode,
            changeViewMode,
            currentDate,
            setCalendarDate,
            changeFinishTask,
            deleteTask,
            taskSelected,
            isTaskSelected,
            setIsTaskSelected,
            selectTask,
            filters,
            updateFilters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,
            isLoading,
            lastPage: tasksResponse?.lastPage,
            totalResults: tasksResponse?.totalRegisters,
            projectView,
        }}>
            {children}
        </TasksCalendarContext.Provider>
    );
}

TasksCalendarProvider.defaultProps = {
    defaultFilters: {} as FilterOptions,
    defaultOrders: [] as FilterOrders,
    defaultPage: 1,
    defaultPageSize: 500,
}

export { TasksCalendarProvider, TasksCalendarContext };

export function useTasksCalendar() {
    return useContext(TasksCalendarContext);
}