import { useFormik } from "formik";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Button from "../../../components/bootstrap/Button";
import { useTasksCalendar } from "../providers/TasksCalendarProvider";
import { UserSelector } from "../../../components/selector/TaskUserSelector.";
import Select from "../../../components/bootstrap/forms/Select";
import { useState } from "react";

export const TasksCalendarFilters: React.FC = () => {

    const { updateFilters, filters } = useTasksCalendar();

    const [isFinished, setIsFinished] = useState<number>(filters.filter_filters?.is_finished);

    const formik = useFormik({
        initialValues: { user: undefined },
        onSubmit: (values) => { updateFilters(values) }
    });

    return (
        <form onSubmit={formik.handleSubmit} className="d-flex flex-column">
            <FormGroup id='filter1' label='Estado:'>
                <Select
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updateFilters({ is_finished: parseInt(e.target.value) });
                        setIsFinished(parseInt(e.target.value));
                    }}
                    value={isFinished?.toString()}
                    id='active-select'
                    ariaLabel='Selector de estado'
                    placeholder='Estado ...'
                    list={[
                        { value: 3, label: 'Todas las tareas' },
                        { value: 1, label: 'Finalizada' },
                        { value: 0, label: 'Pendiente' }
                    ]}
                />
            </FormGroup>

            <FormGroup id="filter2" label="Usuario:" className="mt-2">
                <UserSelector name="user" formik={formik} selectedId={filters.filter_filters?.user} onChange={formik.handleSubmit} />
            </FormGroup>

            <div className="d-flex justify-content-end mt-5">
                <Button
                    type="button" color="secondary" isLight
                    onClick={() => {
                        updateFilters({ is_finished: undefined, user: undefined });
                        setIsFinished(3);
                    }}
                >
                    Limpiar
                </Button>
            </div>
        </form>
    );
}