import { useEffect, useRef, useState } from "react";
import { CirclePicker } from "react-color";
import { COLORS_36 } from "../utils/colors";

function ColorPicker(props: any) {

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState(props.color || "#607d8b");
    const colorPickerRef = useRef<HTMLDivElement>(null);

    const handleClick = () => setShowColorPicker(!showColorPicker);

    const handleChange = (colorPick: any) => {
        setColor(colorPick.hex);
        if (colorPick.hex !== color) handleSave(colorPick.hex);
    };

    const handleSave = (colorPick: string) => {
        if (props.user) {
            props.handleSave({
                user: props.user?.id,
                role: props.user?.userRoles[0]?.role?.id,
                company: props.user?.userRoles[0]?.company?.id,
                name: props.user?.name,
                email: props.user?.email,
                dni: props.user?.dni,
                color: colorPick,
                last_name: props.user?.lastName,
                address: props.user?.address,
            });
        } else {
            props.handleSave({
                status: props.item?.id ?? '',
                company: props.item?.company?.id ?? '',
                color: colorPick,
                orientedTo: props.item?.orientedTo,
                name: props.item?.name,
                orderStatus: props.item?.orderStatus,
                mainStatus: props.item?.mainStatus,
                description: props.item?.description ?? null,
                createdAt: props.item?.createdAt ?? null,
                updateAt: props.item?.updateAt ?? null,
                projectHasStatus: props.item?.projectHasStatus ?? null,
                budgetHasStatus: props.item?.budgetHasStatus ?? null,
            });
        }
        setShowColorPicker(false);
    };

    const noAction = () => {
        return null;
    };

    useEffect(() => {
        function handleDocumentClick(event: MouseEvent) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
                setShowColorPicker(false);
            }
        }

        if (showColorPicker) {
            document.addEventListener("click", handleDocumentClick);
        } else {
            document.removeEventListener("click", handleDocumentClick);
        }

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [showColorPicker]);

    return (
        <div className="d-flex align-items-center" ref={colorPickerRef}>
            <div
                onClick={props.disabled ? noAction : handleClick}
                style={{
                    backgroundColor: color,
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                }}
                className={`rounded-circle mr-3 p-${props.circleSize || 3}`}
            >
                <div
                    className="rounded-circle"
                    style={{ backgroundColor: color, width: "5px", height: "5px" }}
                />
            </div>

            {showColorPicker && (
                <div className="ms-3 bg-light rounded p-3">
                    <CirclePicker color={color} onChange={handleChange} width="260px" colors={COLORS_36} />
                </div>
            )}
        </div>
    );
}

export default ColorPicker;