import { useCallback, useRef } from "react";
import useFetch from "../../../hooks/useFetch";
import ErrorMessage from "../../../components/ErrorMessage";
import { useParams } from "react-router-dom";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import { toast } from "react-toastify";
import { CompanyService } from "../../../services/companies/organizationService";
import Spinner from "../../../components/bootstrap/Spinner";
import useHandleDownloadDocument from "../../../hooks/useDownloadDocument";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import moment from "moment";
import { authMenu } from "../../../menu";

const OrganizationDocuments = () => {

  const { id = '' } = useParams<{ id: string }>();
  const { handleDownloadDocument } = useHandleDownloadDocument();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const organizationService = new CompanyService();

  const [documents, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await organizationService.listDocuments(id);
    return response.getResponseData();
  }, []));

  const _handleUpload = async (e: React.ChangeEvent<any>) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile) {
      try {
        const response = (await (organizationService.uploadDocument(id, selectedFile))).getResponseData();
        if (response) {
          refetch();
          toast.success('Documento subido correctamente');
        } else {
          response.data.errors.forEach((error: any) => {
            toast.error(error.message);
          });
        }
      } catch (error: any) {
        toast.error(error.message || 'Error al subir el documento');
      }
    }
  };

  const _handleDelete = async (id: string) => {
    try {
      const response = (await (organizationService.deleteDocument(id))).getResponseData();
      if (response) {
        refetch();
        toast.success('Documento eliminado correctamente');
      } else {
        response.data.errors.forEach((error: any) => {
          toast.error(error.message);
        });
      }
    } catch (error: any) {
      toast.error(error.message || 'Error al eliminar el documento');
    }
  };

  return (
    <>
      {loading && <div className="d-flex justify-content-center align-items-center"><Spinner /></div>}
      {error && <ErrorMessage />}

      <div className="row">
        <div className="col-md-11">
          {documents && documents.companyDocuments && (
            <CustomTable
              title="Documentos"
              data={documents.companyDocuments || null}
              pagination={false}
              className={"table-striped table-hover"}
              columns={[
                {
                  name: "Nombre",
                  keyValue: "name",
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div
                        className="cursor-pointer text-primary fw-bold text-center"
                        onClick={() => { handleDownloadDocument(organizationService.downloadDocument, element.document.id, element.document.originalName) }}
                      >
                        {element.document.originalName}
                      </div>
                    )
                  },
                },
                {
                  name: "Usuario",
                  keyValue: "user",
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="cursor-pointer text-primary text-center">
                        <a href={`${authMenu.users.path}/${element.user.id}/perfil`}>
                          {element.user.name + ' ' + (element.user.firstName ? element.user.firstName : "") + ' ' + (element.user.lastName ? element.user.lastName : "")}
                        </a>
                      </div>
                    )
                  },
                },
                {
                  name: 'Fecha de subida',
                  keyValue: 'createdAt',
                  className: 'text-center',
                  render: (element: any) => {
                    return (
                      <div className="text-center">
                        {moment(element.createdAt.date).format('DD/MM/YYYY HH:mm')}
                      </div>
                    )
                  },
                },
                { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
              ]}
              actions={[
                {
                  title: 'Descargar',
                  buttonType: 'icon',
                  icon: 'Download',
                  additionalClasses: 'text-primary',
                  description: 'Descargar documento',
                  callback: (item: any) => {
                    handleDownloadDocument(organizationService.downloadDocument, item.document.id, item.document.originalName);
                  }
                },
                {
                  title: "Eliminar",
                  buttonType: 'icon',
                  icon: "Delete",
                  additionalClasses: 'text-danger',
                  description: "Eliminar documento",
                  callback: (item: any) => {
                    handleConfirmationAlert({
                      title: "Eliminar documento",
                      text: "¿Está seguro que desea eliminar el documento?",
                      icon: "warning",
                      onConfirm: () => { _handleDelete(item.document.id) }
                    })
                  },
                },
              ]}
            />
          )}
        </div>

        <div className="col-md-1 text-end pe-0">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => { _handleUpload(e) }}
          />
          <Button
            color='primary' isLight
            onClick={() => { fileInputRef.current?.click() }}
            className="btnUploadDocument"
          >
            Subir <Icon icon='Add' title="Subir documento" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrganizationDocuments;