import { CardTitle } from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import { FinancesMenu } from "../../../../menu";
import { useNavigate, useParams } from "react-router-dom";
import CloneBudgetModal from "../../../budgets/components/CloneBudgetModal";
import useFetch from "../../../../hooks/useFetch";
import { useCallback, useContext, useState } from "react";
import useFilters from "../../../../hooks/useFilters";
import { BudgetService } from "../../../../services/budgets/budgetService";
import { StatusService } from "../../../../services/status/statusService";
import { StatusApiResponse } from "../../../../type/status-type";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/ErrorMessage";
import { Budget, BudgetsApiResponse } from "../../../../type/budgets-type";
import { CustomTable } from "../../../../components/table/CustomTable";
import StatusDropdown from "../../../budgets/components/StatusDropdown";
import moment from "moment";
import IconWithTooltip from "../../../../components/IconWithTooltip";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import useHandleErrors from "../../../../hooks/useHandleErrors";

interface IBudgetsFilters {
    client: string;
}

const Budgets = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();

    const budgetService = new BudgetService();
    const statusService = new StatusService();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [budgetData, setBudgetData] = useState<{ id: string, name: string }>();
    const [budgetStatus, setBudgetStatus] = useState<string[]>([]);

    const budgetFilters: IBudgetsFilters = {
        client: id,
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(budgetFilters);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await budgetService.listBudgets(filters);
        return response.getResponseData() as Budget;
    }, [filters]));

    const [statuses] = useFetch(useCallback(async () => {
        const response = await statusService.listStatuses();
        return response.getResponseData() as StatusApiResponse;
    }, []));

    const getStatusesList = () => {
        if (statuses) {
            return statuses.statuses.map((status: any) => status.name);
        }
        return [];
    }

    const downloadDocument = async (documentId: string, documentName: string) => {
        try {
            const response = (await budgetService.generateBudgetPDF(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar la factura');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const toggleStatus = async (id: string, status: string, toggleStatus: Function) => {
        try {
            setBudgetStatus([...budgetStatus, id]);
            const response = (await budgetService.toggleBudgetStatus(id, status)).getResponseData();
            if (response.success) {
                setBudgetStatus(budgetStatus.filter((item) => item !== id));
                toggleStatus(status);
                toast.success(<div>Se ha cambiado el estado a <strong>{status.toLocaleLowerCase()}</strong></div>);
            } else {
                setBudgetStatus(budgetStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setBudgetStatus(budgetStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await budgetService.deleteBudget(id)).getResponseData();

            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success("Presupuesto eliminado");
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage message={error} />;

        if (data && data.budgets.length > 0) {
            let apiData = data as BudgetsApiResponse;

            return (
                <CustomTable
                    title="Presupuestos"
                    loading={loading}
                    data={apiData.budgets ? apiData.budgets : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as BudgetsApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Identificador",
                            keyValue: "code_identifier",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary" onClick={() => { navigate(`${FinancesMenu.finances.path}/${element.id}/vista`) }}>
                                        {element.codeIdentifier}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Precio total",
                            keyValue: "totalPrice",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>{`${Math.round(element.totalPrice)} €`}</>
                                )
                            },
                        },
                        {
                            name: "Estado del presupuesto",
                            keyValue: "status",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <StatusDropdown
                                            className="fs-6"
                                            itemId={element.id}
                                            statusName={element.status?.name}
                                            change={toggleStatus}
                                            options={getStatusesList()}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt?.date).format('DD/MM/YYYY')}
                                    </>
                                )
                            },
                        },
                        {
                            name: "Encargo",
                            keyValue: "createProject",
                            render: (element: any) => {
                                return (
                                    <>
                                        {
                                            element.budgetStatus?.finalStatus ? (
                                                <Button
                                                    title="Crear proyecto"
                                                    color="secondary"
                                                    isLink
                                                    isLight
                                                    icon="Add"
                                                    className={`${userCan("create", "project") ? "" : "d-none"}`}
                                                    onClick={() => { if (userCan("create", "project")) return navigate(`${FinancesMenu.project.path}/creacion/${element.id}`) }}
                                                />
                                            ) : (
                                                <IconWithTooltip
                                                    icon="Info"
                                                    label="El presupuesto no está en la fase final"
                                                />
                                            )
                                        }
                                    </>
                                )
                            }
                        },
                        {
                            name: "Documento",
                            keyValue: "budgetPDF",
                            render: (element: any) => {
                                return (
                                    <div
                                        onClick={() => { element.budgetStatus?.finalStatus && downloadDocument(element.id, element.codeIdentifier) }}
                                        className={`${element.budgetStatus?.finalStatus ? "cursor-pointer text-primary" : "text-muted"}`}
                                    >
                                        {element.budgetStatus?.finalStatus ? 'Descargar' : "No disponible"}
                                    </div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: 'Ver',
                            buttonType: 'icon',
                            icon: 'Preview',
                            additionalClasses: 'text-primary',
                            description: 'Ver presupuesto',
                            callback: (item: any) => { navigate(`${FinancesMenu.finances.path}/${item.id}/vista`) },
                        },
                        {
                            title: 'Editar',
                            buttonType: 'icon',
                            icon: 'Edit',
                            additionalClasses: `text-primary ${userCan("edit", "budget") ? "" : "d-none"}`,
                            description: 'Editar presupuesto',
                            callback: (item: any) => {
                                if (userCan("edit", "budget")) {
                                    item.budgetStatus?.finalStatus === false
                                        ? navigate(`${FinancesMenu.finances.path}/${item.id}/edicion`)
                                        : toast.info('No se puede editar un presupuesto que ya ha llegado a su estado final')
                                }
                            },
                        },
                        {
                            title: 'Clonar',
                            buttonType: 'icon',
                            icon: 'ContentCopy',
                            additionalClasses: 'text-primary text-center',
                            description: 'Clonar presupuesto',
                            callback: (item: any) => {
                                setOpenModal(true);
                                const budget = { id: item.id, name: item.codeIdentifier }
                                setBudgetData(budget);
                            },
                        },
                        {
                            title: "Eliminar",
                            buttonType: "icon",
                            icon: "Delete",
                            additionalClasses: `text-primary ${userCan("delete", "budget") ? "" : "d-none"}`,
                            description: "Eliminar presupuesto",
                            callback: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar Presupuesto",
                                    text: "¿Está seguro que desea eliminar el presupuesto?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    },
                                });
                            },
                        }, {
                            title: "Sin permisos",
                            buttonType: 'icon',
                            icon: "Block",
                            additionalClasses: `text-danger ${(userCan('edit', 'budget') || userCan('delete', 'budget')) ? 'd-none' : ''}`,
                            description: "No tiene permisos para editar ni eliminar",
                            callback: (item: any) => { },
                        }
                    ]}
                />
            );
        } else {
            return (
                <div className="text-center">
                    <h5 className="text-muted mt-5">No se han encontrado presupuestos</h5>
                </div>
            );
        }
    };

    return (
        <>
            <div className="row d-flex justify-content-between">
                <div className="col-md-11">
                    <CardTitle className="m-0">Lista de presupuestos</CardTitle>
                </div>
                <div className="col-md-1 d-flex justify-content-end">
                    <Button
                        title="Crear presupuesto"
                        color="secondary"
                        isLink
                        isLight
                        icon="Add"
                        className={`${userCan("create", "budget") ? "" : "d-none"}`}
                        onClick={() => { if (userCan("create", "budget")) return navigate(`${FinancesMenu.finances.path}/creacion/${id}`) }}

                    />
                </div>
            </div>

            {getContent()}

            {openModal && budgetData && <CloneBudgetModal isOpen={openModal} setIsOpen={setOpenModal} budget={budgetData} client={id} refetch={refetch} />}
        </>
    )
}

export default Budgets;