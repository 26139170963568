
import { Link, useNavigate } from "react-router-dom";
import { FinancesMenu, authMenu } from "../../../menu";
import { TaskService } from "../../../services/tasks/taskService";
import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { TasksApiResponse } from "../../../type/task-type";
import { toast } from "react-toastify";
import Checks from "../../../components/bootstrap/forms/Checks";
import { CustomTable } from "../../../components/table/CustomTable";
import ErrorMessage from "../../../components/ErrorMessage";
import TaskModalForm from "../../tasks/TaskModalForm";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import useFilters from "../../../hooks/useFilters";

const DashBoardTasksList = () => {

    const user = useSelector((state: RootState) => state.auth?.user);
    const { filters, updateFilterOrder } = useFilters({ is_finished: 0, user: user?.id }, [], 1, 25);
    const navigate = useNavigate();

    const taskService = new TaskService();

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);
    const [finishedTasks, setFinishedTasks] = useState<{ id: string, isFinished: boolean }[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await taskService.listTasks(filters);
        return response.getResponseData() as TasksApiResponse;
    }, [filters]));

    const getTaskStatus = (taskId: string) => {
        const task = finishedTasks.find((task) => task.id === taskId);
        return task ? task.isFinished : false;
    };

    const _handleIsFinished = async (taskId: string) => {
        try {
            const response = await (await taskService.updateTaskStatus(taskId)).getResponseData();
            if (response.success) {
                const task = finishedTasks.find((task) => task.id === taskId);

                // Si existe la tarea en el array de finishedTasks, se cambia el estado
                if (task) {
                    const newFinishedTasks = finishedTasks.map((task) => {
                        if (task.id === taskId) {
                            return { id: taskId, isFinished: !task.isFinished };
                        } else {
                            return task;
                        }
                    });
                    setFinishedTasks(newFinishedTasks);
                } else {
                    const newFinishedTasks = [...finishedTasks, { id: taskId, isFinished: true }];
                    setFinishedTasks(newFinishedTasks);
                }

                toast.success('Estado de la tarea actualizado');
            } else {
                toast.error(response.message || "Error al finalizar la tarea");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            setSelectedItem(null);
            setShow(false);
            setModalOpen(false);
            refetch();
            toast.success(message);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner isGrow /></div>;

        if (error) return <ErrorMessage error={error} />;

        if (data) {
            let apiData = data as TasksApiResponse;

            return (
                <CustomTable
                    title="Tareas"
                    data={apiData ? apiData.tasks : null}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-center" onClick={() => {
                                        setSelectedItem(element);
                                        setModalOpen(true);
                                        setShow(true);
                                    }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Dirección',
                            keyValue: 'address',
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.project?.address || '-'}
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Usuario',
                            keyValue: 'user',
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.user?.name + ' ' + (element.user?.lastName || '')}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Fecha",
                            keyValue: "date",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="text-secondary text-center" >
                                        {moment(element.date.date).format('DD/MM/YYYY')}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Proyecto",
                            keyValue: "project",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    element.project ?
                                        <div
                                            className="cursor-pointer text-primary fw-bold text-center"
                                            onClick={() => { navigate(`${FinancesMenu.project.path}/${element.project.id}/vista`) }}
                                        >
                                            {element.project.budget?.name || element.project.codeIdentifier}
                                        </div>
                                        : <div className="text-center">-</div>
                                )
                            },
                        },
                        {
                            name: "Finalizar",
                            keyValue: "finishDate",
                            className: "text-center",
                            sortable: false,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <Checks
                                            type="switch"
                                            checked={getTaskStatus(element.id)}
                                            onChange={() => { _handleIsFinished(element.id) }}
                                        />
                                        {element.isFinished && moment(element.finishDate?.date).format('DD/MM/YYYY')}
                                    </div>
                                )
                            }
                        },

                    ]}
                />
            );
        }
    };

    return (
        <Card stretch>
            <CardHeader>
                <CardTitle className="text-center">Tareas</CardTitle>
            </CardHeader>
            <CardBody>
                <>{getContent()}</>
            </CardBody>
            <CardFooter className="d-flex justify-content-center">
                <Link className="link" to={authMenu.tasks.path} style={{ color: '#ed291f', textDecoration: 'none' }}>Ver todas</Link>
            </CardFooter>
            {modalOpen && (<TaskModalForm isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />)}
        </Card>
    );
};

export default DashBoardTasksList;