import { FC } from "react";
import { useParams } from "react-router-dom";
import BudgetsForm from "../BudgetsForm";
import { BudgetProvider } from "../providers/BudgetProvider";

const CreateBudget: FC = () => {
    const { id = '' } = useParams<{ id: string }>();

    return (
        <BudgetProvider client={id}>
            <BudgetsForm />
        </BudgetProvider>
    );
}

export default CreateBudget;