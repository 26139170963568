import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { CashFlowProvider } from "./provider/CashFlowProvider";
import CashFlowSchedulePage from "./CashFlowSchedulePage";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export const CashFlowScheduleWrapper = () => {

    const flowFilters = useSelector((state: RootState) => state.scheduler.flowFilters);

    const defaultFilter = {
        estimated_entry_date: {
            from: flowFilters?.estimated_entry_date.from,
            to: flowFilters?.estimated_entry_date.to,
        },
        range_mode_view: "day",
        path: null,
    };

    return (
        <Fragment>
            <ToastContainer />
            <CashFlowProvider defaultFilters={defaultFilter}>
                <CashFlowSchedulePage />
            </CashFlowProvider>
        </Fragment>
    );
}