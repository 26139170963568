import React from 'react';
import SelectReact from 'react-select';

type SelectProps = {
    name?: string;
    isSearchable?: boolean;
    isMulti?: boolean;
    options?: any;
    onChange?: any;
    handle?: any;
    value?: any;
    defaultValue?: any;
    placeholder?: string;
    classname?: string;
    onBlur?: any;
    isClearable?: boolean;
    isDisabled?: boolean;
};

const SearchableSelect: React.FC<SelectProps> = ({ name, isSearchable, isMulti, options, onChange, handle, value, defaultValue, placeholder, classname, onBlur, isClearable, isDisabled }) => {
    return (
        <SelectReact
            name={name}
            id={name}
            isSearchable={isSearchable}
            isMulti={isMulti}
            options={options}
            onChange={onChange ? ((selectedOption: any) => { onChange(selectedOption) }) : handle}
            value={value}
            defaultValue={defaultValue}
            isClearable={isClearable ? isClearable : false}
            placeholder={'Elegir ' + placeholder + '...'}
            noOptionsMessage={() => 'No se ha encontrado ningún resultado'}
            className={classname}
            onBlur={onBlur}
            styles={{
                control: (base, state) => ({
                    ...base,
                    borderRadius: '10px',
                    boxShadow: state.isFocused ? '0 0 0 3px rgba(208, 70, 54, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                    border: '0px !important',
                    backgroundColor: isDisabled ? '#e9ecef' : '#f8f9fa',
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#d04636' : 'white',
                    color: state.isFocused ? 'white' : 'black',
                    '&:hover': {
                        backgroundColor: '#d04636',
                        color: 'white',
                        borderColor: '#30b7af !important'
                    }
                })
            }}
            isDisabled={isDisabled}
        />
    );
};

export default SearchableSelect;