import { FC, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../components/bootstrap/Dropdown";
import classNames from "classnames";

interface StatusDropdownProps {
    itemId: string;
    statusName: string;
    change: (id: string, status: string, toggleStatus: Function) => void;
    options: string[];
    disabled?: boolean;
    className?: string;
}

const StatusDropdown: FC<StatusDropdownProps> = ({ itemId, statusName, change, disabled, className, options }) => {

    const [defaultStatus, setDefaultStatus] = useState<string>(statusName);

    const toggleState = (newStatus: string) => {
        change(itemId, newStatus, setDefaultStatus);
    }

    // Cambiar el color del botón según el estado
    const colorButton = () => {
        switch (defaultStatus) {
            case "En estudio":
                return "dark";
            case "Entregado":
                return "info";
            case "Pendiente":
                return "warning";
            case "Rechazado":
                return "danger";
            case "Aceptado":
                return "success";
            default:
                return "dark";
        }
    }

    return (
        <Dropdown>
            <DropdownToggle hasIcon={false}>
                <Button
                    isLink
                    isDisable={defaultStatus === "Aceptado"}
                    icon='KeyboardArrowDown'
                    color={colorButton()}
                    className={classNames('text-nowrap', className)}
                >
                    {defaultStatus}
                </Button>
            </DropdownToggle>
            <DropdownMenu>
                {options.map((status, index) => (
                    status !== defaultStatus
                        ? (
                            <DropdownItem key={status + index}>
                                <Button
                                    isLink
                                    onClick={() => { toggleState(status) }}
                                    color={colorButton()}
                                    className={classNames('text-nowrap', className)}
                                >
                                    {status}
                                </Button>
                            </DropdownItem>
                        ) : null
                ))}
            </DropdownMenu>
        </Dropdown>
    )
}

export default StatusDropdown;