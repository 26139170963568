import { useCallback, useEffect } from "react";
import { CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import useFetch from "../../../hooks/useFetch";
import { ProjectService } from "../../../services/projects/projectService";
import useHandleDownloadDocument from "../../../hooks/useDownloadDocument";
import { DocumentService } from "../../../services/documents/documentService";
import { CardText } from "react-bootstrap";
import moment from "moment";
import useFilters from "../../../hooks/useFilters";
import { useDirectoryNavigator } from "../../../components/DirectoryNavigator/DirectoryNavigatorProvider";
import { useParams } from "react-router-dom";
import useGetIcon from "../../../hooks/useGetIcon";

export const RecentProjectDocuments = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { updateList, setUpdateList } = useDirectoryNavigator();
    const { handleDownloadDocument } = useHandleDownloadDocument();
    const { getIcon } = useGetIcon();

    const documentService = new DocumentService();
    const projectService = new ProjectService();
    const docsService = projectService.downloadDocument;

    const { filters } = useFilters({ project: id }, [{ field: 'created_at', order: 'desc' }], 1, 14);

    const [documents, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await documentService.listProjectDocuments(filters);
        return response.getResponseData();
    }, [filters]));

    useEffect(() => {
        if (updateList) {
            refetch();
            setUpdateList(false);
        }
    }, [updateList]);

    return (
        <>
            <CardTitle className="g-4 ps-4 h4">Documentos recientes</CardTitle>

            <div className="ps-4 pt-4">
                {documents?.projectDocuments?.map((document: any) => (
                    <div className="row d-flex align-items-center pb-2 mb-2" key={document.document.id} style={{ borderBottom: '1px solid lightgrey' }}>
                        <div className="col-md-8">
                            <CardLabel icon={getIcon(document.document.extension)} iconColor='secondary' className="w-auto">
                                <CardText
                                    onClick={() => { handleDownloadDocument(docsService, document.document.id, document.document.originalName) }}
                                    className="cursor-pointer"
                                    style={{ overflowWrap: 'anywhere' }}
                                >
                                    {document.document.originalName}
                                </CardText>
                            </CardLabel>
                        </div>
                        <div className="col-md-4 text-muted text-end">
                            {moment(document.document.createdAt.date).format("DD/MM/yyyy")}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};