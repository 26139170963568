import { RestServiceConnection } from '../restServiceConnection';

const DEVICES_ENDPOINT = '/devices';

export class DeviceService extends RestServiceConnection {

    register = async (device_token: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DEVICES_ENDPOINT + '/register',
            data: {
                device_token,
                device_type: 'web'
            }
        }, true);
        return this;
    }

}