import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { UserService } from "../../../services/users/userService";
import { User } from "../../../type/user-type";
import { CardTitle, CardBody } from "react-bootstrap";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Card from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import moment from "moment";
import Badge from "../../../components/bootstrap/Badge";
import AsyncImg from "../../../components/AsyncImg";
import { Empty, Skeleton } from "antd";
import { authMenu } from "../../../menu";

const UserProfile = () => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const userService = new UserService;

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await userService.getUserById(id);
        return response.getResponseData() as User;
    }, []));

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>Perfil del usuario</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="brand"
                        icon="Edit"
                        onClick={() => navigate(`${authMenu.users.path}/${id}/edicion`, { replace: true })}
                    >
                        Editar
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>
                <Card>
                    <CardBody>
                        <Skeleton loading={loading} round />

                        {!loading && error && <Empty description="No hay datos del usuario" />}

                        {data && (
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <AsyncImg
                                        isBackground
                                        height="120px"
                                        width="120px"
                                        styles="rounded-circle"
                                        id={data.profileImg?.id}
                                    />
                                </div>

                                <div className="col-md-10">
                                    <div className="row mb-5">
                                        <FormGroup label="NOMBRE:" className="col-md-3">
                                            <p>{data.name + (data.firstName ? ' ' + data.firstName : '') + (data.lastName ? ' ' + data.lastName : '')}</p>
                                        </FormGroup>

                                        <FormGroup label="TELÉFONO:" className="col-md-2">
                                            <p>{data.telephone || '-'}</p>
                                        </FormGroup>

                                        <FormGroup label="EMAIL:" className="col-md-2">
                                            <p>{data.email || '-'}</p>
                                        </FormGroup>

                                        <FormGroup label="DNI:" className="col-md-1">
                                            <p>{data.dni || '-'}</p>
                                        </FormGroup>

                                        <FormGroup label="DIRECCIÓN:" className="col-md-3">
                                            <p>{data.address || '-'}</p>
                                        </FormGroup>
                                    </div>

                                    <div className='row'>
                                        <FormGroup label="ROL:" className="col-md-3">
                                            <div className="d-inline-block ps-2">
                                                <Badge color="secondary" isLight className="fs-6 p-2">{data.userRoles[0]?.role?.name || "-"}</Badge>
                                            </div>
                                        </FormGroup>

                                        <FormGroup label="Nº PERMISOS:" className="col-md-2">
                                            <div className="d-inline-block ps-2">
                                                <Badge color="secondary" isLight className="fs-6 p-2">{data.userPermissions?.length || "-"}</Badge>
                                            </div>
                                        </FormGroup>

                                        <FormGroup label="ÚLTIMO ACCESO:" className="col-md-3">
                                            <div className="d-inline-block ps-2">
                                                {data.lastLogin ? moment(data.lastLogin.date).format("DD-MM-YYYY HH:mm") : "-"}
                                            </div>
                                        </FormGroup>

                                        <FormGroup label="ALTA:" className="col-md-2">
                                            <div className="d-inline-block ps-2">
                                                {data.createdAt ? moment(data.createdAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Page>
        </>
    )
}

export default UserProfile;