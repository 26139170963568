import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ProjectService } from "../../../services/projects/projectService";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { UserService } from "../../../services/users/userService";
import { loadUserFromLocalStorage } from "../../../utils/jwt";
import { CompanyService } from "../../../services/companies/organizationService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Select from "../../../components/bootstrap/forms/Select";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data?: any;
    onClose: (type: number, message?: string, errors?: any) => void;
    show?: boolean;
}

const CreateModalSubsidy: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');
    const proyectService = new ProjectService();

    const [modal, setModal] = useState(false);
    const [company, setCompany] = useState("");
    const [companyList, setCompanyList] = useState([])

    const getCompany = async () => {
        const userService = new UserService;
        const response = (await userService.getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData()
        if (response.success) {
            setCompany(response.data.userRoles[0].company.id)
        }
    }

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    useEffect(() => {
        if (!isSuperAdmin) {
            getCompany();
        } else {
            getCompanies();
        }
    }, [isSuperAdmin])

    const create = async (values: any) => {
        try {
            const response = await (await proyectService.createSubsidy(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear la subvención');
        }
    }

    const update = async (values: any) => {
        try {
            const response = await (await proyectService.editSubsidy(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar la subvención');
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            subsidy: data?.id || '',
            convocatoryName: data?.name || '',
            description: data?.description || '',
            company: data?.company?.id || company,
        },
        onSubmit: (values) => {
            if (values.company != '') {
                if (data != null) {
                    update(values)
                } else {
                    create(values);
                }
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'md' : 'sm'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{show ? 'Ver Subvención' : data ? 'Editar Subvención' : 'Crear Subvención'}</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='convocatoryName' label='Nombre de la Convocatoria' className={isSuperAdmin ? 'col-md-6' : "col-md-12"}>
                                <Input value={formik.values.convocatoryName || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            {isSuperAdmin && (
                                <FormGroup requiredInputLabel id='company' label='Organización' className='col-md-6'>
                                    <Select id="company" disabled={show ? true : false} list={getCompanyList()} onChange={formik.handleChange} value={formik.values.company || ''} ariaLabel='Select Companies' placeholder='Elegir Organización...' />
                                </FormGroup>
                            )}
                            <FormGroup id='description' label='Descripción:' className={"col-md-12"}>
                                <Textarea value={formik.values.description || ''} disabled={show ? true : false} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'}>
                            {data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (
                <div className="d-flex justify-content-center p-5">
                    <Spinner />
                </div>
            )}
        </Modal>
    )
}

export default CreateModalSubsidy;