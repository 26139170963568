import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { DashboardProvider } from "./providers/DashboardProvider";
import DashboardPage from "./DashboardPage";

type DashboardWrapperProps = {}

export const DashboardWrapper: React.FC<DashboardWrapperProps> = () => {
    return (
        <Fragment>
            <ToastContainer />
            <DashboardProvider>
                <DashboardPage />
            </DashboardProvider>
        </Fragment>
    );
}