import { Fragment } from "react"
import { ToastContainer } from "react-toastify"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import ContactsList from "./clients-list/ContactsList"

const ContactsListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider defaultFilterFilters={{ classification: "" }}>
                <ToastContainer />
                <ContactsList />
            </FiltersProvider>
        </Fragment>
    )
}

export default ContactsListWrapper;