import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import useFilters, { FilterOptions, FilterOrders } from "../../../hooks/useFilters";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Budget } from "../../../type/budgets-type";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { BudgetService } from "../../../services/budgets/budgetService";
import { Concept, Stage } from "../BudgetsForm";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { ClientService } from "../../../services/clients/clientService";
import useFetch from "../../../hooks/useFetch";
import { CodeService } from "../../../services/codes/codeService";
import { CodeApiResponse } from "../../../type/code-type";
import { StatusService } from "../../../services/status/statusService";
import { StatusApiResponse } from "../../../type/status-type";
import { SerieApiResponse } from "../../../type/serie-type";
import { SerieService } from "../../../services/series/serieService";
import { TaxService } from "../../../services/taxes/taxService";
import { TaxApiResponse } from "../../../type/tax-type";

const budgetSchema = yup.object({
    client: yup.string(),
    sponsor: yup.string().required('El promotor es obligatorio'),
    name: yup.string().required('El nombre es obligatorio'),
    codeIdentifier: yup.string().matches(/^[0-9]{1,4}-[0-9]{2}-[A-Z]{2,5}-[A-Z]{2,5}$/, 'El código debe tener el siguiente formato: 0000-00-AAAAA-AAAAA'),
    address: yup.string(),
    code: yup.string().required('El código es obligatorio'),
    status: yup.string().required('El estado es obligatorio'),
    series: yup.string().required('La serie es obligatoria'),
    paymentMethod: yup.string(),
    expirationDate: yup.string().required('La fecha de vencimiento es obligatoria'),
    taxes: yup.string().required('El impuesto es obligatorio'),
    withholdings: yup.number().required('La retención es obligatoria').min(0, 'La retención no puede ser inferior a 0%').max(100, 'La retención no puede ser superior a 100%'),
});

const budgetSchemaClientRequired = yup.object({
    client: yup.string().required('El cliente es obligatorio'),
    sponsor: yup.string().required('El promotor es obligatorio'),
    name: yup.string().required('El nombre es obligatorio'),
    codeIdentifier: yup.string().matches(/^[0-9]{1,4}-[0-9]{2}-[A-Z]{2,5}-[A-Z]{2,5}$/, 'El código debe tener el siguiente formato: 0000-00-AAAAA-AAAAA'),
    address: yup.string(),
    code: yup.string().required('El código es obligatorio'),
    status: yup.string().required('El estado es obligatorio'),
    series: yup.string().required('La serie es obligatoria'),
    paymentMethod: yup.string(),
    expirationDate: yup.string().required('La fecha de vencimiento es obligatoria'),
    taxes: yup.string().required('El impuesto es obligatorio'),
    withholdings: yup.number().required('La retención es obligatoria').min(0, 'La retención no puede ser inferior a 0%').max(100, 'La retención no puede ser superior a 100%'),
});

const conceptInitialValues: Concept = {
    id: '',
    name: '',
    textField: '',
    amount: 0,
    quantity: 1,
    tax: 0,
    withholdings: 0,
}

const conceptsSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    textField: yup.string(),
    amount: yup.number().required('El precio es obligatorio').min(0, 'El precio no puede ser inferior a 0€'),
    quantity: yup.number().required('La cantidad es obligatoria').min(1, 'La cantidad no puede ser inferior a 1'),
    taxes: yup.string(),
});

const stageInitialValues: Stage = {
    id: '',
    name: '',
    textField: '',
    duration: 1,
    concepts: [],
}

const stageSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    textField: yup.string(),
    duration: yup.number().required('El tiempo estimado es obligatorio').min(0, 'No puede ser inferior a 0 semanas').max(1000, 'No puede ser superior a 1000 semanas'),
    concepts: yup.array().of(
        yup.object().shape({
            id: yup.string().required('El concepto es obligatorio'),
            percentage: yup.number().required('El porcentaje es obligatorio').min(0, 'No puede ser inferior a 0%').max(100, 'No puede ser superior a 100%'),
        })
    )
});

type BudgetProviderProps = {
    children: React.ReactNode,
    defaultFilters?: FilterOptions,
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
    budgetData?: Budget,
    submitEdit?: (values: any) => void,
    client?: string,
    edit?: boolean,
}

type BudgetContextData = {
    budgetData: Budget,
    client: string,
    filters: FilterOptions | any,
    updateFilters: (filters: any) => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    formik: any,
    verifyClass: (inputConceptID: keyof Budget) => '' | 'is-invalid',
    showErrors: (inputConceptID: keyof Budget) => JSX.Element,
    isSubmitting: boolean,
    formikConcepts: any,
    verifyClassConcepts: (inputConceptID: keyof Concept) => '' | 'is-invalid',
    showErrorsConcepts: (inputConceptID: keyof Concept) => JSX.Element,
    formikStages: any,
    verifyClassStages: (inputConceptID: keyof Stage) => '' | 'is-invalid',
    showErrorsStages: (inputConceptID: keyof Stage) => JSX.Element,
    mode: string,
    taxesApiData: any,

    setClientSearch: (value: string) => void,
    setSponsorSearch: (value: string) => void,
    clientOptions: any,
    sponsorOptions: any,
    setTaxSelected: (tax: any) => void,

    defaultCodeValue: { value: string, label: string } | null,
    defaultStatusValue: { value: string, label: string } | null,
    defaultSeriesValue: { value: string, label: string } | null,
    defaultTaxesValue: { value: string, label: string } | null,
    getCodesIdentifiersList: () => { value: string, label: string, isSelected: boolean }[],
    getStatusList: () => { value: string, label: string, isSelected: boolean }[],
    getSeriesList: () => { value: string, label: string, isSelected: boolean }[],
    taxesList: () => { value: string, label: string, isSelected: boolean }[],

    conceptsData: Concept[],
    setConceptsData: (concepts: Concept[]) => void,
    stagesData: Stage[],
    setStagesData: (stages: Stage[]) => void,
    nameSelected: string,
    setNameSelected: (name: string) => void,
    stageConceptId: { value: string, label: string },
    setStageConceptId: (id: { value: string, label: string }) => void,
    stageConceptPercentage: number,
    setStageConceptPercentage: (percentage: number) => void,
    stageConceptsPercents: { id: string, percent: number }[],
    setStageConceptsPercents: (percents: { id: string, percent: number }[]) => void,
}

const BudgetContext: React.Context<BudgetContextData> = React.createContext<BudgetContextData>({
    budgetData: {} as Budget,
    client: '',
    filters: {} as FilterOptions | any,
    updateFilters: (filters: any) => { },
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    formik: {} as any,
    verifyClass: (inputConceptID: keyof Budget) => { return '' },
    showErrors: (inputConceptID: keyof Budget) => { return (<></>) },
    isSubmitting: false,
    formikConcepts: {} as any,
    verifyClassConcepts: (inputConceptID: keyof Concept) => { return '' },
    showErrorsConcepts: (inputConceptID: keyof Concept) => { return (<></>) },
    formikStages: {} as any,
    verifyClassStages: (inputConceptID: keyof Stage) => { return '' },
    showErrorsStages: (inputConceptID: keyof Stage) => { return (<></>) },
    mode: '',
    taxesApiData: null,

    setClientSearch: () => { },
    setSponsorSearch: () => { },
    clientOptions: [],
    sponsorOptions: [],
    setTaxSelected: () => { },

    defaultCodeValue: null,
    defaultStatusValue: null,
    defaultSeriesValue: null,
    defaultTaxesValue: null,
    getCodesIdentifiersList: () => [],
    getStatusList: () => [],
    getSeriesList: () => [],
    taxesList: () => [],

    conceptsData: [],
    setConceptsData: () => { },
    stagesData: [],
    setStagesData: () => { },
    nameSelected: '',
    setNameSelected: () => { },
    stageConceptId: { value: '', label: '' },
    setStageConceptId: () => { },
    stageConceptPercentage: 0,
    setStageConceptPercentage: () => { },
    stageConceptsPercents: [],
    setStageConceptsPercents: () => { },
});

const BudgetProvider: React.FC<BudgetProviderProps> = ({ children, defaultFilters, defaultOrders, defaultPage, defaultPageSize, budgetData, submitEdit, client, edit }) => {

    const mode = edit ? 'Editar' : 'Crear';
    const currentYear = moment().year();
    const token = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const timeoutRef = useRef<any>(null);
    const budgetService = new BudgetService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [codes] = useFetch(useCallback(async () => {
        const codeService = new CodeService();
        const response = await codeService.listCodes();
        return response.getResponseData() as CodeApiResponse;
    }, []));

    const [statuses] = useFetch(useCallback(async () => {
        const statusService = new StatusService();
        const response = await statusService.listStatuses({ filter_filters: { oriented_to: 'budget' } });
        return response.getResponseData() as StatusApiResponse;
    }, []));

    const [series] = useFetch(useCallback(async () => {
        const serieService = new SerieService();
        const response = await serieService.listSeries();
        return response.getResponseData() as SerieApiResponse;
    }, []));

    const [taxes] = useFetch(useCallback(async () => {
        const taxService = new TaxService();
        const response = await taxService.listTaxes();
        return response.getResponseData() as TaxApiResponse;
    }, []));

    const [budgetInitialValues, setBudgetInitialValues] = useState<Budget | any>({
        budget: '',
        name: '',
        codeIdentifier: '',
        address: '',
        client: '',
        sponsor: '',
        code: '',
        status: '',
        company: '',
        year: currentYear,
        series: '',
        paymentMethod: 'Transferencia bancaria',
        expirationDate: (' ')[0] || '',
        taxes: '',
        withholdings: 0,
        concepts: [],
        stages: [],
    });
    const [conceptsData, setConceptsData] = useState<Concept[]>([]);
    const [stagesData, setStagesData] = useState<Stage[]>([]);
    const [taxSelected, setTaxSelected] = useState<any>();
    const [clientSearch, setClientSearch] = useState('');
    const [sponsorSearch, setSponsorSearch] = useState('');
    const [clientOptions, setClientOptions] = useState<any>([]);
    const [sponsorOptions, setSponsorOptions] = useState<any>([]);
    const [nameSelected, setNameSelected] = useState<string>('');
    const [stageConceptId, setStageConceptId] = useState<{ value: string, label: string }>({ value: '', label: '' });
    const [stageConceptPercentage, setStageConceptPercentage] = useState<number>(0);
    const [stageConceptsPercents, setStageConceptsPercents] = useState<{ id: string, percent: number }[]>([]);

    const getCodesIdentifiersList = () => {
        if (codes) {
            return codes.codes.map((code: any) => {
                return {
                    value: code.id,
                    label: code.name,
                    isSelected: code.id === budgetInitialValues.code,
                }
            })
        }
        return [];
    }

    const getStatusList = () => {
        if (statuses) {
            return statuses.statuses.map((status: any) => {
                return {
                    value: status.id,
                    label: status.name,
                    isSelected: status.id === budgetInitialValues.status,
                }
            })
        }
        return [];
    }

    const getSeriesList = () => {
        if (series) {
            return series.series.map((serie: any) => {
                return {
                    value: serie.id,
                    label: serie.name,
                    isSelected: serie.id === budgetInitialValues.series,
                }
            })
        }
        return [];
    }

    const taxesList = () => {
        if (taxes) {
            return taxes.taxes.map((tax: any) => {
                return {
                    value: tax.id,
                    label: tax.value,
                    isSelected: tax.id === budgetInitialValues.taxes,
                }
            })
        }
        return [];
    }

    // Seleccionar por defecto el cliente, código, estado, serie e impuesto del presupuesto
    const codeSelected = getCodesIdentifiersList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const statusSelected = getStatusList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const seriesSelected = getSeriesList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const taxesSelected = taxesList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const defaultCodeValue = codeSelected[0] ? { value: codeSelected[0].value, label: codeSelected[0].label } : null;
    const defaultStatusValue = statusSelected[0] ? { value: statusSelected[0].value, label: statusSelected[0].label } : null;
    const defaultSeriesValue = seriesSelected[0] ? { value: seriesSelected[0].value, label: seriesSelected[0].label } : null;
    const defaultTaxesValue = taxesSelected[0] ? { value: taxesSelected[0].value, label: taxesSelected[0].label } : null;

    // Fill the select fields with the API data
    const getClientsOptions = async (id?: string) => {
        if (id !== undefined) { updateFilters({ client: id }) };
        const response = (await ((new ClientService).getClients({ ...filters, limit: 1000 }))).response;
        if (response?.status === 200) {
            const options = response?.data?.data?.clients?.map((item: { id: number; name: string, firstName: string, lastName: string, clientContactPerson: any }) => (
                {
                    value: item.id,
                    label: item.name
                        + (item.firstName ? ' ' + item.firstName : '') + (item.lastName ? ' ' + item.lastName : '')
                        + (item.clientContactPerson ? ' (' + item.clientContactPerson.name + (item.clientContactPerson?.firstName ? (' ' + item.clientContactPerson.firstName) : '') + (item.clientContactPerson?.lastName ? (' ' + item.clientContactPerson.lastName) : '') + ')' : ''),
                }
            ));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setClientOptions(options);
        }
    };
    const getSponsorsOptions = async (id?: string) => {
        if (id !== undefined) { updateFilters({ client: id }) };
        const response = (await ((new ClientService).getClients({ ...filters, limit: 1000 }))).response;

        if (response?.status === 200) {
            const options = response?.data?.data?.clients?.map((item: { id: number; name: string, firstName: string, lastName: string, clientContactPerson: any }) => (
                {
                    value: item.id,
                    label: item.name
                        + (item.firstName ? ' ' + item.firstName : '') + (item.lastName ? ' ' + item.lastName : '')
                        + (item.clientContactPerson ? ' (' + item.clientContactPerson.name + (item.clientContactPerson?.firstName ? (' ' + item.clientContactPerson.firstName) : '') + (item.clientContactPerson?.lastName ? (' ' + item.clientContactPerson.lastName) : '') + ')' : ''),
                }
            ));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setSponsorOptions(options);
        }
    };

    /* *********** General budget information *********** */
    // Create budget
    const _handleCreateBudget = async (values: any) => {
        setIsSubmitting(true);
        values.company = token?.user ? token.user.company : '';
        client !== '' && (values.client = client);
        values.concepts = conceptsData ? [...conceptsData, formikConcepts.values] : [formikConcepts.values];
        values.stages = stagesData ? [...stagesData, formikStages.values] : [formikStages.values];
        values.concepts = values.concepts.filter((concept: any) => concept.id !== '');
        //values.stages = values.stages.filter((stage: any) => (stage.id !== '' && stage.name !== ''));

        try {
            const response = (await budgetService.createBudget(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                toast.success('Presupuesto creado correctamente');
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el presupuesto');
        } finally {
            setIsSubmitting(false);
        }
    }
    const formik = useFormik<Budget>({
        initialValues: mode === 'Crear' ? budgetInitialValues : budgetData,
        validationSchema: client !== '' ? budgetSchema : budgetSchemaClientRequired,
        onSubmit: values => {
            if (mode === 'Crear') {
                _handleCreateBudget(values);
            } else {
                submitEdit && submitEdit(values);
            }
        }
    });
    const verifyClass = (inputConceptID: keyof Budget) => { return (formik.touched[inputConceptID] && formik.errors[inputConceptID]) ? 'is-invalid' : '' };
    const showErrors = (inputConceptID: keyof Budget) => {
        return (formik.touched[inputConceptID] && formik.errors[inputConceptID]) ? (<div className="invalid-feedback">{String(formik.errors[inputConceptID])}</div>) : (<></>);
    };

    /* *********** Concepts methods *********** */
    // Add concept to budget
    const _handleAddConcept = async (values: any) => {
        if (formik.values.taxes === undefined || formik.values.taxes === '') {
            toast.error('Debes seleccionar un impuesto');
            return;
        }
        if (conceptsData && conceptsData.some((concept: Concept) => (concept.name !== 'Otros') && (concept.name === values.name))) {
            toast.error('El concepto ' + values.name + ' ya existe');
            return;
        }
        values.id = uuidv4(values.name);
        values.taxes = taxSelected;
        values.withholdings = formik.values.withholdings;
        setConceptsData(conceptsData ? [...conceptsData, values] : [values]);
        formik.setFieldValue('concepts', [...conceptsData, values]);
        formikConcepts.resetForm();
    };
    const formikConcepts = useFormik<Concept>({
        initialValues: conceptInitialValues,
        validationSchema: conceptsSchema,
        onSubmit: values => _handleAddConcept(values)
    });
    const verifyClassConcepts = (inputConceptID: keyof Concept) => { return (formikConcepts.touched[inputConceptID] && formikConcepts.errors[inputConceptID]) ? 'is-invalid' : '' };
    const showErrorsConcepts = (inputConceptID: keyof Concept) => {
        return (formikConcepts.touched[inputConceptID] && formikConcepts.errors[inputConceptID]) ? (<div className="invalid-feedback">{String(formikConcepts.errors[inputConceptID])}</div>) : (<></>);
    };


    /* *********** Stage methods *********** */
    // Add stage to budget
    const _handleAddStage = async (values: any) => {
        if (stagesData && stagesData.some((stage: Stage) => stage === values)) {
            toast.error('Ya existe una fase exactamente igual');
            return;
        }
        setStagesData(stagesData ? [...stagesData, values] : [values]);
        formik.setFieldValue('stages', [...stagesData, values]);
        formikStages.resetForm();
    };
    const formikStages = useFormik<Stage>({
        initialValues: stageInitialValues,
        validationSchema: stageSchema,
        onSubmit: values => _handleAddStage(values)
    });
    const verifyClassStages = (inputConceptID: keyof Stage) => { return (formikStages.touched[inputConceptID] && formikStages.errors[inputConceptID]) ? 'is-invalid' : '' };
    const showErrorsStages = (inputConceptID: keyof Stage) => {
        return (formikStages.touched[inputConceptID] && formikStages.errors[inputConceptID]) ? (<div className="invalid-feedback">{String(formikStages.errors[inputConceptID])}</div>) : (<></>);
    };

    // Fill the fields with the API data
    useEffect(() => {
        if (budgetData) {
            budgetData.client && getClientsOptions(budgetData.client);
            budgetData.sponsor && getSponsorsOptions(budgetData.sponsor);

            setBudgetInitialValues({
                budget: budgetData?.budget,
                name: budgetData?.name,
                codeIdentifier: budgetData?.codeIdentifier,
                address: budgetData?.address,
                client: budgetData?.client,
                sponsor: budgetData?.sponsor,
                code: budgetData?.code,
                status: budgetData?.status,
                company: budgetData?.company,
                year: budgetData?.year,
                series: budgetData?.series,
                paymentMethod: budgetData?.paymentMethod,
                expirationDate: budgetData?.expirationDate?.split(' ')[0],
                taxes: budgetData?.taxes,
                withholdings: budgetData?.withholdings,
                concepts: budgetData?.concepts,
                stages: budgetData?.stages,
            });

            // Fill the concepts and stages with the budget data
            const concepts = budgetData?.concepts?.map((concept: any) => {
                return {
                    id: concept.id,
                    name: concept.name,
                    textField: concept.textField,
                    amount: concept.amount,
                    quantity: concept.quantity,
                    tax: budgetData.taxes,
                    withholdings: budgetData.withholdings,
                }
            });
            setConceptsData(concepts);

            const stages = budgetData?.stages?.map((stage: any) => {
                const stageConcepts = stage.stageHasConcept.map((concept: any) => {
                    return {
                        id: concept.concepts.id,
                        percentage: concept.percentage,
                    }
                });
                return {
                    id: stage.id,
                    name: stage.name,
                    textField: stage.textField,
                    duration: stage.duration,
                    concepts: stageConcepts,
                }
            });
            setStagesData(stages);
        }
    }, [budgetData]);

    // Prevents the tab from closing or changing routes if there are unsaved changes
    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
            if (formik.dirty || formikConcepts.dirty || formikStages.dirty) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formik.values, formikConcepts.values, formikStages.values]);

    // When a client is selected and the sponsor is empty, it is filled with the selected client
    useEffect(() => {
        if (formik.values.client !== '' && formik.values.sponsor === '') {
            formik.setFieldValue('sponsor', formik.values.client);
        }
    }, [formik.values.client]);


    // Search clients and sponsors
    useEffect(() => {
        if (clientSearch !== '' && clientSearch.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getClientsOptions();
            }, 500);
            return () => { clearTimeout(timeoutRef.current) }
        }
    }, [clientSearch]);
    useEffect(() => {
        if (sponsorSearch !== '' && sponsorSearch.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getSponsorsOptions();
            }, 500);
            return () => { clearTimeout(timeoutRef.current) }
        }
    }, [sponsorSearch]);

    return (
        <BudgetContext.Provider value={{
            budgetData: budgetData || budgetInitialValues,
            client: client || '',
            filters,
            updateFilters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,

            formik,
            verifyClass,
            showErrors,
            isSubmitting,
            formikConcepts,
            verifyClassConcepts,
            showErrorsConcepts,
            formikStages,
            verifyClassStages,
            showErrorsStages,
            mode,
            taxesApiData: taxes,

            setClientSearch,
            setSponsorSearch,
            clientOptions,
            sponsorOptions,
            setTaxSelected,

            defaultCodeValue,
            defaultStatusValue,
            defaultSeriesValue,
            defaultTaxesValue,
            getCodesIdentifiersList,
            getStatusList,
            getSeriesList,
            taxesList,

            conceptsData,
            setConceptsData,
            stagesData,
            setStagesData,
            nameSelected,
            setNameSelected,
            stageConceptId,
            setStageConceptId,
            stageConceptPercentage,
            setStageConceptPercentage,
            stageConceptsPercents,
            setStageConceptsPercents,
        }}>
            {children}
        </BudgetContext.Provider>
    );
}

BudgetProvider.defaultProps = {
    budgetData: {} as Budget,
    defaultFilters: {} as FilterOptions,
    defaultOrders: [] as FilterOrders,
    defaultPage: 1,
    defaultPageSize: 10,
    submitEdit: () => { },
    client: '',
    edit: false,
}

export { BudgetProvider, BudgetContext };

export function useBugdetForm() {
    return useContext(BudgetContext);
}