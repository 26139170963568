import { useFormik } from "formik";
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardTitle } from "../../../../components/bootstrap/Card";
import { toast } from "react-toastify";
import { ChangeEvent, FC, useContext, useEffect } from "react";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";

interface NotesProps {
    noteValue: string;
    clientId: string;
    service: any;
    refetch: any;
}

const Notes: FC<NotesProps> = ({ noteValue, clientId, service, refetch }) => {

    const { userCan } = useContext(PrivilegeContext);

    const _handleSaveNote = async (values: any) => {
        values.client = clientId;

        try {
            const response = await (await service.editNote(values)).getResponseData();

            if (response.success) {
                refetch();
                toast.success('Nota guardada correctamente');
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }

        } catch (error: any) {
            toast.error(error.message || "Error al guardar la nota");
        }
    };

    const formik = useFormik({
        initialValues: { note: noteValue },
        onSubmit: values => _handleSaveNote(values)
    });

    useEffect(() => {
        if (noteValue !== null && noteValue !== undefined) {
            formik.setFieldValue('note', noteValue);
        }
    }, [noteValue]);

    return (
        <>
            <div className="row d-flex justify-content-between align-items-center">
                <div className="col-md-11">
                    <CardTitle className="m-0">Notas del cliente</CardTitle>
                </div>
                <div className="col-md-1 d-flex justify-content-end">
                    <Button
                        title="Guardar nota"
                        color="secondary"
                        isLink
                        isLight
                        icon="Save"
                        className={userCan('edit', 'clients') ? '' : 'd-none'}
                        onClick={formik.handleSubmit}
                    />
                </div>
            </div>

            <CardBody>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <textarea
                        id='legalText'
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => formik.setFieldValue('note', e.target.value)}
                        rows={4}
                        value={formik.values.note}
                        onBlur={formik.handleBlur}
                        style={{
                            borderRadius: '10px',
                            boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                            backgroundColor: '#f8f9fa',
                            width: '100%',
                            padding: '15px',
                            border: '0'
                        }}
                        disabled={!userCan('edit', 'clients')}
                    />
                </form>
            </CardBody>
        </>
    )
}

export default Notes;