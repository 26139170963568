import React, { FC, Fragment, useContext, useEffect, useState } from 'react'
import Button from '../../../../../components/bootstrap/Button'
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../../components/icon/Icon'
import Select from '../../../../../components/bootstrap/forms/Select'
import { PrivilegeContext } from '../../../../../components/priviledge/PriviledgeProvider'
import { CompanyService } from '../../../../../services/companies/organizationService'
import CustomSearchInput from '../../../../../components/bootstrap/forms/CustomSearchInput'
import { CostService } from '../../../../../services/costs/costService'
import Input from '../../../../../components/bootstrap/forms/Input'
import { FormControl } from 'react-bootstrap'

interface IFixedCostFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: () => void;
    filters: any;
}

const FixedCostFilters: FC<IFixedCostFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const [filterMenu, setFilterMenu] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [costTypeList, setCostTypeList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState<any>(filters.filter_filters.company || '');

    const [startDate, setStartDate] = useState<string>(filters.filter_filters?.estimated_entry_date?.from || '');
    const [endDate, setEndDate] = useState<string>(filters.filter_filters?.estimated_entry_date?.to || '');
    const [startDateEffective, setStartDateEffective] = useState<string>(filters.filter_filters?.effective_entry_date?.from || '');
    const [endDateEffective, setEndDateEffective] = useState<string>(filters.filter_filters?.effective_entry_date?.to || '');

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const getCostTypes = async () => {
        const costService = new CostService();
        const response = await (await costService.getCostTypes({
            filter_order: [
                {
                    "field": "name",
                    "order": "asc"
                }
            ]
        })).getResponseData();
        if (response.success) {
            const costTypes = response.data.costsTypes;
            setCostTypeList(costTypes);
        }
    }

    const getCostTypeList = () => {
        if (costTypeList as any) {
            let costTypeOptions = costTypeList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return [{ value: '', label: 'Todos' }, ...costTypeOptions]
        }
        return [];
    }

    useEffect(() => {
        getCostTypes();
        if (isSuperAdmin) {
            getCompanies();
        }
    }, [isSuperAdmin]);

    function resetValues() {
        setStartDate('');
        setEndDate('');
        setStartDateEffective('');
        setEndDateEffective('');
    }

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput

                onSearch={(search: string) => {
                    updateFilters({ search_text: search });
                }}
                placeholder='Buscar...'
            />

            <Button color='primary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='FixedCostFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='FixedCostFilters'> Filtros de Costes Fijos </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userCan('list', 'companies') && (
                            <FormGroup id='filter0' label='Organización' className='col-md-12'>
                                <Select
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ company: e.target.value })
                                        setSelectedCompany(e.target.value)
                                    }}
                                    value={selectedCompany}
                                    id='company-select'
                                    ariaLabel='Company selector'
                                    placeholder='Organización...'
                                />
                            </FormGroup>
                        )}

                        <FormGroup id='filter1' label='Tipo de Coste' className='col-md-12'>
                            <Select
                                list={getCostTypeList()}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { updateFilters({ costs_type: e.target.value }) }}
                                value={filters.filter_filters?.costs_type || ''}
                                id='costs-type-select'
                                ariaLabel='Costs type selector'
                            />
                        </FormGroup>

                        <FormGroup id='filter2' label='Rango de Precios:' className='col-md-12'>
                            <div className='row me-2'>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Mínimo: </p>
                                        </div>
                                        <div className='col-md-8 p-0'>
                                            <Input
                                                name='minAmount'
                                                type='number'
                                                value={filters.filter_filters?.price?.from ?? ''}
                                                onChange={(e: any) => { updateFilters({ price: { from: e.target.value, to: filters.filter_filters?.price?.to ?? null } }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Máximo: </p>
                                        </div>
                                        <div className='col-md-8 p-0'>
                                            <Input
                                                name='maxAmount'
                                                type='number'
                                                value={filters.filter_filters?.price?.to || ''}
                                                onChange={(e: any) => { updateFilters({ price: { from: filters.filter_filters?.price?.from ?? null, to: e.target.value } }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>

                        <FormGroup id='filter3' className='col-md-12' label='Fecha Estimada'>
                            <div className="row">
                                <div className='flex-column col-md-6'>
                                    <label htmlFor="startDate" className='mr-2 nowrap font-weight-bold'>Fecha Inicial</label>
                                    <FormControl
                                        type="date"
                                        id="startDate"
                                        value={startDate}
                                        onChange={(e: any) => {
                                            setStartDate(e.target.value);
                                            updateFilters({
                                                estimated_entry_date: {
                                                    from: e.target.value,
                                                    to: filters.filter_filters?.estimated_entry_date?.to ?? null
                                                }
                                            });
                                        }}
                                        max={endDate ? endDate : ''}
                                    />
                                </div>
                                <div className='flex-column col-md-6'>
                                    <label htmlFor="endDate" className='mr-2 nowrap font-weight-bold'>Fecha Final</label>
                                    <FormControl
                                        type="date"
                                        id="endDate"
                                        value={endDate}
                                        onChange={(e: any) => {
                                            setEndDate(e.target.value);
                                            updateFilters({
                                                estimated_entry_date: {
                                                    from: filters.filter_filters?.estimated_entry_date?.from ?? null,
                                                    to: e.target.value
                                                }
                                            })
                                        }}
                                        min={startDate ? startDate : ''}
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </div>

                    <div className='col-12 text-center'>
                        <Button
                            className='mt-4'
                            color="secondary"
                            onClick={() => {
                                resetFilters()
                                setSelectedCompany('')
                                resetValues()
                            }}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default FixedCostFilters;