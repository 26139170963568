import { ChangeEvent, Fragment, useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { RoleService } from "../../../services/auth/roleService";
import { RolesApiResponse } from "../../../type/role-type";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import RoleCreateModal from "../role-create/RoleCreateModal";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import useFilters from "../../../hooks/useFilters";
import { loadUserFromLocalStorage } from "../../../utils/jwt";
import { UserService } from "../../../services/users/userService";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";

export interface IRoleFilters {
  name?: string;
}

const roleFilters: IRoleFilters = {
  name: '',
};

const RoleList = () => {

  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const id = loadUserFromLocalStorage()?.id;
  const roleService = new RoleService();

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(roleFilters);

  const [roleCreationModal, setRoleCreationModal] = useState(false);
  const [company, setCompany] = useState<string | null>(null);

  const getCompanyId = async () => {
    if (id) {
      const response = ((await (new UserService()).getUserById(id)).getResponseData());
      if (response.success) {
        setCompany(response.data.userRoles[0].company.id);
      }
    }
  }

  const [roleData, loadingRole, errorRole, refetch] = useFetch(useCallback(async () => {
    const response = await roleService.listRoles(filters);
    return response.getResponseData() as RolesApiResponse;
  }, [filters]));

  const handleDelete = async (id: string) => {
    try {
      const response = (await roleService.deleteRole(id, company ?? '')).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success("Rol eliminado correctamente");
        }, 100);
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error(error);
    }
  }

  useEffect(() => {
    getCompanyId();
  }, []);

  const getContent = () => {
    if (errorRole) return <ErrorMessage error={errorRole} />;

    const renderRoleTable = (props: any) => {
      const { roleData, handleConfirmationAlert, handleDelete, navigate } = props;

      /* const filteredRoleData = roleData.filter((item: any) =>
        item.company && item.company.name.toLowerCase().includes(searchValue.toLowerCase())
      ); */

      return (
        <CustomTable
          title="Roles"
          data={roleData.roles ? roleData.roles : null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: roleData as RolesApiResponse ? roleData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "Organización",
              keyValue: 'organization',
              className: (userCan("list", "companies") ? "d-bolck" : "d-none"),
              isVisible: (userCan("list", "companies") ? true : false),
              render: (item: any) => {
                return <>{item.company ? item.company.name : '-'}</>
              },
            },
            {
              name: "Nombre del rol",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>{element.name || "-"}</>
                );
              },
            },
            {
              name: "Descripción",
              keyValue: "description",
              render: (element: any) => {
                return (
                  <>{element.description || "-"}</>
                );
              },
            },
            {
              name: "Nº Permisos asociados",
              keyValue: "permissions",
              render: (item: any) => {
                return (
                  <>{item.permissions.length}</>
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={[
            {
              title: "Editar",
              buttonType: 'icon',
              icon: "Edit",
              additionalClasses: `text-primary ${userCan("edit", "roles") ? "" : "d-none"}`,
              description: "Editar rol",
              callback: (item: any) => {
                if (userCan("edit", "roles")) return navigate(`${item.id}/edicion`);
              },
            },
            {
              title: "Eliminar",
              buttonType: 'icon',
              icon: "Delete",
              additionalClasses: `text-danger ${userCan("delete", "roles") ? "" : "d-none"}`,
              description: "Eliminar rol",
              callback: (item: any) => {
                if (userCan("delete", "roles")) return;
                handleConfirmationAlert({
                  title: "Eliminar organización",
                  text: "¿Está seguro que desea eliminar el rol?",
                  icon: "warning",
                  onConfirm: () => {
                    handleDelete(item.id);
                  }
                })
              },
            },
            {
              title: "Sin permisos",
              buttonType: 'icon',
              icon: "Block",
              additionalClasses: `text-danger ${(userCan('edit', 'roles') || userCan('delete', 'roles')) ? 'd-none' : ''}`,
              description: "No tiene permisos para editar ni eliminar",
              callback: (item: any) => { },
            }
          ]}
        />
      );
    };

    if (roleData) {
      return renderRoleTable({
        roleData,
        handleConfirmationAlert,
        handleDelete,
        navigate,
      });
    };
  }

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Roles</CardTitle>
          <SubheaderSeparator />
          <Button
            color="primary"
            icon="Add"
            isLight
            onClick={() => setRoleCreationModal(true)}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
            <Icon icon='Search' size='2x' color='primary' />
          </label>

          <CustomSearchInput
            onSearch={(search: string) => {
              updateFilters({ search_array: search });
            }}
            placeholder='Buscar...'
          />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card>
          <CardBody className="table-responsive">
            <Fragment>{getContent()}</Fragment>
          </CardBody>
        </Card>

        {roleCreationModal && <RoleCreateModal isOpen={roleCreationModal} setIsOpen={setRoleCreationModal} refetch={refetch} />}
      </Page>
    </Fragment>
  );
};

export default RoleList;