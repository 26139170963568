import React, { FC, Fragment, useContext, useEffect, useState } from 'react'
import Button from '../../../../../components/bootstrap/Button'
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../../components/icon/Icon'
import Select from '../../../../../components/bootstrap/forms/Select'
import { PrivilegeContext } from '../../../../../components/priviledge/PriviledgeProvider'
import { CompanyService } from '../../../../../services/companies/organizationService'
import CustomSearchInput from '../../../../../components/bootstrap/forms/CustomSearchInput'
import { IncomeService } from '../../../../../services/incomes/incomeService'

interface IIncomeTypeFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: () => void;
    filters: any;
}

const IncomeTypeFilters: FC<IIncomeTypeFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const [filterMenu, setFilterMenu] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [incomeTypeList, setIncomeTypeList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState<any>(filters.filter_filters.company || '');

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }
    const getIncomeTypes = async () => {
        const incomeService = new IncomeService();
        const response = await (await incomeService.getIncomeTypes({
            filter_filters: { 
                has_children: true 
            }, 
            filter_order: [
                {
                    "field": "name",
                    "order": "asc"
                }
            ]
        })).getResponseData();
        if (response.success) {
            const incomeTypes = response.data.incomeTypes;
            setIncomeTypeList(incomeTypes);
        }
    }

    const getIncomeTypeList = () => {
        if (incomeTypeList as any) {
            let incomeTypeOptions = incomeTypeList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return [{ value: '', label: 'Todos' }, ...incomeTypeOptions]
        }
        return [];
    }

    useEffect(() => {
        getIncomeTypes();
        if (isSuperAdmin) {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput
            
                onSearch={(search: string) => {
                    updateFilters({ search_text: search });
                }}
                placeholder='Buscar...'
            />

            <Button color='primary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='incomeTypeFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='incomeTypeFilters'> Filtros de Tipo de Ingresos </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userCan('list', 'companies') && (
                            <FormGroup id='filter0' label='Organización' className='col-md-12'>
                                <Select
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ company: e.target.value })
                                        setSelectedCompany(e.target.value)
                                    }}
                                    value={selectedCompany}
                                    id='company-select'
                                    ariaLabel='Company selector'
                                    placeholder='Organización...'
                                />
                            </FormGroup>
                        )}
                        <FormGroup id='filter1' label='Categoria padre' className='col-md-12'>
                            <Select
                                list={getIncomeTypeList()}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    updateFilters({ parent: e.target.value })
                                    setSelectedCompany(e.target.value)
                                }}
                                value={selectedCompany}
                                id='company-select'
                                ariaLabel='Company selector'
                                placeholder='Organización...'
                            />
                        </FormGroup>

                        <FormGroup id='filter2' label='Contiene categoria:' className='col-md-12'>
                            <Select
                                id='active-select'
                                list={[
                                    { value: '3', label: 'Todos', text: 'Todos' },
                                    { value: '0', label: 'Con categoria padre', text: 'Con categoria padre' },
                                    { value: '1', label: 'Sin categoria padre', text: 'Sin categoria padre' },
                                ]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ parents: e.target.value === '3' ? undefined : e.target.value === '1' })}
                                value={filters.filter_filters.parents != undefined ? filters.filter_filters.parents ? '1' : '0' : '3' } 
                                placeholder='Categorias padres...'
                                ariaLabel='Selector'
                            />
                        </FormGroup>
                    </div>

                    <div className='col-12 text-center'>
                        <Button
                            className='mt-4'
                            color="secondary"
                            onClick={() => {
                                resetFilters()
                                setSelectedCompany('')
                            }}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default IncomeTypeFilters;