import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import { Client } from "../../../../type/client-type";
import { ClientService } from "../../../../services/clients/clientService";
import { Empty, Skeleton } from 'antd';
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import moment from "moment";
import { CardTitle } from "react-bootstrap";
import Badge from "../../../../components/bootstrap/Badge";
import classNames from "classnames";

export const ContactInfo = () => {

    const { id = '' } = useParams<{ id: string }>();
    const clientService = new ClientService;

    const [contactData, setContactData] = useState<any>({});

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await clientService.getClientById(id);
        return response.getResponseData() as Client;
    }, []));

    useEffect(() => {
        if (data) {
            setContactData(data.clientContactPerson);
        }
    }, [data]);

    return (
        <>
            <Skeleton loading={loading} round />

            {!loading && error && <Empty description="No hay datos del contacto" />}

            {data && (
                <>
                    <div className="row mb-5">
                        <div className="col-md-12 mb-3">
                            <CardTitle>Datos del cliente</CardTitle>
                        </div>

                        <FormGroup label="NOMBRE:" className="col-md-3">
                            <p>{data.name + (data.firstName ? ' ' + data.firstName : '') + (data.lastName ? ' ' + data.lastName : '')}</p>
                        </FormGroup>

                        <FormGroup label="TELÉFONO:" className="col-md-2">
                            <p>{data.telephone || '-'}</p>
                        </FormGroup>

                        <FormGroup label="EMAIL:" className="col-md-2">
                            <p>{data.email || '-'}</p>
                        </FormGroup>

                        <FormGroup label="NIF:" className="col-md-1">
                            <p>{data.cifNif || '-'}</p>
                        </FormGroup>

                        <FormGroup label="PROVINCIA:" className="col-md-2">
                            <p>{data.province || '-'}</p>
                        </FormGroup>

                        <FormGroup label="POBLACIÓN:" className="col-md-2">
                            <p>{data.population || '-'}</p>
                        </FormGroup>

                        <FormGroup label="DIRECCIÓN:" className="col-md-3">
                            <p>{data.address || '-'}</p>
                        </FormGroup>

                        <FormGroup label="CÓDIGO POSTAL:" className="col-md-2">
                            <p>{data.pc || '-'}</p>
                        </FormGroup>

                        <FormGroup label="CATEGORÍA:" className="col-md-2">
                            <p>{data.clientCategory?.name || '-'}</p>
                        </FormGroup>

                        <FormGroup label="ORIGEN:" className="col-md-2">
                            <p>{data.clientOrigin?.name || '-'}</p>
                        </FormGroup>
                    </div>

                    <div
                        className={classNames("row mt-3", contactData && "mb-5")}
                        style={contactData && { borderBottom: '1px solid lightgrey' }}
                    >
                        <FormGroup label="CLASIFICACIÓN:" className="col-md-3">
                            <div className="d-inline-block ps-2">
                                <Badge color="info" className="p-2">{data.classification || "-"}</Badge>
                            </div>
                        </FormGroup>

                        {data.user && (
                            <FormGroup label="CREADO POR:" className="col-md-4">
                                <div className="d-inline-block ps-2">
                                    {data.user.name + ' ' + (data.user?.lastName || '')}
                                </div>
                            </FormGroup>
                        )}

                        <FormGroup label="ALTA:" className="col-md-2">
                            <div className="d-inline-block ps-2">
                                {data.createdAt ? moment(data.createdAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                            </div>
                        </FormGroup>

                        <FormGroup label="ACTUALIZADO:" className="col-md-2 mb-5">
                            <div className="d-inline-block ps-2">
                                {data.updateAt ? moment(data.updateAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                            </div>
                        </FormGroup>
                    </div>
                </>
            )}

            {contactData && (
                <>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <CardTitle>Datos del contacto</CardTitle>
                        </div>

                        <FormGroup label="NOMBRE:" className="col-md-3">
                            <p>{contactData.name + (contactData.firstName ? ' ' + contactData.firstName : '') + (contactData.lastName ? ' ' + contactData.lastName : '')}</p>
                        </FormGroup>

                        <FormGroup label="TELÉFONO:" className="col-md-2">
                            <p>{contactData.telephone || '-'}</p>
                            {contactData.telephoneAux && <p>{contactData.telephoneAux}</p>}
                        </FormGroup>

                        <FormGroup label="EMAIL:" className="col-md-2">
                            <p>{contactData.email || '-'}</p>
                        </FormGroup>

                        <FormGroup label="CARGO:" className="col-md-2">
                            <p>{contactData.position || '-'}</p>
                        </FormGroup>

                        <FormGroup label="NIF:" className="col-md-2">
                            <p>{contactData.nif || '-'}</p>
                        </FormGroup>
                    </div>

                    <div className="row mt-3">
                        <FormGroup label="ALTA:" className="col-md-2">
                            <div className="d-inline-block ps-2">
                                {contactData.createdAt ? moment(contactData.createdAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                            </div>
                        </FormGroup>

                        <FormGroup label="ACTUALIZADO:" className="col-md-2">
                            <div className="d-inline-block ps-2">
                                {contactData.updateAt ? moment(contactData.updateAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                            </div>
                        </FormGroup>
                    </div>
                </>
            )}
        </>
    )
}