import { FC, Fragment } from "react";
import { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Progress from "../../../components/bootstrap/Progress";

interface OrganizationUsersProps {
    users: any;
}

const OrganizationUsers: FC<OrganizationUsersProps> = ({ users }) => {

    return (
        <Fragment>
            <CardHeader>
                <CardLabel>
                    <CardTitle>Usuarios activos</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody>
                <div className='d-flex justify-content-between'>
                    {/* <p>Objetivo completado este mes</p> */}
                    <p className='fw-bold'>{users.length}</p>
                </div>
                <Progress value={60} color='secondary' />
            </CardBody>
        </Fragment>
    )
}

export default OrganizationUsers;