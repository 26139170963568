import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const TAX_ENDPOINT = '/financial';

export class TaxService extends RestServiceConnection {

    listTaxes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TAX_ENDPOINT + '/list-budget-taxes',
            data: filters
        }, true) as AxiosResponse;
        return this;
    }

    createTax = async (tax: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TAX_ENDPOINT + '/create-budget-taxes',
            data: tax
        }, true) as AxiosResponse;
        return this;
    }

    editTax = async (tax: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TAX_ENDPOINT + '/edit-budget-taxes',
            data: tax
        }, true) as AxiosResponse;
        return this;
    }

    deleteTax = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TAX_ENDPOINT + '/delete-budget-taxes',
            data: { taxes: id }
        }, true) as AxiosResponse;
        return this;
    }

}