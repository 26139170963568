import { CSSProperties, FC } from "react";

interface FormLabelProps {
    label: string;
    cols?: number;
    className?: string;
    style?: CSSProperties;
    required?: boolean;
}

const FormLabel: FC<FormLabelProps> = ({ label, cols, className, required, style }) => {
    return (
        <span className={`labelStyle col-md-${cols} ${className} pe-0`} style={style}>
            {label}
            {required && <span className="labelRequired"> *</span>}
        </span>
    )
}

export default FormLabel;