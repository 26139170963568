import { BudgetService } from "../../../services/budgets/budgetService";
import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import PieChartBox from '../../../components/extras/PieChartBox';
import { useDashboard } from "../providers/DashboardProvider";

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const formattedStartDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`;
const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

const DashBoardCardBudget = () => {

    const { filters,  dashboardDates } = useDashboard();
    
    const budgetService = new BudgetService();

    const [data] = useFetch(useCallback(async () => {
        const budgetFilters = {
            filter_filters: {
                between_dates: {
                    start_date: dashboardDates.startDate ? dashboardDates.startDate : formattedStartDate,
                    end_date: dashboardDates.endDate ? dashboardDates.endDate : formattedEndDate,
                },
                path: filters?.path || "dashboard_count_budgets",
            },
            filter_order: filters?.filter_order || [],
            limit: filters?.limit || 0,
            page: filters?.page || 0,
        };
        const response = await budgetService.listStatusCountBudget(budgetFilters);
        return response.getResponseData();
    }, [filters]));

    if (!data) return null;

    return <PieChartBox title="Estados de Presupuestos" chartData={data[0]} />;
};

export default DashBoardCardBudget;