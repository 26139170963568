import { useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useDirectoryNavigator } from "./DirectoryNavigatorProvider";
import { useParams } from "react-router-dom";
import { ProjectService } from "../../services/projects/projectService";
import { toast } from "react-toastify";
import { File } from "buffer";
import classNames from "classnames";
import Icon from "../icon/Icon";
import Button from "../bootstrap/Button";

export const DirectoryNavigatorDropzone = () => {

    const { selectedDocumentType, setUpdateList, isLoading, setIsLoading } = useDirectoryNavigator();
    const { id = '' } = useParams<{ id: string }>();

    const [files, setFiles] = useState<any[]>([]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop(acceptedFiles) {
            setFiles(files.concat(
                acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
            ))
        },
        multiple: true,
        maxFiles: 100,
        maxSize: 1048576000, // 1GB
    });

    const _handleUpload = async (selectedFiles: File[], selectedDocumentType: string) => {
        setIsLoading(true);
        try {
            const response = (await ((new ProjectService).uploadDocument(id, selectedFiles, selectedDocumentType))).getResponseData();
            if (response) {
                setUpdateList(true); // Actualiza la lista de documentos
                toast.success('Documento subido correctamente');
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error('Error al subir el documento');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="mt-3">
            {selectedDocumentType?.id !== "" && (
                !isLoading ? (
                    <div className="row">
                        <div
                            {...getRootProps(
                                {
                                    className: classNames('text-center', {
                                        'col-md-12': files.length === 0,
                                        'col-md-9': files.length > 0,
                                    }),
                                    style: { cursor: isDragActive ? 'grab' : 'pointer', padding: '6rem 0', border: '2px dashed #ccc', borderRadius: '15px' }
                                },
                            )}
                        >
                            <input {...getInputProps()} />
                            {
                                isDragActive
                                    ? <p>Suelta aquí el documento ...</p>
                                    : <p>Arrastra aquí el documento o haz click para seleccionarlo</p>
                            }
                        </div>

                        {files.length > 0 && (
                            <div className="col-md-3 ps-5">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        _handleUpload(files, selectedDocumentType?.id);
                                        setFiles([]);
                                    }}
                                    className="mb-3"
                                >
                                    Subir documentos
                                </Button>

                                <ul>
                                    {files.map((file, index) => (
                                        <li key={file?.name + index}>
                                            <span className="me-3">{file?.name}</span>
                                            <Icon
                                                icon="Delete" size={'lg'} className="cursor-pointer"
                                                onClick={() => { setFiles(files.filter((file, i) => i !== index)) }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )
                    : (
                        <div className="text-center mt-5">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )
            )}
        </div>
    );
};