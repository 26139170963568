import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "./priviledge/PriviledgeProvider";
import { loadUserFromLocalStorage } from "../utils/jwt";
import { UserService } from "../services/users/userService";
import { CompanyService } from "../services/companies/organizationService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "./bootstrap/Modal";
import FormGroup from "./bootstrap/forms/FormGroup";
import Select from "./bootstrap/forms/Select";
import Input from "./bootstrap/forms/Input";
import Button from "./bootstrap/Button";
import Spinner from "./bootstrap/Spinner";
import Textarea from "./bootstrap/forms/Textarea";
import { StrategyService } from "../services/strategies/strategyService";
import { CategoryService } from "../services/categories/categoryService";
import useHandleErrors from "../hooks/useHandleErrors";

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data?: any;
    name: string;
    label: string
    onClose: (message: any, type: number) => void;
    show?: boolean;
}

const CreateModal: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, name, label, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const isSuperAdmin = userCan('list', 'companies');

    const strategyService = new StrategyService();
    const categoryService = new CategoryService;

    const [modal, setModal] = useState(false);
    const [company, setCompany] = useState("");
    const [companyList, setCompanyList] = useState([])

    const getCompany = async () => {
        const userService = new UserService;
        const response = (await userService.getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData()
        if (response.success) {
            setCompany(response.data.userRoles[0].company.id)
        }
    }

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data?.name || '',
            description: data?.description || '',
            company: data?.company.id || company,
        },
        onSubmit: (values) => {
            if (values.company !== '') {
                if (data !== null) {
                    update(values)
                } else {
                    create(values);
                }
            }
        },
    });

    const create = async (values: any) => {
        try {
            let response
            if (name === "category") {
                response = await (await categoryService.createCategory(values)).getResponseData();
            } else {
                response = await (await strategyService.createStrategy(values)).getResponseData();
            }
            if (response.success) {
                onClose(response.message, 1);
            } else {
                //onClose(response.data.errors, 0);
                handleErrors(response);
            }
        } catch (e) {
            toast.error('Error al crear');
        }
    }

    const update = async (values: any) => {
        try {
            values[name] = data.id;
            let response
            if (name === "category") {
                response = await (await categoryService.editCategory(values)).getResponseData();
            } else {
                response = await (await strategyService.editStrategy(values)).getResponseData();
            }
            if (response.success) {
                onClose(`${name === "category" ? " Categoría editada con exito" : "Estrategia editada con exito"}`, 1);
            } else {
                onClose(response.data.errors, 0);
            }
        } catch (e) {
            toast.error('Error al editar');
            console.log('error', e);
        }
    }

    useEffect(() => {
        if (!isSuperAdmin) {
            getCompany();
        } else {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'xl' : 'sm'} titleId='Nueva Categoría/Estrategia'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_cat_str'>{label}</ModalTitle>
            </ModalHeader>

            {
                (true && !modal)
                    ? (
                        <form onSubmit={formik.handleSubmit}>
                            <ModalBody className='px-4'>
                                <div className='row g-4'>
                                    {isSuperAdmin && (
                                        <FormGroup requiredInputLabel id='company' label='Organización' className='col-md-6'>
                                            <Select id="company" disabled={show ? true : false} list={getCompanyList()} onChange={formik.handleChange} value={formik.values.company || ''} ariaLabel='Select Companies' placeholder='Elegir Organización...' />
                                        </FormGroup>
                                    )}

                                    <FormGroup requiredInputLabel id='name' label='Nombre' className={isSuperAdmin ? 'col-md-6' : "col-md-12"}>
                                        <Input value={formik.values.name || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                                    </FormGroup>

                                    <FormGroup id='description' label='Descripción:' className={"col-md-12"}>
                                        <Textarea value={formik.values.description || ''} disabled={show ? true : false} autoComplete="off" onChange={formik.handleChange} />
                                    </FormGroup>
                                </div>
                            </ModalBody>

                            <ModalFooter className='px-4 pb-4 d-flex'>
                                <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'}>
                                    Guardar
                                </Button>
                            </ModalFooter>
                        </form>
                    )
                    : (
                        <div className="d-flex justify-content-center p-5">
                            <Spinner />
                        </div>
                    )
            }
        </Modal>
    )
}

export default CreateModal;