import { useFormik } from "formik";
import { ChangeEvent, FC, Fragment, useCallback, useState, } from "react";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardHeader, CardLabel, CardTitle, } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import { CompanyService } from "../../services/companies/organizationService";
import useFetch from "../../hooks/useFetch";
import { RoleService } from "../../services/auth/roleService";
import { Roles, RolesApiResponse } from "../../type/role-type";
import { UserService } from "../../services/users/userService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AsyncImg from "../../components/AsyncImg";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../layout/SubHeader/SubHeader";
import Page from "../../layout/Page/Page";
import { loadUserFromLocalStorage } from "../../utils/jwt";
import InputPhone, { isValidPhoneNumber } from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import { Editor } from "react-draft-wysiwyg";
import "react-phone-number-input/style.css";
import { COLORS_36 } from "../../utils/colors";
import { CirclePicker } from "react-color";

interface CreateFormProps {
  isLoading: boolean;
  submit: Function;
  userData?: UserForm;
  profileImg?: any;
  signatureImg?: any;
}

export interface UserForm {
  name: string;
  last_name: string;
  email: string;
  dni: string;
  address: string;
  telephone?: string;
  password: string;
  passwordConfirm: string;
  company: string;
  companyName: string;
  role: string;
  roleName: string;
  birthday_date: string;
  signatureText: string;
  color: string;
}

const userInitialValues: UserForm = {
  name: "",
  last_name: "",
  email: "",
  dni: "",
  address: "",
  telephone: "",
  password: "",
  passwordConfirm: "",
  company: "",
  companyName: "",
  role: "",
  roleName: "",
  birthday_date: "",
  signatureText: "",
  color: "",
};

const UserEditSchema = yup.object({
  name: yup.string().min(1, "Demasiado Corto").max(20, "Demasiado Largo").matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, "Sólo se permiten caracteres alfabéticos").required("Campo obligatorio"),
  last_name: yup.string().min(1, "Demasiado Corto").max(40, "Demasiado Largo").matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, "Sólo se permiten caracteres alfabéticos"),
  email: yup.string().email("Correo Inválido").required("Campo obligatorio"),
  dni: yup.string().matches(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i, "Formato de DNI no válido").required("Campo obligatorio"),
  color: yup.string(),
  note: yup.string(),
  address: yup.string(),
  telephone: yup.string().notRequired().test("phone-number", "El número de teléfono no es válido", (value) => {
    if (value != undefined) {
      return isValidPhoneNumber(value);
    } else return true;
  }),
  password: yup.string().min(8, "Contraseña de al menos 8 caracteres").max(20, "Contraseña menor de 20 caracteres"),
  passwordConfirm: yup.string().oneOf([yup.ref("password"), ""], "Contraseñas no coinciden"), birthday_date: yup.date().max(new Date(), "Fecha no válida"),
  //company: yup.string().required('Campo obligatorio'),
  role: yup.string().required("Campo obligatorio"),
});

const UserCreateSchema = UserEditSchema.concat(yup.object({
  password: yup.string().required("Contraseña Obligatoria").min(8, "Contraseña de al menos 8 caracteres").max(20, "Contraseña menor de 20 caracteres"),
  passwordConfirm: yup.string().required("Confirmación de contraseña obligatoria").oneOf([yup.ref("password"), ""], "Contraseñas no coinciden"),
}));

const UserForm: FC<CreateFormProps> = ({ isLoading, submit, userData, profileImg, signatureImg }) => {

  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const mode = userData ? "Editar" : "Crear";
  const userService = new UserService();

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedSignature, setSelectedSignature] = useState<any>(null);
  const [organizationSelected, setOrganizationSelected] = useState<string | undefined>(userData?.company || undefined);

  const [organizations, fetchingOrganizations] = useFetch(useCallback(async () => {
    const companyService = new CompanyService();
    if (organizationSelected) return;

    const response = await companyService.getOrganizations();
    if (response.getResponseData() === undefined) {
      const result = (await new UserService().getUserById(loadUserFromLocalStorage()?.id || "")).getResponseData();
      formik.values.company = result?.data.userRoles[0].company.id;
      return response.getResponseData() as CompaniesApiResponse;
    } else {
      return response.getResponseData() as CompaniesApiResponse;
    }
  }, []));

  const [roleList] = useFetch(useCallback(async () => {
    const roleService = new RoleService();
    if (organizationSelected != undefined) {
      const response = await roleService.getRolesByOrganization(organizationSelected);
      return response.getResponseData() as RolesApiResponse;
    }
  }, [organizationSelected]));

  const [adminInfo] = useFetch(useCallback(async () => {
    const adminOrganizationService = new UserService();
    const response = await adminOrganizationService.getAdminInfo();
    return response.getResponseData() as any;
  }, [id]));

  const handleImageUpload = async (event: React.ChangeEvent<any>, image: boolean, signature: boolean) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const setState = image ? setSelectedImage : signature && setSelectedSignature as any;
    const apiCall = image ? userService.editUserImg : signature && userService.uploadUserSignature as any;
    const name = image ? "Imagen" : signature && "Firma";

    reader.onload = () => { setState(reader.result) };
    reader.readAsDataURL(file);

    try {
      const response = await apiCall(id, file);

      const responseData = response.getResponseData();

      if (responseData.success) {
        toast.success(`${name} actualizada`);
      } else {
        toast.error(responseData.message);
      }
    } catch (error: any) {
      toast.error("Formato de imagen incorrecto");
    }
  };

  const deleteImage = async (image: boolean, signature: boolean) => {
    const setState = image ? setSelectedImage : signature && setSelectedSignature as any;
    const apiCall = image ? userService.deleteUserImg : signature && userService.deleteUserSignature as any;
    const name = image ? "imagen de perfil" : signature && "firma";

    handleConfirmationAlert({
      title: "¿Estás seguro?",
      text: `Vas a eliminar la ${name}`,
      icon: "warning",
      onConfirm: async () => {
        try {
          const response = await apiCall(id);
          const responseData = response.getResponseData();

          if (responseData.success) {
            setState(null);
            window.location.reload();
          }
        } catch (error: any) {
          toast.error(`Error al eliminar la ${name}`);
        }
      },
    });
  };

  const getOrganizationsList = () => {
    if (organizations as Companies) {
      return organizations.companies.map((option: { id: any; name: any }) => {
        return {
          value: option.id,
          label: option.name,
        };
      });
    }
    return [];
  };

  const getRolesList = () => {
    if (roleList as Roles) {
      return roleList.map((option: { id: string; name: string }) => {
        return {
          value: option.id,
          label: option.name,
        };
      });
    }
    return [];
  };

  const getAdminRoles = () => {
    if (adminInfo as Roles) {
      return adminInfo.map((option: { id: string; name: string }) => {
        return {
          value: option.id,
          label: option.name,
        };
      });
    }
    return [];
  };

  const formik = useFormik({
    initialValues: userData ? userData : userInitialValues,
    validationSchema: mode === "Editar" ? UserEditSchema : UserCreateSchema,
    onSubmit: (values) => submit(values),
  });

  const verifyClass = (inputFieldID: keyof UserForm) => { return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : ""; };

  const showErrors = (inputFieldID: keyof UserForm) => {
    return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button color="secondary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
          <SubheaderSeparator />
          <CardTitle>{mode} Usuario</CardTitle>
        </SubHeaderLeft>
        <SubHeaderRight>
          <Button type="submit" color="primary" onClick={formik.handleSubmit}>
            {isLoading ? <Spinner /> : `${mode} Usuario`}
          </Button>
        </SubHeaderRight>
      </SubHeader>
      <Page container="xxl">
        <Card stretch={true}>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <CardBody isScrollable={false}>
              <div className="row">
                <div className="col-md-9">
                  <CardHeader className="rounded-1 mb-0">
                    <CardLabel icon="AccountCircle" iconColor="primary">
                      <CardTitle>Información personal</CardTitle>
                    </CardLabel>
                  </CardHeader>

                  <CardBody>
                    <div className="row">
                      <FormGroup requiredInputLabel label="Nombre" className="col-md-3">
                        <Input id="name" onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass("name")} />
                        {showErrors("name")}
                      </FormGroup>

                      <FormGroup label="Apellidos" className="col-md-4">
                        <Input id="last_name" onChange={formik.handleChange} value={formik.values.last_name} onBlur={formik.handleBlur} className={verifyClass("last_name")} />
                        {showErrors("last_name")}
                      </FormGroup>

                      <FormGroup requiredInputLabel label="Correo electrónico" className="col-md-5">
                        <Input id="email" type="email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} className={verifyClass("email")} />
                        {showErrors("email")}
                      </FormGroup>
                    </div>

                    <div className="row mt-3">
                      <FormGroup id='color' label='Color' className='col-md-4'>
                        <CirclePicker
                          circleSize={25}
                          color={formik.values.color}
                          colors={COLORS_36}
                          onChangeComplete={(color: any) => {
                            formik.setFieldValue('color', color.hex)
                          }}
                        />
                      </FormGroup>

                      <div className="col-md-8">
                        <>
                          <div className="row d-flex justify-content-between">
                            <FormGroup requiredInputLabel label="DNI" className="col-md-3">
                              <Input id="dni" onChange={formik.handleChange} value={formik.values.dni} onBlur={formik.handleBlur} className={verifyClass("dni")} />
                              {showErrors("dni")}
                            </FormGroup>

                            <FormGroup label="Teléfono" className="col-md-4">
                              <InputPhone id="telephone"
                                onBlur={formik.handleBlur}
                                labels={es}
                                international
                                defaultCountry="ES"
                                onChange={(e) => { formik.values.telephone = e }}
                                value={formik.values.telephone || ""}
                                className={verifyClass("telephone")}
                              />
                              {showErrors("telephone")}
                            </FormGroup>

                            <FormGroup label="Fecha nacimiento" className="col-md-3">
                              <Input id="birthday_date" type="date" onChange={formik.handleChange} value={formik.values.birthday_date} className={verifyClass("birthday_date")} />
                              {showErrors("birthday_date")}
                            </FormGroup>

                            <FormGroup label="Dirección" className="col-md-8 mt-2">
                              <Input id="address" onChange={formik.handleChange} value={formik.values.address} className={verifyClass("address")} />
                              {showErrors("address")}
                            </FormGroup>
                          </div>
                        </>
                      </div>
                    </div>
                  </CardBody>
                </div>

                <div className="col-md-3">
                  <CardHeader className="rounded-1 mb-0">
                    <CardLabel icon={!fetchingOrganizations && organizations ? "Business" : "SupervisorAccount"} iconColor="primary">
                      <CardTitle>
                        {!fetchingOrganizations && organizations ? "Organización" : "Rol"}
                      </CardTitle>
                    </CardLabel>
                  </CardHeader>
                  <CardBody>
                    {
                      // si es superadmin: mostrar select de organizaciones y roles dinámicos
                      // si es admin: mostrar select de roles según la organización a la que pertenece el admin
                      (!fetchingOrganizations && organizations)
                        ? (
                          <Fragment>
                            <FormGroup requiredInputLabel label="Organización" className="col-md-12">
                              <Select
                                id="company"
                                list={getOrganizationsList()}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  formik.handleChange(e);
                                  formik.setFieldValue("company", e.target.value);
                                  setOrganizationSelected(e.target.value);
                                  formik.setFieldValue("role", "");
                                }}
                                value={formik.values.company}
                                onBlur={formik.handleBlur}
                                className={verifyClass("company")}
                                ariaLabel="Listado de organizaciones"
                                placeholder={formik.values.company ? formik.values.company : "Elegir..."}
                              />
                              {showErrors("company")}
                            </FormGroup>

                            <FormGroup requiredInputLabel id="role" label="Rol" className="col-md-12 mt-2">
                              <Select
                                name="role"
                                id="inputGroupSelect01"
                                list={getRolesList() ? getRolesList() : [{ value: "", label: "No hay roles disponibles" }]}
                                ariaLabel="Select de organizaciones"
                                placeholder={"Elegir rol"}
                                onChange={(e: any) => formik.setFieldValue("role", e.target.value)}
                                value={formik.values.role.toString()}
                                onBlur={formik.handleBlur}
                                className={verifyClass("role")}
                              />
                              {showErrors("role")}
                            </FormGroup>
                          </Fragment>
                        )
                        : (
                          <FormGroup id="role" label="Rol" className="col-md-12">
                            <Select
                              name="role"
                              id="roles-select-admin"
                              ariaLabel="Select de organizaciones"
                              placeholder={"Listado de roles del admin"}
                              list={getAdminRoles()}
                              onChange={(e: any) => formik.setFieldValue("role", e.target.value)}
                              value={formik.values.role.toString()}
                              onBlur={formik.handleBlur}
                              className={verifyClass("role")}
                            />
                            {showErrors("role")}
                          </FormGroup>
                        )
                    }
                  </CardBody>
                </div>
              </div>

              <div className="row">
                {mode === "Editar" && (
                  <div className="col-md-6">
                    <CardHeader className="rounded-1 mb-0">
                      <CardLabel icon="PhotoCamera" iconColor="primary">
                        <CardTitle>Imagen de perfil</CardTitle>
                      </CardLabel>
                    </CardHeader>
                    <CardBody>
                      <div className="row align-items-center">
                        <div className="col-lg-3">
                          {
                            selectedImage
                              ? <img width={70} height={70} src={selectedImage} alt="selected" className="mx-auto d-block img-fluid mb-3 rounded" />
                              : profileImg
                                ? <AsyncImg isBackground height="100%" width="100%" styles="rounded-circle" id={profileImg.id} />
                                : <PlaceholderImage width={70} height={70} className="mx-auto d-block img-fluid mb-3 rounded" />
                          }
                        </div>
                        <div className="col-lg-9 col-12">
                          <div className="row g-4">
                            <div className="col-8">
                              <Input
                                type="file"
                                onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e, true, false)}
                                autoComplete="photo"
                              />
                            </div>
                            <div className="col-4">
                              <Button
                                color="dark"
                                isLight
                                icon="Delete"
                                onClick={() => deleteImage(true, false)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                )}

                <div className="col-md-6">
                  <CardHeader className="rounded-1 mb-0">
                    <CardLabel icon="Security" iconColor="primary">
                      <CardTitle>Seguridad</CardTitle>
                    </CardLabel>
                  </CardHeader>
                  <CardBody className="row">
                    <FormGroup requiredInputLabel label="Contraseña" className="col-md-6">
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        className={verifyClass("password")}
                      />
                      {showErrors("password")}
                    </FormGroup>
                    <FormGroup requiredInputLabel label="Confirmar Contraseña" className="col-md-6">
                      <Input
                        type="password"
                        id="passwordConfirm"
                        name="passwordConfirm"
                        autoComplete="new-password"
                        onChange={formik.handleChange}
                        value={formik.values.passwordConfirm}
                        onBlur={formik.handleBlur}
                        className={verifyClass("passwordConfirm")}
                      />
                      {showErrors("passwordConfirm")}
                    </FormGroup>
                  </CardBody>
                </div>
              </div>

              <div className="row">
                <CardHeader className="rounded-1 mb-0">
                  <CardLabel icon="Draw" iconColor="primary">
                    <CardTitle>Firma para los presupuestos</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody>
                  {mode === "Editar" && (
                    <div className="row align-items-center w-50">
                      <div className="col-lg-3">
                        {selectedSignature
                          ? <img width={70} height={70} src={selectedSignature} alt="selected" className="mx-auto d-block img-fluid mb-3 rounded" />
                          : signatureImg
                            ? <AsyncImg isBackground height="100%" width="100%" styles="rounded" id={signatureImg?.id} />
                            : <PlaceholderImage width={100} height={150} className="mx-auto d-block img-fluid mb-3 rounded" />
                        }
                      </div>
                      <div className="col-lg-9 col-12">
                        <div className="row g-4">
                          <div className="col-8">
                            <Input
                              type="file"
                              onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e, false, true)}
                              autoComplete="photo"
                            />
                          </div>
                          <div className="col-4">
                            <Button
                              color="dark"
                              isLight
                              icon="Delete"
                              onClick={() => deleteImage(false, true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*  <div className="row">
                    <div className="col-md-12">
                      <span className="labelStyle">Texto del pie de firma</span>
                      <Editor
                        onChange={(e: any) => {
                          console.log(e);
                          // TODO: guardar en el formik el texto de la firma
                          //formik.setFieldValue('signatureText', e);
                        }}
                      />
                    </div>
                  </div> */}
                </CardBody>
              </div>
            </CardBody>
          </form>
        </Card>
      </Page>
    </Fragment>
  );
};

export default UserForm;