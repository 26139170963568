import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import PieDonutChartBox from "../../../components/extras/PieDonutChartBox";
import { ProjectService } from "../../../services/projects/projectService";
import { useDashboard } from "../providers/DashboardProvider";

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const formattedStartDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`;
const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

const DashBoardCardProjects = () => {

    const { filters, dashboardDates } = useDashboard();

    const projectService = new ProjectService();

    const [data] = useFetch(useCallback(async () => {
        const projectsFilters = {
            filter_filters: {
                between_dates: {
                    start_date: dashboardDates.startDate ? dashboardDates.startDate : formattedStartDate,
                    end_date: dashboardDates.endDate ? dashboardDates.endDate : formattedEndDate,
                },
                path: filters?.path || "dashboard_count_projects",
            },
            filter_order: filters?.filter_order || [],
            limit: filters?.limit || 0,
            page: filters?.page || 0,
        };
        const response = await projectService.listStatusCountProject(projectsFilters);
        return response.getResponseData();
    }, [filters]));

    if (!data) return null;

    return <PieDonutChartBox title="Estados de Proyectos" chartData={data[0]} />;
};

export default DashBoardCardProjects;