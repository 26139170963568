import { useFormik } from "formik";
import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Accordion, { AccordionItem } from "../../components/bootstrap/Accordion";
import Button from "../../components/bootstrap/Button";
import { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Checks from "../../components/bootstrap/forms/Checks";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import ErrorMessage from "../../components/ErrorMessage";
import useFetch from "../../hooks/useFetch";
import { PermissionService } from "../../services/auth/permissionService";
import { RoleService } from "../../services/auth/roleService";
import { Permission, PermissionGroup, RolesApiResponse } from "../../type/role-type";
import { CompanyService } from "../../services/companies/organizationService";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import Select from "../../components/bootstrap/forms/Select";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Page from "../../layout/Page/Page";
import { loadUserFromLocalStorage } from "../../utils/jwt";
import useHandleErrors from "../../hooks/useHandleErrors";

interface CreateFormProps {
    isLoading: boolean;
    roleData?: any;
}

export interface RoleForm {
    name: string;
    description: string;
    permissions: RolePermissions;
    company: RoleCompany;
}

export interface RoleCompany {
    id: string;
    cif: string;
    name: string;
    address: string;
    active: boolean;
}

export interface RolePermissions {
    users: string[];
    companies: string[];
    roles: string[];
}

const roleInitialValues: RoleForm = {
    name: '',
    description: '',
    company: {
        id: '',
        cif: '',
        name: '',
        address: '',
        active: false,
    },
    permissions: {
        users: [],
        companies: [],
        roles: [],
    },
}

const RoleForm: FC<CreateFormProps> = ({ isLoading, roleData }) => {

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const rol = loadUserFromLocalStorage()?.roles[0];

    const [selectedPermissions, setSelectedPermissions] = useState<number[] | null>(null);
    const [selectAll, setSelectAll] = useState<number[]>([]);

    const [companies] = useFetch(useCallback(async () => {
        const companyService = new CompanyService();
        const response = await companyService.getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []));

    const [permissionData, loadingPermission, errorPermission, fetchPermissionData] = useFetch(useCallback(async () => {
        const permissionService = new PermissionService();
        const response = await permissionService.getPermissions();

        const allPermissionsData = response.getResponseData().data;

        // find permissions selected in roleData
        const defaultSelectedPermissions = allPermissionsData.reduce((acc: number[], group: PermissionGroup) => {
            let permissionIdsOfGroup: number[] = [];
            group.permissions.forEach((permission: Permission) => {
                if (roleData?.permissions[group.name]?.includes(permission.action)) {
                    permissionIdsOfGroup.push(permission.id);
                }
            });
            return [...acc, ...permissionIdsOfGroup];
        }, []);

        setSelectedPermissions(roleData?.permissions.map((item: any) => item.permission.id));


        return response.getResponseData() as RolesApiResponse;

    }, []));

    const handleEditRole = async (values: any) => {
        values.role = id;
        values.permissions = selectedPermissions;

        if (values && id) {
            try {
                const response = await (await new RoleService().editRole(values)).getResponseData();

                if (response.success) {
                    navigate('/roles');
                    setTimeout(() => {
                        toast.success('Rol editado correctamente');
                    }, 100);
                } else {
                    handleErrors(response);
                }
            } catch (e: any) {
                toast.error('Error al editar el rol');
            }
        }
    };

    // Obtener la lista de organizaciones para mostrar en el select
    const getCompanyList = () => {
        if (companies as Companies) {
            return companies.companies.map((productType: { id: any; name: any }) => {
                return {
                    value: productType.id,
                    label: productType.name,
                };
            });
        }
        return [];
    };

    const formik = useFormik({
        initialValues: roleData ? roleData : roleInitialValues,
        onSubmit: handleEditRole,
    });

    const showPermissions = () => {
        if (loadingPermission) return (<div className="text-center">{" "}<Spinner />{" "}</div>);

        if (errorPermission) return <ErrorMessage error={errorPermission} />;

        if (permissionData && selectedPermissions !== null) {

            return (
                <div className="row w-75">
                    {permissionData?.map((group: PermissionGroup, index: number) => {
                        return (
                            <div className="col-lg-3 col-md-6 col-sm-6 mt-5" key={group.id}>
                                <Accordion id={group.name} isFlush activeItemId={group.id}>
                                    <AccordionItem id={group.id} title={group.label}>
                                        <Fragment>
                                            <Checks
                                                label="Seleccionar todos"
                                                value="all"
                                                checked={selectAll.includes(group.id)}
                                                onChange={() => {
                                                    const list = group.permissions.map((item: Permission) => item.id);
                                                    if (selectAll.includes(group.id)) {
                                                        setSelectAll(selectAll.filter((id: number) => id !== group.id));
                                                        setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
                                                    } else {
                                                        setSelectAll([...selectAll, group.id]);
                                                        setSelectedPermissions([...selectedPermissions.concat(list)]);
                                                    }
                                                }}
                                            />
                                        </Fragment>

                                        {group.permissions.map((permission: Permission, index: number) => {

                                            return (
                                                <div key={permission.id}>
                                                    <Checks
                                                        label={permission.label}
                                                        value={permission.id}
                                                        name={`permissions[]`}
                                                        checked={selectedPermissions.includes(
                                                            permission.id
                                                        )}
                                                        onChange={() => {
                                                            selectedPermissions.includes(permission.id)
                                                                ? setSelectedPermissions(
                                                                    selectedPermissions.filter(
                                                                        (id: number) => id !== permission.id
                                                                    )
                                                                )
                                                                : setSelectedPermissions([
                                                                    ...selectedPermissions,
                                                                    permission.id,
                                                                ]);
                                                        }}
                                                    />
                                                </div>
                                            );
                                        },
                                        )}
                                    </AccordionItem>
                                </Accordion>
                            </div>

                        );
                    })}
                </div>
            )
        }
    };

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit}>
                <CardBody isScrollable={false}>
                    <div className="row">
                        <div /* className='col-md-6' */>
                            <CardBody className="d-flex flex-column">
                                <div className="row d-flex justify-content-around ">
                                    <FormGroup requiredInputLabel id="name" label="Nombre" className={rol === 'Superadministrador' ? 'col-md-3' : 'col-md-6'} >
                                        <Input required value={formik.values.name} onChange={formik.handleChange} />
                                    </FormGroup>
                                    <FormGroup
                                        id="description" label="Breve descripción" className="col-md-6">
                                        <Input id="description" value={formik.values.description} onChange={formik.handleChange} />
                                    </FormGroup>
                                    {rol === 'Superadministrador' && (
                                        <FormGroup requiredInputLabel id="company" label="Organización" className="col-md-3"          >
                                            <Select
                                                required id="company"
                                                onChange={formik.handleChange}
                                                value={formik.values.company}
                                                ariaLabel="Default select example"
                                                placeholder="Elegir organización..."
                                                list={getCompanyList()}
                                            />
                                        </FormGroup>
                                    )}
                                </div>
                                <div className="row mt-5 d-flex justify-content-center">
                                    {showPermissions()}
                                </div>
                            </CardBody>
                        </div>
                    </div>
                </CardBody>
            </form>
        )
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate("/roles", { replace: true })}
                    ></Button>
                    <SubheaderSeparator />
                    <CardTitle>Editar rol</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : 'Editar Rol'}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                {roleData ? getContent() : <Spinner />}
            </Page>
        </Fragment>
    )
}

export default RoleForm;