import { useFormik } from "formik";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import { UserService } from "../../services/users/userService";
import { loadUserFromLocalStorage } from "../../utils/jwt";
import { CompanyService } from "../../services/companies/organizationService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../components/bootstrap/Modal";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Button from "../../components/bootstrap/Button";
import { Task } from "../../type/task-type";
import { TaskService } from "../../services/tasks/taskService";
import * as yup from 'yup';
import useFetch from "../../hooks/useFetch";
import { UsersApiResponse } from "../../type/user-type";
import SearchableSelect from "../../components/selector/SearchableSelect";
import { ProjectService } from "../../services/projects/projectService";
import { ProjectsApiResponse } from "../../type/project-type";
import moment from "moment";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { get } from "http";
import useFilters from "../../hooks/useFilters";
import { Spinner } from "react-bootstrap";

interface CreateModalProps {
    data?: any;
    isOpen: boolean;
    show?: boolean;
    setIsOpen(...args: unknown[]): unknown;
    onClose: (type: number, message?: string, errors?: any) => void;
}

const validationSchema = yup.object({
    company: yup.string().required('La organización es obligatoria'),
    user: yup.string().notRequired(),
    project: yup.string().notRequired(),
    name: yup.string().required('El nombre es obligatorio'),
    comments: yup.string().notRequired(),
    date: yup.string().required('La fecha de vencimiento es obligatoria'),
});

const TaskModalForm: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const token = useSelector((state: RootState) => state.auth.user);
    const isSuperAdmin = userCan('list', 'companies');
    const taskService = new TaskService();

    const { filters } = useFilters({}, [], 0, 999999999);

    const [company, setCompany] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [defaultProject, setDefaultProject] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [users] = useFetch(useCallback(async () => {
        const response = await (new UserService).getUsers(filters);
        return response.getResponseData() as UsersApiResponse;
    }, [filters]));

    const [projects] = useFetch(useCallback(async () => {
        const response = await (new ProjectService).listProjects(filters);
        const data = response.getResponseData();
        return data as ProjectsApiResponse;
    }, [filters]));

    const getCompany = async () => {
        const userService = new UserService;
        const response = (await userService.getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData()
        if (response.success) {
            setCompany(response.data.userRoles[0].company.id)
        }
    };

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    };

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const getUsersList = () => {
        if (users) {
            return users.users.map((users: any) => {
                return {
                    value: users.id,
                    label: users.name + (users.firstName ? (" " + users.firstName) : '') + (users.lastName ? (" " + users.lastName) : ''),
                    isSelected: (users.id === data?.user?.id) || (users.id === data?.user),
                }
            })
        }
        return [];
    };

    const getProjectsList = () => {
        if (projects) {
            return projects.projects.map((p: any) => {
                return {
                    value: p.id,
                    label: `${p.budget?.name} - ${p.budget?.address}`,
                    isSelected: p.id === data?.project?.id,
                }
            })
        }
        return [];
    };

    const _handleCreateTask = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (await taskService.createTask(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear la tarea');
        } finally {
            setIsLoading(false);
        }
    };

    const _handleEditTask = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (await taskService.editTask(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar la tarea');
        } finally {
            setIsLoading(false);
        }
    };

    // Renderiza los campos de tipo select con el componente SearchableSelect
    const renderSearchableSelect = (label: string, required: boolean, optionsGetter: any, fieldName: keyof Task, defaultValue = null) => {
        const placeholder = (fieldName === 'user' && 'usuario') || (fieldName === 'project' && 'proyecto') || '';
        const commonProps = {
            isSearchable: true,
            isClearable: true,
            options: optionsGetter(),
            onChange: (e: any) => {
                formik.handleChange(e?.value || '');
                formik.setFieldValue(fieldName, e?.value || '');
            },
            name: `${fieldName}-select`,
            id: `${fieldName}-select`,
            placeholder: placeholder,
            classname: verifyClass(fieldName),
            isDisabled: show || false,
        };

        return (
            <FormGroup requiredInputLabel={required} label={label} className="col-md-12">
                <SearchableSelect {...commonProps} defaultValue={defaultValue} />
                {showErrors(fieldName)}
            </FormGroup>
        );
    };

    const formik = useFormik<Task>({
        enableReinitialize: true,
        initialValues: {
            // Pueden venir los datos del listado o del calendario
            task: data?.id || '',
            company: data?.company?.id || token?.company || company,
            user: data?.user?.id || data?.user || '',
            project: data?.project?.id || '',
            name: data?.name || '',
            comments: data?.comments || '',
            date: data?.date ? moment(data.date.date).format('YYYY-MM-DD') : (data?.start ? moment(data.start).format('YYYY-MM-DD') : ''),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.user == '' && values.project == '') {
                toast.error('Debes seleccionar un usuario o un proyecto para poder asignarle la tarea')
                return;
            }
            if (values.company != '') {
                if (data != null) {
                    _handleEditTask(values);
                } else {
                    _handleCreateTask(values);
                }
            }
        },
    });

    const verifyClass = (inputFieldID: keyof Task) => { return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : "" };

    const showErrors = (inputFieldID: keyof Task) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    const userSelected = getUsersList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const defaultUserValue = userSelected[0] ? { value: userSelected[0].value, label: userSelected[0].label } : null;
    const projectSelected = getProjectsList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const defaultProjectValue = projectSelected[0] ? { value: projectSelected[0].value, label: projectSelected[0].label } : null;

    useEffect(() => {
        if (!isSuperAdmin) {
            getCompany();
        } else {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size='md' titleId='Crear tarea'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_task'>{show ? 'Ver tarea' : data ? 'Editar tarea' : 'Crear tarea'}</ModalTitle>
            </ModalHeader>

            {
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <ModalBody className='row px-4 g-4'>
                        {isSuperAdmin && (
                            <FormGroup requiredInputLabel={!show} id='company' label='Organización' className='col-md-12'>
                                <Select id="company" disabled={show ? true : false} list={getCompanyList()} onChange={formik.handleChange} value={formik.values.company || ''} ariaLabel='Select Companies' placeholder='Elegir Organización...' />
                            </FormGroup>
                        )}

                        {(!data || defaultUserValue) && renderSearchableSelect('Usuario', true, getUsersList, 'user', defaultUserValue as any)}

                        <FormGroup id='project' label='Proyecto:' className="col-md-12" key={defaultProjectValue?.value}>
                            <SearchableSelect
                                name='project'
                                isClearable
                                isSearchable
                                defaultValue={defaultProjectValue}
                                options={getProjectsList()}
                                onChange={(e: any) => {
                                    setDefaultProject(e);
                                    formik.setFieldValue('project', e?.value || '');
                                }}
                                placeholder="proyecto"
                            />
                            {showErrors('project')}
                        </FormGroup>

                        <FormGroup requiredInputLabel={!show} id='name' label='Nombre:' className="col-md-12">
                            <Input id='name' value={formik.values.name} disabled={show ? true : false} autoComplete="off" onChange={(e: any) => formik.setFieldValue('name', e.target.value)} className={verifyClass('name')} />
                            {showErrors('name')}
                        </FormGroup>

                        <FormGroup label="Fecha de vencimiento:" requiredInputLabel={!show} className="col-md-5">
                            <Input
                                type="date"
                                name="date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.date}
                                disabled={show ? true : false}
                                className={verifyClass('date')}
                            />
                            {showErrors('date')}
                        </FormGroup>

                        <FormGroup label="Descripción:">
                            <textarea
                                name="comments"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.comments}
                                rows={4}
                                style={{
                                    borderRadius: '10px',
                                    boxShadow: show ? '0 0 0 3px #e9ecef' : '0 0 0 3px rgb(249, 230, 236)',
                                    backgroundColor: show ? '#e9ecef' : '#f8f9fa',
                                    width: '100%',
                                    padding: '15px',
                                    border: '0'
                                }}
                                disabled={show ? true : false}
                                className={verifyClass('comments')}
                            />
                            {showErrors('comments')}
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'} isDisable={isLoading}>
                            {isLoading ? <Spinner animation="border" size="sm" /> : data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>
            }
        </Modal>
    );
}

export default TaskModalForm;