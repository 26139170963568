import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import CustomSearchSelect from "../../../components/selector/CustomSearchSelect";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import Select from "../../../components/bootstrap/forms/Select";
import { Budget } from "../../../type/budgets-type";
import { useBugdetForm } from "../providers/BudgetProvider";
import FormLabel from "../../../components/FormLabel";

const BudgetGeneralForm = () => {

    const {
        formik, verifyClass, showErrors, mode, budgetData, client, setClientSearch, setSponsorSearch, clientOptions, sponsorOptions, setTaxSelected,
        defaultTaxesValue, defaultCodeValue, defaultStatusValue, defaultSeriesValue, getCodesIdentifiersList, getStatusList, getSeriesList, taxesList
    } = useBugdetForm();

    // Renders the select type fields with the SearchableSelect component
    const renderSearchableSelect = (label: string, optionsGetter: any, ConceptName: keyof Budget, defaultValue = null) => {
        const placeholder = (ConceptName === 'series' && 'serie') || (ConceptName === 'status' && 'estado') || (ConceptName === 'code' && 'código') || (ConceptName === 'client' && 'cliente') || (ConceptName === 'sponsor' && 'promotor') || (ConceptName === 'paymentMethod' && 'método de pago') || '';
        const commonProps = {
            isSearchable: true,
            options: optionsGetter(),
            onChange: (e: any) => {
                formik.handleChange(e.value);
                formik.setFieldValue(ConceptName, e.value);
            },
            name: `${ConceptName}-select`,
            id: `${ConceptName}-select`,
            placeholder: placeholder,
            classname: verifyClass(ConceptName),
        };

        return (
            <FormGroup requiredInputLabel label={label} className={ConceptName === 'series' || ConceptName === 'status' || ConceptName === 'paymentMethod' ? "col-md-2" : "col-md-3"}>
                <SearchableSelect {...commonProps} defaultValue={defaultValue} />
                {showErrors(ConceptName)}
            </FormGroup>
        );
    };

    if (!budgetData) return null;

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off">
            {(client === '' || mode === 'Editar') // Check so that the client field is not displayed when accessing the budget creation route from the client list
                ? (
                    <div className="row mt-2 mb-3">
                        {(mode === 'Crear' || clientOptions.find((option: any) => option.value === formik.values.client)) &&
                            <FormGroup label='Cliente' requiredInputLabel className="col-md-3">
                                <CustomSearchSelect
                                    id='client-select'
                                    options={clientOptions}
                                    onInputChange={(e: any) => { setClientSearch(e) }}
                                    onChangeSingle={(e: any) => {
                                        formik.handleChange(e.value);
                                        formik.setFieldValue('client', e.value)
                                    }}
                                    defaultValue={clientOptions.find((option: any) => option.value === formik.values.client) || null}
                                />
                            </FormGroup>
                        }

                        {(mode === 'Crear' || sponsorOptions.find((option: any) => option.value === budgetData?.sponsor)) &&
                            <FormGroup label='Promotor' className="col-md-3">
                                <CustomSearchSelect
                                    id='sponsor-select'
                                    options={sponsorOptions}
                                    onInputChange={(e: any) => { setSponsorSearch(e) }}
                                    onChangeSingle={(e: any) => {
                                        formik.handleChange(e.value);
                                        formik.setFieldValue('sponsor', e.value)
                                    }}
                                    defaultValue={sponsorOptions.find((option: any) => option.value === budgetData?.sponsor) || null}
                                />
                            </FormGroup>
                        }
                    </div>
                )
                : (
                    <div className="row mt-2 mb-3">
                        <FormGroup label='Promotor' className="col-md-3">
                            <CustomSearchSelect
                                id='custom-select'
                                options={sponsorOptions}
                                onInputChange={(e: any) => { setSponsorSearch(e) }}
                                onChangeSingle={(e: any) => {
                                    formik.handleChange(e.value);
                                    formik.setFieldValue('sponsor', e.value)
                                }}
                                defaultValue={sponsorOptions.find((option: any) => option.value === formik.values.sponsor) || ''}
                            />
                        </FormGroup>
                    </div>
                )
            }

            <div className="row mt-2">
                <FormGroup label='Nombre del proyecto' requiredInputLabel className="col-md-3">
                    <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                    {showErrors('name')}
                </FormGroup>
                <FormGroup label="Identificador" className="col-md-2">
                    <Input id="codeIdentifier" onChange={formik.handleChange} value={formik.values.codeIdentifier || ''} onBlur={formik.handleBlur} className={verifyClass('codeIdentifier')} />
                    {showErrors('codeIdentifier')}
                </FormGroup>
                <FormGroup label='Dirección' className="col-md-4">
                    <Input id='address' onChange={formik.handleChange} value={formik.values.address} onBlur={formik.handleBlur} className={verifyClass('address')} />
                    {showErrors('address')}
                </FormGroup>
                <FormGroup label='Impuestos' requiredInputLabel style={{ width: '10%' }} key={defaultTaxesValue?.value}>
                    <SearchableSelect
                        isSearchable
                        options={taxesList()}
                        onChange={(e: any) => {
                            formik.handleChange(e.value);
                            formik.setFieldValue('taxes', e.value);
                            setTaxSelected(e.value);
                        }}
                        defaultValue={defaultTaxesValue}
                        name='taxes-select'
                        placeholder=''
                        classname={verifyClass('taxes')}
                    />
                    {showErrors('taxes')}
                </FormGroup>
                <FormGroup label="Retenciones" requiredInputLabel style={{ width: '10%' }}>
                    <Input
                        type="number"
                        onChange={(e: any) => { formik.setFieldValue('withholdings', e.target.value) }}
                        value={formik.values.withholdings}
                        onBlur={formik.handleBlur}
                        className={verifyClass('withholdings')}
                    />
                    {showErrors('withholdings')}
                </FormGroup>
            </div>

            <div className="row mt-2 d-flex justify-content-between">
                {(mode === 'Crear' || defaultCodeValue) && renderSearchableSelect('Código', getCodesIdentifiersList, 'code', defaultCodeValue as any)}
                {(mode === 'Crear' || defaultStatusValue) && renderSearchableSelect('Estado', getStatusList, 'status', defaultStatusValue as any)}
                {(mode === 'Crear' || defaultSeriesValue) && renderSearchableSelect('Serie', getSeriesList, 'series', defaultSeriesValue as any)}

                <div className="col-md-2 d-flex flex-column">
                    <FormLabel label='Método de pago' required />
                    <Select
                        name='paymentMethod'
                        value={formik.values.paymentMethod}
                        onChange={formik.handleChange}
                        list={[
                            { value: 'Transferencia bancaria', label: 'Transferencia' },
                            { value: 'Efectivo', label: 'Efectivo' },
                            { value: 'Remesa', label: 'Remesa' },
                        ]}
                        className={verifyClass('paymentMethod')}
                        ariaLabel="Método de pago"
                    />
                </div>

                <FormGroup label='Fecha de vencimiento' requiredInputLabel className="col-md-2" key={formik.values.expirationDate}>
                    <Input
                        type='date'
                        name='expirationDate'
                        value={formik.values.expirationDate?.split(' ')[0]}
                        onChange={formik.handleChange}
                        className={verifyClass('expirationDate')}
                    />
                    {showErrors('expirationDate')}
                </FormGroup>
            </div>
        </form>
    )
}

export default BudgetGeneralForm;