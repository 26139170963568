import { Fragment, useCallback, useContext, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import StrategyFilters from "./strategy-options/StrategyFilters";
import CreateModal from "../../../components/CreateModal";
import { OriginsApiResponse } from "../../../type/origin-type";
import { StrategyService } from "../../../services/strategies/strategyService";

export interface IStrategyFilters {
  active?: number;
  name?: string;
}

const clasificationtFilters: IStrategyFilters = {
  name: "",
  active: 3,
};

const StrategyList = () => {

  const { userCan } = useContext(PrivilegeContext);
  const strategyService = new StrategyService();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState("");

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize, } = useFilters(clasificationtFilters);

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await strategyService.lisStrategies(filters);
    return response.getResponseData() as OriginsApiResponse;
  }, [filters]));

  const handleDelete = async (id: string) => {
    try {
      const response = (await strategyService.deleteStrategy(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success("Estrategia eliminada correctamente");
        }, 100);
      } else {
        toast.error(response.message || "Error al eliminar la Estrategia");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const _onCloseModal = (message: any, type: number) => {
    setModalOpen(false);
    setSelectedItem(null);
    setLabel("");
    setShow(false);
    if (type === 1) {
      toast.success(message);
    } else {
      message.forEach((element: any) => {
        toast.error(element.message);
      });
    }
    refetch();
  };

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data) {
      let apiData = data as OriginsApiResponse;

      return (
        <CustomTable
          title="Estrategias"
          data={apiData.origins ? apiData.origins : null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: (apiData as OriginsApiResponse) ? apiData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "Origen",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div
                    className="cursor-pointer cursor-pointer fw-bold text-primary"
                    onClick={() => {
                      setModalOpen(true);
                      setSelectedItem(element);
                      setLabel("Ver Origen");
                      setShow(true);
                    }}
                  >
                    {element.name}
                  </div>
                );
              },
            },
            {
              name: "Descripción",
              keyValue: "description",
              render: (element: any) => {
                return <div className="">{element.description || "-"}</div>;
              },
            },
            {
              name: "Organización",
              keyValue: "company",
              className: userCan("list", "companies") ? "d-block" : "d-none",
              isVisible: userCan("list", "companies") ? true : false,
              render: (element: any) => {
                return <>{element.company.name || "-"}</>;
              },
            },
            {
              name: "Acciones",
              className: "min-w-100px text-end",
              isActionCell: true,
            },
          ]}
          actions={[
            {
              title: "Editar",
              buttonType: "icon",
              icon: "Edit",
              additionalClasses: `text-primary ${userCan("edit", "origin") ? "" : "d-none"}`,
              description: "Editar Estrategia",
              callback: (item: any) => {
                if (!userCan("edit", "origin")) return;
                setModalOpen(true);
                setSelectedItem(item);
                setLabel("Editar Estrategia");
                setShow(false);
              },
            },
            {
              title: "Eliminar",
              buttonType: "icon",
              icon: "Delete",
              additionalClasses: `text-danger ${userCan("delete", "origin") ? "" : "d-none"}`,
              description: "Eliminar Estrategia",
              callback: (item: any) => {
                if (!userCan("delete", "origin")) return;
                handleConfirmationAlert({
                  title: "Eliminar Estrategia",
                  text: "¿Está seguro que desea eliminar la Estrategia?",
                  icon: "warning",
                  onConfirm: () => {
                    handleDelete(item.id);
                  },
                });
              },
            },
            {
              title: "Sin permisos",
              buttonType: 'icon',
              icon: "Block",
              additionalClasses: `text-danger ${(userCan('edit', 'origin') || userCan('delete', 'origin')) ? 'd-none' : ''}`,
              description: "No tiene permisos para editar ni eliminar",
              callback: (item: any) => { },
            }
          ]}
        />
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Estrategias</CardTitle>
          <SubheaderSeparator />
          <Button
            color="primary"
            title="Crear Origen"
            icon="Add"
            isLight
            className={userCan("create", "origin") ? "" : "d-none"}
            onClick={() => {
              if (!userCan("create", "origin")) return;
              setModalOpen(true);
              setSelectedItem(null);
              setLabel("Crear Estrategia");
              setShow(false);
            }}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <StrategyFilters updateFilters={updateFilters} updatePageSize={updatePageSize} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card>
          <CardBody className="table-responsive">
            <Fragment>{getContent()}</Fragment>
          </CardBody>
        </Card>
      </Page>
      {modalOpen && (
        <CreateModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          name="origin"
          label={label}
          onClose={_onCloseModal}
          data={selectedItem}
          show={show}
        />
      )}
    </Fragment>
  );
};

export default StrategyList;