import { useContext, useState } from 'react';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { ClientMenu, FinancesMenu, AccountingPagesMenu, SuperAdminMenu, authMenu, calendarMenu, dashboardPagesMenu, schedulerMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import { loadUserFromLocalStorage } from '../../../utils/jwt';
import { CheckAccessAndLog } from '../../../utils/CheckMenuPermissions';

const DefaultAside = () => {

	const rol = loadUserFromLocalStorage()?.roles[0];
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />

				{CheckAccessAndLog(calendarMenu) &&
					<>
						<Navigation menu={calendarMenu} id='aside-calendar' />
						<NavigationLine />
					</>
				}
				{CheckAccessAndLog(schedulerMenu) &&
					<>
						<Navigation menu={schedulerMenu} id='aside-scheduler' />
						<NavigationLine />
					</>
				}
				{CheckAccessAndLog(ClientMenu) &&
					<>
						<Navigation menu={ClientMenu} id='aside-clients' />
						<NavigationLine />
					</>
				}
				{CheckAccessAndLog(FinancesMenu) &&
					<>
						<Navigation menu={FinancesMenu} id='aside-finances' />
						<NavigationLine />
					</>
				}
				{CheckAccessAndLog(AccountingPagesMenu, [{ group: 'accounting', action: 'admin_accounting' }]) &&
					<>
						<Navigation menu={AccountingPagesMenu} id='aside-accounting' />
						<NavigationLine />
					</>
				}
				<Navigation menu={authMenu} id='aside-auth' />
				{
					rol === 'Superadministrador'
						? (<>
							<Navigation menu={SuperAdminMenu} id='aside-superAdmin' />
							<NavigationLine />
						</>)
						: null
				}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;