import { useFormik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from 'yup';
import { useSelector } from "react-redux";
import { TaskService } from "../../../services/tasks/taskService";
import useFetch from "../../../hooks/useFetch";
import { UserService } from "../../../services/users/userService";
import { UsersApiResponse } from "../../../type/user-type";
import { Task } from "../../../type/task-type";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import { useParams } from "react-router-dom";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { useTasksCalendar } from "../../calendar/providers/TasksCalendarProvider";

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    refetch?: () => void;
}

const validationSchema = yup.object({
    company: yup.string().required(),
    user: yup.string().required('El usuario es obligatorio'),
    project: yup.string().required(),
    name: yup.string().required('El nombre es obligatorio'),
    comments: yup.string().notRequired(),
    date: yup.string().required('La fecha de vencimiento es obligatoria'),
});

const CreateProjectTask: FC<CreateModalProps> = ({ isOpen, setIsOpen, refetch }) => {

    const token = useSelector((state: any) => state.auth.user);
    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { refetchTasks } = useTasksCalendar();

    const taskService = new TaskService();

    const [defaultUserValue, setDefaultUserValue] = useState<{ value: string, label: string }>({ value: '', label: '' });

    const [users] = useFetch(useCallback(async () => {
        const response = await (new UserService).getUsers();
        return response.getResponseData() as UsersApiResponse;
    }, []));

    const getUsersList = () => {
        if (users) {
            return users.users.map((users: any) => {
                return {
                    value: users.id,
                    label: users.name + (users.firstName ? (" " + users.firstName) : '') + (users.lastName ? (" " + users.lastName) : ''),
                }
            })
        }
        return [];
    };

    const _handleCreateTask = async (values: any) => {
        try {
            const response = await (await taskService.createTask(values)).getResponseData();
            if (response.success) {
                toast.success('Tarea creada correctamente');
                refetch && refetch();
                refetchTasks(); // Update calendar
                setIsOpen(false);
            } else {
                handleErrors(response);
            }
        } catch (e) {
            toast.error('Error al crear la tarea');
        }
    };

    const formik = useFormik<Task>({
        enableReinitialize: true,
        initialValues: {
            task: '',
            company: token.company,
            user: defaultUserValue?.value || '' /* token?.id */,
            project: id,
            name: '',
            comments: '',
            date: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.user == '') {
                toast.error('Debes seleccionar un usuario');
                return;
            }
            if (values.company != '') {
                _handleCreateTask(values);
            }
        },
    });

    const verifyClass = (inputFieldID: keyof Task) => { return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : "" };

    const showErrors = (inputFieldID: keyof Task) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    useEffect(() => {
        if (token) {
            setDefaultUserValue({ value: token.id, label: token.name + (token.firstName ? (" " + token.firstName) : '') + (token.lastName ? (" " + token.lastName) : '') });
        }
    }, [token]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size='md' titleId='Crear tarea'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_task'>Crear tarea</ModalTitle>
            </ModalHeader>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <ModalBody className='row px-4 g-4'>
                    <FormGroup label="Asignar a:" className="col-md-12" requiredInputLabel>
                        <SearchableSelect
                            options={getUsersList()}
                            onChange={(e: any) => {
                                formik.setFieldValue('user', e.value);
                                setDefaultUserValue(e);
                            }
                            }
                            placeholder="usuario"
                            value={defaultUserValue}
                        />
                        {showErrors('user')}
                    </FormGroup>

                    <FormGroup id='name' label='Nombre:' className="col-md-12" requiredInputLabel>
                        <Input id='name' value={formik.values.name} autoComplete="off" onChange={(e: any) => formik.setFieldValue('name', e.target.value)} className={verifyClass('name')} />
                        {showErrors('name')}
                    </FormGroup>

                    <FormGroup label="Fecha de vencimiento:" className="col-md-5" requiredInputLabel>
                        <Input
                            type="date"
                            name="date"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.date}
                            className={verifyClass('date')}
                        />
                        {showErrors('date')}
                    </FormGroup>

                    <FormGroup label="Descripción:">
                        <textarea
                            name="comments"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.comments}
                            rows={4}
                            style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                                backgroundColor: '#f8f9fa',
                                width: '100%',
                                padding: '15px',
                                border: '0'
                            }}
                            className={verifyClass('comments')}
                        />
                        {showErrors('comments')}
                    </FormGroup>
                </ModalBody>

                <ModalFooter className='px-4 pb-4 d-flex'>
                    <Button color='secondary' type="submit" icon="Save" className='d-block'>Crear</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default CreateProjectTask;