export default function useGetIcon() {

    const getIcon = (extension: string) => {

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'svg':
                return 'Image';
            case 'mp4':
            case 'avi':
            case 'mov':
            case 'wmv':
            case 'flv':
            case 'webm':
                return 'OndemandVideo';
            case 'txt':
            case 'doc':
            case 'docx':
            case 'odt':
            case 'pdf':
            case 'rtf':
            case 'tex':
            case 'wpd':
            case 'xlsx':
                return 'Article';
            case 'zip':
            case 'rar':
            case '7z':
            case 'tar':
            case 'gz':
            case 'pkg':
            case 'deb':
                return 'Folder';
            case 'sql':
                return 'Storage';
            default:
                return 'Apps';
            //return '';
        }
    }

    return { getIcon };
}