import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export interface SchedulerState {
    viewMode: "Mes" | "Semana" | "Día",
    costFilters: {
        estimated_entry_date: {
            from: string,
            to: string
        }
    },
    incomeFilters: {
        estimated_entry_date: {
            from: string,
            to: string
        }
    },
    flowFilters: {
        estimated_entry_date: {
            from: string,
            to: string
        }
    }
};

export const initialState: SchedulerState = {
    viewMode: "Día",
    costFilters: {
        estimated_entry_date: {
            from: moment().subtract(2, 'months').format('YYYY-MM-DD'),
            to: moment().add(2, 'months').format('YYYY-MM-DD')
        }
    },
    incomeFilters: {
        estimated_entry_date: {
            from: moment().subtract(2, 'months').format('YYYY-MM-DD'),
            to: moment().add(2, 'months').format('YYYY-MM-DD')
        }
    },
    flowFilters: {
        estimated_entry_date: {
            from: moment().subtract(2, 'months').format('YYYY-MM-DD'),
            to: moment().add(2, 'months').format('YYYY-MM-DD')
        }
    }
};

const schedulerSlice = createSlice({
    name: 'invoicing_scheduler',
    initialState,
    reducers: {
        changeStorageViewMode: (state, action) => {
            state.viewMode = action.payload;
        },
        changeCostFilters: (state, action) => {
            state.costFilters = action.payload;
        },
        changeIncomeFilters: (state, action) => {
            state.incomeFilters = action.payload;
        },
        changeFlowFilters: (state, action) => {
            state.flowFilters = action.payload;
        }
    },
});

export const { changeStorageViewMode, changeCostFilters, changeIncomeFilters, changeFlowFilters } = schedulerSlice.actions;

export default schedulerSlice.reducer;