export const COLORS_36 = [
    '#8c0000',
    '#ff0000',
    '#fd7f7f',
    '#00651d',
    '#001373',
    '#002aff',
    '#653300',
    '#ff7800',
    '#4f0079',
    '#a100ff',
    '#6c0053',
    '#ff00c3',
    '#005644',
    '#808080',
    '#000000',
    // a partir de aqui son colores claros
    '#fdbfbf',
    '#00ff3d',
    '#7ffd9e',
    '#b4fac3',
    '#7e92fd',
    '#b6c3ff',
    '#ffcb7f',
    '#fde3b7',
    '#c77fff',
    '#e3b7ff',
    '#ff80e0',
    '#ffabea',
    '#00ffc6',
    '#81fde1',
    '#b8ffee',
];