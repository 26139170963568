import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const BUDGETS_ENDPOINT = '/financial';

export class BudgetService extends RestServiceConnection {

    listBudgets = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/list-budgets',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    listStatusCountBudget = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/count-budget-statuses',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getBudget = async (budget: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/get-budget',
            data: { budget },
        }, true) as AxiosResponse;
        return this;
    }

    createBudget = async (budget: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/create-budget',
            data: budget,
        }, true) as AxiosResponse;
        return this;
    }

    editBudget = async (budget: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/edit-budget',
            data: budget,
        }, true) as AxiosResponse;
        return this;
    }

    deleteBudget = async (budget: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/delete-budget',
            data: { budget },
        }, true) as AxiosResponse;
        return this;
    }

    toggleBudgetStatus = async (id: string, status: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/toggle-budget-status',
            data: {
                budget: id,
                status: status
            }
        }, true) as AxiosResponse;
        return this;
    }

    checkInvoiceData = async (budgetId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/check-invoice-data',
            data: { budgetId }
        }, true) as AxiosResponse;
        return this;
    }

    toggleStageValidate = async (stageId: string, concept: string, description: string, estimatedEntryDate: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/toggle-stage-validate',
            data: { 
                stageId: stageId,
                concept: concept,
                description: description,
                estimatedEntryDate: estimatedEntryDate
             }
        }, true) as AxiosResponse;
        return this;
    }

    getBudgetStages = async (budget: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/get-budget-stages',
            data: { budget },
        }, true) as AxiosResponse;
        return this;
    }

    downloadInvoice = async (budgetId: string, stageId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/export-budget-to-pdf',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                budget: budgetId,
                stages: stageId,
            }
        }, true);
        return this;
    }

    sendBudgetToClient = async (budget: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/send-budget-to-client',
            data: { budget }
        }, true);
        return this;
    }

    sendInvoiceToClient = async (budgetId: string, stageId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/send-invoice-to-client',
            data: {
                budget: budgetId,
                stage: stageId,
            }
        }, true);
        return this;
    }

    generateBudgetPDF = async (budget: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/generate-budget',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: { budget }
        }, true);
        return this;
    }

    cloneBudget = async (budget: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/clone-budget',
            data: budget,
        }, true) as AxiosResponse;
        return this;
    }

    saveExtraInfo = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/insert-pdf-extra-info',
            data: values,
        }, true) as AxiosResponse;
        return this;
    }

    uploadImage = async (budget: string, image: File, imageType: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/insert-pdf-images',
            data: {
                budget,
                image,
                imageType,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true) as AxiosResponse;
        return this;
    }

    exportBudgets = async (endpoint: string, company: string, user: string, clientId: string, filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `/${endpoint}/export`,
            responseType: 'arraybuffer', // define el tipo de respuesta que se espera
            data: {
                company: company,
                user: user,
                client: clientId,
                filter_filters: {
                    name: filters.searchText,
                },
            }
        }, true);
        return this;
    }

    importBudgets = async (endpoint: string, file: File, company: string, user: string, clientId?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `/${endpoint}/import`,
            data: {
                file: file,
                company: company,
                user: user,
                client: clientId,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    togglePaidStage = async (stage: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/toggle-paid-stage',
            data: { stage }
        }, true) as AxiosResponse;
        return this;
    }

    listStatusChanges = async (budget: string, startDate?: string, endDate?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUDGETS_ENDPOINT + '/list-status-changes',
            data: {
                budget,
                startDate,
                endDate
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }

}