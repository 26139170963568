import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Icon from '../../../../components/icon/Icon';
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider';
import { CompanyService } from '../../../../services/companies/organizationService';
import Button from '../../../../components/bootstrap/Button';
import useFetch from '../../../../hooks/useFetch';
import { UserService } from '../../../../services/users/userService';
import { UsersApiResponse } from '../../../../type/user-type';
import SearchableSelect from '../../../../components/selector/SearchableSelect';
import { ProjectService } from '../../../../services/projects/projectService';
import { ProjectsApiResponse } from '../../../../type/project-type';

interface ITaskFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: () => void;
    filters: any;
}

const TaskFilters: FC<ITaskFiltersProps> = ({ updateFilters, updatePageSize, filters, resetFilters }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const [filterMenu, setFilterMenu] = useState<boolean>(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState<any>(filters.filter_filters.company || '');

    const [users] = useFetch(useCallback(async () => {
        const response = await (new UserService).getUsers();
        return response.getResponseData() as UsersApiResponse;
    }, []));

    const [projects] = useFetch(useCallback(async () => {
        const response = await (new ProjectService).listProjects();
        return response.getResponseData() as ProjectsApiResponse;
    }, []));

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const getUserById = (id: string) => {
        const user = users?.users?.find((user: any) => user.id === id);
        if (user) {
            return {
                value: user.id,
                label: user.name + (user.firstName ? (" " + user.firstName) : '') + (user.lastName ? (" " + user.lastName) : ''),
            }
        }
        return null;
    }

    const getProjectById = (id: string) => {
        const project = projects?.projects?.find((project: any) => project.id === id);
        if (project) {
            return {
                value: project.id,
                label: project.codeIdentifier,
            }
        }
        return null;
    }

    useEffect(() => {
        if (isSuperAdmin) {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                }}
                placeholder='Buscar...'
            />

            <Button color='primary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='taskFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Tarea</OffCanvasTitle>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            {userCan('list', 'companies') && (
                                <FormGroup id='filter0' label='Organización'>
                                    <Select
                                        id='company-select'
                                        list={getCompanyList()}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            updateFilters({ company: e.target.value })
                                            setSelectedCompany(e.target.value)
                                        }}
                                        value={selectedCompany}
                                        ariaLabel='Company selector'
                                        placeholder='Organización...'
                                    />
                                </FormGroup>
                            )}

                            <FormGroup id='filter1' label='Estado:' className='mt-3'>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ is_finished: parseInt(e.target.value) })}
                                    value={filters.filter_filters.is_finished.toString()}
                                    id='active-select'
                                    ariaLabel='Selector de estado'
                                    placeholder='Estado ...'
                                    list={[
                                        { value: 3, label: 'Todas las tareas' },
                                        { value: 1, label: 'Finalizada' },
                                        { value: 0, label: 'Pendiente' }
                                    ]}
                                />
                            </FormGroup>

                            <FormGroup id='filter2' label='Asignada al usuario:' className='mt-3'>
                                <SearchableSelect
                                    name='assigned-user-select'
                                    options={
                                        users?.users?.map((users: any) => {
                                            return {
                                                value: users.id,
                                                label: users.name + (users.firstName ? (" " + users.firstName) : '') + (users.lastName ? (" " + users.lastName) : ''),
                                            }
                                        })
                                    }
                                    onChange={(e: { value: string, label: string }) => updateFilters({ user: e.value })}
                                    value={getUserById(filters.filter_filters.user)}
                                    placeholder='usuario '
                                />
                            </FormGroup>

                            <FormGroup id='filter3' label='Proyecto:' className='mt-3'>
                                <SearchableSelect
                                    name='project-select'
                                    options={
                                        projects?.projects?.map((project: any) => {
                                            return {
                                                value: project.id,
                                                label: project.codeIdentifier,
                                            }
                                        })
                                    }
                                    onChange={(e: { value: string, label: string }) => updateFilters({ project: e.value })}
                                    value={getProjectById(filters.filter_filters.project)}
                                    placeholder='proyecto '
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <div className='col-12 text-center'>
                        <Button
                            className='mt-4'
                            color="secondary"
                            onClick={() => {
                                resetFilters()
                                setSelectedCompany('')
                            }}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default TaskFilters;