import { Fragment } from "react";
import { TasksCalendarProvider } from "../providers/TasksCalendarProvider";
import { TasksCalendar } from "./TasksCalendar";
import { ToastContainer } from "react-toastify";

type TasksCalendarWrapperProps = {}

export const TasksCalendarWrapper: React.FC<TasksCalendarWrapperProps> = () => {
    return (
        <Fragment>
            <ToastContainer />
            <TasksCalendarProvider>
                <TasksCalendar />
            </TasksCalendarProvider>
        </Fragment>
    );
}