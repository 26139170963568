import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import BudgetFilters from "./budgets-options/BudgetsFilters";
import Button from "../../../components/bootstrap/Button";
import useFetch from "../../../hooks/useFetch";
import CloneBudgetModal from "../components/CloneBudgetModal";
import { useNavigate } from "react-router-dom";
import { ClientMenu, FinancesMenu } from "../../../menu";
import { BudgetService } from "../../../services/budgets/budgetService";
import { Budget, BudgetsApiResponse } from "../../../type/budgets-type";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import moment from "moment";
import IconWithTooltip from "../../../components/IconWithTooltip";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import { StatusService } from "../../../services/status/statusService";
import { Status } from "../../../type/status-type";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import StatusesDropdown from "../../../components/dropdowns/StatusesDropdown";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import EmptyTable from "../../../components/EmptyTable";
import Tooltips from "../../../components/bootstrap/Tooltips";
import slugify from "slugify";
import Spinner from "../../../components/bootstrap/Spinner";
import useHandleErrors from "../../../hooks/useHandleErrors";

export interface IBudgetsFilters {
    company?: string;
    client: string;
    classification?: string;
    status?: string;
    minAmount?: number;
    maxAmount?: number;
}

const BudgetsList = () => {

    const token = useSelector((state: RootState) => state.auth);
    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const fileInputRef = useRef<HTMLInputElement>(null);  // asocia el input file a un ref para poder hacerlo con un boton

    const budgetService = new BudgetService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFiltersPR();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [budgetData, setBudgetData] = useState<{ id: string, name: string }>();
    const [budgetStatuses, setBudgetStatuses] = useState<Status[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await budgetService.listBudgets(filters);
        return response.getResponseData() as Budget;
    }, [filters]));

    const getStatusList = async (orientedTo: string): Promise<Status[] | undefined> => {
        const response = await (await (new StatusService()).listStatuses({ filter_filters: { oriented_to: orientedTo }, limit: 999999 })).getResponseData();
        if (response.success) {
            return response.data.statuses;
        } else {
            return [];
        }
    }

    // Carga los estados de los presupuestos
    const fetchStatuses = async () => {
        let budgetStatusList = await getStatusList("budget");
        setBudgetStatuses(budgetStatusList !== undefined ? budgetStatusList : []);
    };

    // Descargar documento
    const downloadDocument = async (documentId: string, codeIdentifier: string, address: string) => {
        try {
            const response = (await budgetService.generateBudgetPDF(documentId));
            const responseData = response.getResponseData();

            if (responseData) {
                const pdfName = `${codeIdentifier.replace(/-/g, '_')} ${slugify(address, { remove: /[.]/g })}.pdf`;
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', pdfName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Importar presupuestos desde excel
    const handleImport = async (e: React.ChangeEvent<any>) => {
        try {
            setIsUploading(true);
            const selectedFile = e.target.files && e.target.files[0];

            const response = (await budgetService.importBudgets('budgets', selectedFile, token.user?.company || '', token.user?.id || '')).getResponseData();
            if (response.success) {
                toast.success('Presupuestos importados correctamente');
                refetch();
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsUploading(false);
        }
    };

    // Exportar presupuestos a excel
    const handleExport = async () => {
        try {
            const response = await budgetService.exportBudgets('financial', token.user?.company || '', token.user?.id || '', '', filters);
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'listado_presupuestos.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar los presupuestos');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await budgetService.deleteBudget(id)).getResponseData();

            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success("Presupuesto eliminado");
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar el presupuesto");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchStatuses();
    }, []);

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>;

        if (error) return <ErrorMessage /* error={error} */ />;

        if (data && data.budgets?.length > 0) {
            return (
                <CustomTable
                    title="Presupuestos"
                    data={data.budgets ? data.budgets : null}
                    defaultLimit={filters.limit || 50}
                    defaultOrder={filters.filter_order || undefined}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data as BudgetsApiResponse ? data.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Estado",
                            keyValue: "currentStatus",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (item: any) => {
                                return (
                                    <div className="p-0" key={item.id}>
                                        <StatusesDropdown
                                            onChange={() => { refetch && refetch() }}
                                            key={item.id + "-status"}
                                            itemId={item.id}
                                            statuses={budgetStatuses}
                                            status={
                                                item.budgetStatus != null
                                                    ? item.budgetStatus
                                                    : null
                                            }
                                            additionalInfo={
                                                item.budgetStatusDate
                                                    ? moment(item.budgetStatusDate.date).format("DD/MM/yyyy HH:mm")
                                                    : undefined
                                            }
                                            orientedTo={"budget"}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Identificador",
                            keyValue: "code_identifier",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary">
                                        <a href={`${FinancesMenu.finances.path}/${element.id}/vista`}>{element.codeIdentifier}</a>
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Cliente",
                            keyValue: "client",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary">
                                        {
                                            element.client.name !== null ? (
                                                <Tooltips placement="top" title={(
                                                    element.client?.clientContactPerson
                                                        ? element.client?.clientContactPerson?.name
                                                        + (element.client?.clientContactPerson?.firstName ? (' ' + element.client?.clientContactPerson.firstName) : '')
                                                        + (element.client?.clientContactPerson?.lastName ? (' ' + element.client?.clientContactPerson.lastName) : '')
                                                        + (element.client?.clientContactPerson?.telephone ? (' ' + element.client?.clientContactPerson.telephone) : '')
                                                        : 'No tiene persona de contacto'
                                                )}>
                                                    <a href={`${ClientMenu.clients.path}/${element.client.id}/perfil`}>
                                                        {element.client?.name + (element.client.firstName ? " " + element.client.firstName : '') + (element.client.lastName ? " " + element.client.lastName : '')}
                                                    </a>
                                                </Tooltips>
                                            ) : '-'
                                        }
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Dirección',
                            keyValue: 'address',
                            sortable: true,
                            sortColumn: updateFilterOrder,

                        },
                        /* {
                            name: "Precio total",
                            keyValue: "totalPrice",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="w-50">{`${Math.round(element.totalPrice)} €`}</div>
                                )
                            },
                        }, */
                        {
                            name: 'Precio sin IVA',
                            keyValue: 'totalPriceWithoutTaxes',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="w-50">{`${Math.round(element.totalPriceWithoutTaxes)} €`}</div>
                                )
                            },
                        },
                        /* {
                            name: 'Seguimiento',
                            keyValue: 'statusChanges',
                            render: (element: any) => {
                                console.log(element.budgetHasStatus)
                                return (
                                    <>
                                        {element.budgetHasStatus?.map((status: any, index: number) => {
                                            return (
                                                <Tooltips placement="left" title={status.comments}>
                                                    <div key={status.id + index} className="d-flex align-items-center">
                                                        <div className="me-2">{moment(status.date?.date).format('DD/MM/YY')}</div>
                                                        <div className="me-2">{status.concept}</div>
                                                    </div>
                                                </Tooltips>
                                            )
                                        })}
                                    </>
                                )
                            }
                        }, */
                        {
                            name: "Fecha de presentación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt.date).format('DD/MM/YYYY')}
                                    </>
                                )
                            },
                        },
                        {
                            name: "Fecha de aceptación",
                            keyValue: "acceptedDate",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.acceptedDate ? moment(element.acceptedDate.date).format('DD/MM/YYYY') : '-'}
                                    </>
                                )
                            },
                        },
                        {
                            name: "Proyecto",
                            keyValue: "createProject",
                            render: (element: any) => {
                                return (
                                    <>
                                        {
                                            element.project !== null
                                                ? (
                                                    <div className="cursor-pointer text-primary">
                                                        <a href={`${FinancesMenu.project.path}/${element.project.id}/vista`}>{element.project.codeIdentifier}</a>
                                                    </div>
                                                ) : element.budgetStatus?.finalStatus
                                                    ? (
                                                        <Button
                                                            title="Crear proyecto"
                                                            color="secondary"
                                                            isLink
                                                            isLight
                                                            icon="Add"
                                                            onClick={() => { navigate(`${FinancesMenu.project.path}/creacion/${element.id}`) }}
                                                        />
                                                    )
                                                    : <IconWithTooltip icon="Info" label="El presupuesto no está en la fase final" />
                                        }
                                    </>
                                )
                            }
                        },
                        {
                            name: "Documento",
                            keyValue: "budgetPDF",
                            render: (element: any) => {
                                return (
                                    <div
                                        onClick={() => { downloadDocument(element.id, element.codeIdentifier, element.address) }}
                                        className="cursor-pointer text-primary"
                                    >
                                        Descargar
                                    </div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: 'Editar',
                            buttonType: 'icon',
                            icon: 'Edit',
                            additionalClasses: `text-primary ${userCan("edit", "budget") ? "" : "d-none"}`,
                            description: 'Editar presupuesto',
                            //click: (item: any) => { navigate(`${FinancesMenu.finances.path}/${item.id}/edicion`) },
                            callback: (item: any) => {
                                if (userCan("edit", "budget")) {
                                    item.budgetStatus?.finalStatus === false
                                        ? navigate(`${FinancesMenu.finances.path}/${item.id}/edicion`)
                                        : toast.info('No se puede editar un presupuesto que ya ha llegado a su estado final')
                                }
                            },
                        },
                        {
                            title: 'Clonar',
                            buttonType: 'icon',
                            icon: 'ContentCopy',
                            additionalClasses: `text-primary ${userCan("create", "budget") ? "" : "d-none"}`,
                            description: 'Clonar presupuesto',
                            callback: (item: any) => {
                                if (!userCan("create", "budget")) return;
                                setOpenModal(true);
                                const budget = { id: item.id, name: item.codeIdentifier }
                                setBudgetData(budget);
                            },
                        },
                        {
                            title: "Eliminar",
                            buttonType: "icon",
                            icon: "Delete",
                            additionalClasses: `text-primary ${userCan("delete", "budget") ? "" : "d-none"}`,
                            description: "Eliminar presupuesto",
                            callback: (item: any) => {
                                if (userCan("delete", "budget")) {
                                    handleConfirmationAlert({
                                        title: "Eliminar Presupuesto",
                                        text: "¿Está seguro que desea eliminar el presupuesto?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        },
                                    });
                                }
                            },
                        },
                        {
                            title: "Sin permisos",
                            buttonType: 'icon',
                            icon: "Block",
                            additionalClasses: `text-danger ${(userCan('edit', 'budget') || userCan('delete', 'budget')) ? 'd-none' : ''}`,
                            description: "No tiene permisos para editar ni eliminar",
                            callback: (item: any) => { },
                        }
                    ]}
                />
            )
        } else {
            return <EmptyTable row="presupuestos" />
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Presupuestos</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="primary"
                        title="Crear presupuesto"
                        icon="Add"
                        isLight
                        className={`${userCan("create", "budget") ? "" : "d-none"}`}
                        onClick={() => { if (userCan("create", "budget")) { navigate(`${FinancesMenu.finances.path}/creacion`) } }}
                    />

                    {/* <Button
                        color="light"
                        isLight
                        title="Importar excel"
                        onClick={() => fileInputRef.current?.click()}
                    >Importar</Button>

                    {isUploading && <Spinner color={"primary"} />} */}

                    <Button
                        color="light"
                        isLight
                        title="Exportar excel"
                        onClick={handleExport}
                    >Exportar</Button>

                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleImport} />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <BudgetFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        {getContent()}
                    </CardBody>
                </Card>

                {openModal && budgetData && <CloneBudgetModal isOpen={openModal} setIsOpen={setOpenModal} budget={budgetData} refetch={refetch} />}
            </Page>
        </Fragment>
    )
}

export default BudgetsList;