import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Timeline, { TimelineItem } from "../../../components/extras/Timeline";
import dayjs from "dayjs";
import { FC, useState } from "react";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";

interface StatusesTimeLineProps {
    statusChanges: any;
    className?: string;
}

const StatusesTimeLine: FC<StatusesTimeLineProps> = ({ statusChanges, className }) => {

    const [reverseOrder, setReverseOrder] = useState(false);

    return (
        <div className="cardTabDocTypes">
            <Card className={className} stretch>
                <CardHeader className="p-0 d-flex">
                    <CardLabel icon="NotificationsActive" iconColor="warning">
                        <CardTitle tag="h4" className="h5">
                            Cambios de estado del presupuesto
                        </CardTitle>
                    </CardLabel>
                    <CardActions className="d-flex">
                        <Button
                            color="primary" isLight
                            onClick={() => setReverseOrder((prevOrder) => !prevOrder)}
                            title={`Ordenar de ${reverseOrder ? "más reciente a más antiguo" : "más antiguo a más reciente"}`}
                        >
                            <Icon icon="SwapVert" size='lg' />
                        </Button>
                    </CardActions>
                </CardHeader>

                <CardBody className="ps-0 pe-0">
                    {statusChanges?.budgetStatuses?.length === 0
                        ? <div className="text-center">No hay cambios de estado para este proyecto</div>
                        : (
                            <Timeline noTruncate={true}>
                                {
                                    statusChanges?.budgetStatuses.length > 0 ? (
                                        statusChanges?.budgetStatuses?.map((status: any) => ({
                                            id: status.id,
                                            color: status.status?.color ? status.status?.color : "warning",
                                            date: status.date?.date,
                                            content: `${status?.user?.name + (status?.user?.firstName ? (' ' + status.user.firstName) : '') + (status?.user?.lastName ? (' ' + status.user.lastName) : '')}: ${status.status?.name}`,
                                            concept: status.concept,
                                            comment: status.comments
                                        }))
                                            .filter((status: any) => status.date)
                                            .sort((a: any, b: any) => reverseOrder
                                                ? new Date(a.date).getTime() - new Date(b.date).getTime()
                                                : new Date(b.date).getTime() - new Date(a.date).getTime()
                                            )
                                            .map((status: any, index: number) => (
                                                <TimelineItem
                                                    noTruncate={true}
                                                    key={index}
                                                    label={dayjs(status.date).format("DD-MM-YY | HH:mm")}
                                                    color={status.color}
                                                >
                                                    {status.content}
                                                    <div>
                                                        <p className="m-0 text-muted">
                                                            Concepto: {status.concept || '-'}
                                                        </p>
                                                        <p className="text-muted" style={{ maxWidth: '2000px', width: '100%', fontSize: '0.875em' }}>
                                                            Comentario: {status.comment || '-'}
                                                        </p>
                                                    </div>
                                                </TimelineItem>
                                            ))
                                    ) : <></>
                                }
                            </Timeline>
                        )
                    }
                </CardBody>
            </Card>
        </div>
    );
};

export default StatusesTimeLine;