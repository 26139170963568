import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Select from "../../../components/bootstrap/forms/Select";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { IncomeService } from "../../../services/incomes/incomeService";
import { TaxService } from "../../../services/taxes/taxService";
import Checks from "../../../components/bootstrap/forms/Checks";
import moment from "moment";
import { DocumentService } from "../../../services/documents/documentService";

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data?: any;
    onClose: (type: number, message?: string, errors?: any) => void;
    show?: boolean;
}

const CreateModalIncome: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');
    const incomeService = new IncomeService();
    const documentService = new DocumentService();

    const [modal, setModal] = useState(false);
    const [incomeTypeList, setIncomeTypeList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    const getIncomeTypes = async () => {
        const incomeTypeId = data?.id || '';
        const response = await (await incomeService.getIncomeTypes({
            filter_filters: {
                not_income_type: incomeTypeId
            },
            filter_order: [
                {
                    "field": "name",
                    "order": "asc"
                }
            ],
            limit: 999999
        })).getResponseData();
        if (response.success) {
            const incomeTypes = response.data.incomeTypes;
            setIncomeTypeList(incomeTypes);
        }
    }

    const getIncomeTypeList = () => {
        if (incomeTypeList as any) {
            let incomeTypeOptions = incomeTypeList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return incomeTypeOptions;
        }
        return [];
    }

    const getTaxs = async () => {
        const taxService = new TaxService()
        const response = await (await taxService.listTaxes()).getResponseData();
        if (response.success) {
            const taxs = response.data.taxes;
            setTaxList(taxs);
        }
    }

    const getTaxsList = () => {
        if (taxList as any) {
            let taxOptions = taxList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return taxOptions;
        }
        return [];
    }

    useEffect(() => {
        getIncomeTypes();
        getTaxs();
    }, [isSuperAdmin])

    const create = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await (await incomeService.createIncome(values)).getResponseData();
            if (response.success) {
                if (selectedDocument) {
                    const documentResponse = await (await documentService.uploadDocumentIncomeCost(response.data, 'income', selectedDocument)).getResponseData();

                    if (documentResponse.success) {
                        toast.success('Documento subido correctamente');
                    } else {
                        toast.error(documentResponse.data.errors);
                    }
                }
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear el ingreso');
        } finally {
            setIsLoading(false);
        }
    };

    const update = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await (await incomeService.editIncome(values)).getResponseData();
            if (response.success) {
                if (selectedDocument) {
                    const documentResponse = await (await documentService.uploadDocumentIncomeCost(response.data, 'income', selectedDocument)).getResponseData();

                    if (documentResponse.success) {
                        toast.success('Documento subido correctamente');
                    } else {
                        toast.error(documentResponse.data.errors);
                    }
                }
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar el ingreso');
        } finally {
            setIsLoading(false);
        }
    };

    const downloadInvoice = async (documentId: string, concept: string, originalName: string) => {
        try {
            if (concept === '') concept = 'ingreso';
            else {
                concept = concept.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                concept = concept.replace(/ /g, "_");
            }
            const response = await (new DocumentService()).downloadDocument(documentId);
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', originalName || `${concept}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar la factura');
            }
        } catch (e) {
            toast.error('Error al descargar la factura');
        }
    };

    const handleDocumentUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        setSelectedDocument(file);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            incomeId: data?.id || '',
            document: data?.document?.id || '',
            concept: data?.concept || '',
            price: data?.price || '',
            description: data?.description || '',
            incomeTypeId: data?.incomeType?.id || null,
            taxId: data?.taxes?.id || null,
            paid: data?.paid || false,
            estimatedEntryDate: data?.estimatedEntryDate?.date?.split(' ')[0] || null,
            effectiveEntryDate: data?.effectiveEntryDate?.date?.split(' ')[0] || null,
            stages: data?.stages || [],
        },
        onSubmit: (values) => {
            if (data != null) {
                update(values)
            } else {
                create(values);
            }

        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'lg' : 'md'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{show ? 'Ver Ingreso' : data ? 'Editar Ingreso' : 'Crear Ingreso'}</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='concept' label='Concepto' className={isSuperAdmin ? 'col-md-6' : "col-md-9"}>
                                <Input value={formik.values.concept || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='price' label='Importe' className={isSuperAdmin ? 'col-md-6' : "col-md-3"}>
                                <Input type="number" step={0.01} min={0} value={formik.values.price || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='incomeTypeId' label='Tipo de Ingreso' className={isSuperAdmin ? 'col-md-6' : "col"}>
                                <Select
                                    id="incomeTypeId"
                                    disabled={show ? true : false}
                                    list={getIncomeTypeList()}
                                    onChange={formik.handleChange}
                                    value={formik.values.incomeTypeId || ''}
                                    ariaLabel='Select incomeType'
                                    placeholder='Seleciona Tipo de Ingreso ...'
                                    required
                                />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='taxId' label='Impuesto' className={isSuperAdmin ? 'col-md-6' : "col-md-5"}>
                                <Select
                                    id='taxId'
                                    disabled={show ? true : false}
                                    list={getTaxsList()}
                                    onChange={formik.handleChange}
                                    value={formik.values.taxId || ''}
                                    ariaLabel='Select tax'
                                    placeholder='Seleciona Impuesto ...'
                                    required
                                />
                            </FormGroup>

                            <FormGroup id='paid' label='Pagado' className={isSuperAdmin ? 'col-md-12' : "col-auto"}>
                                <Checks
                                    type="switch"
                                    id="paid"
                                    name="paid"
                                    checked={formik.values.paid}
                                    onChange={() => {
                                        formik.setFieldValue('paid', !formik.values.paid);
                                        formik.setFieldValue('effectiveEntryDate', !formik.values.paid ? moment().format('YYYY-MM-DD') : null);
                                    }}
                                    disabled={show ? true : false}
                                />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='estimatedEntryDate' label='Fecha de entrada estimada' className={isSuperAdmin ? 'col-md-5' : "col-md-6"}>
                                <Input type='date' value={formik.values.estimatedEntryDate || ''} disabled={show ? true : false} required onChange={(e: any) => {
                                    const { value } = e.target;
                                    formik.setFieldValue('estimatedEntryDate', value === '' ? null : value);
                                }} />
                            </FormGroup>

                            {formik.values.paid && (
                                <FormGroup id='effectiveEntryDate' label='Fecha de entrada efectiva' className={isSuperAdmin ? 'col-md-5' : "col-md-6"}>
                                    <Input type='date' value={formik.values.effectiveEntryDate || ''} disabled={show ? true : false} required={formik.values.paid} onChange={(e: any) => {
                                        const { value } = e.target;
                                        formik.setFieldValue('effectiveEntryDate', value === '' ? null : value);
                                    }} />
                                </FormGroup>
                            )}

                            <FormGroup id='document' label='Documento:' className={"col"}>
                                <Input type='file' disabled={show ? true : false} onChange={(e: React.ChangeEvent<any>) => handleDocumentUpload(e)} autoComplete='photo' />
                            </FormGroup>

                            {(data && formik.values?.document) && (
                                <FormGroup id='document' label='Documento:' className={"col-md-2"}>
                                    <Button
                                        color='dark'
                                        icon='SimCardDownload'
                                        title="Descargar factura"
                                        className="me-2 col-12"
                                        onClick={() => { downloadInvoice(formik.values.document.id, formik.values.concept, formik.values.document.originalName) }}
                                    />
                                </FormGroup>
                            )}

                            <FormGroup id='description' label='Descripción:' className={"col-md-12"}>
                                <Textarea value={formik.values.description || ''} disabled={show ? true : false} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'} isDisable={isLoading}>
                            {isLoading ? <Spinner /> : (data ? 'Editar' : 'Crear')}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (
                <div className="d-flex justify-content-center p-5">
                    <Spinner />
                </div>
            )}
        </Modal>
    )
}

export default CreateModalIncome;