import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider'
import { CompanyService } from '../../../../services/companies/organizationService'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import { CategoryService } from '../../../../services/categories/categoryService'
import SearchableSelect from '../../../../components/selector/SearchableSelect'
import useFetch from '../../../../hooks/useFetch'
import { ProvincesApiResponse } from '../../../../type/client-type'
import { CategoriesApiResponse } from '../../../../type/category-type'
import { ClientService } from '../../../../services/clients/clientService'

interface IClientssFiltersProps {
    updateFilters: (filters: any) => void;
    resetFilters: any;
    filters: any;
    name: string;
    classification?: boolean;
}

const ClientFilters: FC<IClientssFiltersProps> = ({ updateFilters, filters, resetFilters, name, classification }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const [searchText, setSearchText] = useState('');
    const [filterMenu, setFilterMenu] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [contactPerson, setContactPerson] = useState<any>(filters.filter_filters.clientContactPerson || '');
    const [selectedClasification, setSelectedClasification] = useState<any>(filters.filter_filters.clasification || '');
    const [selectedCompany, setSelectedCompany] = useState<any>(filters.filter_filters.company || '');

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    };

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const [categories] = useFetch(useCallback(async () => {
        const response = await (new CategoryService).listCategories();
        return response.getResponseData() as CategoriesApiResponse;
    }, []));

    const [provinces, loadingProvinces, provincesError] = useFetch(useCallback(async () => {
        const response = await (new ClientService).listProvinces();
        return response.getResponseData() as ProvincesApiResponse;
    }, []));

    const getCategoriesById = (id: string) => {
        const category = categories?.categories?.find((category: any) => category.id === id);
        if (category) {
            return {
                value: category.id,
                label: category.name,
            }
        }
        return null;
    };

    const getProvincesById = (id: string) => {
        if (loadingProvinces || provincesError) return null;

        const province = provinces?.find((province: { province: string }) => province.province === id);
        if (province) {
            return {
                value: province.province,
                label: province.province,
            }
        }
    };

    useEffect(() => {
        if (isSuperAdmin) {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                }}
                placeholder='Buscar...'
            />

            <Button color='primary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de {name}</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userCan('list', 'companies') && (
                            <FormGroup id='filter0' label='Organización' className='col-md-12'>
                                <Select
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ company: e.target.value })
                                        setSelectedCompany(e.target.value)
                                    }}
                                    value={selectedCompany}
                                    id='company-select'
                                    ariaLabel='Company selector'
                                    placeholder='Organización ...'
                                />
                            </FormGroup>
                        )}

                        <FormGroup id='filter1' label='Estado:' className='col-md-12'>
                            <Select
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                value={filters.filter_filters?.active?.toString() || '3'}
                                id='active-select' ariaLabel='Default select example' placeholder='Estado ...'
                                list={[
                                    { value: 3, label: 'Todos', text: 'Todos' },
                                    { value: 1, label: 'Activo', text: 'Activo' },
                                    { value: 0, label: 'Desactivado', text: 'Desactivado' }
                                ]}
                            />
                        </FormGroup>

                        <FormGroup id='filter2' label='Persona de contacto:' className='col-md-12'>
                            <CustomSearchInput
                                onSearch={(search: string) => {
                                    updateFilters({ clientContactPerson: search });
                                }}
                                onChange={setContactPerson}
                                value={contactPerson}
                                placeholder='Buscar ...'
                            />
                        </FormGroup>

                        <FormGroup id='filter3' label='Categoría:' className='col-md-12'>
                            <SearchableSelect
                                name='category-select'
                                options={
                                    categories?.categories?.map((category: any) => {
                                        return {
                                            value: category.id,
                                            label: category.name,
                                        }
                                    })
                                }
                                onChange={(e: { value: string, label: string }) => updateFilters({ clientCategory: e.value })}
                                value={getCategoriesById(filters.filter_filters.clientCategory)}
                                placeholder='categoría'
                            />
                        </FormGroup>

                        {provinces && (
                            <FormGroup id='filter4' label='Provincia:' className='col-md-12'>
                                <SearchableSelect
                                    name='province-select'
                                    options={
                                        provinces?.map((option: { province: any; }) => {
                                            return {
                                                value: option.province,
                                                label: option.province === ' ' ? 'Sin provincia' : option.province,
                                            }
                                        })
                                    }
                                    onChange={(e: { value: string, label: string }) => updateFilters({ province: e.value })}
                                    value={getProvincesById(filters.filter_filters.province)}
                                    placeholder='provincia'
                                />
                            </FormGroup>
                        )}

                        <FormGroup id='filter5' label='Población:' className='col-md-12'>
                            <CustomSearchInput
                                onSearch={(search: string) => {
                                    updateFilters({ population: search });
                                }}
                                onChange={setSearchText}
                                value={searchText || ''}
                                placeholder='Población ...'
                            />
                        </FormGroup>

                        {classification && (
                            <FormGroup id='filter6' label='Clasificación:' className='col-md-12'>
                                <Select
                                    id='active-select'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ classification: e.target.value })
                                        setSelectedClasification(e.target.value)
                                    }}
                                    value={selectedClasification}
                                    list={[
                                        { value: 'Posible', label: 'Posible' },
                                        { value: 'Potencial', label: 'Potencial' },
                                        { value: 'Consolidado', label: 'Consolidado' }
                                    ]}
                                    ariaLabel='Select de clasificación'
                                    placeholder='Clasificación ...'
                                />
                            </FormGroup>
                        )}
                    </div>

                    <div className='col-12 text-center'>
                        <Button
                            className='mt-4'
                            color="secondary"
                            onClick={() => {
                                resetFilters()
                                setSelectedCompany('')
                                setSelectedClasification('')
                                setContactPerson('')
                            }}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default ClientFilters;