import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import Textarea from "../../../../components/bootstrap/forms/Textarea";
import Button from "../../../../components/bootstrap/Button";
import Spinner from "../../../../components/bootstrap/Spinner";
import { CostService } from "../../../../services/costs/costService";
import Checks from "../../../../components/bootstrap/forms/Checks";
import FormLabel from "../../../../components/FormLabel";
import { SketchPicker } from "react-color";

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data?: any;
    onClose: (type: number, message?: string, errors?: any) => void;
    show?: boolean;
}

const CreateModalCostType: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');
    const costService = new CostService();

    const [modal, setModal] = useState(false);
    const [costTypeList, setCostTypeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

    const getCostTypes = async () => {
        const costTypeId = data?.id || '';
        const response = await (await costService.getCostTypes({
            filter_filters: {
                not_cost_type: costTypeId
            },
            filter_order: [
                {
                    "field": "name",
                    "order": "asc"
                }
            ],
            limit: 999999
        })).getResponseData();
        if (response.success) {
            const costTypes = response.data.costsTypes;
            setCostTypeList(costTypes);
        }
    }

    const getCostTypeList = () => {
        if (costTypeList as any) {
            let costTypeOptions = costTypeList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return [{ value: '', label: 'Ninguno' }, ...costTypeOptions]
        }
        return [];
    }

    useEffect(() => {
        getCostTypes();
    }, [isSuperAdmin])

    const create = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await (await costService.createCostType(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
            setIsLoading(false);
        } catch (e) {
            toast.error('Error al crear tipo de coste');
        }
    }

    const update = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await (await costService.editCostType(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
            setIsLoading(false);
        } catch (e) {
            toast.error('Error al editar el tipo de coste');
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            costTypeId: data?.id || null,
            name: data?.name || '',
            description: data?.description || '',
            parent: data?.parent?.id || '',
            displayOnSelect: data?.displayOnSelect,
            color: data?.color || '#000000',
        },
        onSubmit: (values) => {
            if (data != null) {
                update(values)
            } else {
                create(values);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'md' : 'sm'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{show ? 'Ver Tipo de Coste' : data ? 'Editar Tipo de Coste' : 'Crear Tipo de Coste'}</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className={isSuperAdmin ? 'col-md-6' : "col-md-12"}>
                                <Input value={formik.values.name || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup requiredInputLabel id='parent' label='Categoría Padre' className={isSuperAdmin ? 'col-md-6' : "col-md-12"}>
                                <Select id="parent" disabled={show ? true : false} list={getCostTypeList()} onChange={formik.handleChange} value={formik.values.parent || ''} ariaLabel='Select Cost Types' placeholder='Elegir Tipo de Coste...' />
                            </FormGroup>
                            <FormGroup id='description' label='Descripción:' className={"col-md-12"}>
                                <Textarea value={formik.values.description || ''} disabled={show ? true : false} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup id="displayOnSelect" label="Mostrar en el selector de costes:" className={"col-md-6"}>
                                <Checks
                                    type="switch"
                                    id="displayOnSelect"
                                    name="displayOnSelect"
                                    checked={formik.values.displayOnSelect}
                                    onChange={formik.handleChange}
                                    disabled={show ? true : false}
                                />
                            </FormGroup>
                            <div className="col-md-3">
                                <FormLabel label="Color" required />
                                <div
                                    key={formik.values.costTypeId + formik.values.color}
                                    onClick={() => setShowColorPicker(!showColorPicker)}
                                    style={{
                                        backgroundColor: formik.values.color,
                                        border: "1px solid rgba(0, 0, 0, 0.1)",
                                        cursor: "pointer",
                                        width: "25px",
                                        height: "25px",
                                    }}
                                    className={'rounded-circle mr-3 p-3'}
                                >
                                    <div className="rounded-circle" style={{ backgroundColor: formik.values.color, width: "5px", height: "5px" }} />
                                </div>
                                {showColorPicker && <SketchPicker color={formik.values.color} onChange={(color) => { formik.setFieldValue('color', color.hex) }} />}
                                <></>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'} isDisable={isLoading}>
                            {isLoading ? <Spinner /> : data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>
            ) : (
                <div className="d-flex justify-content-center p-5">
                    <Spinner />
                </div>
            )}
        </Modal>
    )
}

export default CreateModalCostType;