import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import Page from "../../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../../components/ErrorMessage";
import { CustomTable } from "../../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import useFilters from "../../../../hooks/useFilters";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import { Income, IncomeType, IncomeTypesApiResponse } from "../../../../type/income-type";
import CreateModalIncomeType from "../income-type-create/createModal";
import { IncomeService } from "../../../../services/incomes/incomeService";
import IncomeTypeFilters from "./income-type-options/IncomeTypeFilters";
import Icon from "../../../../components/icon/Icon";
import Spinner from "../../../../components/bootstrap/Spinner";
import { Filters } from "../../../../type/apiResponse-type";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import { SketchPicker } from "react-color";

export interface IIncomeTypeFilters {
    active?: number;
}

const incomeTypeFilters: IIncomeTypeFilters = {
    active: 1
};

const IncomeTypeList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const incomeService = new IncomeService();

    const [incomeTypesTree, setIncomeTypesTree] = useState<any[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);
    const [visiblePickerId, setVisiblePickerId] = useState(null);
    const [colors, setColors] = useState([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(incomeTypeFilters);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        filters.limit = 99999999999;
        const filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy.filter_filters = null;
        const response = await incomeService.getIncomeTypes(filtersCopy);
        return response.getResponseData() as IncomeTypesApiResponse;
    }, [filters]));


    const handleColorChange = async (itemId: string, color: any) => {
        try {
            const response = await (await incomeService.changeCostTypeColor(itemId, color.hex)).getResponseData();
            if (response.success) {
                setColors(prevColors => ({
                    ...prevColors,
                    [itemId]: color.hex
                }));
                setVisiblePickerId(null);
                toast.success('Color actualizado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (e) {
            toast.error('Error al actualizar el color');
        }
    };

    const toggleColorPicker = (itemId: any) => {
        setVisiblePickerId(prevId => (prevId === itemId ? null : itemId));
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new IncomeService()).deleteIncomeType(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Tipo de ingreso eliminado correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar tipo de ingreso");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            refetch();
            setTimeout(() => {
                setSelectedItem(null);
                setShow(false);
                setModalOpen(false);
                toast.success(message);
            }, 100);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    /**
     * Build tree structure for incomes types list
     */
    const buildTree = (incomeTypes: any, parentId: any) => {
        let level = [];
        for (let i = 0; i < incomeTypes.length; i++) {
            if (incomeTypes[i].parent?.id === parentId) {
                let element = incomeTypes[i];
                let subchildren = buildTree(incomeTypes, incomeTypes[i].id);
                element.children = subchildren;
                level.push(element);
            }
        }
        return level;
    };

    /**
     * FILTER TREE ARRAY BY SEARCH_TEXT
     * @param incomeTypes TREE array of incometypes
     * @param searchName text to search
     * @returns filtered incometypes array
     */
    const filterByNameAndDescription = (incomeTypes: any, searchText: string) => {
        return incomeTypes.reduce((filtered: any[], item: { name: string; description: string, children: string | any[]; }) => {
            const searchLower = searchText.toLowerCase();
            const itemMatches = item.name.toLowerCase().includes(searchLower) || (item.description && item.description.toLowerCase().includes(searchLower));
            // Check if the item name or description matches the search criteria
            if (itemMatches) {
                // If it matches, add the entire item to the filtered results
                filtered.push(item);
            } else if (item.children && item.children.length > 0) {
                // Otherwise, check its children
                const filteredChildren = filterByNameAndDescription(item.children, searchText);
                if (filteredChildren.length > 0) {
                    // If any children match, add the item with only those children
                    filtered.push({ ...item, children: filteredChildren });
                }
            }
            return filtered;
        }, []);
    };

    /**
     * FILTER TREE ARRAY BY PARENT ID
     * @param incomeTypes TREE array of incometypes
     * @param parentId parent id to filter
     * @returns filtered incometypes array
     */
    const filterByParentId = (incomeTypes: any, parentId: string) => {
        return incomeTypes.reduce((filtered: any[], item: { parent: { id: string; }; children: string | any[]; }) => {
            // Check if the item has the specified parentId
            if (item.parent && item.parent.id === parentId) {
                // If it matches, add the entire item to the filtered results
                filtered.push(item);
            } else if (item.children && item.children.length > 0) {
                // Otherwise, check its children
                const filteredChildren = filterByParentId(item.children, parentId);
                if (filteredChildren.length > 0) {
                    // If any children match, add the item with only those children
                    filtered.push({ ...item, children: filteredChildren });
                }
            }
            return filtered;
        }, []);
    };

    /**
     * FILTER TREE ARRAY BY PARENTS EXISTENCE
     * @param incomeTypes TREE array of incometypes
     * @param parentsId parents id to filter
     * @returns filtered incometypes array
     */
    const filterByParentExistence = (incomeTypes: any, hasParent: boolean) => {
        return incomeTypes.reduce((filtered: any[], item: { parent: null; children: string | any[]; }) => {
            // Check if the item matches the parent existence criteria
            if ((hasParent && item.parent !== null) || (!hasParent && item.parent === null)) {
                // If it matches, add the entire item to the filtered results
                filtered.push(item);
            } else if (item.children && item.children.length > 0) {
                // Otherwise, check its children
                const filteredChildren = filterByParentExistence(item.children, hasParent);
                if (filteredChildren.length > 0) {
                    // If any children match, add the item with only those children
                    filtered.push({ ...item, children: filteredChildren });
                }
            }
            return filtered;
        }, []);
    };

    /**
     * Update incomes tree when incomes types change
     */
    useEffect(() => {
        let tree = [];
        if (data && data.incomeTypes && data.incomeTypes.length > 0) {
            tree = buildTree(data.incomeTypes, undefined);
            setIncomeTypesTree(tree);

            // FILTER TREE ARRAY BY SEARCH_TEXT
            if (filters.filter_filters?.search_text) {
                tree = filterByNameAndDescription(tree, filters.filter_filters.search_text);
                setIncomeTypesTree(tree);
            }
            // FILTER TREE BY PARENT ID
            if (filters.filter_filters?.parent) {
                tree = filterByParentId(tree, filters.filter_filters.parent);
                setIncomeTypesTree(tree);
            }
            // FILTER TREE BY PARENTS EXISTENCE
            if (filters.filter_filters?.parents !== undefined) {
                tree = filterByParentExistence(tree, !filters.filter_filters.parents);
                setIncomeTypesTree(tree);
            }
        }
        if (data && data.incomeTypes) setIsLoading(false);

    }, [data, filters]);

    /**
     * Update colors state
     */
    useEffect(() => {
        if (data && data.costsTypes) {
            const colors = data.costsTypes.reduce((acc: any, item: any) => {
                acc[item.id] = item.color;
                return acc;
            }, {});
            setColors(colors);
        }
    }, [data]);

    const getContent = () => {
        if (error) return <ErrorMessage error={error} />;
        if (isLoading) return <Spinner isSmall />;

        if (data) {
            const ids = data.incomeTypes.map((item: IncomeType) => item.id);

            return (
                <CustomTable
                    title="Tipo de Ingresos"
                    data={incomeTypesTree ? incomeTypesTree : null}
                    expandedRowIds={ids}
                    pagination={false}
                    treeSource={true}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            className: "text-start",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-start align-content-center"
                                        style={{ marginLeft: `${element.level * 20}px` }}
                                        onClick={() => {
                                            setSelectedItem(element);
                                            setModalOpen(true);
                                            setShow(true);
                                        }}>
                                        {element.level > 0 ? '↳' : ''}{element.name}

                                    </div>
                                )
                            },

                        },
                        {
                            name: "Color",
                            keyValue: "color",
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <div
                                            onClick={() => toggleColorPicker(item.id)}
                                            style={{
                                                backgroundColor: colors[item.id],
                                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                                cursor: "pointer",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                            className="rounded-circle mr-3 p-3"
                                        >
                                            <div
                                                className="rounded-circle"
                                                style={{ backgroundColor: colors[item.id], width: "5px", height: "5px" }}
                                            />
                                        </div>
                                        {visiblePickerId === item.id && (
                                            <SketchPicker
                                                color={colors[item.id]}
                                                onChange={(color) => handleColorChange(item.id, color)}
                                            />
                                        )}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <div className="text-start">
                                            {element.description || "-"}
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            className: (userCan("list", "companies") ? "text-center" : "d-none"),
                            isVisible: (userCan("list", "companies") ? true : false),
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.company.name || "-"}
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Editar",
                                buttonType: 'icon',
                                icon: "Edit",
                                additionalClasses: `text-primary ${userCan("admin_income", "income") ? "" : "d-none"}`,
                                description: "Editar tipo de ingreso",
                                callback: (item: any) => {
                                    if (!userCan("admin_income", "income")) return;
                                    setSelectedItem(item);
                                    setModalOpen(true);
                                    setShow(false);
                                },
                            },
                            {
                                title: "Eliminar",
                                buttonType: 'icon',
                                icon: "Delete",
                                additionalClasses: `text-danger ${userCan("admin_income", "income") ? "" : "d-none"}`,
                                description: "Eliminar Tipo de Ingreso",
                                callback: (item: any) => {
                                    if (!userCan("admin_income", "income")) return;
                                    handleConfirmationAlert({
                                        title: "Eliminar Tipo de Ingreso",
                                        text: "¿Está seguro que desea eliminar el tipo de ingreso?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        }
                                    })
                                },
                            },
                            {
                                title: "Sin permisos",
                                buttonType: 'icon',
                                icon: "Block",
                                additionalClasses: `text-danger ${(userCan('admin_income', 'income')) ? 'd-none' : ''}`,
                                description: "No tiene permisos para editar ni eliminar",
                                callback: (item: any) => { },
                            }
                        ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Tipos de Ingresos</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="primary"
                        title="Crear Tipo de Ingreso"
                        icon="Add"
                        isLight
                        className={userCan("admin_income", "income") ? "" : "d-none"}
                        onClick={() => {
                            if (!userCan("admin_income", "income")) return;
                            setModalOpen(true);
                            setSelectedItem(null);
                            setShow(false);
                        }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <IncomeTypeFilters filters={filters} updateFilters={updateFilters} updatePageSize={updatePageSize} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card>
                    <CardBody className="table-responsive">
                        {isLoading ? (
                            <div className="w-100 text-center"><Spinner /></div>
                        ) : (
                            <Fragment>{getContent()}</Fragment>
                        )}
                    </CardBody>
                </Card>
            </Page>
            {modalOpen && (<CreateModalIncomeType isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />)}
        </Fragment>
    );
};

export default IncomeTypeList;