import React, { useCallback, useContext, useEffect, useState } from "react";
import useFilters, { FilterOptions, FilterOrders } from "../../../hooks/useFilters";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useFetch from "../../../hooks/useFetch";
import { IncomeService } from "../../../services/incomes/incomeService";
import { IncomesApiResponse } from "../../../type/income-type";
import { changeStorageViewMode } from "../../../redux/schedulerSlice";
import { CostService } from "../../../services/costs/costService";
import { UserService } from "../../../services/users/userService";
import moment from "moment";

type CashFlowProviderProps = {
    children: React.ReactNode,
    defaultFilters?: any,
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
}

type CashFlowContextData = {
    viewMode: "Mes" | "Semana" | "Día",
    changeViewMode: (view: "Mes" | "Semana" | "Día") => void,
    filters: FilterOptions | any,
    updateFilters: (filters: any) => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    isLoading: boolean,
    setIsLoading: (loading: boolean) => void,
    fromDate: string,
    setFromDate: (date: string) => void,
    toDate: string,
    setToDate: (date: string) => void,
    incomesList: any,
    refetchIncomes: () => void,
    costsList: any,
    costsTypesList: any,
    refetchCosts: () => void,
    initialBalance: any,
    incomesFromInitialBalanceDate: any,
    costsFromInitialBalanceDate: any,
}

const CashFlowContext: React.Context<CashFlowContextData> = React.createContext<CashFlowContextData>({
    viewMode: 'Día',
    changeViewMode: (view: "Mes" | "Semana" | "Día") => { },
    filters: {} as FilterOptions | any,
    updateFilters: (filters: any) => { },
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    isLoading: false,
    setIsLoading: (loading: boolean) => { },
    fromDate: '',
    setFromDate: (date: string) => { },
    toDate: '',
    setToDate: (date: string) => { },
    incomesList: null,
    refetchIncomes: () => { },
    costsList: null,
    costsTypesList: null,
    refetchCosts: () => { },
    initialBalance: null,
    incomesFromInitialBalanceDate: null,
    costsFromInitialBalanceDate: null,
});

const CashFlowProvider: React.FC<CashFlowProviderProps> = ({ children, defaultFilters, defaultOrders = [] as FilterOrders, defaultPage = 1, defaultPageSize = 999999 }) => {

    //  Redux connection
    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.scheduler.viewMode);
    const flowFilters = useSelector((state: RootState) => state.scheduler.flowFilters);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>(flowFilters?.estimated_entry_date?.from || moment().subtract(2, 'months').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<string>(flowFilters?.estimated_entry_date?.to || moment().add(2, 'months').format('YYYY-MM-DD'));
    const [costsList, setCostsList] = useState<any>(null);
    const [incomesFromInitialBalanceDate, setIncomesFromInitialBalanceDate] = useState<any>(null);
    const [costsFromInitialBalanceDate, setCostsFromInitialBalanceDate] = useState<any>(null);

    const [incomesList, loadingIncomes, incomesError, refetchIncomes] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) return null;
        setIsLoading(true);
        const response = await (new IncomeService).getIncomes(filters);
        setIsLoading(false);
        return response.getResponseData() as IncomesApiResponse;
    }, [filters]));

    const [regularCostsList, loadingCosts, costsError, refetchCosts] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) return null;
        setIsLoading(true);
        const response = await (new CostService).getCosts(filters);
        setIsLoading(false);
        return response.getResponseData() as any;
    }, [filters]));

    const [costsTypesList] = useFetch(useCallback(async () => {
        setIsLoading(true);
        const response = await (new CostService).getCostTypes(filters);
        setIsLoading(false);
        return response.getResponseData() as any;
    }, [filters]));

    const [initialBalance] = useFetch(useCallback(async () => {
        const response = await (new UserService).getInitialBankBalance();
        return response.getResponseData() as any;
    }, []));

    /**
     * Fetch incomes from the initial balance date to the start date of the filters. This is used to properly calculate the daily balance
     */
    const fetchIncomesFromInitialBalanceDate = async (filters: any, initialBalance: any) => {
        if (!initialBalance?.initialBankBalanceDate?.date || !filters.filter_filters?.estimated_entry_date?.to) return null;
        setIsLoading(true);
        const response = await (new IncomeService).getIncomes({
            filter_filters: {
                estimated_entry_date: {
                    from: initialBalance.initialBankBalanceDate.date.split(' ')[0],
                    to: filters.filter_filters?.estimated_entry_date.to.split(' ')[0],
                }
            }
        });
        const filteredIncomesBeforeStartDate = response.getResponseData().data.income.filter((income: any) => {
            if (income.effectiveEntryDate?.date) {
                const incomeDate = new Date(income.effectiveEntryDate.date);
                return incomeDate < new Date(filters.filter_filters?.estimated_entry_date.from);
            } else return false;
        });
        setIsLoading(false);
        setIncomesFromInitialBalanceDate(filteredIncomesBeforeStartDate);
    };

    /**
     * Fetch costs from the initial balance date to the start date of the filters. This is used to properly calculate the daily balance
     */
    const fetchCostsFromInitialBalanceDate = async (filters: any, initialBalance: any) => {
        if (!initialBalance?.initialBankBalanceDate?.date || !filters.filter_filters?.estimated_entry_date?.to) return null;
        setIsLoading(true);
        const response = await (new CostService).getCosts({
            filter_filters: {
                estimated_entry_date: {
                    from: initialBalance.initialBankBalanceDate.date.split(' ')[0],
                    to: filters.filter_filters?.estimated_entry_date.to.split(' ')[0],
                }
            }
        });
        const filteredCostsBeforeStartDate = response.getResponseData().data.costs.filter((cost: any) => {
            if (cost.effectiveEntryDate?.date) {
                const costDate = new Date(cost.effectiveEntryDate.date);
                return costDate < new Date(filters.filter_filters?.estimated_entry_date.from);
            } else return false;
        });
        setIsLoading(false);
        setCostsFromInitialBalanceDate(filteredCostsBeforeStartDate);
    };

    useEffect(() => {
        fetchIncomesFromInitialBalanceDate(filters, initialBalance);
        fetchCostsFromInitialBalanceDate(filters, initialBalance);
    }, [filters, initialBalance]);

    const changeViewMode = (view: "Mes" | "Semana" | "Día") => {
        dispatch(changeStorageViewMode(view));
    };

    /**
     * Update the view mode filter when the view mode from the redux store changes
     */
    useEffect(() => {
        if (viewMode) {
            switch (viewMode) {
                case 'Mes':
                    updateFilters({ range_mode_view: 'month' });
                    break;
                case 'Semana':
                    updateFilters({ range_mode_view: 'week' });
                    break;
                case 'Día':
                    updateFilters({ range_mode_view: 'day' });
                    break;
                default:
                    break;
            }
        }
    }, [viewMode]);

    /**
     * Update costs list when filters change
     */
    useEffect(() => {
        if (regularCostsList && regularCostsList.costs) {
            setCostsList({ costs: regularCostsList.costs });
        }
    }, [regularCostsList]);

    useEffect(() => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) {
            updateFilters({ estimated_entry_date: { from: fromDate, to: toDate } });
        }
    }, [flowFilters]);

    return (
        <CashFlowContext.Provider value={{
            viewMode,
            changeViewMode,
            filters,
            updateFilters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,
            isLoading,
            setIsLoading,
            fromDate,
            setFromDate,
            toDate,
            setToDate,
            incomesList,
            refetchIncomes,
            costsList,
            costsTypesList,
            refetchCosts,
            initialBalance,
            incomesFromInitialBalanceDate,
            costsFromInitialBalanceDate,
        }}>
            {children}
        </CashFlowContext.Provider>
    );
}

export { CashFlowProvider, CashFlowContext };

export function useCashFlow() {
    return useContext(CashFlowContext);
}