import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const CLIENTS_ENDPOINT = '/clients';

export class ClientService extends RestServiceConnection {


    getClients = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/list',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getContactPersons = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/list-contact-person',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getCountContact = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/count-clients',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getClientById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/get',
            data: {
                client: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createClient = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/create',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    editClient = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/edit',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    deleteClient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/delete',
            data: {
                client: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    toggleClientStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/toggle',
            data: {
                client: id,
                status: status
            }
        }, true) as AxiosResponse;
        return this;
    }

    toggleClientClasificationStatus = async (id: string, status: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/set-classification',
            data: {
                client: id,
                classification: status
            }
        }, true) as AxiosResponse;
        return this;
    }

    toggleCategoryStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/toggle-category',
            data: {
                category: id,
                status: status
            }
        }, true) as AxiosResponse;
        return this;
    }

    editClientImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('client', id);
        formData.append('profile_img', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteClientImg = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/remove-image',
            data: {
                client: id
            }
        }, true);
        return this;
    }

    editNote = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/edit-note',
            data: {
                client: data.client,
                note: data.note
            }
        }, true) as AxiosResponse;
        return this;
    }

    listProvinces = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/list-provinces',
        }, true) as AxiosResponse;
        return this;
    }

}