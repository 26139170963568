import { Fragment } from "react"
import { ToastContainer } from "react-toastify"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import ClientListConsolidated from "./clients-list/ClientListConsolidated"

const ClientsListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider defaultFilterFilters={{ classification: "Consolidado" }}>
                <ToastContainer />
                <ClientListConsolidated />
            </FiltersProvider>
        </Fragment>
    )
}

export default ClientsListWrapper;