import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../../services/users/userService";
import UserForm from "../UserForm";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateUser: FC = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		const formData = new FormData();

		for (let value in values) {
			if (values[value]) {
				formData.append(value, values[value]);
			}
		}

		if (formData.get('dni') === null) {
			formData.append('dni', '');
		}

		try {
			const response = await (await (new UserService()).createUser(formData)).getResponseData();

			if (response.success) {
				navigate(-1);
				toast.success('Usuario creado correctamente');
			} else {
				response.data.errors.forEach((error: any) => {
					toast.error(error.message);
				});
			}
		} catch (error: any) {
			toast.error(error.message || 'Error al crear el usuario');
		} finally {
			setLoading(false);
		}
	}

	return <UserForm submit={handleCreation} isLoading={loading} />;
}

export default CreateUser;