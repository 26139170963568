import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClientForm from "../ClientsForm";
import { ClientService } from "../../../services/clients/clientService";
import Spinner from "../../../components/bootstrap/Spinner";

interface Props {
	isClient?: boolean;
}

const EditClient: FC<Props> = ({ isClient }) => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [client, setClient] = useState<any>(null);
	const { id = '' } = useParams<{ id: string }>();

	const getClient = async () => {
		const clientService = new ClientService;
		const response = (await clientService.getClientById(id)).getResponseData()
		if (response.success) {
			setClient(response.data)
		}
	}

	useEffect(() => {
		getClient()
	}, [])

	const handleEdit = async (values: any) => {
		setLoading(true);

		try {
			const clientData = {
				client: {
					client: values.id,
					name: values.name == "" ? null : values.name,
					category: values.clientCategory == "" ? null : values.clientCategory,
					origin: values.clientOrigin == "" ? null : values.clientOrigin,
					first_name: values.firstName == "" ? null : values.firstName,
					last_name: values.lastName == "" ? null : values.lastName,
					address: values.address == "" ? null : values.address,
					cifDni: values.cifNif == "" ? null : values.cifNif,
					population: values.population == "" ? null : values.population,
					province: values.province == "" ? null : values.province,
					pc: values.pc == "" ? null : values.pc,
					user: values.user == "" ? null : values.user,
					classification: values.classification,
					note: values.note == "" ? null : values.note,
					email: values.email == "" ? null : values.email,
				},
				contactPerson: {
					contactPerson: values.clientContactPerson,
					name: values.cpname,
					first_name: values.cpfirst_name == "" ? null : values.cpfirst_name,
					last_name: values.cplast_name == "" ? null : values.cplast_name,
					nif: values.cpnif == "" ? null : values.cpnif,
					telephone: values.telephone,
					telephoneAux: values.telephoneAux == "" ? null : values.telephoneAux,
					email: values.cpemail == "" ? null : values.cpemail,
					position: values.cpposition == "" ? null : values.cpposition,
				}
			}
			const response = await (await (new ClientService()).editClient(clientData)).getResponseData();
			if (response.success) {
				isClient ? navigate('/clientes', { replace: true }) :
					navigate('/contactos', { replace: true });
				setTimeout(() => {
					toast.success('Cliente editado correctamente');
				}, 100);
			} else if (response.data.errors) {
				response.data.errors.forEach((error: any) => {
					toast.error(error.message);
				});
			} else {
				throw new Error(response.message);
			}
		} catch (error: any) {
			toast.error(error.message || 'Error al editar el cliente');
			setError(error.message);
			setTimeout(() => {
				setError(error.message);
			}, 5000);
		} finally {
			setLoading(false);
		}
	}

	return (
		(client != null)
			? isClient
				? <ClientForm submit={handleEdit} isLoading={loading} clientData={client} isClient profileImg={client.profileImg} />
				: <ClientForm submit={handleEdit} isLoading={loading} clientData={client} profileImg={client.profileImg} />
			: <Spinner />
	)
}

export default EditClient;