import { FC, ReactNode, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
import Button from '../../components/bootstrap/Button';
import useAsideTouch from '../../hooks/useAsideTouch';

interface ISubHeaderLeftProps {
	children: ReactNode;
	className?: string;
}
export const SubHeaderLeft: FC<ISubHeaderLeftProps> = ({ children, className = undefined }) => {
	return <div className={classNames('subheader-left', 'col-6', className)}>{children}</div>;
};
SubHeaderLeft.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

interface ISubHeaderRightProps {
	children: ReactNode;
	className?: string;
}
export const SubHeaderRight: FC<ISubHeaderRightProps> = ({ children, className = undefined }) => {
	return (
		<div className={classNames('subheader-right', 'col-6 d-flex justify-content-end', className)}>{children}</div>
	);
};
SubHeaderRight.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

interface ISubheaderSeparatorProps {
	className?: string;
}
export const SubheaderSeparator: FC<ISubheaderSeparatorProps> = ({ className = undefined }) => {
	return <div className={classNames('subheader-separator', className)} />;
};
SubheaderSeparator.propTypes = {
	className: PropTypes.string,
};

export interface ISubHeaderProps {
	children: ReactNode;
	className?: string;
}
const SubHeader: FC<ISubHeaderProps> = ({ children, className = undefined }) => {

	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const { mobileDesign } = useAsideTouch();

	const root = document.documentElement;
	root.style.setProperty('--subheader-height', `${height}px`);

	useLayoutEffect(() => {
		document.body.classList.add('subheader-enabled');
		return () => {
			document.body.classList.remove('subheader-enabled');
		};
	});

	return (
		<div ref={ref} className={classNames('subheader', 'row', className)}>
			<Button
				className={classNames('btn-subheader-toggle bg-light mt-2', { 'd-none': !mobileDesign })}
				onClick={() => { document.getElementById('main-aside')?.classList.toggle('open'); }}
				icon={'Menu'}
			/>
			{children}
		</div>
	);
};
SubHeader.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export default SubHeader;