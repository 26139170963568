import React, { FC, Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider'
import { CompanyService } from '../../../../services/companies/organizationService'
import { ClientService } from '../../../../services/clients/clientService'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import SearchableSelect from '../../../../components/selector/SearchableSelect'
import useFetch from '../../../../hooks/useFetch'
import { StatusService } from "../../../../services/status/statusService";
import { StatusApiResponse } from '../../../../type/status-type'
import Input from '../../../../components/bootstrap/forms/Input'
import CustomSearchSelect from '../../../../components/selector/CustomSearchSelect'

interface IBudgetsFiltersProps {
    updateFilters: (filters: any) => void;
    resetFilters: any;
    filters: any;
}

const BudgetFilters: FC<IBudgetsFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const [filterMenu, setFilterMenu] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState<any>(filters.filter_filters.company || '');
    const [contactPerson, setContactPerson] = useState<any>(filters.filter_filters.contact || '');
    const [clientSearch, setClientSearch] = useState<string>('');
    const [contactPersonSearch, setContactPersonSearch] = useState<string>('');
    const [clientOptions, setClientOptions] = useState<any>([]);
    const [contactOptions, setContactOptions] = useState<any>([]);

    const timeoutRef = useRef<any>(null);

    // Buscar clientes
    useEffect(() => {
        if (clientSearch !== '' && clientSearch.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getClientsOptions();
            }, 500);
            return () => { clearTimeout(timeoutRef.current) }
        }
    }, [clientSearch]);

    // Buscar contactos
    useEffect(() => {
        if (contactPersonSearch !== '' && contactPersonSearch.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getContactsOptions();
            }, 500);
            return () => { clearTimeout(timeoutRef.current) }
        }
    }, [contactPersonSearch]);

    // Rellenar los campos de tipo select con los datos de la API
    const getClientsOptions = async (id?: string) => {
        if (id !== undefined) { updateFilters({ client: id }) };
        const response = (await ((new ClientService).getClients({ ...filters, limit: 999999999 }))).response;

        if (response?.status === 200) {
            const options = response?.data?.data?.clients?.map((item: { id: number; name: string, firstName: string, lastName: string, clientContactPerson: any }) => (
                {
                    value: item.id,
                    label: item.name
                        + (item.firstName ? ' ' + item.firstName : '') + (item.lastName ? ' ' + item.lastName : '')
                        + (item.clientContactPerson ? ' (' + item.clientContactPerson.name + (item.clientContactPerson?.firstName ? (' ' + item.clientContactPerson.firstName) : '') + (item.clientContactPerson?.lastName ? (' ' + item.clientContactPerson.lastName) : '') + ')' : ''),
                }
            ));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setClientOptions(options);
        }
    };

    // Rellenar los campos de tipo select con los datos de la API
    const getContactsOptions = async (id?: string) => {
        if (id !== undefined) { updateFilters({ contact: id }) };
        const response = (await ((new ClientService).getContactPersons({ ...filters, limit: 999999999 }))).response;

        if (response?.status === 200) {
            const options = response?.data?.data?.contactPersons?.map((item: { id: number; name: string, firstName: string, lastName: string, clientContactPerson: any }) => (
                {
                    value: item.id,
                    label: item.name + (item.firstName ? ' ' + item.firstName : '') + (item.lastName ? ' ' + item.lastName : ''),
                }
            ));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setContactOptions(options);
        }
    }

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    };

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const [statuses] = useFetch(useCallback(async () => {
        const response = await (new StatusService).listStatuses({ filter_filters: { oriented_to: 'budget' } });
        return response.getResponseData() as StatusApiResponse;
    }, []));

    const getStatusById = (id: string) => {
        const status = statuses?.statuses?.find((status: any) => status.id === id);
        if (status) {
            return {
                value: status.id,
                label: status.name,
            }
        }
        return null;
    };

    useEffect(() => {
        if (isSuperAdmin) {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                }}
                placeholder='Buscar...'
            />

            <Button color='primary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='budgetFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'>Filtros de Presupuesto</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userCan('list', 'companies') && (
                            <FormGroup id='filter0' label='Organización' className='col-md-12'>
                                <Select
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ company: e.target.value })
                                        setSelectedCompany(e.target.value)
                                    }}
                                    value={selectedCompany}
                                    id='company-select'
                                    ariaLabel='Company selector'
                                    placeholder='Organización...'
                                />
                            </FormGroup>
                        )}

                        <FormGroup id='filter1' label='Cliente:' className='col-md-12'>
                            <CustomSearchSelect
                                id='client-select'
                                options={clientOptions}
                                onInputChange={(e: any) => { setClientSearch(e) }}
                                onChangeSingle={(e: any) => updateFilters({ client: e.value })}
                                defaultValue={null}
                            />
                        </FormGroup>

                        {/* <FormGroup id='filter2' label='Contacto:' className='col-md-12'>
                            <CustomSearchSelect
                                id='contact-select'
                                options={contactOptions}
                                onInputChange={(e: any) => { setContactPersonSearch(e) }}
                                onChangeSingle={(e: any) => updateFilters({ contact: e.value })}
                                defaultValue={null}
                            />
                        </FormGroup> */}
                        <FormGroup id='filter2' label='Persona de contacto:' className='col-md-12'>
                            <CustomSearchInput
                                onSearch={(search: string) => {
                                    updateFilters({ contact: search });
                                }}
                                onChange={setContactPerson}
                                value={contactPerson}
                                placeholder='Buscar ...'
                            />
                        </FormGroup>

                        <FormGroup id='filter3' label='Estado del presupuesto:' className='col-md-12'>
                            <SearchableSelect
                                name='status-select'
                                isMulti
                                isClearable
                                options={
                                    statuses?.statuses?.map((status: any) => {
                                        return {
                                            value: status.id,
                                            label: status.name,
                                        }
                                    })
                                }
                                onChange={(e: any) => {
                                    updateFilters({ statuses: e.map((status: any) => status.value) })
                                }}
                                defaultValue={getStatusById(filters.filter_filters.statuses)}
                                placeholder='estado'
                            />
                        </FormGroup>

                        <FormGroup id='filter4' label='Precio total:' className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Mínimo: </p>
                                        </div>
                                        <div className='col-md-8 p-0'>
                                            <Input
                                                name='minAmount'
                                                type='number'
                                                value={filters.filter_filters.minAmount}
                                                onChange={(e: any) => { updateFilters({ minAmount: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Máximo: </p>
                                        </div>
                                        <div className='col-md-8 p-0'>
                                            <Input
                                                name='maxAmount'
                                                type='number'
                                                value={filters.filter_filters.maxAmount}
                                                onChange={(e: any) => { updateFilters({ maxAmount: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>

                        <FormGroup id='filter5' label='Dirección:' className='col-md-12'>
                            <Input
                                name='address'
                                value={filters.filter_filters.address}
                                onChange={(e: any) => { updateFilters({ address: e.target.value }) }}
                            />
                        </FormGroup>

                        <div className='col-12 text-center'>
                            <Button
                                className='mt-4'
                                color="secondary"
                                onClick={() => {
                                    resetFilters()
                                    setSelectedCompany('')
                                }}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default BudgetFilters;