import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CompanyService } from "../../../services/companies/organizationService";
import CompanyForm from "../CompanyForm";

const CreateCompany: FC = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		const formData = new FormData();

		for (let value in values) {
			if (values[value]) {
				formData.append(value, values[value]);
			}
		}

		try {
			const response = await (await (new CompanyService()).createCompany(formData)).getResponseData();
			if (response.success) {
				setLoading(false);
				navigate(-1);
				setTimeout(() => {
					toast.success('Organización creada');
				}, 100)
			} else {
				response.data.errors.forEach((error: any) => {
					toast.error(error.message);
				});
			}
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	return <CompanyForm submit={handleCreation} isLoading={loading} />;
}

export default CreateCompany;