import React from 'react';
import { DirectoryNavigatorBreadcrumb } from './DirectoryNavigatorBreadcrumb';
import { DirectoryNavigatorList } from './DirectoryNavigatorList';
import { DirectoryNavigatorGroups } from './DirectoryNavigatorGroups';
import { DirectoryNavigatorDropzone } from './DirectoryNavigatorDropzone';

interface DirectoryNavigatorProps { }

const DirectoryNavigator: React.FC<DirectoryNavigatorProps> = () => {

    return (
        <div className='d-flex flex-column justify-content-between h-100'>
            <div>
                <DirectoryNavigatorBreadcrumb />
                <DirectoryNavigatorGroups />
                <DirectoryNavigatorList />
            </div>

            <DirectoryNavigatorDropzone />
        </div>
    );
};

export default DirectoryNavigator;