import { FC, useEffect, useState } from "react";
import Button from "../bootstrap/Button";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../bootstrap/Dropdown";
import Spinner from "../bootstrap/Spinner";
import useHandleErrors from "../../hooks/useHandleErrors";
import { Status } from "../../type/status-type";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import { ClientService } from "../../services/clients/clientService";
import './dropdownStyles.css';

interface ClassificationsDropdownProps {
    itemId: string;
    status: any;
    disabled?: boolean;
    statuses?: any[];
}

const ClassificationsDropdown: FC<ClassificationsDropdownProps> = ({ itemId, status, disabled = false, statuses }) => {

    const { handleErrors } = useHandleErrors();

    const [currentStatus, setCurrentStatus] = useState<string>(status);
    const [color, setColor] = useState<string>(status.color);
    const [statusList, setStatusList] = useState<Status[]>(statuses ? statuses : []);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Modal de confirmación para cambiar el estado
    const _toggleState = (newStatus: string) => {
        handleConfirmationAlert({
            title: "¿Estás seguro de que deseas cambiar la clasificación?",
            icon: "warning",
            onConfirm: () => {
                setIsLoading(true);
                setTimeout(() => {
                    _toggleStatus(newStatus);
                }, 200);
            },
        });
    };

    // Cambiar el estado
    const _toggleStatus = async (status: string) => {
        try {
            let response = await (await new ClientService().toggleClientClasificationStatus(itemId, status)).getResponseData();

            if (response && response.success) {
                setCurrentStatus(response.data);
                let st = statusList.find(st => st.id === status);
                if (st) { setColor(st.color ? st.color : 'whitesmoke') }
                toast.success("Se ha cambiado la clasificación del contacto");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al cambiar la clasificación del cliente');
        } finally {
            setIsLoading(false);
        }
    };

    // Actualizar la lista de estados
    useEffect(() => {
        setStatusList(statuses ? statuses : []);
    }, [statuses]);

    // Actualiza el color del estado
    useEffect(() => {
        if (status && statusList) {
            let st = statusList.find(st => st.id === status);
            if (st) {
                setColor(st.color ? st.color : 'whitesmoke');
            }
        }
    }, [status, statusList]);

    // Lista de estados para el dropdown
    const loadStatusesList = (st: Status) => {
        return (
            <DropdownItem key={itemId + "-" + st.id + "-dropdownItem"} className='mt-2' >
                <Button
                    className="text-nowrap fs-8  fw-semibold text-center"
                    style={{ backgroundColor: st.color ? st.color : 'white', color: "white" }}
                    isLink
                    onClick={() => _toggleState(st.id)}
                >
                    <span className="text-center w-100 fs-7 " style={{ color: 'white' }}>
                        {st.name}
                    </span>
                </Button>
            </DropdownItem>
        )
    };

    return (
        <>
            <Dropdown className={'status-dropdown'} key={itemId + "-dropdownItem"}>
                <DropdownToggle hasIcon={false}>
                    <Button isLink isDisable={disabled} className='text-nowrap fs-8  p-2' style={{ backgroundColor: color }}>
                        {
                            isLoading
                                ? <Spinner isSmall color='light' />
                                : <span className='text-white fw-bold fs-7 p-2 ' style={{ color: '#3e3e3e' }}>{currentStatus}</span>
                        }
                    </Button>
                </DropdownToggle>

                <DropdownMenu className="max-h-250 overflow-auto">
                    <>
                        {
                            statusList?.filter((st: Status) => st.id !== currentStatus)?.map((st: Status) => {
                                return loadStatusesList(st)
                            })
                        }
                    </>
                </DropdownMenu>
            </Dropdown>
        </>
    )
}

export default ClassificationsDropdown;