import { useFormik } from "formik";
import { ChangeEvent, FC, useEffect, useState } from "react";
import InputPhone, { isValidPhoneNumber } from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Input from "../../../components/bootstrap/forms/Input";
import * as yup from 'yup';
import { validateNif } from "../../../utils/ValidationCifNif";
import Select from "../../../components/bootstrap/forms/Select";
import { ContactService } from "../../../services/clients/contactService";

interface CreateStrategyModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    onClose: (values?: any) => void;
}

export interface ContactForm {
    contactId?: string;
    telephone: string;
    name: string;
    first_name: string
    last_name: string;
    nif: string;
    email: string;
    position: string;
}

const schema = yup.object({
    telephone: yup.string().required('El telefono es obligatorio').test("valid-phone", "El teléfono no es válido", (value) =>
        isValidPhoneNumber(value)),
    name: yup.string().required('El nombre es obligatorio'),
    first_name: yup.string().required('El apellido es obligatorio'),
    last_name: yup.string().required('El apellido es obligatorio'),
    nif: yup.string().required('El nif es obligatorio').test('valid-nif', 'El NIF no es válido', (value) =>
        validateNif(value)
    ),
    email: yup.string().required('El email es obligatorio'),
    position: yup.string().required('Dato requerido')
});

const ContactDataModal: FC<CreateStrategyModalProps> = ({ isOpen, setIsOpen, onClose }) => {

    const [modal, setModal] = useState(false);

    const [contacts, setContacts] = useState([]);

    const initialValues: ContactForm = {
        contactId: '',
        telephone: '',
        name: '',
        first_name: '',
        last_name: '',
        nif: '',
        email: '',
        position: '',
    };

    const fetchContactData = async () => {
        try {
            const contactService = new ContactService();
            const response = (await contactService.listContacts()).getResponseData();
            if (response.success) {
                setContacts(response.data['contact-persons']);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getList = () => {
        if (contacts) {
            return contacts.map((contact: any) => ({
                value: contact.id,
                label: `${contact.name} ${contact.first_name || ''} ${contact.last_name || ''}`
            }));
        }
        return [];
    }

    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const contact: any = contacts.find((contact: any) => contact.id === e.target.value);
        if (contact) {
            formik.setFieldValue('contactId', contact.id);
            formik.setFieldValue('telephone', contact.telephone);
            formik.setFieldValue('name', contact.name);
            formik.setFieldValue('first_name', contact.firstName);
            formik.setFieldValue('last_name', contact.lastName);
            formik.setFieldValue('nif', contact.nif);
            formik.setFieldValue('email', contact.email);
            formik.setFieldValue('position', contact.position);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: (values) => {
            setIsOpen(false);
            onClose(values);
        },
    });

    const verifyClass = (inputFieldID: keyof ContactForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof ContactForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    useEffect(() => {
        fetchContactData();
    }, []);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'xl' : 'sm'} titleId='Nueva estrategia'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>Cargar Datos de Contacto</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <FormGroup label='Contacto:' requiredInputLabel>
                                    <Select id='contact' required ariaLabel="Select Contact" placeholder="Seleccione Contacto..." onChange={(e: ChangeEvent<HTMLInputElement>) => { handleSelect(e) }} value={formik.values.contactId} list={getList()} />
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup label='Nombre:' requiredInputLabel>
                                    <Input id='name' required onChange={formik.handleChange} value={formik.values.name} className={verifyClass('name')} />
                                    {showErrors('name')}
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup label='Primer Apellido:' requiredInputLabel>
                                    <Input id='first_name' required onChange={formik.handleChange} value={formik.values.first_name} className={verifyClass('first_name')} />
                                    {showErrors('first_name')}
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup label='Segundo Apellido:' requiredInputLabel>
                                    <Input id='last_name' required onChange={formik.handleChange} value={formik.values.last_name} className={verifyClass('last_name')} />
                                    {showErrors('last_name')}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <FormGroup label='NIF:' requiredInputLabel>
                                    <Input id='nif' required onChange={formik.handleChange} value={formik.values.nif} className={verifyClass('nif')} />
                                    {showErrors('nif')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup label='Teléfono'>
                                    <InputPhone id='telephone' onBlur={formik.handleBlur} labels={es} international defaultCountry="ES" onChange={e => { formik.setFieldValue('telephone', e) }} value={formik.values.telephone || ''} className={verifyClass('telephone')} />
                                    {showErrors('telephone')}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <FormGroup label='Email:' requiredInputLabel>
                                    <Input id='email' type="email" required onChange={formik.handleChange} value={formik.values.email} className={verifyClass('email')} />
                                    {showErrors('email')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup label='Origen del Contacto:' requiredInputLabel>
                                    <Input id='position' required onChange={formik.handleChange} value={formik.values.position} className={verifyClass('position')} />
                                    {showErrors('position')}
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Download">
                            {"Seleccionar"}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (
                <div className="d-flex justify-content-center p-5">
                    <Spinner />
                </div>
            )}
        </Modal>
    )
}

export default ContactDataModal;