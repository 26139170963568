import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './redux/store';
import { Provider } from 'react-redux';
import { debounce } from './helpers/helpers';
import { saveState } from './redux/browser-storage';

const client = new QueryClient();

// here we subscribe to the store changes
store.subscribe(
	// we use debounce to save the state once each 800ms
	// for better performances in case multiple changes occur in a short time
	debounce(() => {
		saveState(store.getState());
	}, 800)
);

const children = (
	<ThemeContextProvider>
		<Provider store={store}>
			<QueryClientProvider client={client}>
				<Router>
					{/* <React.StrictMode> */}
					<App />
					{/* </React.StrictMode> */}
				</Router>
			</QueryClientProvider>
		</Provider>
	</ThemeContextProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();