import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { UserService } from "../../../services/users/userService";
import { loadUserFromLocalStorage } from "../../../utils/jwt";
import { CompanyService } from "../../../services/companies/organizationService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Select from "../../../components/bootstrap/forms/Select";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { CodeService } from "../../../services/codes/codeService";
import * as yup from 'yup';

interface CreateModalProps {
    data?: any;
    isOpen: boolean;
    show?: boolean;
    setIsOpen(...args: unknown[]): unknown;
    onClose: (type: number, message?: string, errors?: any) => void;
}

interface CodeForm {
    name: string;
    acronym: string;
    company: string;
}

const validationSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    acronym: yup.string().required('El acrónimo es obligatorio'),
    company: yup.string().required('La organización es obligatoria'),
});

const CreateModalCode: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');
    const codeService = new CodeService();

    const [modal, setModal] = useState(false);
    const [company, setCompany] = useState("");
    const [companyList, setCompanyList] = useState([]);

    const _handleCreateCode = async (values: any) => {
        try {
            const response = await (await codeService.createCode(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear el código');
        }
    }

    const _handleEditCode = async (values: any) => {
        try {
            const response = await (await codeService.editCode(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar el código');
        }
    }

    const getCompany = async () => {
        const userService = new UserService;
        const response = (await userService.getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData()
        if (response.success) {
            setCompany(response.data.userRoles[0].company.id)
        }
    }

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: data?.id || '',
            name: data?.name || '',
            acronym: data?.acronym || '',
            company: data?.company?.id || company,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.company != '') {
                if (data != null) {
                    _handleEditCode(values)
                } else {
                    _handleCreateCode(values);
                }
            }
        },
    });

    const verifyClass = (inputFieldID: keyof CodeForm) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : "";
    };

    const showErrors = (inputFieldID: keyof CodeForm) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    useEffect(() => {
        if (!isSuperAdmin) {
            getCompany();
        } else {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size='sm' titleId='Nuevo código'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_code'>{show ? 'Ver Código' : data ? 'Editar Código' : 'Crear Código'}</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            {isSuperAdmin && (
                                <FormGroup requiredInputLabel id='company' label='Organización' className='col-md-12'>
                                    <Select id="company" disabled={show ? true : false} list={getCompanyList()} onChange={formik.handleChange} value={formik.values.company || ''} ariaLabel='Select Companies' placeholder='Elegir Organización...' />
                                </FormGroup>
                            )}
                            <FormGroup requiredInputLabel id='name' label='Nombre del código' className="col-md-12">
                                <Input value={formik.values.name} disabled={show ? true : false} autoComplete="off" onChange={(e: any) => formik.setFieldValue('name', e.target.value)} className={verifyClass('name')} />
                                {showErrors('name')}
                            </FormGroup>
                            <FormGroup requiredInputLabel id='acronym' label='Acrónimo:' className="col-md-12">
                                <Input value={formik.values.acronym} disabled={show ? true : false} autoComplete="off" onChange={(e: any) => formik.setFieldValue('acronym', e.target.value)} className={verifyClass('acronym')} />
                                {showErrors('acronym')}
                            </FormGroup>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'}>
                            {data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (
                <div className="d-flex justify-content-center p-5">
                    <Spinner />
                </div>
            )}
        </Modal>
    )
}

export default CreateModalCode;