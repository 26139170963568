import { IEvent } from "../../type/calendar-type"
import Tooltips from "../bootstrap/Tooltips"
import { CardActions } from "../bootstrap/Card"
import Button from "../bootstrap/Button"
import moment from "moment"
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert"
import { useTasksCalendar } from "../../pages/calendar/providers/TasksCalendarProvider"
import AsyncImg from "../AsyncImg"

type CalendarEventWindowProps = {
    event: IEvent,
    onDelete?: (id?: string) => void
    onClose?: () => void
}

export const CalendarEventWindow: React.FC<CalendarEventWindowProps> = ({ event, onDelete, onClose }) => {

    const { selectTask, setIsTaskSelected } = useTasksCalendar();

    const _handleEdit = () => {
        selectTask({ ...event, user: event.user?.id });
        setIsTaskSelected(true);
        _closeEvent();
    };

    const _deleteEvent = () => {
        _closeEvent();
        handleConfirmationAlert({
            title: 'Eliminar tarea',
            text: '¿Está seguro que desea eliminar esta tarea?',
            icon: 'warning',
            onConfirm: () => {
                if (onDelete === undefined) return;
                onDelete(event.id);
            }
        })
    };

    const _closeEvent = () => {
        if (onClose === undefined) return;
        onClose();
    };

    return (
        <div className="mw-100">
            <div className="row mb-2">
                {event?.user && (
                    <Tooltips title={`Usuario: ${event.user.name} ${event.user.lastName}`}>
                        <div className='col-auto'>
                            <AsyncImg id={event.user.src} styles="rounded" width="40" height="40" defaultAvatarSize={40} />
                        </div>
                    </Tooltips>
                )}
                <div className="col-auto flex-grow-1">
                    <CardActions className="text-end">
                        <Button title="Editar tarea" icon="edit" className="p-1 m-0" onClick={_handleEdit}></Button>
                        <Button title="Eliminar tarea" icon="delete" className="p-1 m-0" onClick={_deleteEvent}></Button>
                        <Button title="Cerrar ventana" icon="close" className="p-1 m-0" onClick={_closeEvent}></Button>
                    </CardActions>
                </div>
            </div>
            <div className='row g-2 align-items-center'>
                <div className='col-3 flex-grow-1'>
                    <h5><strong>Tarea</strong></h5>
                </div>
                <div className='col-9 flex-grow-1'>
                    <p className="text-muted h6">{event?.name}</p>
                </div>
            </div>
            <div className="row align-items-center">
                <div className='col-3 flex-grow-1'>
                    <h5><strong>Fecha</strong></h5>
                </div>
                <div className='col-9 flex-grow-1'>
                    <p className="text-muted h6">{moment(event?.end).format('DD-MM-YYYY')}</p>
                </div>
            </div>
            <div className="row align-items-center">
                <div className='col-3 flex-grow-1 text-truncate'>
                    <h5><strong>Descripción</strong></h5>
                </div>
                <div className='col-9 flex-grow-1'>
                    <p className="text-muted h6">{event.comments ? event.comments : "-"}</p>
                </div>
            </div>
        </div>
    )
}