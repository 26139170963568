import { useIncomes } from "./provider/IncomesProvider";
import { useEffect, useState } from "react";
import { Income } from "../../../type/invoicing-type";
import HeaderComponent from "../../../components/scheduler/HeaderComponent";
import ResourcesComponent from "../../../components/scheduler/ResourcesComponent";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { CardTitle } from "../../../components/bootstrap/Card";
import IncomesFilters from "./filters/IncomesFilters";
import { generateMonthsBetweenDates, generateWeeksBetweenDates } from "../../../utils/schedulerViewMode";
import Button from "../../../components/bootstrap/Button";
import * as XLSX from 'xlsx';
import moment from "moment";
import CalendarSkeleton from "../../../components/skeleton/CalendarSkeleton";
import '../../../styles/scheduler.css';

const IncomesSchedulePage = () => {

    const { filters, incomesList, isLoading, refetchIncomes } = useIncomes();

    const [months, setMonths] = useState<any[]>([]);
    const [days, setDays] = useState<any[]>([]);
    const [incomes, setIncomes] = useState<any[]>([]);
    const [incomesTree, setIncomesTree] = useState<any[]>([]);

    /**
     * Export calendar data to Excel
     */
    const handleExportToExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet([['Tipo de ingreso', 'Concepto', 'Cantidad', 'Pagado', 'Fecha estimada de pago', 'Fecha efectiva de pago']]);

        const headerStyle = {
            font: { bold: true },
        };

        for (let col = 0; col < 6; col++) {
            const cellRef = XLSX.utils.encode_cell({ c: col, r: 0 });
            if (!ws[cellRef]) ws[cellRef] = {};
            ws[cellRef].s = headerStyle;
        }

        incomes.forEach(income => {
            XLSX.utils.sheet_add_aoa(ws, [[
                income.typeName || income.budgetName,
                income.title,
                income.price + '€',
                income.paid ? 'Sí' : 'Pendiente',
                moment(income.estimatedEntryDate).format('DD-MM-YYYY'),
                income.effectiveEntryDate ? moment(income.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A'
            ]], { origin: -1 });
        });

        const colWidths = incomes.reduce((widths, income) => {
            return [
                Math.max(widths[0], (income.typeName || income.budgetName).length),
                Math.max(widths[1], income.title.length),
                Math.max(widths[2], (income.price + '€').length),
                Math.max(widths[3], (income.paid ? 'Sí' : 'Pendiente').length),
                Math.max(widths[4], moment(income.estimatedEntryDate).format('DD-MM-YYYY').length),
                Math.max(widths[5], (income.effectiveEntryDate ? moment(income.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A').length),
            ];
        }, [0, 0, 0, 0, 0, 0]);

        ws['!cols'] = colWidths.map((w: any) => ({ wch: w + 2 })); // Adding padding to widths

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Ingresos');
        XLSX.writeFile(wb, 'calendario_ingresos.xlsx');
    };

    /**
     * Generate days between two dates and group them by month
     */
    const generateDaysBetweenDates = (start: Date, end: Date, mode: string): { months: any[]; days: any[] } => {
        const months: any[] = [];
        const days: any[] = [];
        let currentDate = new Date(start);
        const currentYear = start.getFullYear();

        // Helper function to get week number
        const getWeekNumber = (date: Date) => {
            const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        };

        if (mode === 'day') {
            while (currentDate <= end) {
                const year = currentDate.getFullYear();
                const month = currentDate.toLocaleString('default', { month: 'long' });
                const day = new Date(currentDate);

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ date: day });
                days.push(day);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        } else if (mode === 'week') {
            const weeks = generateWeeksBetweenDates(start, end);

            weeks.forEach(([weekStart, weekEnd]) => {
                const startDate = new Date(weekStart);
                const endDate = new Date(weekEnd);
                const year = startDate.getFullYear();
                const month = startDate.toLocaleString('default', { month: 'long' });
                const weekNumber = getWeekNumber(startDate);

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ start: startDate, end: endDate, weekNumber });
                days.push({ start: startDate, end: endDate, weekNumber });
            });
        } else if (mode === 'month') {
            const monthsInYear = generateMonthsBetweenDates(start, end);

            monthsInYear.forEach(([monthStart, monthEnd]) => {
                const startDate = new Date(monthStart);
                const endDate = new Date(monthEnd);
                const year = startDate.getFullYear();
                const month = startDate.toLocaleString('default', { month: 'long' });

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ start: startDate, end: endDate });
                days.push({ start: startDate, end: endDate });
            });
        }

        return { months, days };
    };

    /**
     * Build tree structure for incomes based on types or budgets
     */
    const buildTree = (incomes: any[], useBudgets: boolean) => {
        const treeMap = new Map<string, any>();

        incomes.forEach(income => {
            const key = useBudgets && income.budgetId ? income.budgetId : income.typeId;
            const name = useBudgets && income.budgetId ? income.budgetName : income.typeName;

            if (!treeMap.has(key)) {
                treeMap.set(key, {
                    id: key,
                    name: name,
                    incomes: [],
                    children: [],
                });
            }
            treeMap.get(key).incomes.push(income);
        });

        return Array.from(treeMap.values());
    };

    /**
     * Update dates when filters change to generate the calendar header
     */
    useEffect(() => {
        if (filters.filter_filters?.estimated_entry_date?.from && filters.filter_filters?.estimated_entry_date?.to && filters.filter_filters?.range_mode_view) {
            const start = new Date(filters.filter_filters.estimated_entry_date.from);
            const end = new Date(filters.filter_filters.estimated_entry_date.to);
            const { months, days } = generateDaysBetweenDates(start, end, filters.filter_filters.range_mode_view);
            setMonths(months);
            setDays(days);
        }
    }, [filters.filter_filters?.estimated_entry_date?.from, filters.filter_filters?.estimated_entry_date?.to, filters.filter_filters?.range_mode_view]);

    /**
     * Update incomes state when incomes list change
     */
    useEffect(() => {
        if (incomesList) {
            const newIncomes = incomesList.income?.map((income: Income) => ({
                id: income.id,
                title: income.concept,
                estimatedEntryDate: income.estimatedEntryDate.date,
                price: income.price,
                typeId: income.incomeType?.id,
                typeName: income.incomeType?.name,
                level: income.incomeType?.level,
                paid: income.paid,
                effectiveEntryDate: income.effectiveEntryDate?.date || null,
                color: income.incomeType?.color,
                budgetId: income.stages ? income.stages.budget.id : null,
                budgetName: income.stages ? `${income.stages.budget.address} (${income.stages.budget.name})` : null,
            }));
            setIncomes(newIncomes);
        }
    }, [incomesList]);

    /**
     * Update incomes tree when incomes change
     */
    useEffect(() => {
        if (incomes && incomes.length > 0) {
            const useBudgets = incomes.some(income => income.budgetId !== null);
            const tree = buildTree(incomes, useBudgets);
            setIncomesTree(tree);
        }
    }, [incomes]);

    return (
        <>
            {!isLoading
                ? incomesList && (
                    <>
                        <SubHeader>
                            <SubHeaderLeft>
                                <CardTitle>Calendario de ingresos</CardTitle>
                                <SubheaderSeparator />
                                <CardTitle>
                                    <span className="text-muted fs-5">Total: </span>{incomesList.income?.reduce((acc: number, income: Income) => { return acc + income.price }, 0)} €
                                </CardTitle>
                                <CardTitle>
                                    <span className="title-paid fs-5">Pagado: </span>{incomesList.income?.reduce((acc: number, income: Income) => { return income.paid ? acc + income.price : acc }, 0)} €
                                </CardTitle>
                                <CardTitle>
                                    <span className="title-pending fs-5">Pendiente: </span>{incomesList.income?.reduce((acc: number, income: Income) => { return income.paid ? acc : acc + income.price }, 0)} €
                                </CardTitle>
                                <SubheaderSeparator />
                                <Button color="secondary" isLight onClick={handleExportToExcel}>Exportar</Button>
                            </SubHeaderLeft>
                            <SubHeaderRight>
                                <IncomesFilters />
                            </SubHeaderRight>
                        </SubHeader>

                        <div className="calendar-container">
                            <div className="calendar-content">
                                <table>
                                    <HeaderComponent months={months} mode={filters.filter_filters?.range_mode_view} />
                                    <ResourcesComponent data={incomesTree} days={days} mode={filters.filter_filters?.range_mode_view} entity="incomes" refetch={refetchIncomes} />
                                </table>
                            </div>
                        </div>
                    </>
                )
                : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}><CalendarSkeleton /></div>
            }
        </>
    );
};

export default IncomesSchedulePage;