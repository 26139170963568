import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import projectReducer from './projectSlice';
import DocumentTypeReducer from './documentTypeSlice';
import clientReducer from './clientSlice';
import calendarReducer from './calendarSlice';
import dashBoardReducer from './dashboardSlice';
import schedulerReducer from './schedulerSlice';
import { loadState } from './browser-storage';

const store = configureStore({
  reducer: {
    auth: authReducer,
    project_info: projectReducer,
    project_documentParents: DocumentTypeReducer,
    client_profile: clientReducer,
    calendar: calendarReducer,
    dashboard: dashBoardReducer,
    scheduler: schedulerReducer,
  },
  preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;