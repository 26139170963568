import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import Textarea from "../../../../components/bootstrap/forms/Textarea";
import Button from "../../../../components/bootstrap/Button";
import Spinner from "../../../../components/bootstrap/Spinner";
import { CostService } from "../../../../services/costs/costService";
import { TaxService } from "../../../../services/taxes/taxService";

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data?: any;
    onClose: (type: number, message?: string, errors?: any) => void;
    show?: boolean;
}

const CreateModalFixedCost: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');
    const costService = new CostService();

    const [modal, setModal] = useState(false);
    const [costTypeList, setCostTypeList] = useState([]);
    const [taxList, setTaxList] = useState([]);

    const getCostTypes = async () => {
        const costTypeId = data?.id || '';
        const response = await (await costService.getCostTypes({
            filter_filters: { 
                not_cost_type: costTypeId,
                display_all_on_select: false
            }, 
            filter_order: [
                {
                    "field": "name",
                    "order": "asc"
                }
            ],
            limit: 999999
        })).getResponseData();
        if (response.success) {
            const costTypes = response.data.costsTypes;
            setCostTypeList(costTypes);
        }
    }

    const getCostTypeList = () => {
        if (costTypeList as any) {
            let costTypeOptions = costTypeList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return costTypeOptions;
        }
        return [];
    }

    const getTaxs = async () => {
        const taxService = new TaxService()
        const response = await (await taxService.listTaxes()).getResponseData();
        if (response.success) {
            const taxs = response.data.taxes;
            setTaxList(taxs);
        }
    }

    const getTaxsList = () => {
        if (taxList as any) {
            let taxOptions = taxList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
            return taxOptions;
        }
        return [];
    }

    useEffect(() => {
        getCostTypes();
        getTaxs();
    }, [isSuperAdmin])

    const create = async (values: any) => {
        try {
            const response = await (await costService.createFixedCost(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear el coste fijo');
        }
    }

    const update = async (values: any) => {
        try {
            const response = await (await costService.editFixedCost(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar el coste fijo');
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            costFixedId: data?.id || '',
            documentId: data?.document?.id || '',
            concept: data?.concept || '',
            price: data?.price || '',
            timePeriod: data?.timePeriod || 'monthly',
            timePeriodQuantity: data?.timePeriodQuantity || 1,
            repeatsNumber: data?.repeatsNumber || 1,
            description: data?.description || '',
            costTypeId: data?.costsType?.id || null,
            taxId: data?.taxes?.id || null,
            estimatedEntryDate: data?.estimatedEntryDate?.date?.split(' ')[0] || null,
        },
        onSubmit: (values) => {
            if (data != null) {
                update(values)
            } else {
                create(values);
            }

        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'lg' : 'md'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{show ? 'Ver Coste Fijo' : data ? 'Editar Coste Fijo' : 'Crear Coste Fijo'}</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='concept' label='Concepto' className={isSuperAdmin ? 'col-md-6' : "col-md-9"}>
                                <Input value={formik.values.concept || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='price' label='Importe' className={isSuperAdmin ? 'col-md-6' : "col-md-3"}>
                                <Input type="number" step={0.01} min={0} value={formik.values.price || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='costTypeId' label='Tipo de Coste' className={isSuperAdmin ? 'col-md-6' : "col"}>
                                <Select
                                    id="costTypeId"
                                    disabled={show ? true : false}
                                    list={getCostTypeList()}
                                    onChange={formik.handleChange}
                                    value={formik.values.costTypeId || ''}
                                    ariaLabel='Select costType'
                                    placeholder='Seleciona Tipo de Coste ...'
                                    required
                                />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='taxId' label='Impuesto' className={isSuperAdmin ? 'col-md-6' : "col-md-4"}>
                                <Select
                                    id='taxId'
                                    disabled={show ? true : false}
                                    list={getTaxsList()}
                                    onChange={formik.handleChange}
                                    value={formik.values.taxId || ''}
                                    ariaLabel='Select tax'
                                    placeholder='Seleciona Impuesto ...'
                                    required
                                />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='estimatedEntryDate' label='Día de comienzo' className={isSuperAdmin ? 'col-md-5' : "col-md-4"}>
                                <Input type='date' value={formik.values.estimatedEntryDate || ''} disabled={show ? true : false} required onChange={(e: any) => {
                                    const { value } = e.target;
                                    formik.setFieldValue('estimatedEntryDate', value === '' ? null : value);
                                }} />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='timePeriodQuantity' label='Cantidad de Periodos' className={isSuperAdmin ? 'col-md-5' : "col-md-4"}>
                                <Input type="number" min={1} value={formik.values.timePeriodQuantity || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='timePeriod' label='Periodo ' className={isSuperAdmin ? 'col-md-5' : "col-md-4"}>
                                <Select
                                    id='timePeriod'
                                    disabled={show ? true : false}
                                    list={[
                                        { value: 'daily', label: 'Día' },
                                        { value: 'weekly', label: 'Semana' },
                                        { value: 'monthly', label: 'Mes' },
                                        { value: 'annually', label: 'Año' },
                                    ]}
                                    onChange={formik.handleChange}
                                    value={formik.values.timePeriod || ''}
                                    ariaLabel='Select timePeriod'
                                    placeholder='Seleciona un periodo ...'
                                    required
                                />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='repeatsNumber' label='Número de Repeticiones' className={isSuperAdmin ? 'col-md-5' : "col-md-4"}>
                                <Input type="number" min={1} value={formik.values.repeatsNumber || ''} disabled={show ? true : false} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup id='description' label='Descripción:' className={"col-md-12"}>
                                <Textarea value={formik.values.description || ''} disabled={show ? true : false} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'}>
                            {data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (
                <div className="d-flex justify-content-center p-5">
                    <Spinner />
                </div>
            )}
        </Modal>
    )
}

export default CreateModalFixedCost;