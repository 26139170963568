import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import { IEvent } from "../../type/calendar-type";
import Avatar, { AvatarGroup } from "../Avatar";
import Tooltips from "../bootstrap/Tooltips";
import Popovers from "../bootstrap/Popovers";
import { useTasksCalendar } from "../../pages/calendar/providers/TasksCalendarProvider";
import { CalendarEventWindow } from "./CalendarEventWindow";
import { useState } from "react";
import AsyncImg from "../AsyncImg";
import Icon from "../icon/Icon";
import './CalendarEvents.css';

const MyEvent = (data: { event: IEvent }) => {
    const { darkModeStatus } = useDarkMode();
    const { event } = data;
    const [windowOpened, setWindowOpened] = useState<boolean>(false);
    const { deleteTask, refetchTasks, changeFinishTask } = useTasksCalendar();

    const _handleFinish = () => {
        changeFinishTask(event.id as string);
    };

    return (
        <Popovers
            opened={windowOpened}
            onOpen={() => setWindowOpened(true)}
            className="window-event-body"
            desc={(
                <CalendarEventWindow
                    event={event}
                    onClose={() => setWindowOpened(false)}
                    onDelete={async (id?: string) => {
                        if (id === undefined) return;
                        await deleteTask(id);
                        setWindowOpened(false);
                        refetchTasks();
                    }}
                />
            )}
        >
            <div className='row g-2 flex-nowrap'>
                <div className='col-auto flex-grow-1 d-flex justify-content-between align-items-center'>
                    <small
                        style={{ maxWidth: '150px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        className={classNames('text-truncate', {
                            'text-dark': !darkModeStatus,
                            'text-white': darkModeStatus,
                        })}
                    >
                        {event?.name}
                    </small>
                    <div className="d-flex align-items-center">
                        {event?.user && (
                            <Tooltips title={`Usuario: ${event.user?.name} ${event.user?.lastName}`}>
                                {/* <Avatar src={documentService.renderDocumentURL(event?.user?.src)} srcSet={documentService.renderDocumentURL(event?.user?.src)} size={25} /> */}
                                <AsyncImg id={event.user.src} styles="rounded" width="25" height="25" defaultAvatarSize={20} />
                            </Tooltips>
                        )}
                        {event?.isFinished
                            ? <Icon title={'Completada'} onClick={_handleFinish} icon="TaskAlt" className="ms-3" size={'lg'} color="dark" />
                            : <Icon title={'Sin completar'} onClick={_handleFinish} icon="RadioButtonUnchecked" className="ms-3" size={'lg'} color="dark" />
                        }
                    </div>
                </div>
                {event?.users && (
                    <div className='col-auto'>
                        <AvatarGroup size={18}>
                            {event.users.map((user) => (
                                <Avatar key={user?.id} src={user?.src ? user.src : ""} />
                            ))}
                        </AvatarGroup>
                    </div>
                )}
            </div>
        </Popovers>
    );
};

const MyWeekEvent = (data: { event: IEvent }) => {
    const { darkModeStatus } = useDarkMode();
    const { event } = data;
    const [windowOpened, setWindowOpened] = useState<boolean>(false);
    const { deleteTask, refetchTasks, changeFinishTask } = useTasksCalendar();

    const _handleFinish = () => {
        changeFinishTask(event.id as string);
        setTimeout(() => {
            setWindowOpened(false);
        }, 10);
    };

    return (
        <Popovers
            opened={windowOpened}
            onOpen={() => setWindowOpened(true)}
            className="window-event-body"
            desc={(
                <CalendarEventWindow
                    event={event}
                    onClose={() => setWindowOpened(false)}
                    onDelete={async (id?: string) => {
                        if (id === undefined) return;
                        await deleteTask(id)
                        setWindowOpened(false)
                        refetchTasks()
                    }}
                />
            )}
        >
            <div className='row h-100'>
                <div className='col-md-12 flex-grow-1 d-flex justify-content-between align-items-center'>
                    <small
                        style={{ maxWidth: '150px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        className={classNames('text-truncate', {
                            'text-dark': !darkModeStatus,
                            'text-white': darkModeStatus,
                        })}
                    >
                        {event?.name}
                    </small>
                    <div className="d-flex align-items-center">
                        {event?.user && (
                            <Tooltips title={`Usuario: ${event.user.name} ${event.user.lastName}`}>
                                <AsyncImg id={event.user.src} styles="rounded" width="25" height="25" defaultAvatarSize={25} />
                            </Tooltips>
                        )}
                        {event?.isFinished
                            ? <Icon title={'Completada'} onClick={_handleFinish} icon="TaskAlt" className="ms-3" size={'2x'} color="dark" />
                            : <Icon title={'Sin completar'} onClick={_handleFinish} icon="RadioButtonUnchecked" className="ms-3" size={'2x'} color="dark" />
                        }
                    </div>
                </div>
                {event?.users && (
                    <div className='col-auto'>
                        <AvatarGroup size={18}>
                            {event.users.map((user) => (
                                <Avatar key={user?.id} src={user?.src ? user.src : ""} />
                            ))}
                        </AvatarGroup>
                    </div>
                )}
                <div className='col-md-12 pt-1'>
                    <p className="text-muted small text-truncate">{event.comments}</p>
                </div>
            </div>
        </Popovers>
    );
};

const MyEventDay = (data: { event: IEvent }) => {
    const { darkModeStatus } = useDarkMode();
    const { event } = data;

    const { changeFinishTask } = useTasksCalendar();

    const _handleFinish = () => {
        changeFinishTask(event.id as string);
    };

    return (
        <div className='row g-2'>
            <div className='col-md-12 flex-grow-1 d-flex justify-content-between align-items-center '>
                <small
                    style={{ maxWidth: '350px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    className={classNames('text-truncate', {
                        'text-dark': !darkModeStatus,
                        'text-white': darkModeStatus,
                    })}
                >
                    {event?.name}
                </small>
                <div className="d-flex align-items-center">
                    {event?.user && (
                        <div className='d-flex align-items-center'>
                            <span className={classNames('me-2', {
                                'text-dark': !darkModeStatus,
                                'text-white': darkModeStatus
                            })}
                            >
                                {event.user.name} {event.user.lastName}
                            </span>
                            <AsyncImg id={event.user.src} styles="rounded" width="25" height="25" defaultAvatarSize={25} />
                        </div>
                    )}
                    {event?.isFinished
                        ? <Icon title={'Completada'} onClick={_handleFinish} icon="TaskAlt" className="ms-3" size={'2x'} color="dark" />
                        : <Icon title={'Sin completar'} onClick={_handleFinish} icon="RadioButtonUnchecked" className="ms-3" size={'2x'} color="dark" />
                    }
                </div>
            </div>
            {event?.users && (
                <div className='col-md-12'>
                    <AvatarGroup size={16}>
                        {event.users.map((user) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Avatar key={user?.id} src={user?.src ? user.src : ""} />
                        ))}
                    </AvatarGroup>
                </div>
            )}
            <div className='col-md-12'>
                <p className="text-muted small">{event.comments}</p>
            </div>
        </div>
    );
};

const eventStyleGetter = (event: any, start: any, end: any, isSelected: boolean) => {
    const now = new Date();
    const isActiveEvent = start <= now && end >= now;
    const isPastEvent = end < now;
    const color = event.colorHex ? event.colorHex : 'secondary';

    return {
        className: classNames({
            'border border-secondary': isActiveEvent,
            'opacity-50': isPastEvent,
        }),
        style: {
            backgroundColor: color
        }
    };
};

export { MyEvent, MyEventDay, MyWeekEvent, eventStyleGetter };