import { FC, Fragment, useEffect, useState } from "react"
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader"
import { Calendar, Views, momentLocalizer } from "react-big-calendar"
import WithDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import moment from "moment";
import classNames from "classnames";
import { CardTitle } from "../../../components/bootstrap/Card";
import Popovers from "../../../components/bootstrap/Popovers";
import { Calendar as CalendarPicker } from "react-date-range";
import Button from "../../../components/bootstrap/Button";
import { CalendarTodayButton, getLabel, getUnitType } from "../../../components/extras/calendarHelper";
import { CalendarViewModeButtons } from "../../../components/extras/calendarHelper";
import { Task, IEvent } from "../../../type/calendar-type"
import { DocumentService } from "../../../services/documents/documentService"
import { useTasksCalendar } from "../providers/TasksCalendarProvider"
import { MyEvent, MyEventDay, MyWeekEvent, eventStyleGetter } from "../../../components/calendar/CalendarEvents"
import TaskModalForm from "../../tasks/TaskModalForm"
import { toast } from "react-toastify"
import Checks from "../../../components/bootstrap/forms/Checks"
import { useNavigate, useParams } from "react-router-dom"
import { TaskService } from "../../../services/tasks/taskService"
import { RootState } from "../../../redux/store"
import { useDispatch, useSelector } from "react-redux"
import { TasksFiltersCanvas } from "../components/TasksFiltersCanvas"
import ListTasks from "../../projects/components/ListTasks"
import { changeStorageProjectView } from "../../../redux/calendarSlice"
import 'moment/locale/es'
import './TasksCalendar.css'

moment.locale('es-ES');

const localizer = momentLocalizer(moment);

interface currentDate {
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
}

const DragAndDropCalendar = WithDragAndDrop(Calendar);

export const TasksCalendar: FC = () => {

    const token = useSelector((state: RootState) => state.auth.user);
    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeView = useSelector((state: any) => state.calendar.projectView);

    // Get Calendar Provider
    const { tasks, refetchTasks, viewMode, changeViewMode, setCalendarDate, currentDate, selectTask, taskSelected, isTaskSelected, setIsTaskSelected, projectView } = useTasksCalendar();

    const [displayList, setDisplayList] = useState<boolean>(false);
    const [calendarEvents, setCalendarEvents] = useState<IEvent[]>([]);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const calendarDateLabel = getLabel(currentDate, viewMode);
    const unitType = getUnitType(viewMode);
    const newCurrentDate: currentDate = {
        year: moment().year(),
        month: moment().month(),
        day: moment().date(),
        hour: moment().hour(),
        minute: moment().minute(),
        second: moment().second(),
    };

    const changeProjectView = (calendar: true | false) => {
        dispatch(changeStorageProjectView(calendar));
    };

    // Get tasks
    useEffect(() => {
        if (!tasks) return;

        const events: IEvent[] = tasks.map((task: Task): IEvent => {
            const isBefore = moment(task.date?.date).isBefore(moment(), 'day');

            return {
                id: task.id,
                name: task.name,
                start: moment(task.date?.date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
                end: moment(task.date?.date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
                user: {
                    id: task.user?.id ? task.user?.id : "",
                    name: task.user ? task.user?.name : "",
                    lastName: task.user?.lastName ? task.user?.lastName : "",
                    src: task.user?.profileImg?.id ? task.user.profileImg.id : "",
                    //src: task.user?.profileImg?.id ? (new DocumentService()).renderDocumentURL(task.user.profileImg.id) : "",
                    srcSet: task.user?.profileImg?.id ? (new DocumentService()).renderDocumentURL(task.user.profileImg.id) : "",
                },
                project: task.project ? task.project : '',
                colorHex: isBefore && !task.isFinished
                    ? '#ff0000' //FFBD00
                    : isBefore && task.isFinished
                        ? '#00ff00'
                        : task.taskColor ? task.taskColor : (`${task.user?.color}60` || "light"),
                comments: task.comments,
                isFinished: task.isFinished,
            }
        })
        setCalendarEvents(events);
    }, [tasks]);

    // Redirect to tasks list
    useEffect(() => {
        if (displayList) {
            navigate('/tareas');
        }
    }, [displayList]);

    // Create new task
    const handleSelectNew = ({ start, end }: { start: any; end: any }) => {
        selectTask({
            id: null,
            start,
            end,
            user: null,
            name: '',
            comments: '',
            taskColor: '',
        });
        setIsTaskSelected(true);
    };

    // Change view mode
    const handleViewMode = (e: any) => {
        setCalendarDate(moment(e).toDate());
        changeViewMode(Views.DAY);
    };

    const _handleEventDrop = async ({ event, start, end, isAllDay }: any) => {
        // remove event from calendar events
        let calendarEventsFiltered = calendarEvents.filter((item: IEvent) => item.id !== event.id);
        setCalendarEvents(calendarEventsFiltered);

        let eventReceived: any = {
            ...event,
            task: event.id,
            user: event.user?.id,
            project: event.project?.id,
            company: token?.company,
            date: moment(start).format('YYYY-MM-DD'),
            colorHex: event.colorHex,
        };

        let updatedAppointment = await (new TaskService()).editTask(eventReceived);
        let data = updatedAppointment.getResponseData();

        if (data.success) {
            refetchTasks();
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            toast.success(message);
            refetchTasks();
            setIsTaskSelected(false);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    const commonProps = {
        toolbar: false,
        localizer: localizer,
        events: calendarEvents,
        defaultView: Views.WEEK,
        // @ts-ignore
        views: Object.keys(Views).map((k: string) => Views[k]),
        view: viewMode,
        date: currentDate,
        onNavigate: (_date: Date) => setCalendarDate(_date),
        scrollToTime: new Date(1970, 1, 1, 6),
        defaultDate: new Date(),
        /* selectable
        onSelectSlot={handleSelectNew} */
        onView: handleViewMode,
        onEventDrop: _handleEventDrop,
        resizable: false,
        onDrillDown: handleViewMode,
        timeslots: 1,
        step: 120,
        min: new Date(newCurrentDate.year, newCurrentDate.month, newCurrentDate.day, 7, 0, 0),
        slotPropGetter: (date: Date) => {
            return {
                className: classNames({
                    'bg-light-secondary': moment(date).isSame(moment(), 'day'),
                }),
            };
        },
        components: {
            event: MyEvent, // used by each view (Month, Day, Week)
            work_week: { event: MyWeekEvent },
            week: { event: MyWeekEvent },
            day: { event: MyEventDay },
        },
        eventPropGetter: eventStyleGetter,
    };
    const renderButtons = projectView ? activeView : true; // Si es vista de proyecto, renderiza los botones de vista de calendario conditionalmente

    return (
        <Fragment>
            <div className="d-flex justify-content-between p-2 mb-3">
                <SubHeaderLeft>
                    {activeView && (
                        <>
                            <Popovers
                                desc={
                                    <CalendarPicker
                                        onChange={(item) => { setCalendarDate(item) }}
                                        date={currentDate}
                                        color={process.env.REACT_APP_PRIMARY_COLOR}
                                    />
                                }
                                placement='bottom-end'
                                className='mw-100'
                                trigger='click'
                            >
                                <Button color='secondary' isLight>{calendarDateLabel}</Button>
                            </Popovers>
                            <SubheaderSeparator className="ms-3 me-3" />
                        </>
                    )}
                    <CardTitle>{activeView ? 'Calendario' : 'Lista'} de Tareas</CardTitle>
                    <SubheaderSeparator className="ms-3 me-3" />
                    <Checks
                        type="switch"
                        checked={projectView ? activeView : true}
                        onChange={() => { projectView ? changeProjectView(!activeView) : setDisplayList(!displayList) }}
                        label="Calendario"
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="secondary" isLight icon="filter"
                        onClick={() => { setShowFilters(true) }}
                    >
                        Filtros
                    </Button>
                    {
                        renderButtons &&
                        (<>
                            <CalendarViewModeButtons
                                viewMode={viewMode}
                                setViewMode={changeViewMode}
                            />
                            <CalendarTodayButton
                                unitType={unitType}
                                date={currentDate as object}
                                setDate={setCalendarDate}
                                viewMode={viewMode}
                            />
                        </>)
                    }
                </SubHeaderRight>
            </div>

            <div className="w-100" style={{ height: projectView ? '640px' : '100%' }}>
                {
                    projectView
                        ? activeView
                            ? <DragAndDropCalendar {...commonProps} />
                            : <ListTasks projectId={id} />
                        : <DragAndDropCalendar {...commonProps} />
                }

                {taskSelected && <TaskModalForm data={taskSelected} isOpen={isTaskSelected} setIsOpen={setIsTaskSelected} onClose={_onCloseModal} />}

                <TasksFiltersCanvas isOpen={showFilters} setOpen={(status: boolean) => { setShowFilters(status) }} />
            </div>
        </Fragment>
    );
};