import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export interface CalendarState {
    projectView: 'calendar' | 'list';
    viewMode: "month" | "week" | "work_week" | "day";
    date: string;
}

export const initialState: CalendarState = {
    projectView: 'calendar',
    viewMode: 'week',
    date: moment().format('YYYY-MM-DD'),
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        changeStorageProjectView: (state, action) => {
            state.projectView = action.payload;
        },
        changeStorageViewMode: (state, action) => {
            state.viewMode = action.payload;
        },
        changeStorageDate: (state, action) => {
            state.date = action.payload;
        }
    },
});

export const { changeStorageProjectView, changeStorageViewMode, changeStorageDate } = calendarSlice.actions;

export default calendarSlice.reducer;