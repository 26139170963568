import { FC, useCallback } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { CardBody, CardFooter } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import { useFormik } from "formik";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import { ClientService } from "../../../services/clients/clientService";
import useFetch from "../../../hooks/useFetch";
import { ClientsApiResponse } from "../../../type/client-type";
import { BudgetService } from "../../../services/budgets/budgetService";
import Select from "../../../components/bootstrap/forms/Select";
import { toast } from "react-toastify";
import { IBudgetsFilters } from "../budgets-list/BudgetsList";
import useFilters from "../../../hooks/useFilters";
import { Budget } from "../../../type/budgets-type";

interface CloneBudgetModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    budget: { id: string, name: string };
    client?: string;
    refetch: () => void;
}

interface CloneBudgetModalForm {
    client: string;
    budget: string;
    expirationDate: string;
    paymentMethod: string;
    name: string;
    address: string;
    sponsor: string;
}

const clientFilters: IBudgetsFilters = {
    client: '',
    //classification: 'Consolidado',
};

const CloneBudgetModal: FC<CloneBudgetModalProps> = ({ isOpen, setIsOpen, client, budget, refetch }) => {

    const { filters } = useFilters(clientFilters, [], 0, 999999999);

    const budgetService = new BudgetService();

    const [budgetData] = useFetch(useCallback(async () => {
        const response = (await budgetService.getBudget(budget.id as string));
        return response.getResponseData() as Budget;
    }, [budget]));

    const [clients] = useFetch(useCallback(async () => {
        const response = await (new ClientService).getClients(filters);
        return response.getResponseData() as ClientsApiResponse;
    }, [filters]));

    const _handleSaveClonedBudget = async (values: CloneBudgetModalForm) => {
        try {
            const response = (await (new BudgetService).cloneBudget(values)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    setIsOpen(false);
                    toast.success('Presupuesto clonado correctamente');
                }, 100);
            } else {
                response.data.errors.map((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al clonar el presupuesto');
        }
    }

    const getClientsList = () => {
        if (clients) {
            return clients.clients.map((client: { id: string, name: string, firstName: string, lastName: string, clientContactPerson: any }) => {
                const clientContact = client.clientContactPerson?.name + (client.clientContactPerson?.firstName ? ' ' + client.clientContactPerson?.firstName : '') + (client.clientContactPerson?.lastName ? ' ' + client.clientContactPerson?.lastName : '');
                return {
                    value: client.id,
                    label: (client.name || 'Contacto: ' + clientContact) + (client.firstName ? ' ' + client.firstName : '') + (client.lastName ? ' ' + client.lastName : ''),
                }
            })
        }
        return [];
    }

    const _handleSelectClientChange = (selectedOption: any) => {
        formik.handleChange(selectedOption.value);
        formik.setFieldValue('client', selectedOption.value);
    };

    const _handleSelectSponsorChange = (selectedOption: any) => {
        formik.handleChange(selectedOption.value);
        formik.setFieldValue('sponsor', selectedOption.value);
    };

    const formik = useFormik({
        initialValues: {
            client: client || '',
            budget: budget.id,
            expirationDate: '',
            paymentMethod: 'Transferencia bancaria',
            name: '',
            address: '',
            sponsor: '',
        },
        validate: values => {
            const errors: any = {};
            if (!values.client) {
                errors.client = 'El cliente es obligatorio';
            }
            if (!values.expirationDate) {
                errors.expirationDate = 'La fecha de vencimiento es obligatoria';
            }
            if (!values.paymentMethod) {
                errors.paymentMethod = 'La forma de pago es obligatoria';
            }
            if (!values.name) {
                errors.name = 'El nombre es obligatorio';
            }
            if (!values.sponsor) {
                errors.sponsor = 'El patrocinador es obligatorio';
            }

            return errors;
        },
        onSubmit: values => _handleSaveClonedBudget(values),
    });

    const verifyClass = (input: keyof CloneBudgetModalForm) => { return (formik.touched[input] && formik.errors[input]) ? 'is-invalid' : '' };

    const showErrors = (input: keyof CloneBudgetModalForm) => {
        return (formik.touched[input] && formik.errors[input]) ? (<div className="invalid-feedback">{String(formik.errors[input])}</div>) : (<></>);
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size='md' titleId='Clonar presupuesto'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='clone_budget'>Clonar presupuesto: {budget.name}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody className="row">
                        <div className="col-md-12 mt-2">
                            {
                                !client && (
                                    <FormGroup label="Cliente:" requiredInputLabel>
                                        <SearchableSelect
                                            isSearchable
                                            options={getClientsList()}
                                            onChange={_handleSelectClientChange}
                                            onBlur={formik.handleBlur}
                                            name="client-select"
                                            placeholder="cliente"
                                            classname={verifyClass('client')}
                                        />
                                        {showErrors('client')}
                                    </FormGroup>
                                )
                            }
                        </div>

                        <div className="col-md-6 mt-2">
                            <FormGroup label="Forma de pago:" requiredInputLabel>
                                <Select
                                    name="paymentMethod"
                                    list={[
                                        { value: 'Transferencia bancaria', label: 'Transferencia' },
                                        { value: 'Efectivo', label: 'Efectivo' },
                                        { value: 'Remesa', label: 'Remesa' },
                                    ]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.paymentMethod}
                                    className={verifyClass('paymentMethod')}
                                    ariaLabel="Forma de pago"
                                />
                                {showErrors('paymentMethod')}
                            </FormGroup>
                        </div>

                        <div className="col-md-6 mt-2">
                            <FormGroup label="Fecha de vencimiento:" requiredInputLabel>
                                <Input
                                    type="date"
                                    name="expirationDate"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.expirationDate}
                                    min={budgetData?.createdAt?.date.split(' ')[0]}
                                    className={verifyClass('expirationDate')}
                                />
                                {showErrors('expirationDate')}
                            </FormGroup>
                        </div>

                        <div className="col-md-12 mt-2">
                            <FormGroup label="Nombre:" requiredInputLabel>
                                <Input
                                    type="text"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    className={verifyClass('name')}
                                />
                                {showErrors('name')}
                            </FormGroup>
                        </div>

                        <div className="col-md-12 mt-2">
                            <FormGroup label="Dirección:">
                                <textarea
                                    name="address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    className={verifyClass('address')}
                                    rows={2}
                                    style={{
                                        borderRadius: '10px',
                                        boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                                        backgroundColor: '#f8f9fa',
                                        width: '100%',
                                        padding: '15px',
                                        border: '0'
                                    }}
                                />
                                {showErrors('address')}
                            </FormGroup>
                        </div>

                        <div className="col-md-12 mt-2">
                            {
                                !client && (
                                    <FormGroup label="Promotor:" requiredInputLabel>
                                        <SearchableSelect
                                            isSearchable
                                            options={getClientsList()}
                                            onChange={_handleSelectSponsorChange}
                                            onBlur={formik.handleBlur}
                                            name="sponsor-select"
                                            placeholder="promotor"
                                            classname={verifyClass('sponsor')}
                                        />
                                        {showErrors('sponsor')}
                                    </FormGroup>
                                )
                            }
                        </div>
                    </CardBody>

                    <CardFooter className="d-flex justify-content-end">
                        <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                            Clonar
                        </Button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CloneBudgetModal;