import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Error from '../../assets/img/error.png';
import Forbidden from '../../assets/img/forbidden.jpg';
import Button from '../../components/bootstrap/Button';

const Page404 = () => {
	return (
		<PageWrapper>
			<Page>
				<div className='row d-flex align-items-center h-100 errorBackground'>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<div
							className='text-primary fw-bold'
							style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
							Ups! Algo va mal
						</div>
					</div>
					<div className='col-12 d-flex align-items-baseline justify-content-center'>
						<img
							src={Forbidden}
							alt='Error Page'
							style={{ height: '50vh' }}
						/>
					</div>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<Button
							className='px-4 py-3'
							color='dark'
							isLight
							tag='a'
							href='/usuarios'>
							Página de inicio
						</Button>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Page404;