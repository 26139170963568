import { Fragment, useCallback, useContext, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import StatusDropdown from "../../../components/StatusDropdown";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { ClientService } from "../../../services/clients/clientService";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { CategoriesApiResponse } from "../../../type/category-type";
import CreateModal from "../../../components/CreateModal";
import CategorytFilters from "./category-options/CategoryFilters";
import { CategoryService } from "../../../services/categories/categoryService";

export interface ICategoryFilters {
    role?: any;
    active?: number;
}

const categorytFilters: ICategoryFilters = {
    role: '',
    active: 1,
};

const CategoryList = () => {

    const { userCan } = useContext(PrivilegeContext);

    const categoryService = new CategoryService();

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [label, setLabel] = useState('');
    const [show, setShow] = useState(false);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(categorytFilters);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await categoryService.listCategories(filters);
        return response.getResponseData() as CategoriesApiResponse;
    }, [filters]));

    const handleDelete = async (id: string) => {
        try {
            const response = (await categoryService.deleteCategory(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Categoría eliminada correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar la categoría");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const toggleCategoryStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = await (
                await new ClientService().toggleCategoryStatus(id, status)
            ).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                toast.success(status ? 'Se ha activado la caregoria' : 'Se ha desactivado la categoría');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const _onCloseModal = (message: any, type: number) => {
        setModalOpen(false);
        setSelectedItem(null);
        setLabel('');
        setShow(false);
        if (type === 1) {
            toast.success(message);
        } else {
            message.forEach((element: any) => {
                toast.error(element.message);
            });
        }
        refetch();
    };

    const getContent = () => {
        if (error) return <ErrorMessage error={error} />;

        if (data) {
            let apiData = data as CategoriesApiResponse;

            return (
                <CustomTable
                    title="Categorías"
                    loading={loading}
                    data={apiData.categories ? apiData.categories : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as CategoriesApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold" onClick={() => {
                                        setSelectedItem(element);
                                        setLabel('Editar Categoría');
                                        setShow(true);
                                        setModalOpen(true);
                                    }}>
                                        {element.name}
                                    </div>
                                )
                            },

                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.description || "-"}
                                    </>
                                );
                            },
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            className: (userCan("list", "companies") ? "d-block" : "d-none"),
                            isVisible: (userCan("list", "companies") ? true : false),
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.company.name || "-"}
                                    </>
                                );
                            },
                        },
                        {
                            name: "Estado",
                            keyValue: "active",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={element.id}>
                                        <StatusDropdown
                                            disabled={changingStatus.includes(element.id)}
                                            itemId={element.id}
                                            status={element.active}
                                            change={toggleCategoryStatus}
                                        />
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Editar",
                                buttonType: 'icon',
                                icon: "Edit",
                                additionalClasses: `text-primary ${userCan("edit", "categories") ? "" : "d-none"}`,
                                description: "Editar Categoría",
                                callback: (item: any) => {
                                    if (!userCan("edit", "categories")) return;
                                    setModalOpen(true);
                                    setSelectedItem(item);
                                    setLabel('Editar Categoría');
                                    setShow(false);
                                },
                            },
                            {
                                title: "Eliminar",
                                buttonType: 'icon',
                                icon: "Delete",
                                additionalClasses: `text-primary ${userCan("delete", "categories") ? "" : "d-none"}`,
                                description: "Eliminar Categoria",
                                callback: (item: any) => {
                                    if (!userCan("delete", "categories")) return;
                                    handleConfirmationAlert({
                                        title: "Eliminar Categoría",
                                        text: "¿Está seguro que desea eliminar la categoría?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        }
                                    })
                                },
                            },
                            {
                                title: "Sin permisos",
                                buttonType: 'icon',
                                icon: "Block",
                                additionalClasses: `text-danger ${(userCan('edit', 'categories') || userCan('delete', 'categories')) ? 'd-none' : ''}`,
                                description: "No tiene permisos para editar ni eliminar",
                                callback: (item: any) => { },
                            }
                        ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Categorias</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="primary"
                        title="Crear Categoría"
                        icon="Add"
                        isLight
                        className={`${userCan("create", "categories") ? "" : "d-none"}`}
                        onClick={() => {
                            if (!userCan("create", "categories")) return;
                            setModalOpen(true);
                            setSelectedItem(null);
                            setLabel("Crear Categoría");
                            setShow(false);
                        }}
                    >
                    </Button>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <CategorytFilters updateFilters={updateFilters} updatePageSize={updatePageSize} filters={filters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card>
                    <CardBody className="table-responsive">
                        <Fragment>{getContent()}</Fragment>
                    </CardBody>
                </Card>
            </Page>
            {modalOpen && <CreateModal isOpen={modalOpen} setIsOpen={setModalOpen} name="category" label={label} data={selectedItem} onClose={_onCloseModal} show={show} />}
        </Fragment>
    );
};

export default CategoryList;