import { FC, useContext, useEffect, useRef, useState } from "react";
import { PrivilegeContext } from "../priviledge/PriviledgeProvider";
import Button from "../bootstrap/Button";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../bootstrap/Dropdown";
import Spinner from "../bootstrap/Spinner";
import useHandleErrors from "../../hooks/useHandleErrors";
import { Status } from "../../type/status-type";
import { StatusService } from "../../services/status/statusService";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import FormGroup from "../bootstrap/forms/FormGroup";
import { COLORS_36 } from "../../utils/colors";
import classNames from "classnames";
import ProjectStatusModal from "../../pages/projects/components/ProjectStatusModal";
import './dropdownStyles.css';

interface StatusesDropdownProps {
    itemId: string;
    status: any;
    orientedTo: string;
    additionalInfo?: string;
    onChange?: () => void;
    disabled?: boolean;
    statuses?: Status[];
    startEnd?: boolean;
}

const StatusesDropdown: FC<StatusesDropdownProps> = ({ itemId, status, onChange, disabled = false, orientedTo, additionalInfo, statuses, startEnd }) => {

    const { handleErrors } = useHandleErrors();
    const { userCan } = useContext(PrivilegeContext);
    const conceptRef = useRef<HTMLInputElement | null>(null);
    const commentRef = useRef<HTMLTextAreaElement | null>(null);
    const dateRef = useRef<HTMLInputElement | null>(null);

    const [currentStatus, setCurrentStatus] = useState<Status>(status || { color: 'black', name: 'Estado Eliminado', orientedTo: orientedTo });
    const [statusSelected, setStatusSelected] = useState<string>();
    const [nameStatus, setNameStatus] = useState<string>(status?.name);
    const [color, setColor] = useState<string>(status?.color);
    const [actionStatusList, setActionStatusList] = useState<Status[]>(statuses ? statuses : []);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menuMinHeight, setMenuMinHeight] = useState<string>('min');
    const [projectStatusModal, setProjectStatusModal] = useState<boolean>(false);

    // Modal de confirmación para cambiar el estado
    const _toggleState = (newStatus: string) => {
        handleConfirmationAlert({
            title: "¿Estás seguro de que deseas cambiar de estado?",
            html: (
                <>
                    {orientedTo === 'budget' && (
                        <>
                            {/* Hay que usar referencias porque al ser html nativo no re-renderiza el componente y por lo tanto no cambia el estado */}
                            <div className="d-flex justify-content-center">
                                <FormGroup label="Fecha:" className="col-md-6 mb-2">
                                    <input
                                        type="date"
                                        name="date"
                                        ref={dateRef}
                                        className="form-control"
                                    />
                                </FormGroup>
                            </div>
                            <FormGroup label="Concepto:" className="mb-2">
                                <input type="text" ref={conceptRef} className="form-control" />
                            </FormGroup>
                        </>
                    )}
                    <FormGroup label="Comentario:">
                        <textarea ref={commentRef} className="form-control" rows={5}></textarea>
                    </FormGroup>
                </>
            ),
            icon: "warning",
            onConfirm: () => {
                let dateValue = dateRef.current?.value || '';
                let conceptValue = conceptRef.current?.value || '';
                let commentValue = commentRef.current?.value || '';
                setIsLoading(true);
                setTimeout(() => {
                    _toggleStatus(newStatus, dateValue, conceptValue, commentValue);
                }, 200);
            },
        });
    };

    // Cambiar el estado
    const _toggleStatus = async (status: string, dateValue: string, conceptValue: string, commentValue: string) => {
        //const service = orientedTo === 'budget' ? new StatusService().toggleBudgetStatus(itemId, status, dateValue, conceptValue, commentValue) : new StatusService().toggleProjectStatus(itemId, status, commentValue);
        const service = new StatusService().toggleBudgetStatus(itemId, status, dateValue, conceptValue, commentValue);

        try {
            let response = await (await service).getResponseData();

            if (response && response.success) {
                setCurrentStatus(response.data);
                onChange && onChange();
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al cambiar el estado');
        } finally {
            setIsLoading(false);
        }
    };

    // Actualizar el tamaño del dropdown según los permisos del usuario
    useEffect(() => {
        setMenuMinHeight(
            userCan('down_status', 'statuses') && userCan('up_statuses', 'statuses')
                ? 'large'
                : userCan('down_status', 'statuses') || userCan('up_statuses', 'statuses')
                    ? 'large'
                    : 'min'
        );
    }, []);

    // Actualizar la lista de estados
    useEffect(() => {
        setActionStatusList(statuses ? statuses : []);
    }, [statuses]);

    // Actualizar el estado actual
    useEffect(() => {
        if (currentStatus) {
            setColor(currentStatus?.color ? currentStatus.color : 'whitesmoke');
            setNameStatus(currentStatus.name);
        }
    }, [currentStatus]);

    // Lista de estados para el dropdown
    const loadStatusesList = (actionStatus: Status) => {
        return (
            <DropdownItem key={itemId + "-" + actionStatus.id + "-dropdownItem"} className='mt-2' >
                <Button
                    className="text-nowrap fs-8 fw-semibold text-center"
                    style={{ backgroundColor: actionStatus.color ? actionStatus.color : 'white', color: "white" }}
                    isLink
                    onClick={() => {
                        setStatusSelected(actionStatus.id);
                        orientedTo === 'budget' ? _toggleState(actionStatus.id) : setProjectStatusModal(true);
                    }}>
                    <span
                        className="text-center w-100 fs-7 "
                        style={{ color: COLORS_36.find((color: string, index: number) => (color === actionStatus.color) && (index > 14)) ? 'black' : 'white' }}
                    >
                        {actionStatus.name}
                    </span>
                </Button>
            </DropdownItem>
        )
    };

    return (
        <>
            {startEnd && <style>{` .dropdown-menu { inset: 0px auto auto -4% !important } `}</style>}

            <Dropdown className={'status-dropdown'} key={itemId + "-" + orientedTo + "-dropdownItem"}>
                <DropdownToggle hasIcon={false}>
                    <Button isLink isDisable={disabled} className='text-nowrap fs-8  p-2' style={{ backgroundColor: color }}>
                        {
                            isLoading
                                ? <Spinner isSmall color='light' />
                                : <span className='fw-bold fs8 p-2 ' style={{ color: COLORS_36.find((c: string, index: number) => (c === color) && (index > 14)) ? 'black' : 'white' }}> {nameStatus}</span>
                        }
                        {
                            additionalInfo
                                ? <small className='fw-bold fs-8 p-2 ' style={{ color: COLORS_36.find((c: string, index: number) => (c === color) && (index > 14)) ? 'black' : 'white' }}> {additionalInfo} </small>
                                : null
                        }
                    </Button>
                </DropdownToggle>

                <DropdownMenu
                    className={classNames("overflow-auto card-body-scrollable", {
                        "min-h": menuMinHeight === 'min',
                        "min-h-200": menuMinHeight === 'large',
                    })}
                >
                    <>
                        {
                            // Si tiene ambos permisos muestra todos los estados
                            userCan('down_status', 'statuses') && userCan('up_statuses', 'statuses') && (
                                actionStatusList?.filter((actionStatus: Status) => actionStatus.id !== currentStatus?.id)?.map((actionStatus: Status) => {
                                    return loadStatusesList(actionStatus)
                                })
                            )
                        }
                        {
                            // Muestra los anteriores y justo el siguiente
                            userCan('down_status', 'statuses') && !userCan('up_statuses', 'statuses') && (
                                actionStatusList?.slice(0, actionStatusList?.findIndex((actionStatus: Status) => actionStatus.id === currentStatus?.id) + 2)?.
                                    filter((actionStatus: Status) => actionStatus.id !== currentStatus?.id)?.
                                    map((actionStatus: Status) => { return loadStatusesList(actionStatus) })
                            )
                        }
                        {
                            // Solo muestra los siguientes
                            !userCan('down_status', 'statuses') && userCan('up_statuses', 'statuses') && (
                                actionStatusList?.slice(actionStatusList?.findIndex((actionStatus: Status) => actionStatus.id === currentStatus?.id) + 1)?.map((actionStatus: Status) => {
                                    return loadStatusesList(actionStatus)
                                })
                            )
                        }
                        {
                            // Si no tiene ninguno de los dos permisos que muestre solo el siguiente estado
                            !userCan('down_status', 'statuses') && !userCan('up_statuses', 'statuses') && (
                                actionStatusList?.slice(
                                    actionStatusList?.findIndex((actionStatus: Status) => actionStatus.id === currentStatus?.id) + 1,
                                    actionStatusList?.findIndex((actionStatus: Status) => actionStatus.id === currentStatus?.id) + 2)?.
                                    map((actionStatus: Status) => { return loadStatusesList(actionStatus) })
                            )
                        }
                    </>
                </DropdownMenu>
            </Dropdown>


            {projectStatusModal && <ProjectStatusModal
                isOpen={projectStatusModal} setIsOpen={setProjectStatusModal}
                statusId={statusSelected} projectId={itemId}
                onChange={onChange} setCurrentStatus={setCurrentStatus}
                isLoading={isLoading} setIsLoading={setIsLoading}
            />
            }
        </>
    )
}

export default StatusesDropdown;