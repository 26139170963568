import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTabItem } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/organizationService";
import { Company } from "../../../type/company-type";
import Estadisticas from "./Estadisticas";
import OrganizationInfoCard from "./OrganizationInfoCard";
import OrganizationUsers from "./OrganizationUsers";
import { RoleService } from "../../../services/auth/roleService";
import { Role, Roles, RolesApiResponse } from "../../../type/role-type";
import ErrorMessage from "../../../components/ErrorMessage";
import OrganizationDocuments from "./OrganizationDocuments";

const OrganizationProfile: FC = () => {

    const navigate = useNavigate();
    const { id = "" } = useParams<{ id: string }>();

    const organizationService = new CompanyService();
    const roleService = new RoleService();

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await organizationService.getCompanyById(id as string);
        return response.getResponseData() as Company;
    }, []));

    const [roleList, fetchingRoles, roleError, refetchRoleList] = useFetch(useCallback(async () => {
        if (!id) return;
        const response = await roleService.getRolesByOrganization(id);
        return response.getResponseData() as RolesApiResponse;
    }, [id]));

    const getRolesList = () => {
        if (roleList as Roles) {
            return roleList.map((role: Role) => {
                return (
                    <Button
                        key={role.id}
                        id={role.id + ""}
                        className="btn btn-dark w-auto"
                        onClick={() => navigate(`/roles/${role.id}/edicion`)}
                    >{role.name}</Button>
                );
            });
        }
    }

    return (
        <>
            {loading && <div className="d-flex justify-content-center align-items-center"><Spinner /></div>}
            {error && <ErrorMessage message={error.message} />}
            {data && (
                <Fragment>
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate('/organizaciones', { replace: true })}>
                            </Button>
                        </SubHeaderLeft>
                        <SubHeaderRight>
                            <Button
                                color="brand"
                                icon="Edit"
                                onClick={() => navigate(`/organizaciones/${id}/edicion`, { replace: true })}
                            >
                                Editar
                            </Button>
                        </SubHeaderRight>
                    </SubHeader>
                    <Page container='fluid'>

                        <div className='row'>
                            <div className="pt-3 pb-5 d-flex align-items-center">
                                <span className="display-6 fw-bold me-3">{data.name}</span>
                            </div>

                            <div className="col-3">
                                <OrganizationInfoCard
                                    profileImg={data.logo?.id}
                                    cif={data.cif}
                                    address={data.address}
                                />
                                {/* <Estadisticas /> */}
                            </div>

                            <div className='col-9'>
                                <Card hasTab stretch>
                                    <CardTabItem id='documents' title="Documentos" icon="PhotoSizeSelectActual">
                                        <OrganizationDocuments />
                                    </CardTabItem>

                                    <CardTabItem id='users' title='Usuarios' icon='Person'>
                                        <OrganizationUsers users={data.users} />
                                    </CardTabItem>

                                    {/* <CardTabItem id='profile' title='Perfil' icon='Contacts'>
                                    <CardHeader>
                                        <CardLabel icon='Business'>
                                            <CardTitle>Información de la organización</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4'>

                                        </div>
                                    </CardBody>
                                </CardTabItem>

                                <CardTabItem id='roles' title='Roles' icon='Security'>
                                    <CardHeader>
                                        <CardLabel icon='Lock'>
                                            <CardTitle>Listado de roles</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4 d-flex justify-content-around'>
                                            {getRolesList()}
                                        </div>
                                    </CardBody>
                                </CardTabItem> */}
                                </Card>
                            </div>
                        </div>
                    </Page>
                </Fragment>
            )}
        </>
    )
}

export default OrganizationProfile;