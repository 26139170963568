import { FC } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import moment from "moment";
import { ClientMenu, authMenu } from "../../../menu";
import { useDispatch } from "react-redux";
import { changeClientActiveTab } from "../../../redux/clientSlice";
import classNames from "classnames";

interface ProjectInfoViewProps {
    projectData: any;
}

export const ProjectInfoView: FC<ProjectInfoViewProps> = ({ projectData }) => {

    const dispatch = useDispatch();

    const dispatchSelectedClientTab = (tabId: string) => {
        dispatch(changeClientActiveTab(tabId));
    }

    // Cambia la pestaña activa del cliente y la ruta a la que navega en función de si el cliente está consolidado o no
    const isConsolidated = (classification: string) => {
        if (classification === 'Consolidado') {
            dispatchSelectedClientTab('contact');
            return ClientMenu.clients.path;
        }

        return ClientMenu.contacts.path;
    }

    if (!projectData) return null;

    return (
        <Container>
            <div className="p-4 border rounded mb-3" /* title="Personas" */>
                <h4 className="mb-3">Personas involucradas:</h4>

                <Row className="mb-2">
                    <Col md={4}>
                        <Card.Text className="text-muted fs-6">Cliente</Card.Text>
                        <Card.Text className="fs-5 text-primary cursor-pointer">
                            {projectData.client?.name !== null ? (
                                <a href={`${isConsolidated(projectData.client?.classification)}/${projectData.client?.id}/perfil`}>
                                    {`${projectData.client?.name} ${projectData.client?.firstName || ''} ${projectData.client?.lastName || ''}`}
                                </a>
                            ) : '-'
                            }
                        </Card.Text>
                    </Col>
                    <Col md={4}>
                        <Card.Text className="text-muted fs-6">Representante</Card.Text>
                        <Card.Text className="fs-5 text-primary cursor-pointer">
                            {projectData.clientRepresentative?.name !== null && projectData.clientRepresentative?.name !== undefined ? (
                                <a href={`${isConsolidated(projectData.client?.classification)}/${projectData.client?.id}/perfil`}>
                                    {`${projectData.clientRepresentative?.name} ${projectData.clientRepresentative?.firstName || ''} ${projectData.clientRepresentative?.lastName || ''}`}
                                </a>
                            ) : '-'
                            }
                        </Card.Text>
                    </Col>
                    <Col md={4}>
                        <Card.Text className="text-muted fs-6">Promotor</Card.Text>
                        <Card.Text className="fs-5 text-primary cursor-pointer">
                            {projectData.clientSponsor?.name !== null && projectData.clientSponsor?.name !== undefined ? (
                                <a href={`${isConsolidated(projectData.clientSponsor?.classification)}/${projectData.clientSponsor?.id}/perfil`}>
                                    {`${projectData.clientSponsor?.name} ${projectData.clientSponsor?.firstName || ''} ${projectData.clientSponsor?.lastName || ''}`}
                                </a>
                            ) : '-'
                            }
                        </Card.Text>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <Card.Text className="text-muted fs-6">Aparejador</Card.Text>
                        <Card.Text className="fs-5 text-primary cursor-pointer">
                            {projectData.clientSurveyor?.name !== null && projectData.clientSurveyor?.name !== undefined ? (
                                <a href={`${isConsolidated(projectData.clientSurveyor?.classification)}/${projectData.clientSurveyor?.id}/perfil`}>
                                    {`${projectData.clientSurveyor?.name} ${projectData.clientSurveyor?.firstName || ''} ${projectData.clientSurveyor?.lastName || ''}`}
                                </a>
                            ) : '-'
                            }
                        </Card.Text>
                    </Col>
                    <Col md={4}>
                        <Card.Text className="text-muted fs-6">Jefe de obra</Card.Text>
                        <Card.Text className="fs-5 text-primary cursor-pointer">
                            {projectData.clientConstructionManager?.name !== null && projectData.clientConstructionManager?.name !== undefined ? (
                                <a href={`${isConsolidated(projectData.clientConstructionManager?.classification)}/${projectData.clientConstructionManager?.id}/perfil`}>
                                    {`${projectData.clientConstructionManager?.name} ${projectData.clientConstructionManager?.firstName || ''} ${projectData.clientConstructionManager?.lastName || ''}`}
                                </a>
                            ) : '-'
                            }
                        </Card.Text>
                    </Col>
                    <Col md={4}>
                        <Card.Text className="text-muted fs-6">Proyectista</Card.Text>
                        <Card.Text className="fs-5 text-primary cursor-pointer">
                            {projectData.planner?.name !== null && projectData.planner?.name !== undefined ? (
                                <a href={`${authMenu.users.path}/${projectData.planner?.id}/perfil`}>
                                    {`${projectData.planner?.name} ${projectData.planner?.firstName || ''} ${projectData.planner?.lastName || ''}`}
                                </a>
                            ) : '-'
                            }
                        </Card.Text>
                    </Col>
                </Row>
            </div>

            <div className="p-4 border rounded mb-3">
                <h4 className="mb-3">Ubicación:</h4>
                <Row>
                    <Col md={2}>
                        <Card.Text className="text-muted fs-6">Provincia</Card.Text>
                        <Card.Text className="fs-5">{projectData.province}</Card.Text>
                    </Col>
                    <Col md={2}>
                        <Card.Text className="text-muted fs-6">Población</Card.Text>
                        <Card.Text className="fs-5">{projectData.population}</Card.Text>
                    </Col>
                    <Col md={6}>
                        <Card.Text className="text-muted fs-6">Dirección</Card.Text>
                        <Card.Text className="fs-5">{projectData.address}</Card.Text>
                    </Col>
                    <Col md={2}>
                        <Card.Text className="text-muted fs-6">Código postal</Card.Text>
                        <Card.Text className="fs-5">{projectData.pc}</Card.Text>
                    </Col>
                </Row>
            </div>

            <div className="p-4 border rounded mb-3">
                <h4 className="mb-3">Fechas:</h4>
                <Row>
                    <Col md={2}>
                        <Card.Text className="text-muted fs-6">Inicio de obra</Card.Text>
                        <Card.Text className="fs-5">{moment(projectData.startDate.date).format('DD/MM/YYYY')}</Card.Text>
                    </Col>
                    <Col md={2}>
                        <Card.Text className="text-muted fs-6">Fin de obra</Card.Text>
                        <Card.Text className="fs-5">{moment(projectData.deliveryDate.date).format('DD/MM/YYYY')}</Card.Text>
                    </Col>
                    <Col md={3}>
                        <Card.Text className="text-muted fs-6">Año de construcción</Card.Text>
                        <Card.Text className="fs-5">{projectData.year}</Card.Text>
                    </Col>
                </Row>
            </div>

            <div className="p-4 border rounded mb-3">
                <h4 className="mb-3">Otros datos:</h4>
                <Row>
                    <Col md={3}>
                        <Card.Text className="text-muted fs-6">Referencia catastral</Card.Text>
                        <Card.Text className="fs-5">{projectData.cad_ref}</Card.Text>
                    </Col>
                    <Col md={3}>
                        <Card.Text className="text-muted fs-6">PEM</Card.Text>
                        <Card.Text className="fs-5">{projectData.pem} €</Card.Text>
                    </Col>
                    <Col md={6} className={classNames({ "d-none": !projectData.subsidy })}>
                        <Card.Text className="text-muted fs-6">Subvención</Card.Text>
                        <Card.Text className="fs-5">{projectData?.subsidy?.name}</Card.Text>
                    </Col>
                    <Col md={12} className="mt-2">
                        <Card.Text className="text-muted fs-6">Descripción</Card.Text>
                        <Card.Text className="fs-5">{projectData.description}</Card.Text>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3} className={classNames({ 'd-none': !projectData.fileNumberGMU })}>
                        <Card.Text className="text-muted fs-6">GMU</Card.Text>
                        <Card.Text className="fs-5">{projectData.fileNumberGMU}</Card.Text>
                    </Col>
                    <Col md={3} className={classNames({ 'd-none': !projectData.licenseNumber })}>
                        <Card.Text className="text-muted fs-6">Nº Licencia</Card.Text>
                        <Card.Text className="fs-5">{projectData.licenseNumber}</Card.Text>
                    </Col>
                    <Col md={3} className={classNames({ 'd-none': !projectData.bailNumberRefund })}>
                        <Card.Text className="text-muted fs-6">Nº Devolución fianza</Card.Text>
                        <Card.Text className="fs-5">{projectData.fileNumber}</Card.Text>
                    </Col>
                    <Col md={3} className={classNames({ 'd-none': !projectData.fileNumberUse })}>
                        <Card.Text className="text-muted fs-6">Expdte. utilización</Card.Text>
                        <Card.Text className="fs-5">{projectData.fileNumberUse}</Card.Text>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}