import { createSlice } from "@reduxjs/toolkit";

export interface ProjectState {
    activeTab: string;
}

export const initialState: ProjectState = {
    activeTab: 'info',
};

const projectSlice = createSlice({
    name: 'project_view',
    initialState,
    reducers: {
        changeProjectActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
});

export const { changeProjectActiveTab } = projectSlice.actions;

export default projectSlice.reducer;