import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

const CONTACT_ENDPOINT = '/clients';

export class ContactService extends RestServiceConnection {

    listContacts = async (filter?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONTACT_ENDPOINT + '/list-contact-person',
            data: filter ? filter : {},
        }, true) as AxiosResponse;
        return this;
    }

    // TODO: Lo de debajo de aquí no se está usando
    getContactById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONTACT_ENDPOINT + '/get-contact-person',
            data: {
                contactPerson: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createContact = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONTACT_ENDPOINT + '/create-contact-person',
            data: {
                name: data.name,
                company: data.company,
                first_name: data.first_name,
                last_name: data.last_name,
                nif: data.nif,
                telephone: data.telephone,
                email: data.email,
                position: data.position,

            }
        }, true) as AxiosResponse;
        return this;
    }

    editContact = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONTACT_ENDPOINT + '/edit-contact-person',
            data: {
                contactPerson: data.contactPerson,
                name: data.name,
                company: data.company,
                first_name: data.first_name,
                last_name: data.last_name,
                nif: data.nif,
                telephone: data.telephone,
                email: data.email,
                position: data.position,
            }
        }, true) as AxiosResponse;
        return this;
    }

    deleteContact = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONTACT_ENDPOINT + '/delete-contact-person',
            data: {
                contactPerson: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    exportContacts = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONTACT_ENDPOINT + '/export',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: { ...filters },
        }, true);
        return this;
    }

    importContacts = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONTACT_ENDPOINT + '/import',
            data: { data },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

}