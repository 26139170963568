import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { RoleService } from "../../../services/auth/roleService";
import { Role } from "../../../type/role-type";
import ErrorMessage from "../../../components/ErrorMessage";
import RoleForm from "../RoleForm";

const RoleEdit: FC = () => {

  const { id } = useParams<{ id: string }>();

  const [dataRole, loadingRole, errorRole] = useFetch(useCallback(async () => {
    const roleService = new RoleService();
    const response = await roleService.getRoleById(id as string);
    return response.getResponseData() as Role;
  }, [id]));

  const getContent = () => {
    if (loadingRole) return <Spinner />;

    if (errorRole) return <ErrorMessage error={errorRole} />;

    if (dataRole !== null) {
      const roleData = {
        name: dataRole.name !== null ? dataRole.name : "",
        description: dataRole.description !== null ? dataRole.description : "",
        company: dataRole.company !== null ? dataRole.company.id : "",
        permissions: dataRole.permissions !== null ? dataRole.permissions : [],
      };

      return <RoleForm isLoading={false} roleData={roleData} />;
    }
  };

  return <>{getContent()}</>;
};

export default RoleEdit;