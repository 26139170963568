import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import StatusDropdown from "../../../components/StatusDropdown";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { ClientsApiResponse } from "../../../type/client-type";
import AsyncImg from "../../../components/AsyncImg";
import { ClientService } from "../../../services/clients/clientService";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import ClientFilters from "./clients-options/ClientFilters";
import { ClientMenu } from "../../../menu";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const ClientListConsolidated = () => {

  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const clientService = new ClientService();

  const [changingStatus, setChangingStatus] = useState<string[]>([]);


  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await clientService.getClients(filters);
    return response.getResponseData() as ClientsApiResponse;
  }, [filters]));

  const handleDelete = async (id: string) => {
    try {
      const response = (await clientService.deleteClient(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success("Cliente eliminado");
        }, 100);
      } else {
        toast.error(response.message || "Error al eliminar el cliente");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const toggleClientStatus = async (id: string, status: boolean, toggleStatus: Function) => {
    try {
      setChangingStatus([...changingStatus, id]);
      const response = (await clientService.toggleClientStatus(id, status)).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success(status ? "Se ha activado el cliente" : "Se ha desactivado el cliente");
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toast.error(response.message);
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
      toast.error(error.message);
    }
  };

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data) {
      let apiData = data as ClientsApiResponse;

      return (
        <CustomTable
          title="Clientes"
          data={apiData.clients ? apiData.clients : null}
          defaultLimit={filters.limit || 50}
          defaultOrder={filters.filter_order || undefined}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: (apiData as ClientsApiResponse) ? apiData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "",
              keyValue: "profileImg",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className="user-avatar">
                      <AsyncImg
                        id={element.profileImg ? element.profileImg.id : null}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre/RS",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-primary">
                    <a href={`${ClientMenu.clients.path}/${element.id}/perfil`}>
                      {element.name !== null
                        ? element.name + ' ' + (element.firstName ? element.firstName : "") + ' ' + (element.lastName ? element.lastName : "")
                        : "-"
                      }
                    </a>
                  </div>
                );
              },
            },
            {
              name: "NIF / CIF",
              keyValue: "cifNif",
              render: (element: any) => {
                return <>{element.cifNif || "-"}</>;
              },
            },
            {
              name: "Provincia",
              keyValue: "province",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return <>{element.province || "-"}</>;
              },
            },
            {
              name: "Población",
              keyValue: "population",
              className: "text-center",
              render: (element: any) => {
                return <>{element.population || "-"}</>;
              },
            },
            {
              name: "Contacto",
              keyValue: "contactPerson",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              render: (element: any) => {
                return (
                  <>{element.clientContactPerson?.name ? `${element.clientContactPerson?.name}  ${element.clientContactPerson?.firstName || ''} ${element.clientContactPerson?.lastName || ''}` : '-'}</>
                );
              },
            },
            {
              name: "Teléfono del Contacto",
              keyValue: "contactPhone",
              render: (element: any) => {
                return <>{element.clientContactPerson?.telephone || "-"}</>;
              },
            },
            {
              name: "Categoria",
              keyValue: "category",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return <>{element.clientCategory?.name || "-"}</>;
              },
            },
            {
              name: "Organización",
              keyValue: "company",
              className: userCan("list", "companies") ? "d-bolck text-center" : "d-none",
              isVisible: userCan("list", "companies") ? true : false,
              render: (element: any) => {
                return <>{element.company?.name || "-"}</>;
              },
            },
            {
              name: "Estado",
              keyValue: "active",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center" key={element.id}>
                    <StatusDropdown
                      className="fs-6"
                      disabled={changingStatus.includes(element.id)}
                      itemId={element.id}
                      status={element.active}
                      change={toggleClientStatus}
                    />
                  </div>
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
          ]}
          actions={[
            {
              title: "Editar",
              buttonType: "icon",
              icon: "Edit",
              additionalClasses: `text-primary ${userCan("edit", "clients") ? "" : "d-none"}`,
              description: "Editar perfil de cliente",
              callback: (item: any) => {
                if (!userCan("edit", "clients")) return;
                navigate(`${item.id}/edicion`);
              },
            },
            {
              title: "Eliminar",
              buttonType: "icon",
              icon: "Delete",
              additionalClasses: `text-danger ${userCan("delete", "clients") ? "" : "d-none"}`,
              description: "Eliminar cliente",
              callback: (item: any) => {
                if (!userCan("delete", "clients")) return;
                handleConfirmationAlert({
                  title: "Eliminar Cliente",
                  text: "¿Está seguro que desea eliminar el cliente?",
                  icon: "warning",
                  onConfirm: () => { handleDelete(item.id) },
                });
              },
            },
            {
              title: "Sin permisos",
              buttonType: 'icon',
              icon: "Block",
              additionalClasses: `text-danger ${(userCan('edit', 'clients') || userCan('delete', 'clients')) ? 'd-none' : ''}`,
              description: "No tiene permisos para editar ni eliminar",
              callback: (item: any) => { },
            }
          ]}
        />
      )
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Clientes</CardTitle>
        </SubHeaderLeft>
        <SubHeaderRight>
          <ClientFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} name="Cliente" />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card>
          <CardBody className="table-responsive">
            <Fragment>{getContent()}</Fragment>
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default ClientListConsolidated;