import Footer from '../../../layout/Footer/Footer';
import Icon from '../../../components/icon/Icon';

const DefaultFooter = () => {
	return (
		<Footer>
			<p className='m-0'>Hand-crafted and made with <Icon icon='Favorite' color='primary' size='lg' /> by <span className='text-primary'>Studio128k</span></p>
			<p className='m-0 text-primary'>MAD - Arquitectura &copy;</p>
		</Footer>
	);
};

export default DefaultFooter;
