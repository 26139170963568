import { FC, Fragment, useCallback } from "react";
import Card, { CardTabItem } from "../../../components/bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { changeClientActiveTab } from "../../../redux/clientSlice";
import { Client } from "../../../type/client-type";
import ClientInfoHeader from "./ClientInfoHeader";
import Budgets from "./components/Budgets";
import Projects from "./components/Projects";
import Notes from "./components/Notes";
import { ContactInfo } from "./components/ContactInfo";
import { useParams } from "react-router-dom";
import { ClientService } from "../../../services/clients/clientService";
import useFetch from "../../../hooks/useFetch";

interface ClientTabProps {
    clientData: Client;
}

const ClientTab: FC<ClientTabProps> = ({ clientData }) => {

    const dispatch = useDispatch();
    const activeTab = useSelector((state: any) => state.client_profile?.activeTab);
    const { id = '' } = useParams<{ id: string }>();

    const clientService = new ClientService();

    const [client, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await clientService.getClientById(id as string);
        return response.getResponseData() as Client;
    }, [id]));

    const dispatchSelectedTab = (tabId: string) => {
        dispatch(changeClientActiveTab(tabId));
    };

    return (
        <Fragment>
            <div className="row sticky-top w-100">
                <ClientInfoHeader clientData={clientData} />
            </div>

            <div className="row w-100">
                <div className="row">
                    <Card hasTab>
                        <CardTabItem id='contact' title='Información' icon='Info' onSelected={dispatchSelectedTab} isActive={activeTab === "contact"}>
                            <ContactInfo />
                        </CardTabItem>

                        <CardTabItem id='budgets' title='Presupuestos' icon='CalendarToday' onSelected={dispatchSelectedTab} isActive={activeTab === "budgets"}>
                            <Budgets />
                        </CardTabItem>

                        {/* <CardTabItem id='projects' title='Proyectos' icon='Work' onSelected={dispatchSelectedTab} isActive={activeTab === "projects"}>
                            <Projects />
                        </ CardTabItem> */}

                        <CardTabItem id='notes' title='Observaciones' icon='Notes' onSelected={dispatchSelectedTab} isActive={activeTab === "notes"}>
                            <Notes clientId={id} noteValue={(client?.note !== undefined && client?.note !== null) ? client.note : ''} service={clientService} refetch={refetch} />
                        </CardTabItem>
                    </Card>
                </div>
            </div>
        </Fragment>
    )
}

export default ClientTab;