import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../components/bootstrap/Alert';
import { LoginContainer } from './LoginContainer';
import { LoginForm } from './LoginForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { login } from '../../redux/authSlice';
import { toast } from 'react-toastify';
import { dashboardPagesMenu } from '../../menu';

export const Login: FC = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { isAuthenticated, loading, error } = useSelector((state: RootState) => state.auth);

	const handleLogin = async (username: string, password: string) => {
		const response = await dispatch(login({ email: username, password: password }));

		if (response) {
			navigate(dashboardPagesMenu.dashboard.path)
		} else {
			setTimeout(() => {
				toast.error('Credenciales incorrectas');
			}, 100);
		}
		//navigate(authMenu.users.path)
	};

	return (
		<LoginContainer>

			{/* Alert for login errors taking api msg */}
			{
				error && <Alert color='primary' isLight icon='Warning'>Las credenciales proporcionadas son incorrectas. Por favor, verifícalas e inténtalo nuevamente.</Alert>
			}

			{/* Form section to wrapp all formik logic */}
			<LoginForm isLoading={loading} submit={handleLogin} />

		</LoginContainer>
	);
};