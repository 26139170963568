import { useCallback, useContext, useEffect, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { ProjectsApiResponse } from "../../../type/project-type";
import { useNavigate } from "react-router-dom";
import { ProjectService } from "../../../services/projects/projectService";
import EmptyTable from "../../../components/EmptyTable";
import ProjectFilters from "./project-options/ProjectFilters";
import { FinancesMenu } from "../../../menu";
import StatusesDropdown from "../../../components/dropdowns/StatusesDropdown";
import { Status } from "../../../type/status-type";
import moment from "moment";
import { StatusService } from "../../../services/status/statusService";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import Spinner from "../../../components/bootstrap/Spinner";

export interface IProjectFilters {
    company: string;
    active: number;
    sponsor: string;
    representative: string;
    surveyor: string;
    planner: string;
    constructioManager: string;
}

const ProjectList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const projectService = new ProjectService();

    const [projectStatuses, setProjectStatuses] = useState<Status[]>([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize, checkIfUrlHasFilters } = useFiltersPR();

    useEffect(() => {
        checkIfUrlHasFilters();
    }, []);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await projectService.listProjects(filters);
        return response.getResponseData() as ProjectsApiResponse;
    }, [filters]));

    const getStatusList = async (orientedTo: string): Promise<Status[] | undefined> => {
        //if (!userCan("down_status", "statuses")) return;
        const response = await (await (new StatusService()).listStatuses({ filter_filters: { oriented_to: orientedTo }, limit: 999999 })).getResponseData();
        if (response.success) {
            return response.data.statuses;
        } else {
            return [];
        }
    }

    // Carga los estados de los proyectos
    const fetchStatuses = async () => {
        let projectStatusList = await getStatusList("project");
        setProjectStatuses(projectStatusList !== undefined ? projectStatusList : []);
    };

    // Crea la hoja de control del proyecto
    const _handleGenerateControlSheet = async (codeIdentifier: string, id: string) => {
        try {
            const response = (await projectService.generateControlSheet(id));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', codeIdentifier + '_control-sheet' + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar el presupuesto');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al descargar el presupuesto');
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = (await projectService.deleteProject(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Proyecto eliminado correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar el proyecto");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Carga los estados de los proyectos al montar el componente
    useEffect(() => {
        fetchStatuses();
    }, []);

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>;

        if (error) return <ErrorMessage /* error={error} */ />;

        if (data && data.projects?.length > 0) {
            let apiData = data as ProjectsApiResponse;

            return (
                <CustomTable
                    title="Proyectos"
                    data={apiData ? apiData.projects : null}
                    defaultLimit={filters.limit || 50}
                    defaultOrder={filters.filter_order || undefined}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as ProjectsApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Estado",
                            keyValue: "currentStatus",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (item: any) => {
                                return (
                                    <div className="p-0" key={item.id}>
                                        <StatusesDropdown
                                            onChange={() => { refetch && refetch() }}
                                            key={item.id + "-status"}
                                            itemId={item.id}
                                            statuses={projectStatuses}
                                            status={
                                                item.projectStatus != null
                                                    ? item.projectStatus
                                                    : null
                                            }
                                            additionalInfo={
                                                item.projectStatusDate
                                                    ? moment(item.projectStatusDate.date).format("DD/MM/yyyy HH:mm")
                                                    : undefined
                                            }
                                            orientedTo={"project"}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Código",
                            keyValue: "code_identifier",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="text-primary text-center cursor-pointer">
                                        <a href={`${FinancesMenu.project.path}/${element.id}/vista`}>
                                            {element.codeIdentifier}
                                        </a>
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Dirección',
                            keyValue: 'address',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.budget?.address},
                                        {(element.population && element.population !== element.province)
                                            ? ' ' + element.population + ', ' + element.province
                                            : ' ' + element.province
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Promotor",
                            keyValue: "sponsor",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {
                                            element.clientSponsor?.name !== null ? (
                                                element.clientSponsor?.name + (element.clientSponsor?.firstName ? ' ' + element.clientSponsor.firstName : '') + (element.clientSponsor?.lastName ? ' ' + element.clientSponsor.lastName : '')
                                            ) : '-'
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Representante",
                            keyValue: "representative",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {
                                            element.clientRepresentative?.name !== null ? (
                                                element.clientRepresentative?.name + (element.clientRepresentative?.firstName ? ' ' + element.clientRepresentative.firstName : '') + (element.clientRepresentative?.lastName ? ' ' + element.clientRepresentative.lastName : '')
                                            ) : '-'
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            name: 'Subvención',
                            keyValue: 'subsidy',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.subsidy?.name || '-'}
                                    </div>
                                )
                            }
                        },
                        {
                            name: 'GMU',
                            keyValue: 'fileNumberGMU',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            cellClassName: "text-center",
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: (userCan("list", "companies") ? "text-center" : "d-none"),
                            isVisible: (userCan("list", "companies") ? true : false),
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.company.name || "-"}
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        /* {
                            title: 'Hoja control',
                            buttonType: 'icon',
                            icon: 'Download',
                            additionalClasses: 'text-primary',
                            description: "Generar hoja de control",
                            click: (item: any) => {
                                _handleGenerateControlSheet(item.codeIdentifier, item.id);
                            },
                        }, */
                        {
                            title: "Editar",
                            buttonType: 'icon',
                            icon: 'Edit',
                            additionalClasses: `text-primary ${userCan("edit", "project") ? "" : "d-none"}`,
                            description: "Editar proyecto",
                            callback: (item: any) => {
                                if (userCan("edit", "project")) return navigate(`${item.id}/edicion`);
                            },
                        },
                        {
                            title: "Eliminar",
                            buttonType: 'icon',
                            icon: 'Delete',
                            additionalClasses: `text-danger ${userCan("delete", "project") ? "" : "d-none"}`,
                            description: "Eliminar proyecto",
                            callback: (item: any) => {
                                if (!userCan("delete", "project")) return;
                                handleConfirmationAlert({
                                    title: "Eliminar proyecto",
                                    text: "¿Está seguro que desea eliminar el proyecto?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                        {
                            title: "Sin permisos",
                            buttonType: 'icon',
                            icon: "Block",
                            additionalClasses: `text-danger ${(userCan('edit', 'project') || userCan('delete', 'project')) ? 'd-none' : ''}`,
                            description: "No tiene permisos para editar ni eliminar",
                            callback: (item: any) => { },
                        }
                    ]}
                />
            );
        } else {
            return <EmptyTable row="proyectos" />;
        }
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Proyectos</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ProjectFilters updateFilters={updateFilters} updatePageSize={updatePageSize} filters={filters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <>{getContent()}</>
                    </CardBody>
                </Card>
            </Page>
        </>
    );
};

export default ProjectList;