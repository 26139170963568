import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

export const STRATEGY_ENDPOINT = '/clients';

export class StrategyService extends RestServiceConnection {

    lisStrategies = async (filter?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STRATEGY_ENDPOINT + '/list-origin',
            data: filter ? filter : {},
        }, true) as AxiosResponse;
        return this;
    }

    getStrategyById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STRATEGY_ENDPOINT + '/get-origin',
            data: {
                origin: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createStrategy = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STRATEGY_ENDPOINT + '/create-origin',
            data: {
                name: data.name,
                company: data.company,
                description: data.description ? data.description : null,
            }
        }, true) as AxiosResponse;
        return this;
    }

    editStrategy = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STRATEGY_ENDPOINT + '/edit-origin',
            data: {
                origin: data.origin,
                name: data.name,
                company: data.company,
                description: data.description ? data.description : null,
            }
        }, true) as AxiosResponse;
        return this;
    }

    deleteStrategy = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STRATEGY_ENDPOINT + '/delete-origin',
            data: {
                origin: id
            }
        }, true) as AxiosResponse;
        return this;
    }

}