import { Fragment, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import StatusDropdown from "../../../components/StatusDropdown";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import UsersFilters from "./companies-options/CompaniesFilters";
import { CompanyService } from "../../../services/companies/organizationService";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { CompaniesApiResponse } from "../../../type/company-type";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import AsyncImg from "../../../components/AsyncImg";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

export interface ICompanyFilters {
  role?: any;
}

const companyFilters: ICompanyFilters = {
  role: '',
};

const CompaniesList = () => {
  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(companyFilters);

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    if (userCan('list', 'companies')) {
      const companyService = new CompanyService();
      const response = await companyService.getCompanies(filters);
      return response.getResponseData() as CompaniesApiResponse;
    } else {
      return null;
    }
  }, [filters]));

  const handleDelete = async (id: string) => {
    try {
      const response = await (await (new CompanyService()).deleteCompany(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success('Organización eliminada');
        }, 100)
      } else {
        response.data.errors.forEach((error: any) => {
          toast.error(error.message);
        });
      }
    } catch (error: any) {
      toast.error(error);
    }
  }

  const toggleCompanyStatus = async (id: string, status: boolean, toggleStatus: Function) => {
    try {
      setChangingStatus([...changingStatus, id]);
      const response = await (await new CompanyService().toggleCompanyStatus(id, status)).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success(status ? 'Se ha activado la organización' : 'Se ha desactivado la organización');
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data) {
      let apiData = data as CompaniesApiResponse;

      return (
        <CustomTable
          title="Organizaciones"
          data={apiData.companies ? apiData.companies : null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: apiData as CompaniesApiResponse ? apiData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "",
              keyValue: "logo",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className='user-avatar'>
                      <AsyncImg id={element.logo ? element.logo.id : null} styles={'avatar-contain'} />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-primary fw-bold" onClick={() => { navigate(`${element.id}/perfil`) }}>
                    {element.name}
                  </div>
                )
              },
            },
            {
              name: "Dirección",
              keyValue: "address",
              render: (element: any) => {
                return (
                  <>
                    {element.address || "-"}
                  </>
                );
              },
            },
            {
              name: "CIF",
              keyValue: "cif",
              render: (element: any) => {
                return (
                  <>
                    {element.cif || "-"}
                  </>
                );
              },
            },
            {
              name: "Saldo",
              keyValue: "initialBankBalance",
              render: (element: any) => {
                return (
                  <>
                    {element.initialBankBalance ? element.initialBankBalance + " €" : "-"}
                  </>
                );
              },
            },
            {
              name: "Cuenta bancaria",
              keyValue: "bankAccount",
              render: (element: any) => {
                return (
                  <>
                    {element.bankAccount || "-"}
                  </>
                );
              },
            },
            {
              name: "Nº de roles",
              keyValue: "role",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    {element.roles.length || "-"}
                  </div>
                );
              },
            },
            {
              name: "Estado",
              keyValue: "active",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <StatusDropdown
                      disabled={changingStatus.includes(element.id)}
                      itemId={element.id}
                      status={element.active}
                      change={toggleCompanyStatus}
                    />
                  </div>
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={[
            {
              title: "Editar",
              buttonType: 'icon',
              icon: "Edit",
              additionalClasses: `text-primary ${userCan("edit", "companies") ? "" : "d-none"}`,
              description: "Editar organización",
              callback: (item: any) => {
                if (userCan("edit", "companies")) return navigate(`${item.id}/edicion`);
              },
            },
            {
              title: "Eliminar",
              buttonType: 'icon',
              icon: "Delete",
              additionalClasses: `text-danger ${userCan("delete", "companies") ? "" : "d-none"}`,
              description: "Eliminar organización",
              callback: (item: any) => {
                if (!userCan("delete", "companies")) return;
                handleConfirmationAlert({
                  title: "Eliminar organización",
                  text: "¿Está seguro que desea eliminar la organización?",
                  icon: "warning",
                  onConfirm: () => {
                    handleDelete(item.id);
                  }
                })
              },
            },
            {
              title: "Sin permisos",
              buttonType: 'icon',
              icon: "Block",
              additionalClasses: `text-danger ${(userCan('edit', 'companies') || userCan('delete', 'companies')) ? 'd-none' : ''}`,
              description: "No tiene permisos para editar ni eliminar",
              callback: (item: any) => { },
            }
          ]}
        />
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Organizaciones</CardTitle>
          <SubheaderSeparator />
          <Button
            color="primary"
            icon="Add"
            isLight
            onClick={() => { navigate("creacion") }}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <UsersFilters updateFilters={updateFilters} updatePageSize={updatePageSize} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card>
          <CardBody className="table-responsive">
            {getContent()}
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default CompaniesList;