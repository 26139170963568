import React, { useContext, useState } from "react";
import useFilters, { FilterOptions, FilterOrders } from "../../../hooks/useFilters";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { changeStorageViewMode } from "../../../redux/dashboardSlice";

type DashboardProviderProps = {
    children: React.ReactNode
    defaultFilters?: FilterOptions
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
}

type DashboardContextData = {
    viewMode: "Mes" | "Semana" | "Día",
    changeViewMode: (view: "Mes" | "Semana" | "Día") => void,
    filters: FilterOptions | any,
    updateFilters: (filters: any) => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    dashboardDates: { startDate: string | null, endDate: string | null },
    setDashboardDates: (dates: { startDate: string | null, endDate: string | null }) => void,
}

const DashboardContext: React.Context<DashboardContextData> = React.createContext<DashboardContextData>({
    viewMode: 'Mes',
    changeViewMode: (view: "Mes" | "Semana" | "Día") => { },
    filters: {} as FilterOptions | any,
    updateFilters: (filters: any) => { },
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    dashboardDates: { startDate: null, endDate: null },
    setDashboardDates: (dates: { startDate: string | null, endDate: string | null }) => { },
});

const DashboardProvider: React.FC<DashboardProviderProps> = ({ children, defaultFilters={} as FilterOptions, defaultOrders=[] as FilterOrders, defaultPage=1, defaultPageSize=999999 }) => {
    //  Redux connection
    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.dashboard.viewMode);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [dashboardDates, setDashboardDates] = useState<{ startDate: string | null, endDate: string | null }>({ startDate: null, endDate: null });

    const changeViewMode = (view: "Mes" | "Semana" | "Día") => {
        dispatch(changeStorageViewMode(view));
    };

    return (
        <DashboardContext.Provider value={{
            viewMode,
            changeViewMode,
            filters,
            updateFilters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,
            dashboardDates,
            setDashboardDates,
        }}>
            {children}
        </DashboardContext.Provider>
    );
}

export { DashboardProvider, DashboardContext };

export function useDashboard() {
    return useContext(DashboardContext);
}