import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { CompanyService } from "../../../services/companies/organizationService";
import { Company } from "../../../type/company-type";
import CompanyForm from "../CompanyForm";
import { toast } from "react-toastify";
import moment from "moment";

const MyCompanyEdit: FC = () => {

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, loading] = useFetch(useCallback(async () => {
    const companyService = new CompanyService();
    const response = await companyService.getMyCompany(id as string);
    return response.getResponseData() as Company;
  }, [id]));

  const handleUpdate = async (values: any) => {
    setIsLoading(true);
    const formData = new FormData();
    for (let value in values) {
      if (values[value]) {
        formData.append(value, values[value]);
      }
    }
    formData.append("company", id as string)
    let hasImg = formData.get("profile_img") ? true : false;

    try {
      const response = await (await (new CompanyService()).editMyCompany(formData, hasImg)).getResponseData();
      if (response.success) {
        navigate(-1);
        setTimeout(() => {
          toast.success('Organización editada correctamente');
        }, 100)
      } else {
        response.data.errors.forEach((error: any) => {
          toast.error(error.message);
        });
      }
    } catch (error: any) {
      toast.error(error.message || "Error al editar la organización");
    } finally {
      setIsLoading(false);
    }
  };

  const getContent = () => {
    if (loading) return <div className="text-center mt-5" ><Spinner /></div>;

    if (data !== null) {
      const companyData = {
        cif: data.cif !== null ? data.cif : "",
        name: data.name !== null ? data.name : "",
        address: data.address !== null ? data.address : "",
        bankAccount: data.bankAccount !== null ? data.bankAccount : "",
        legalText: data.legalText !== null ? data.legalText : "",
        initialBankBalance: data.initialBankBalance !== null ? data.initialBankBalance : 0,
        initialBankBalanceDate: data.initialBankBalanceDate !== null ? data.initialBankBalanceDate.date.split(' ')[0] : moment().format('YYYY-MM-DD'),
      };

      return <CompanyForm isLoading={isLoading} submit={handleUpdate} companyData={companyData} logo={data.logo} />;
    }
  };

  return (
    <>{getContent()}</>
  );
};

export default MyCompanyEdit;