import { FC, useCallback, useContext, useEffect, useState } from "react";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import { TaskService } from "../../../services/tasks/taskService";
import { TasksApiResponse } from "../../../type/task-type";
import { authMenu } from "../../../menu";
import moment from "moment";
import Checks from "../../../components/bootstrap/forms/Checks";
import useTruncateString from "../../../hooks/useTruncateString";
import TaskModalForm from "../../tasks/TaskModalForm";
import Button from "../../../components/bootstrap/Button";
import CreateProjectTask from "./CreateProjectTask";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import ColorPicker from "../../../components/ColorPicker";
import { useTasksCalendar } from "../../calendar/providers/TasksCalendarProvider";

interface IListTasksProps {
    projectId?: string;
};

export interface ITaskFilters {
    is_finished?: number;
    project?: string;
};

const ListTasks: FC<IListTasksProps> = ({ projectId }) => {

    const { refetchTasks, filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useTasksCalendar();
    const { userCan } = useContext(PrivilegeContext);
    const { truncateString } = useTruncateString();

    const taskService = new TaskService();

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);
    const [finishedTasks, setFinishedTasks] = useState<{ id: string, isFinished: boolean }[]>([]);
    const [createTask, setCreateTask] = useState(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await taskService.listTasks(filters);
        return response.getResponseData() as TasksApiResponse;
    }, [filters]));

    const getTaskStatus = (taskId: string) => {
        const task = finishedTasks.find((task) => task.id === taskId);
        return task ? task.isFinished : false;
    };

    const _handleIsFinished = async (taskId: string) => {
        try {
            const response = await (await taskService.updateTaskStatus(taskId)).getResponseData();
            if (response.success) {
                const task = finishedTasks.find((task) => task.id === taskId);

                // Si existe la tarea en el array de finishedTasks, se cambia el estado
                if (task) {
                    const newFinishedTasks = finishedTasks.map((task) => {
                        if (task.id === taskId) {
                            return { id: taskId, isFinished: !task.isFinished };
                        } else {
                            return task;
                        }
                    });
                    setFinishedTasks(newFinishedTasks);
                } else {
                    const newFinishedTasks = [...finishedTasks, { id: taskId, isFinished: true }];
                    setFinishedTasks(newFinishedTasks);
                }

                refetch();
                refetchTasks(); // Update calendar
                toast.success('Estado de la tarea actualizado');
            } else {
                toast.error(response.message || "Error al finalizar la tarea");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await taskService.deleteTask(id)).getResponseData();
            if (response.success) {
                refetch();
                refetchTasks(); // Update calendar
                setTimeout(() => {
                    toast.success('Tarea eliminada correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar la tarea");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            setSelectedItem(null);
            setShow(false);
            setModalOpen(false);
            refetch();
            toast.success(message);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    // Update filters when projectId changes
    useEffect(() => {
        updateFilters({ project: projectId });
        updateFilterOrder('finishDate', 'asc');
        updatePageSize(100);
    }, [projectId]);

    useEffect(() => {
        if (data) {
            let apiData = data as TasksApiResponse;
            let newFinishedTasks: { id: string, isFinished: boolean }[] = [];

            apiData.tasks?.forEach((task: any) => {
                newFinishedTasks.push({ id: task.id, isFinished: task.isFinished });
            });

            setFinishedTasks(newFinishedTasks);
        }
    }, [data]);

    return (
        <div className="row">
            {error && <ErrorMessage error={error} />}

            <div className="col-md-11">
                <CustomTable
                    title="Tareas"
                    data={data ? data.tasks : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data as TasksApiResponse ? data.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-center" onClick={() => {
                                        setSelectedItem(element);
                                        setModalOpen(true);
                                        setShow(false);
                                    }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: 'Dirección',
                            keyValue: 'address',
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.project?.address || '-'}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Usuario",
                            keyValue: "user",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="d-flex align-items-center">
                                        <div key={element.id + element.user.id}>
                                            <ColorPicker color={element.user.color} item={element} disabled circleSize='1' />
                                        </div>
                                        <a href={`${authMenu.users.path}/${element.user.id}/perfil`} className="ms-2 cursor-pointer text-primary">
                                            {element.user.name + ' ' + (element.user.firstName ? element.user.firstName : "") + ' ' + (element.user.lastName ? element.user.lastName : "")}
                                        </a>
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Fecha",
                            keyValue: "date",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {moment(element.date.date).format('DD/MM/YYYY')}
                                    </div>
                                )
                            }
                        },
                        {
                            name: 'Fecha de fin',
                            keyValue: 'finishDate',
                            className: 'text-center',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.finishDate ? moment(element.finishDate.date).format('DD/MM/YYYY HH:mm') : ''}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Descripción",
                            keyValue: "comments",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="text-center">
                                        {element.comments ? truncateString(element.comments, 10) : '-'}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Finalizada",
                            keyValue: "finishDate",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <Checks
                                            type="switch"
                                            checked={getTaskStatus(element.id)}
                                            onChange={() => { _handleIsFinished(element.id) }}
                                        />
                                    </div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            buttonType: 'icon',
                            icon: "Edit",
                            additionalClasses: `text-primary ${userCan("edit", "tasks") ? "" : "d-none"}`,
                            description: "Editar tarea",
                            callback: (item: any) => {
                                if (!userCan("edit", "tasks")) return;
                                setSelectedItem(item);
                                setModalOpen(true);
                                setShow(false);
                            },
                        },
                        {
                            title: "Eliminar",
                            buttonType: 'icon',
                            icon: "Delete",
                            additionalClasses: `text-danger ${userCan("delete", "tasks") ? "" : "d-none"}`,
                            description: "Eliminar tarea",
                            callback: (item: any) => {
                                if (!userCan("delete", "tasks")) return;
                                handleConfirmationAlert({
                                    title: "Eliminar tarea",
                                    text: "¿Está seguro que desea eliminar el tarea?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                        {
                            title: "Sin permisos",
                            buttonType: 'icon',
                            icon: "Block",
                            additionalClasses: `text-danger ${(userCan('edit', 'tasks') || userCan('delete', 'tasks')) ? 'd-none' : ''}`,
                            description: "No tiene permisos para editar ni eliminar",
                            callback: (item: any) => { },
                        }
                    ]}
                />
            </div>

            <div className="col-md-1 text-end">
                <Button
                    color="primary"
                    title="Crear tarea"
                    icon="Add"
                    isLight
                    className={`${userCan("create", "tasks") ? "" : "d-none"}`}
                    onClick={() => {
                        if (!userCan("create", "tasks")) return;
                        setCreateTask(true);
                    }}
                />
            </div>

            {modalOpen && (<TaskModalForm isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />)}
            {createTask && (<CreateProjectTask isOpen={createTask} setIsOpen={setCreateTask} refetch={refetch} />)}
        </div>
    );
};

export default ListTasks;