import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { UserService } from "../../../services/users/userService";
import { loadUserFromLocalStorage } from "../../../utils/jwt";
import { CompanyService } from "../../../services/companies/organizationService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Select from "../../../components/bootstrap/forms/Select";
import Button from "../../../components/bootstrap/Button";
import * as yup from 'yup';
import { TaxService } from "../../../services/taxes/taxService";

interface CreateModalProps {
    data?: any;
    isOpen: boolean;
    show?: boolean;
    setIsOpen(...args: unknown[]): unknown;
    onClose: (type: number, message?: string, errors?: any) => void;
}

interface TaxForm {
    name: string;
    value: string;
    company: string;
}

const validationSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    value: yup.number().required('El porcentaje es obligatorio').min(0, 'El porcentaje debe ser mayor a 0').max(100, 'El porcentaje debe ser igual o menor que 100'),
    company: yup.string().required('La organización es obligatoria'),
});

const TaxModalForm: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');
    const taxService = new TaxService();

    const [company, setCompany] = useState("");
    const [companyList, setCompanyList] = useState([]);

    const _handleCreateTax = async (values: any) => {
        try {
            const response = await (await taxService.createTax(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear el impuesto');
        }
    };

    const _handleEditTax = async (values: any) => {
        try {
            const response = await (await taxService.editTax(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar el impuesto');
        }
    };

    const getCompany = async () => {
        const userService = new UserService;
        const response = (await userService.getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData()
        if (response.success) {
            setCompany(response.data.userRoles[0].company.id)
        }
    };

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    };

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            taxes: data?.id || '',
            name: data?.name || '',
            value: data?.value || '',
            company: data?.company?.id || company,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.company != '') {
                if (data != null) {
                    _handleEditTax(values)
                } else {
                    _handleCreateTax(values);
                }
            }
        },
    });

    const verifyClass = (inputFieldID: keyof TaxForm) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : "";
    };

    const showErrors = (inputFieldID: keyof TaxForm) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    useEffect(() => {
        if (!isSuperAdmin) {
            getCompany();
        } else {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size='sm' titleId='Nuevo impuesto'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_tax'>{show ? 'Ver impuesto' : data ? 'Editar impuesto' : 'Crear impuesto'}</ModalTitle>
            </ModalHeader>

            {
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            {
                                isSuperAdmin && (
                                    <FormGroup requiredInputLabel id='company' label='Organización' className='col-md-12'>
                                        <Select id="company" disabled={show ? true : false} list={getCompanyList()} onChange={formik.handleChange} value={formik.values.company || ''} ariaLabel='Select Companies' placeholder='Elegir Organización...' />
                                    </FormGroup>
                                )
                            }

                            <FormGroup requiredInputLabel id='name' label='Nombre:' className="col-md-12">
                                <Input id='name' value={formik.values.name} disabled={show ? true : false} autoComplete="off" onChange={(e: any) => formik.setFieldValue('name', e.target.value)} className={verifyClass('name')} />
                                {showErrors('name')}
                            </FormGroup>

                            <FormGroup requiredInputLabel id='value' label='Porcentaje:' className="col-md-12">
                                <Input type="number" value={formik.values.value} disabled={show ? true : false} autoComplete="off" onChange={(e: any) => formik.setFieldValue('value', e.target.value)} className={verifyClass('value')} />
                                {showErrors('value')}
                            </FormGroup>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'}>
                            {data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>
            }
        </Modal>
    )
}

export default TaxModalForm;