import React, { FC, Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider'
import { CompanyService } from '../../../../services/companies/organizationService'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import SearchableSelect from '../../../../components/selector/SearchableSelect'
import useFetch from '../../../../hooks/useFetch'
import { ClientService } from '../../../../services/clients/clientService'
import { UserService } from '../../../../services/users/userService'
import { UserApiResponse } from '../../../../type/user-type'
import CustomSearchSelect from '../../../../components/selector/CustomSearchSelect'
import { StatusService } from '../../../../services/status/statusService'
import { Status } from '../../../../type/status-type'
import { ProjectService } from '../../../../services/projects/projectService'
import Input from '../../../../components/bootstrap/forms/Input'

interface IProjectsFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: any;
    filters: any;
}

const ProjectFilters: FC<IProjectsFiltersProps> = ({ updateFilters, updatePageSize, filters, resetFilters }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const [filterMenu, setFilterMenu] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState<any>(filters.filter_filters.company || '');
    const [subsidiesSelected, setSubsidiesSelected] = useState<any>([]);

    const [clientSearch, setClientSearch] = useState('');
    const [clientOptions, setClientOptions] = useState<any>([]);

    const timeoutRef = useRef<any>(null);

    // Buscar clientes
    useEffect(() => {
        if (clientSearch !== '' && clientSearch.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getClientsOptions();
            }, 800);
            return () => { clearTimeout(timeoutRef.current) }
        }
    }, [clientSearch]);

    // Rellenar los campos de tipo select con los datos de la API
    const getClientsOptions = async (id?: string) => {
        if (id !== undefined) { updateFilters({ client: id }) };
        const response = (await ((new ClientService).getClients({ ...filters, limit: 999999999 }))).response;

        if (response?.status === 200) {
            const options = response?.data?.data?.clients?.map((item: { id: number; name: string, firstName: string, lastName: string, clientContactPerson: any }) => (
                {
                    value: item.id,
                    label: item.name
                        + (item.firstName ? ' ' + item.firstName : '') + (item.lastName ? ' ' + item.lastName : '')
                        + (item.clientContactPerson ? ' (' + item.clientContactPerson.name + (item.clientContactPerson?.firstName ? (' ' + item.clientContactPerson.firstName) : '') + (item.clientContactPerson?.lastName ? (' ' + item.clientContactPerson.lastName) : '') + ')' : ''),
                }
            ));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setClientOptions(options);
        }
    };

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    };

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const [users] = useFetch(useCallback(async () => {
        const response = await (new UserService).getUsers();
        return response.getResponseData() as UserApiResponse;
    }, []));

    const [statuses] = useFetch(useCallback(async () => {
        const response = await (new StatusService).listStatuses({ filter_filters: { oriented_to: 'project' } });
        return response.getResponseData() as Status[];
    }, []));

    const [subsidies] = useFetch(useCallback(async () => {
        const response = (await (new ProjectService).getSubsidies());
        return response.getResponseData();
    }, []));

    const getUserList = () => {
        if (users?.users) {
            return users.users.map((user: any) => {
                return {
                    value: user.id,
                    label: user.name + (user.firstName ? (" " + user.firstName) : '') + (user.lastName ? (" " + user.lastName) : ''),
                }
            })
        }
        return [];
    };

    const getUserById = (id: string) => {
        const user = users?.users?.find((user: any) => user.id === id);
        if (user) {
            return {
                value: user.id,
                label: user.name + (user.firstName ? (" " + user.firstName) : '') + (user.lastName ? (" " + user.lastName) : ''),
            }
        }
        return null;
    };

    const getStatusById = (id: string) => {
        const status = statuses?.statuses?.find((status: any) => status.id === id);
        if (status) {
            return {
                value: status.id,
                label: status.name,
            }
        }
        return null;
    };

    const getSubsidyById = (id: string) => {
        const subsidy = subsidies?.subsidies?.find((subsidy: any) => subsidy.id === id);
        if (subsidy) {
            return {
                value: subsidy.id,
                label: subsidy.name,
            }
        }
        return null;
    };

    const handleSubsidiesChange = (e: any) => {
        setSubsidiesSelected(e);
        updateFilters({ subsidies: e.map((subsidy: any) => subsidy.value) });
    }

    useEffect(() => {
        if (isSuperAdmin) {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                }}
                placeholder='Buscar...'
            />

            <Button
                color='primary'
                isLight
                icon='Filter'
                onClick={() => { setFilterMenu(true) }}
            >
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Proyecto </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userCan('list', 'companies') && (
                            <FormGroup id='filter01' label='Organización' className='col-md-12'>
                                <Select
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ company: e.target.value })
                                        setSelectedCompany(e.target.value)
                                    }}
                                    value={selectedCompany}
                                    id='company-select'
                                    ariaLabel='Company selector'
                                    placeholder='Organización...'
                                />
                            </FormGroup>
                        )}

                        {userCan('list_all_projects', 'project') && (
                            <FormGroup id='filter02' label='Usuario creador:' className='col-md-12'>
                                <Select
                                    list={getUserList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ user: e.target.value })
                                    }}
                                    value={filters.filter_filters.user}
                                    id='user-select'
                                    ariaLabel='user selector'
                                    placeholder='Creador...'
                                />
                            </FormGroup>
                        )}

                        <FormGroup id='filter1' label='Estado del proyecto:' className='col-md-12'>
                            <SearchableSelect
                                name='status-select'
                                isMulti
                                isClearable
                                options={
                                    statuses?.statuses?.map((status: any) => {
                                        return {
                                            value: status.id,
                                            label: status.name,
                                        }
                                    })
                                }
                                onChange={(e: any) => { updateFilters({ statuses: e.map((status: any) => status.value) }) }}
                                defaultValue={getStatusById(filters.filter_filters.statuses)}
                                placeholder='estado'
                            />
                        </FormGroup>

                        <FormGroup id='filter2' label='Promotor:' className='col-md-12'>
                            <CustomSearchSelect
                                id='client-select'
                                options={clientOptions}
                                onInputChange={(e: any) => { setClientSearch(e) }}
                                onChangeSingle={(e: any) => updateFilters({ sponsor: e.value })}
                                defaultValue={null}
                            />
                        </FormGroup>

                        <FormGroup id='filter3' label='Representante:' className='col-md-12'>
                            <CustomSearchSelect
                                id='client-select'
                                options={clientOptions}
                                onInputChange={(e: any) => { setClientSearch(e) }}
                                onChangeSingle={(e: any) => updateFilters({ representative: e.value })}
                                defaultValue={null}
                            />
                        </FormGroup>

                        <FormGroup id='filter4' label='Aparejador:' className='col-md-12'>
                            <CustomSearchSelect
                                id='client-select'
                                options={clientOptions}
                                onInputChange={(e: any) => { setClientSearch(e) }}
                                onChangeSingle={(e: any) => updateFilters({ surveyor: e.value })}
                                defaultValue={null}
                            />
                        </FormGroup>

                        <FormGroup id='filter5' label='Jefe de obra:' className='col-md-12'>
                            <CustomSearchSelect
                                id='client-select'
                                options={clientOptions}
                                onInputChange={(e: any) => { setClientSearch(e) }}
                                onChangeSingle={(e: any) => updateFilters({ constructionManager: e.value })}
                                defaultValue={null}
                            />
                        </FormGroup>

                        <FormGroup id='filter6' label='Proyectista:' className='col-md-12'>
                            <SearchableSelect
                                name='planner-select'
                                options={
                                    users?.users?.map((user: any) => {
                                        return {
                                            value: user.id,
                                            label: user.name + (user.firstName ? (" " + user.firstName) : '') + (user.lastName ? (" " + user.lastName) : ''),
                                        }
                                    })
                                }
                                onChange={(e: { value: string, label: string }) => updateFilters({ planner: e.value })}
                                value={getUserById(filters.filter_filters.planner)}
                                placeholder='proyectista'
                            />
                        </FormGroup>

                        <FormGroup id='filter7' label='Subvención:' className='col-md-12'>
                            <SearchableSelect
                                isMulti
                                name='subsidy-select'
                                options={
                                    subsidies?.subsidies?.map((subsidy: any) => {
                                        return {
                                            value: subsidy.id,
                                            label: subsidy.name,
                                        }
                                    })
                                }
                                onChange={(e: any) => handleSubsidiesChange(e)}
                                value={subsidiesSelected}
                                //onChange={(e: { value: string, label: string }) => updateFilters({ subsidy: e.value })}
                                //value={getSubsidyById(filters.filter_filters.subsidy)}
                                placeholder='subvención'
                            />
                        </FormGroup>

                        <FormGroup id='filter8' label='Dirección:' className='col-md-12'>
                            <Input
                                name='address'
                                value={filters.filter_filters.address}
                                onChange={(e: any) => { updateFilters({ address: e.target.value }) }}
                            />
                        </FormGroup>
                    </div>

                    <div className='col-12 text-center'>
                        <Button
                            className='mt-4' color="secondary"
                            onClick={() => {
                                resetFilters();
                                setSelectedCompany('');
                                setSubsidiesSelected([]);
                            }}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default ProjectFilters;