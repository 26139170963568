import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useCallback, useState } from "react";
import { ProjectService } from "../../../services/projects/projectService";
import ProjectForm from "../ProjectForm";
import { Project } from "../../../type/project-type";
import { FinancesMenu } from "../../../menu";
import moment from "moment";

const EditProject = () => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const projectService = new ProjectService();
    const [isLoading, setIsLoading] = useState(false);

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = (await projectService.getProject(id as string));
        return response.getResponseData() as Project;
    }, [id]));

    const handleUpdate = async (values: Project) => {
        setIsLoading(true);
        values.projectID = id as string;

        try {
            const response = await projectService.editProject(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(FinancesMenu.project.path);
                setTimeout(() => {
                    toast.success('Proyecto editado correctamente');
                }, 100);
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage message={error.message} />;

        if (data !== null) {
            const startDate = data.startDate?.date.split(' ');
            const deliveryDate = data.deliveryDate?.date.split(' ');

            const projectData: Project = {
                company: data.company?.id !== null ? data.company.id : "",
                budget: data.budget?.id !== null ? data.budget.id : "",
                sponsor: data.clientSponsor?.id !== null ? data.clientSponsor.id : "",
                representative: null,
                client: null,
                surveyor: data?.clientSurveyor?.id !== null ? data.clientSurveyor?.id : "",
                constructionManager: data.clientConstructionManager?.id !== null ? data.clientConstructionManager?.id : "",
                planner: data.planner?.id !== null ? data.planner.id : "",
                subsidy: data.subsidy?.id !== null ? data.subsidy?.id : "",
                code: null,
                project: {
                    type: null,
                    cif: data.cif !== null ? data.cif : "",
                    description: data.description !== null ? data.description : "",
                    address: data.address !== null ? data.address : "",
                    population: data.population !== null ? data.population : "",
                    province: data.province !== null ? data.province : "",
                    pc: data.pc !== null ? data.pc : 0,
                    constructionYear: data.constructionYear !== null ? data.constructionYear : moment().year(),
                    cad_ref: data.cad_ref !== null ? data.cad_ref : "",
                    pem: data.pem !== null ? data.pem : 0,
                    codeIdentifier: null,
                    year: null,
                    startDate: startDate[0] || "",
                    deliveryDate: deliveryDate[0] || "",
                    enumeration: null,
                    gmu: data.fileNumberGMU !== null ? data.fileNumberGMU : null,
                    licenseNumber: data.licenseNumber !== null ? data.licenseNumber : null,
                    use: data.fileNumberUse !== null ? data.fileNumberUse : null,
                    bailNumberRefund: data.bailNumberRefund !== null ? data.bailNumberRefund : null,
                },
                projectAddress: data.address !== null ? data.address : "",
                projectPopulation: data.population !== null ? data.population : "",
                projectProvince: data.province !== null ? data.province : "",
                projectPC: data.pc !== null ? data.pc : 0,
                projectConstructionYear: data.constructionYear !== null ? data.constructionYear : moment().year(),
                projectCadRef: data.cad_ref !== null ? data.cad_ref : "",
                projectPEM: data.pem !== null ? data.pem : 0,
                projectStartDate: startDate[0] || "",
                projectDeliveryDate: deliveryDate[0] || "",
                projectGMU: data.fileNumberGMU !== null ? data.fileNumberGMU : "",
                projectLicenseNumber: data.licenseNumber !== null ? data.licenseNumber : "",
                projectUse: data.fileNumberUse !== null ? data.fileNumberUse : "",
                projectBailNumberRefund: data.bailNumberRefund !== null ? data.bailNumberRefund : "",
            };

            return <ProjectForm budgetId={data.budget?.id} projectData={projectData} submit={handleUpdate} isLoading={isLoading} />;
        }
    };

    return <>{getContent()}</>;
}

export default EditProject;