import { FC } from "react";
import img from "../assets/error_message.jpg";
import forbiddenImg from "../assets/img/forbidden.jpg";

interface ErrorMessageProps {
  message?: string;
  error?: any;
  forbidden?: boolean;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ error, message, forbidden }) => {
  return (
    <div className='row d-flex align-items-center h-100'>
      <div className='col-12 d-flex align-items-baseline justify-content-center'>
        <p className="text-brand h4 mt-5 mb-5">
          {
            error
              ? ((error.error?.message !== undefined) ? error.error.message : 'Ha ocurrido un error al cargar los datos')
              : (message ? message : 'Ha ocurrido un error al cargar los datos')
          }
        </p>
      </div>

      <div className='col-12 d-flex align-items-baseline justify-content-center'>
        {forbidden ? (
          <img width='700px' height='375px' src={forbiddenImg} alt="Error" className="img-responsive" />
        ) : (
          <img width='350px' height='275px' src={img} alt="Error" className="img-responsive" />
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;