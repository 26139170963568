import { useEffect, useState } from "react";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { Concept } from "../BudgetsForm";
import { toast } from "react-toastify";
import { useBugdetForm } from "../providers/BudgetProvider";

const BudgetStages = () => {

    const {
        formik, formikStages, verifyClassStages, showErrorsStages,
        stagesData, setStagesData, conceptsData, stageConceptsPercents, setStageConceptsPercents
    } = useBugdetForm();

    const newStages = [...stagesData];

    const [stageConceptId, setStageConceptId] = useState<{ value: string, label: string }>({ value: '', label: '' });
    const [stageConceptPercentage, setStageConceptPercentage] = useState<number>(0);

    const handleAddConceptToStage = (index: number) => {
        // Check that both fields have been completed
        if (stageConceptId.value === '' || stageConceptPercentage === 0) {
            toast.info('Debes completar ambos campos para poder añadir el concepto');
            return;
        }

        // Check that the concept does not already exist in the phase
        if (stagesData[index].concepts.some((concept: any) => concept.id === stageConceptId.value)) {
            toast.info('Este concepto ya se ha añadido');
            return;
        }

        // Filter the concept of stageConceptsPercents that matches the selected concept
        const concept = stageConceptsPercents.filter((concept: any) => concept.id === stageConceptId.value);

        // Check that the percentage of the concept + stageConceptPercentage does not exceed 100%
        const totalSum = concept[0] ? Number(concept[0].percent) + Number(stageConceptPercentage) : Number(stageConceptPercentage);

        if (totalSum > 100) {
            toast.info('El porcentaje total para este concepto no puede ser superior al 100%');
            return;
        }

        // Update the value of the stageConceptsPercents state with the new percentage
        const newStageConceptsPercents = stageConceptsPercents.map((concept: any) => {
            if (concept.id === stageConceptId.value) {
                concept.percent = totalSum;
            }
            return concept;
        });
        setStageConceptsPercents(newStageConceptsPercents);

        // Add concept to phase and clear fields
        canAddConcept(index);
    }

    const canAddConcept = (index: number) => {
        const newConcept = { id: stageConceptId.value, percentage: stageConceptPercentage };

        setStagesData(stagesData.map((stage: any, i: number) => {
            if (i === index) {
                stage.concepts.push(newConcept);
            }
            return stage;
        }));

        setStageConceptId({ value: '', label: '' });
        setStageConceptPercentage(0);
    }

    const _handleDeleteConceptFromStage = (stageIndex: number, concept: any) => {
        // Eliminar concepto de la fase seleccionada (según el index)
        const newStagesData = stagesData.map((stage: any, i: number) => {
            if (i === stageIndex) {
                stage.concepts = stage.concepts.filter((c: any) => c !== concept);
            }
            return stage;
        });
        setStagesData(newStagesData);

        // Recorrer stageConceptsPercents y restar el porcentaje del concepto eliminado al porcentaje de los conceptos que tengan el mismo id
        const newStageConceptsPercents = stageConceptsPercents.map((c: any) => {
            if (c.id === concept.id) {
                return {
                    id: c.id,
                    percent: Number(c.percent) - Number(concept.percentage),
                }
            }
            return c;
        });
        setStageConceptsPercents(newStageConceptsPercents);
    }

    useEffect(() => {
        formik.setFieldValue("stages", stagesData);
        formikStages.resetForm();
    }, [stagesData]);

    useEffect(() => {
        if (conceptsData) {
            // Delete concepts from stagesData whose name is equal to ''
            const newStagesData = stagesData.map((stage: any) => {
                stage.concepts = stage.concepts.filter((concept: any) => {
                    return conceptsData.some((c: any) => c.id === concept.id);
                })
                return stage;
            });
            setStagesData(newStagesData);
        }
    }, [conceptsData]);

    return (
        <>
            {stagesData.map((stage: any, index: number) => {
                return (
                    <div className="mt-4 p-4 rounded" style={{ border: '1px solid lightgray', backgroundColor: '#EEEEEE' }} key={stage.id}>
                        <div className="row d-flex justify-content-between">
                            <div className="col-md-6">
                                <>
                                    <div className="row d-flex justify-content-between">
                                        <FormGroup label="Nombre" requiredInputLabel className="col-md-7">
                                            <Input
                                                id={stage.name}
                                                value={stage.name}
                                                onChange={(e: any) => {
                                                    newStages[index].name = e.target.value;
                                                    setStagesData(newStages);
                                                }}
                                                className={verifyClassStages("name")}
                                            />
                                            {showErrorsStages("name")}
                                        </FormGroup>
                                        <FormGroup label="Semanas" requiredInputLabel className="col-md-2">
                                            <Input
                                                id={stage.duration.toString()}
                                                value={stage.duration}
                                                onChange={(e: any) => {
                                                    newStages[index].duration = e.target.value;
                                                    setStagesData(newStages);
                                                }}
                                                type="number"
                                                min={0}
                                                className={verifyClassStages("duration")}
                                            />
                                            {showErrorsStages("duration")}
                                        </FormGroup>
                                    </div>
                                    <div className="row mt-3">
                                        <FormGroup label="Descripción" className="col-md-12">
                                            <textarea
                                                id={stage.textField}
                                                value={stage.textField}
                                                onChange={(e: any) => {
                                                    newStages[index].textField = e.target.value;
                                                    setStagesData(newStages);
                                                }}
                                                className={verifyClassStages("textField")}
                                                rows={4}
                                                style={{
                                                    borderRadius: '10px',
                                                    boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                                                    backgroundColor: '#f8f9fa',
                                                    width: '100%',
                                                    padding: '15px',
                                                    border: '0'
                                                }}
                                            />
                                            {showErrorsStages("textField")}
                                        </FormGroup>
                                    </div>
                                </>
                            </div>

                            <div className="col-md-5">
                                <>
                                    <div className="row">
                                        <FormGroup label="Concepto asociado" className="col-md-8">
                                            <SearchableSelect
                                                isSearchable
                                                options={conceptsData?.map((concept: Concept) => {
                                                    return {
                                                        value: concept.id,
                                                        label: concept.name,
                                                    }
                                                })}
                                                value={stageConceptId}
                                                onChange={(e: any) => {
                                                    setStageConceptId(e);
                                                    formik.handleChange(e.value);
                                                }}
                                                placeholder="Seleccione un concepto"
                                                name={stage.concepts}
                                            />
                                        </FormGroup>

                                        <FormGroup label="Porcentaje" className="col-md-2">
                                            <Input
                                                type="number"
                                                id='percentage'
                                                value={stageConceptPercentage}
                                                onChange={(e: any) => {
                                                    if (e.target.value > 100 || e.target.value < 0) {
                                                        toast.info('El porcentaje debe estar entre 1% y 100%');
                                                        return;
                                                    }
                                                    setStageConceptPercentage(e.target.value);
                                                    formik.handleChange(e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                min={0} max={100}
                                            />
                                        </FormGroup>

                                        <FormGroup label="Añadir" className="col-md-2">
                                            <Button
                                                icon="Add"
                                                title="Añadir concepto a la fase"
                                                onClick={() => handleAddConceptToStage(index)}
                                            />
                                        </FormGroup>

                                        {stage.concepts.length > 0 && (
                                            <div className="col-md-12 mt-3">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th scope="col" className="col-md-8">Concepto</th>
                                                            <th scope="col" className="col-md-3">Porcentaje</th>
                                                            <th scope="col" className="col-md-1"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stage.concepts.map((concept: any, i: number) => {
                                                            return (
                                                                <tr key={concept.id + i} className="text-center">
                                                                    <td>{conceptsData?.find((c: any) => c.id === concept.id)?.name}</td>
                                                                    <td>{concept.percentage}%</td>
                                                                    <td className="p-0">
                                                                        <Button
                                                                            icon="Close" title="Eliminar concepto de la fase"
                                                                            onClick={() => _handleDeleteConceptFromStage(index, concept)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                </>
                            </div>

                            <div className="col-md-1 d-flex justify-content-end">
                                <Button
                                    color='dark'
                                    isLight
                                    icon='Delete'
                                    title="Eliminar fase"
                                    onClick={() => {
                                        handleConfirmationAlert({
                                            title: "Eliminar fase",
                                            text: "¿Está seguro que desea eliminar el fase?",
                                            icon: "warning",
                                            onConfirm: () => { setStagesData(stagesData.filter((stage: any) => stage !== stagesData[index])) }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
}

export default BudgetStages;