import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectService } from "../../../services/projects/projectService";
import { toast } from "react-toastify";
import ProjectForm from "../ProjectForm";
import { FinancesMenu } from "../../../menu";

const CreateProject = () => {

	const navigate = useNavigate();
	const { id = '' } = useParams<{ id: string }>();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		try {
			const response = await (await (new ProjectService()).createProject(values)).getResponseData();
			if (response.success) {
				setLoading(false);
				navigate(FinancesMenu.project.path);
				setTimeout(() => {
					toast.success('Proyecto creado correctamente');
				}, 100)
			} else {
				toast.error(response.message === 'Request error' ? response.data.errors[0].message : "Error al crear el proyecto");
			}
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	return <ProjectForm submit={handleCreation} isLoading={loading} budgetId={id} />;
}

export default CreateProject;