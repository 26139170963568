import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const STATUS_ENDPOINT = '/statuses';

export class StatusService extends RestServiceConnection {

    listStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/list-status',
            data: filters
        }, true) as AxiosResponse;
        return this;
    }

    getStatusById = async (status: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/get-status',
            data: { status }
        }, true) as AxiosResponse;
        return this;
    }

    createStatus = async (status: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/create-status',
            data: status
        }, true) as AxiosResponse;
        return this;
    }

    editStatus = async (status: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/edit-status',
            data: status
        }, true) as AxiosResponse;
        return this;
    }

    deleteStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/delete-status',
            data: { status: id }
        }, true) as AxiosResponse;
        return this;
    }

    setOrderStatus = async (status: string, order: number) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/set-order-status',
            data: { status, order }
        }, true) as AxiosResponse;
        return this;
    }

    setMainStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/change-main-status',
            data: { status: id }
        }, true) as AxiosResponse;
        return this;
    }

    setFinalStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/change-final-status',
            data: { status: id }
        }, true) as AxiosResponse;
        return this;
    }

    setHasGMU = async (status: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/toggle-file-number-gmu',
            data: { status }
        }, true) as AxiosResponse;
        return this;
    }

    setHasLicenseNum = async (status: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/toggle-license-number',
            data: { status }
        }, true) as AxiosResponse;
        return this;
    }

    setHasFileNumberUse = async (status: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/toggle-file-number-use',
            data: { status }
        }, true) as AxiosResponse;
        return this;
    }

    setHasBailNumberRefund = async (status: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/toggle-bail-number-refund',
            data: { status }
        }, true) as AxiosResponse;
        return this;
    }

    toggleBudgetStatus = async (budget: string, status: string, date: string, concept: string, comments: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/financial/toggle-budget-status',
            data: { budget, status, date, concept, comments },
        }, true) as AxiosResponse;
        return this;
    }

    toggleProjectStatus = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/project/toggle-project-status',
            data: values,
        }, true) as AxiosResponse;
        return this;
    }

}