import { FC } from "react";
import AsyncImg from "../../../components/AsyncImg";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";

interface ClientInfoHeaderProps {
    clientData: any;
}

const ClientInfoHeader: FC<ClientInfoHeaderProps> = ({ clientData }) => {

    return (
        <div className="row">
            <Card className='shadow-3d-primary'>
                <CardBody>
                    <div className='row g-5 sticky-top'>
                        <div className='col-2 d-flex justify-content-center'>
                            <AsyncImg
                                isBackground
                                height="120px"
                                width="120px"
                                styles="rounded-circle"
                                id={clientData.profileImg}
                            />
                        </div>

                        <div className='col-10 d-flex justify-content-center flex-column p-0'>
                            <div className='row'>
                                <div className='col-3 d-flex justify-content-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='PermIdentity' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-6 mb-0'>
                                                {
                                                    clientData.name
                                                        ? clientData.name + ' ' + (clientData.firstName ? clientData.firstName : "") + ' ' + (clientData.lastName ? clientData.lastName : "")
                                                        : 'No proporcionado'
                                                }
                                            </div>
                                            <div className='text-muted'>
                                                Nombre
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-2 d-flex justify-content-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='MergeType' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-6 mb-0'>
                                                {clientData.classification ? clientData.classification : 'No proporcionada'}
                                            </div>
                                            <div className='text-muted'>
                                                Clasificación
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-3 d-flex justify-content-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Mail' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-6 mb-0'>
                                                {clientData.email ? clientData.email : 'No proporcionado'}
                                            </div>
                                            <div className='text-muted'>
                                                Correo electrónico
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    clientData.address && (
                                        <div className='col-4 d-flex justify-content-center'>
                                            <div className='d-flex align-items-center'>
                                                <div className='flex-shrink-0'>
                                                    <Icon icon='Home' size='2x' color='primary' />
                                                </div>
                                                <div className='flex-grow-1 fs-6 ms-3'>
                                                    <div className='fw-bold mb-0'>
                                                        <span className="text-wrap">{clientData.address ? clientData.address : '-'}</span>
                                                    </div>
                                                    <div className='text-muted'>
                                                        Dirección
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default ClientInfoHeader;