import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '../styles/stylesEdited.scss';

interface IConfirmationAlert {
  title: string;
  text?: string;
  html?: JSX.Element;
  icon: 'warning' | 'success' | 'error' | 'info' | 'question';
  onConfirm: () => void;
  focusCancel?: boolean;
}

const MySwal = withReactContent(Swal);

export const handleConfirmationAlert = async (props: IConfirmationAlert) => {

  return MySwal.fire({
    title: props.title,
    text: props.text,
    html: props.html,
    icon: props.icon,
    iconColor: props.icon === 'warning' && '#CE6B52' || '',
    showCancelButton: true,
    confirmButtonColor: '#76CE52',
    cancelButtonColor: '#e8673d',
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    heightAuto: false,
    focusCancel: props.focusCancel,
  }).then((result) => {
    if (result.isConfirmed) props.onConfirm();
  });
};