import { FC, Fragment, useContext, useEffect, useState } from 'react';
import Icon from '../../../../components/icon/Icon';
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput';
import Button from '../../../../components/bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import { CompanyService } from '../../../../services/companies/organizationService';
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider';

interface IStatusFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: () => void;
    filters: any;
}

const StatusFilters: FC<IStatusFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { userCan } = useContext(PrivilegeContext);

    const [filterMenu, setFilterMenu] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState<any>(filters.filter_filters.company || '');
    const [selectedOrientedTo, setSelectedOrientedTo] = useState<any>(filters.filter_filters.oriented_to || '');
    const isSuperAdmin = userCan('list', 'companies');

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    useEffect(() => {
        if (isSuperAdmin) {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                }}
                placeholder='Buscar...'
            />

            <Button
                color='primary'
                isLight
                icon='Filter'
                onClick={() => { setFilterMenu(true) }}
            >
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='statusFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'>Filtros de Estado</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {userCan('list', 'companies') && (
                            <FormGroup id='filter0' label='Organización' className='col-md-12'>
                                <Select
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({ company: e.target.value })
                                        setSelectedCompany(e.target.value)
                                    }}
                                    value={selectedCompany}
                                    id='company-select'
                                    ariaLabel='Company selector'
                                    placeholder='Organización ...'
                                />
                            </FormGroup>
                        )}

                        <FormGroup id='filter1' label='Pertenece a:' className='col-md-12'>
                            <Select
                                list={[
                                    { value: 'budget', label: 'Presupuesto' },
                                    { value: 'project', label: 'Proyecto' },
                                ]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    updateFilters({ oriented_to: e.target.value })
                                    setSelectedOrientedTo(e.target.value)
                                }}
                                value={selectedOrientedTo}
                                id='oriented_to-select'
                                ariaLabel='oriented_to selector'
                                placeholder='Elegir ...'
                            />
                        </FormGroup>

                        <FormGroup id='filter2' label='Orden:' className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Mínimo: </p>
                                        </div>
                                        <div className='col-md-6 p-0'>
                                            <Input
                                                name='minOrder'
                                                type='number'
                                                value={filters.filter_filters.minOrder}
                                                onChange={(e: any) => { updateFilters({ minOrder: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Máximo: </p>
                                        </div>
                                        <div className='col-md-6 p-0'>
                                            <Input
                                                name='maxOrder'
                                                type='number'
                                                value={filters.filter_filters.maxOrder}
                                                onChange={(e: any) => { updateFilters({ maxOrder: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>

                        <div className='col-12 text-center'>
                            <Button
                                className='mt-4'
                                color="secondary"
                                onClick={() => {
                                    resetFilters()
                                    setSelectedCompany('')
                                    setSelectedOrientedTo('')
                                }}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default StatusFilters;