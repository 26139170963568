import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import { UserService } from "../../services/users/userService";
import { loadUserFromLocalStorage } from "../../utils/jwt";
import { StatusService } from "../../services/status/statusService";
import { CompanyService } from "../../services/companies/organizationService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../components/bootstrap/Modal";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Button from "../../components/bootstrap/Button";
import { CirclePicker, CompactPicker, SliderPicker } from 'react-color';
import Checks from "../../components/bootstrap/forms/Checks";
import * as yup from 'yup';
import FormLabel from "../../components/FormLabel";
import { COLORS_36 } from "../../utils/colors";

interface CreateModalProps {
    data?: any;
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    show?: boolean;
    onClose: (type: number, message?: string, errors?: any) => void;
}

interface StatusForm {
    company: string;
    name: string;
    description?: string;
    color: string;
    orientedTo: string;
    mainStatus: boolean;
    finalStatus: boolean;
    orderStatus: number;
    automaticMode: boolean;
}

const validationSchema = yup.object({
    company: yup.string().required('La organización es obligatoria'),
    name: yup.string().required('El nombre es obligatorio'),
    description: yup.string().notRequired(),
    color: yup.string().required('El color es obligatorio'),
    orientedTo: yup.string().required('Es necesario indicar a qué pertenece'),
    mainStatus: yup.boolean(),
    finalStatus: yup.boolean(),
    orderStatus: yup.number().min(1, 'El orden debe ser mayor a 0').max(1000, 'El orden debe ser menor a 1000'),
    automaticMode: yup.boolean().required('El modo automático es obligatorio'),
});

const StatusModalForm: FC<CreateModalProps> = ({ isOpen, setIsOpen, data, onClose, show }) => {

    const { userCan } = useContext(PrivilegeContext);
    const isSuperAdmin = userCan('list', 'companies');

    const [company, setCompany] = useState("");
    const [companyList, setCompanyList] = useState([]);

    const _handleCreateStatus = async (values: any) => {
        const statusService = new StatusService;
        try {
            const response = await (await statusService.createStatus(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al crear el estado');
        }
    }

    const _handleEditStatus = async (values: any) => {
        const statusService = new StatusService;
        try {
            const response = await (await statusService.editStatus(values)).getResponseData();
            if (response.success) {
                onClose(1, response.message);
            } else {
                onClose(0, '', response.data.errors);
            }
        } catch (e) {
            toast.error('Error al editar el estado');
        }
    }

    const getCompany = async () => {
        const userService = new UserService;
        const response = (await userService.getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData()
        if (response.success) {
            setCompany(response.data.userRoles[0].company.id)
        }
    }

    const getCompanies = async () => {
        const companyService = new CompanyService;
        const response = (await companyService.getCompanies()).getResponseData()
        if (response.success) {
            setCompanyList(response.data.companies)
        }
    }

    const getCompanyList = () => {
        if (companyList as any) {
            return companyList.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            company: data?.company?.id || company,
            status: data?.id || '',
            name: data?.name || '',
            description: data?.description || '',
            color: data?.color || '#607d8b',
            orientedTo: data?.orientedTo || '',
            mainStatus: data?.mainStatus || false,
            finalStatus: data?.finalStatus || false,
            orderStatus: data?.orderStatus || 1,
            automaticMode: data?.automaticMode || true,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.company != '') {
                if (data != null) {
                    _handleEditStatus(values)
                } else {
                    _handleCreateStatus(values);
                }
            }
        },
    });

    const verifyClass = (inputFieldID: keyof StatusForm) => { return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : "" };

    const showErrors = (inputFieldID: keyof StatusForm) => {
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    useEffect(() => {
        if (!isSuperAdmin) {
            getCompany();
        } else {
            getCompanies();
        }
    }, [isSuperAdmin]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size='md' titleId='Nuevo estado'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_code'>{show ? 'Ver estado' : data ? 'Editar estado' : 'Crear estado'}</ModalTitle>
            </ModalHeader>

            {
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <ModalBody className='px-4'>

                        <div className='row g-4'>
                            {
                                isSuperAdmin && (
                                    <FormGroup requiredInputLabel id='company' label='Organización' className='col-md-12'>
                                        <Select id="company" disabled={show ? true : false} list={getCompanyList()} onChange={formik.handleChange} value={formik.values.company || ''} ariaLabel='Select Companies' placeholder='Elegir Organización...' />
                                    </FormGroup>
                                )
                            }

                            <FormGroup requiredInputLabel id='orientedTo' label='Orientado a:' className='col-md-4'>
                                <Select
                                    id="orientedTo"
                                    disabled={show ? true : false}
                                    list={[
                                        { value: 'budget', label: 'Presupuesto' },
                                        { value: 'project', label: 'Proyecto' },
                                    ]}
                                    onChange={formik.handleChange} value={formik.values.orientedTo || ''}
                                    ariaLabel='Select OrientedTo'
                                    placeholder='Elegir ...'
                                />
                            </FormGroup>

                            <FormGroup requiredInputLabel id='name' label='Nombre del estado' className="col-md-8">
                                <Input value={formik.values.name} disabled={show ? true : false} autoComplete="off" onChange={(e: any) => formik.setFieldValue('name', e.target.value)} className={verifyClass('name')} />
                                {showErrors('name')}
                            </FormGroup>

                            <FormGroup id='description' label='Descripción' className="col-md-12">
                                <textarea
                                    className="form-control"
                                    id="description"
                                    rows={3}
                                    value={formik.values.description}
                                    disabled={show ? true : false}
                                    onChange={(e: any) => formik.setFieldValue('description', e.target.value)}
                                />
                            </FormGroup>

                            {
                                // Cuando esté en modo edición, no se mostrará el color picker, cuando se esté creando si se mostrará
                                (!show && !data) && (
                                    <FormGroup id='color' label='Color' className='col-md-7'>
                                        <CirclePicker
                                            circleSize={25}
                                            color={formik.values.color}
                                            colors={COLORS_36}
                                            onChangeComplete={(color: any) => {
                                                formik.setFieldValue('color', color.hex)
                                            }}
                                        />
                                    </FormGroup>
                                )
                            }

                            <div className="col-md-5">
                                <>
                                    {
                                        (!show && !data) && (
                                            <div className="row">
                                                <FormLabel label="Orden automático" className="col-md-8" />
                                                <div className="col-md-4">
                                                    <Checks
                                                        type="switch"
                                                        id="automaticMode"
                                                        name="automaticMode"
                                                        checked={formik.values.automaticMode}
                                                        onChange={(e: any) => formik.setFieldValue('automaticMode', e.target.checked)}
                                                        disabled={show ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        ((formik.values.automaticMode === false) || show || data) && (
                                            <div className="row">
                                                <FormLabel label="Posición" className="col-md-5" />
                                                <div className="col-md-6">
                                                    <Input
                                                        type="number"
                                                        value={formik.values.orderStatus}
                                                        disabled={show ? true : false}
                                                        autoComplete="off"
                                                        onChange={(e: any) => formik.setFieldValue('orderStatus', e.target.value)}
                                                        className={verifyClass('orderStatus')}
                                                    />
                                                    {showErrors('orderStatus')}
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter className='px-4 pb-4 d-flex'>
                        <Button color='secondary' type="submit" icon="Save" className={show ? 'd-none' : 'd-block'}>
                            {data ? 'Editar' : 'Crear'}
                        </Button>
                    </ModalFooter>
                </form>
            }
        </Modal>
    )
}

export default StatusModalForm;