import { FC, useEffect, useRef, useState } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { CardFooter } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { useFormik } from "formik";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import CustomSearchSelect from "../../../components/selector/CustomSearchSelect";
import { UserService } from "../../../services/users/userService";
import { ProjectService } from "../../../services/projects/projectService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface UsersWithAccessModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refetch: () => void;
}

const UsersWithAccessModal: FC<UsersWithAccessModalProps> = ({ open, setOpen, refetch }) => {

    const { id = '' } = useParams<{ id: string }>();
    const timeoutRef = useRef<any>(null);
    const [userSearch, setUserSearch] = useState<string>('');
    const [userOptions, setUserOptions] = useState<any>([]);

    const getClientsOptions = async (id?: string) => {
        const response = (await ((new UserService).getUsers({ limit: 999999999 }))).response;
        if (response?.status === 200) {
            const options = response?.data?.data?.users?.map((item: { id: number; name: string, firstName: string, lastName: string }) => (
                {
                    value: item.id,
                    label: item.name + (item.firstName ? ' ' + item.firstName : '') + (item.lastName ? ' ' + item.lastName : '')
                }
            ));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setUserOptions(options);
        }
    };

    const _handleAddParticipant = async (values: any) => {
        try {
            const response = (await (new ProjectService).addParticipant(id, values.user)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => { setOpen(false) }, 100);
            } else {
                response.data.errors.map((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error('Error al asignar el usuario al proyecto');
        }
    };

    const formik = useFormik({
        initialValues: { user: '' },
        onSubmit: (values) => _handleAddParticipant(values),
    });

    useEffect(() => {
        if (userSearch !== '' && userSearch.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getClientsOptions();
            }, 500);
            return () => { clearTimeout(timeoutRef.current) }
        }
    }, [userSearch]);

    return (
        <Modal isOpen={open} setIsOpen={setOpen} isStaticBackdrop={true} size='md' titleId='Asignar usuario al proyecto'>
            <ModalHeader setIsOpen={setOpen} className='p-4'>
                <ModalTitle id='assign-user'>Asignar usuario al proyecto</ModalTitle>
            </ModalHeader>

            <ModalBody className="d-flex justify-content-center align-items-center">
                <FormGroup label='Usuario' className="w-75">
                    <CustomSearchSelect
                        id='user-select'
                        options={userOptions}
                        onInputChange={(e: any) => { setUserSearch(e) }}
                        onChangeSingle={(e: any) => {
                            formik.handleChange(e.value);
                            formik.setFieldValue('user', e.value)
                        }}
                        defaultValue={userOptions.find((option: any) => option.value === formik.values.user) || null}
                    />
                </FormGroup>

                <CardFooter className="p-0 ms-3 d-flex align-items-end">
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        Asignar
                    </Button>
                </CardFooter>
            </ModalBody>
        </Modal>
    );
};

export default UsersWithAccessModal;