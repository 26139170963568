import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';

const COST_ENDPOINT = '/costs';

export class CostService extends RestServiceConnection {

    // ------------------------------------------------------------
    //  ALL TYPES FOR ENTITY COST
    // ------------------------------------------------------------
    getCosts = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/list',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getCostById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/get',
            data: {
                costId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createCost = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/create',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    editCost = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/edit',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    deleteCost = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/delete',
            data: {
                costId: id
            }
        }, true) as AxiosResponse;
        return this;
    }


    // ------------------------------------------------------------
    //  ALL TYPES FOR ENTITY COST-TYPE
    // ------------------------------------------------------------
    getCostTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/list-types',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getCostTypeById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/get-type',
            data: {
                costTypeId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createCostType = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/create-type',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    editCostType = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/edit-type',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    deleteCostType = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/delete-type',
            data: {
                costTypeId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    increaseCostTypeOrder = async (costTypeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/increase-type-order',
            data: { costTypeId }
        }, true) as AxiosResponse;
        return this;
    }

    decreaseCostTypeOrder = async (costTypeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/decrease-type-order',
            data: { costTypeId }
        }, true) as AxiosResponse;
        return this;
    }

    changeCostTypeColor = async (typeId: string, color: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/edit-type-color',
            data: { typeId, color }
        }, true) as AxiosResponse;
        return this;
    }

    // ------------------------------------------------------------
    //  ALL TYPES FOR ENTITY FIXED COST
    // ------------------------------------------------------------
    getFixedCosts = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/list-fixed',
            data: filters ? filters : {},
        }, true) as AxiosResponse;
        return this;
    }

    getFixedCostById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/get-fixed',
            data: {
                costFixedId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createFixedCost = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/create-fixed',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    editFixedCost = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/edit-fixed',
            data: data
        }, true) as AxiosResponse;
        return this;
    }

    deleteFixedCost = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COST_ENDPOINT + '/delete-fixed',
            data: {
                costFixedId: id
            }
        }, true) as AxiosResponse;
        return this;
    }

}