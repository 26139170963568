export default function useTruncateString() {

    const truncateString = (string: string, wordsNumber: number) => {
        const words = string?.split(' ') || [];
        const truncatedWords = words.slice(0, wordsNumber);
        const truncatedText = truncatedWords.join(' ');

        return (words.length > wordsNumber ? truncatedText + ' ...' : truncatedText);
    }

    return { truncateString };

}