import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const CODES_ENDPOINT = '/financial';

export class CodeService extends RestServiceConnection {

    listCodes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CODES_ENDPOINT + '/list-budget-codes',
            data: filters
        }, true) as AxiosResponse;
        return this;
    }

    createCode = async (code: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CODES_ENDPOINT + '/create-budget-code',
            data: code
        }, true) as AxiosResponse;
        return this;
    }

    editCode = async (code: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CODES_ENDPOINT + '/edit-budget-code',
            data: code
        }, true) as AxiosResponse;
        return this;
    }

    deleteCode = async (code: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CODES_ENDPOINT + '/delete-budget-code',
            data: { code }
        }, true) as AxiosResponse;
        return this;
    }

}