import { Fragment, useContext } from 'react';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../layout/SubHeader/SubHeader";
import { CardTitle } from "../../components/bootstrap/Card";
import Page from '../../layout/Page/Page';
import BudgetStatusesGraph from './dashBoard-cards/BudgetStatusesGraph';
import DashBoardTasksList from './dashBoard-cards/DashBoardTasksList';
import ProjectStatusesGraph from './dashBoard-cards/ProjectStatusesGraph';
import ProfitChart from './dashBoard-cards/ProfitChart';
import LastProjects from './dashBoard-cards/LastProjects';
import LastBudgets from './dashBoard-cards/LastBudgets';
import DashBoardFilters from './dashBoard-options/DashBoardFilters';
import { PrivilegeContext } from '../../components/priviledge/PriviledgeProvider';
import classNames from 'classnames';

export interface ICommandFilter {
	classification?: string;
	between_dates?: {
		start_date?: string,
		end_date?: string,
	};
	path?: string;
}

export interface DashboardProps {
	filters: ICommandFilter | any;
	dashboardDates: { startDate: string | null; endDate: string | null };
}

const DashboardPage = () => {

	const { userCan } = useContext(PrivilegeContext);

	if (!userCan('show_dashboard', 'companies')) return null;

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<CardTitle>Panel de Control</CardTitle>
				</SubHeaderLeft>
				<SubHeaderRight>
					<DashBoardFilters />
				</SubHeaderRight>
			</SubHeader>

			<Page container="fluid">
				<div className='row'>
					<div className='col-md-7'>
						{userCan('list', 'budget') && (
							<>
								<div className='row'>
									<div className='col-md-8'>
										<LastBudgets />
									</div>
									<div className='col-md-4'>
										<BudgetStatusesGraph />
									</div>
								</div>
							</>
						)}

						{userCan('turnover_graph', 'user') && <ProfitChart />}

						{userCan('list', 'project') && (
							<>
								<div className='row'>
									<div className='col-md-8'>
										<LastProjects />
									</div>
									<div className='col-md-4'>
										<ProjectStatusesGraph />
									</div>
								</div>
							</>
						)}
					</div>

					<div className='col-md-5'>
						{userCan('list', 'tasks') && <DashBoardTasksList />}
					</div>
				</div>
			</Page>
		</Fragment>
	);
};

export default DashboardPage;