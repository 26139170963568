import React, { useEffect, useState } from 'react';
import useFilters from '../../hooks/useFilters';
import { useParams } from 'react-router-dom';

interface DirectoryNavigatorProviderProps {
    children: React.ReactNode;
}

interface DirectoryNavigatorContextProps {
    isLoading: boolean,
    setIsLoading: (loading: boolean) => void,
    selectedDocumentType: { id: string, title: string, children: any },
    setSelectedDocumentType: (documentType: { id: string, title: string, children: any }) => void,
    updateList: boolean,
    setUpdateList: (update: boolean) => void,
    filters: any,
    updateFilters: (newFilters: any) => void,
}

export const DirectoryNavigatorContext = React.createContext<DirectoryNavigatorContextProps>({
    isLoading: false,
    setIsLoading: () => { },
    selectedDocumentType: { id: '', title: '', children: [] },
    setSelectedDocumentType: () => { },
    updateList: false,
    setUpdateList: () => { },
    filters: {},
    updateFilters: () => { },
});

const DirectoryNavigatorProvider: React.FC<DirectoryNavigatorProviderProps> = ({ children }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters, updateFilters } = useFilters({ project: id, documentType: "" }, [], 1, 999999999);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState<{ id: string, title: string, children: any }>({ id: '', title: '', children: [] });
    const [updateList, setUpdateList] = useState<boolean>(false);

    // Change selectedDocument filter when selectedDocumentType changes
    useEffect(() => {
        if (selectedDocumentType.id) {
            updateFilters({ parent: selectedDocumentType?.id, documentType: selectedDocumentType?.id });
        } else {
            updateFilters({ documentType: "" });
        }
    }, [selectedDocumentType]);

    const value = {
        isLoading,
        setIsLoading,
        selectedDocumentType,
        setSelectedDocumentType,
        updateList,
        setUpdateList,
        filters,
        updateFilters,
    };

    return (
        <DirectoryNavigatorContext.Provider value={value}>
            {children}
        </DirectoryNavigatorContext.Provider>
    );
};

export default DirectoryNavigatorProvider;


export function useDirectoryNavigator() {
    const context = React.useContext(DirectoryNavigatorContext);
    if (context === undefined) {
        throw new Error('useDirectoryNavigator must be used within a DirectoryNavigatorProvider');
    }
    return context;
};