import React, { useEffect, useRef } from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useCashFlow } from '../../pages/cash-flow/provider/CashFlowProvider';
import '../../styles/scheduler.css';

interface Day {
    date?: Date;
    weekNumber?: number;
    month?: string;
    year?: number;
    start?: Date;
    end?: Date;
}

interface Month {
    name: string;
    year: number;
    days: Day[];
}

interface HeaderComponentProps {
    months: Month[];
    mode: string;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ months, mode }) => {

    const { initialBalance } = useCashFlow();

    const tableRef = useRef<HTMLTableSectionElement>(null);
    const today = new Date();

    const isToday = (date: Date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const isStartBalance = (date: Date) => {
        if (!initialBalance?.initialBankBalanceDate) return false;
        const initialDate = new Date(initialBalance.initialBankBalanceDate?.date);
        return date.getDate() === initialDate.getDate() &&
            date.getMonth() === initialDate.getMonth() &&
            date.getFullYear() === initialDate.getFullYear();
    };

    const addDays = (date: Date, days: number = 1) => {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy;
    };

    /**
     * Scroll to the current day cell
     */
    useEffect(() => {
        if (tableRef.current) {
            const todayCell = tableRef.current.querySelector('th.today');
            if (todayCell) {
                todayCell.scrollIntoView({ behavior: 'smooth', inline: 'center' });
            }
        }
    }, [months]);

    return (
        <thead ref={tableRef}>
            <tr className='text-center'>
                <th className="fixed-column" rowSpan={2}>Categorías</th>
                {mode !== 'month' && (
                    months.map((month, index) => (
                        <th key={index} colSpan={month.days.length}>
                            {capitalizeFirstLetter(month.name)} {month.year}
                        </th>
                    ))
                )}
            </tr>
            <tr>
                {months.flatMap((month, index) =>
                    month.days.map((day, dayIndex) => {
                        if (mode === 'day') {
                            const isTodayClass = day.date && isToday(new Date(day.date)) ? 'today' : '';
                            const isStartBalanceClass = day.date && isStartBalance(new Date(day.date)) ? 'start-balance' : '';
                            return (
                                <th key={`${index}-${dayIndex}`} className={isTodayClass + isStartBalanceClass}>
                                    {day.date?.getDate()}
                                </th>
                            );
                        } else if (mode === 'week') {
                            const isTodayClass = (day.start && day.end) && (today >= addDays(day.start) && today <= addDays(day.end) ? 'today' : '');
                            const initialDate = new Date(initialBalance?.initialBankBalanceDate?.date);
                            const isStartBalanceClass = (day.start && day.end) && (initialDate >= addDays(day.start) && initialDate <= addDays(day.end) ? 'start-balance' : '');
                            return (
                                <th key={`${index}-${dayIndex}`} className={isTodayClass + ' ' + isStartBalanceClass}>
                                    {`Semana ${day.weekNumber}`}
                                </th>
                            );
                        } else if (mode === 'month') {
                            const isTodayClass = (day.start && day.end) && (today >= day.start && today <= day.end ? 'today' : '');
                            const initialDate = new Date(initialBalance?.initialBankBalanceDate?.date);
                            const isStartBalanceClass = (day.start && day.end) && (initialDate >= day.start && initialDate <= day.end ? 'start-balance' : '');
                            return (
                                <th key={`${index}-${dayIndex}`} colSpan={month.days.length} className={isTodayClass + ' ' + isStartBalanceClass}>
                                    {capitalizeFirstLetter(month.name)} {month.year}
                                </th>
                            );
                        }
                        return null;
                    })
                )}
            </tr>
        </thead>
    );
};

export default HeaderComponent;