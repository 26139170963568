import axios from "axios";
import { RestServiceConnection } from "../restServiceConnection";

export class LoginService extends RestServiceConnection {

  authUserCredentials = async (username: string, password: string) => {
    this.response = await this.makeRequest({
      method: "POST",
      url: "/login_check",
      data: {
        username: username,
        password: password,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return this;
  }

  sendEmailToResetPassword = async (email: string) => {
    let response = axios.post(this.apiPublicUrl + "/send-email", {
      email: email,
    });
    return response;
  }

  resetPassword = async (password: string, password_confirmation: string, token: string) => {
    let response = axios.post(this.apiPublicUrl + "/reset-password", {
      password: password,
      password_confirmation: password_confirmation,
      query_token: token,
    });
    return response;
  }

}