import Button from "../../../components/bootstrap/Button";
import { CardTitle } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import BudgetStages from "./BudgetStages";
import { toast } from "react-toastify";
import { useBugdetForm } from "../providers/BudgetProvider";
import { Concept } from "../BudgetsForm";

const BudgetStageForm = () => {

    const {
        formikStages, verifyClassStages, showErrorsStages,
        stagesData, conceptsData, stageConceptsPercents, setStageConceptsPercents,
        stageConceptId, setStageConceptId, stageConceptPercentage, setStageConceptPercentage
    } = useBugdetForm();

    // Check if total percentage of stage concepts is less or equal to 100%
    const canAddConceptToStage = (conceptsFiltered: any) => {
        formikStages.setFieldValue('concepts', [...conceptsFiltered, { id: stageConceptId.value, percentage: stageConceptPercentage }]);
        setStageConceptId({ value: '', label: '' });
        setStageConceptPercentage(0);
    };

    // Calculate total percentage for each stage concept
    const _handleCalculateStageConceptsPercentage = (conceptsFiltered: any) => {
        // Filter stage concepts that have the same id as the concept to be added
        const stageConceptsPercentsFiltered = stageConceptsPercents.filter((concept: any) => concept.id === stageConceptId.value);

        // If the stage percentages array is empty, the sum will be equal to stageConceptPercentage, if not, 
        // the percentages of the concepts that have the same id as the concept to be added are added
        const stageConceptsPercentsSum = stageConceptsPercentsFiltered.length !== 0
            ? stageConceptsPercentsFiltered.reduce((acc: number, concept: any) => Number(acc) + Number(concept.percent), 0)
            : 0;

        if ((Number(stageConceptsPercentsSum) + Number(stageConceptPercentage)) > 100) {
            toast.info('El porcentaje total para este concepto no puede ser superior al 100%');
        } else {
            // If the concept already exists in the percentages array, its percentage is updated, if not, it is added
            if (stageConceptsPercents.some((concept: any) => concept.id === stageConceptId.value)) {
                const stageConceptsPercentsUpdated = stageConceptsPercents.map((concept: any) => {
                    if (concept.id === stageConceptId.value) {
                        return {
                            id: concept.id,
                            percent: Number(concept.percent) + Number(stageConceptPercentage),
                        }
                    }
                    return concept;
                });
                setStageConceptsPercents(stageConceptsPercentsUpdated);
            } else {
                setStageConceptsPercents([...stageConceptsPercents, { id: stageConceptId.value, percent: stageConceptPercentage }]);
            }

            // Add the concept to the stage and reset the fields to be able to add another concept
            canAddConceptToStage(conceptsFiltered);
        }
    };

    // Add concept to stage
    const handleAddConceptToStage = () => {
        if (stageConceptId.value === '' || stageConceptPercentage === 0) {
            toast.info('Debes completar ambos campos para poder añadir el concepto');
            return;
        }

        // Delete empty concept created by default
        const conceptsFiltered = formikStages.values.concepts.filter((concept: any) => concept.id !== '');

        // Check that the concept does not already exist in the stage
        if (conceptsFiltered.some((concept: any) => concept.id === stageConceptId.value)) {
            toast.info('Este concepto ya se ha añadido');
            return;
        }

        // Calculate total percentage for each stage concept and check if it is less or equal to 100%
        _handleCalculateStageConceptsPercentage(conceptsFiltered);
    };

    // Delete concept from stage
    const handleDeleteConceptFromStage = (concept: any) => {
        // Delete concept from stage concepts array
        const conceptsFiltered = formikStages.values.concepts.filter((c: any) => c.id !== concept.id);
        formikStages.setFieldValue('concepts', conceptsFiltered);

        // Go through stageConceptsPercents and subtract the percentage of the deleted concept from the percentage of the concepts that have the same id
        const stageConceptsPercentsUpdated = stageConceptsPercents.map((c: any) => {
            if (c.id === concept.id) {
                return {
                    id: c.id,
                    percent: Number(c.percent) - Number(concept.percentage),
                }
            }
            return c;
        });
        setStageConceptsPercents(stageConceptsPercentsUpdated);
    };

    return (
        <form onSubmit={formikStages.handleSubmit} autoComplete="off">
            <CardTitle className="mt-5 mb-3">Fases</CardTitle>

            {stagesData?.length > 0 && <BudgetStages />}

            {/* Form to add a stage to the budget */}
            <div className="mt-4 p-4 rounded" style={{ border: '1px solid lightgray' }}>
                <div className="row d-flex justify-content-between">
                    <div className="col-md-6">
                        <>
                            <div className="row d-flex justify-content-between">
                                <FormGroup label="Nombre" requiredInputLabel className="col-md-7">
                                    <Input id='name' onChange={formikStages.handleChange} value={formikStages.values.name} onBlur={formikStages.handleBlur} className={verifyClassStages('name')} />
                                    {showErrorsStages('name')}
                                </FormGroup>
                                <FormGroup label="Semanas" requiredInputLabel className="col-md-2">
                                    <Input type="number" id='duration' onChange={formikStages.handleChange} value={formikStages.values.duration} onBlur={formikStages.handleBlur} className={verifyClassStages('duration')} />
                                    {showErrorsStages('duration')}
                                </FormGroup>
                            </div>

                            <div className="row mt-3">
                                <FormGroup label="Descripción" className="col-md-12">
                                    <textarea
                                        id='textField'
                                        value={formikStages.values.textField}
                                        onChange={formikStages.handleChange}
                                        onBlur={formikStages.handleBlur}
                                        className={verifyClassStages('textField')}
                                        rows={4}
                                        style={{
                                            borderRadius: '10px',
                                            boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                                            backgroundColor: '#f8f9fa',
                                            width: '100%',
                                            padding: '15px',
                                            border: '0'
                                        }}
                                    />
                                    {showErrorsStages('textField')}
                                </FormGroup>
                            </div>
                        </>
                    </div>

                    <div className="col-md-5">
                        <>
                            <div className="row">
                                <FormGroup label="Concepto asociado" className="col-md-8">
                                    <SearchableSelect
                                        isSearchable
                                        options={conceptsData?.map((concept: Concept) => {
                                            return {
                                                value: concept.id,
                                                label: concept.name,
                                            }
                                        })}
                                        value={stageConceptId}
                                        onChange={(e: any) => {
                                            setStageConceptId(e);
                                            formikStages.handleChange(e.value);
                                        }}
                                        name='concept-select'
                                        placeholder='concepto'
                                    />
                                </FormGroup>

                                <FormGroup label="Porcentaje" className="col-md-2">
                                    <Input
                                        type="number"
                                        id='percentage'
                                        value={stageConceptPercentage}
                                        onChange={(e: any) => {
                                            if (e.target.value > 100 || e.target.value < 0) {
                                                toast.info('El porcentaje debe estar entre 1% y 100%');
                                                return;
                                            }
                                            setStageConceptPercentage(e.target.value);
                                            formikStages.handleChange(e.target.value);
                                        }}
                                        onBlur={formikStages.handleBlur}
                                        min={0} max={100}
                                    />
                                </FormGroup>

                                <FormGroup label="Añadir" className="col-md-2 d-flex flex-column">
                                    <Button
                                        icon="Add"
                                        title="Añadir concepto a la fase"
                                        onClick={handleAddConceptToStage} />
                                </FormGroup>

                                {formikStages.values.concepts.length > 0 && (
                                    <div className="col-md-12 mt-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="text-center">
                                                    <th scope="col" className="col-md-8">Concepto</th>
                                                    <th scope="col" className="col-md-3">Porcentaje</th>
                                                    <th scope="col" className="col-md-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formikStages.values.concepts.map((concept: any, index: number) => {
                                                    return (
                                                        <tr key={concept.id + index} className="text-center">
                                                            <td>{conceptsData.find((c: any) => c.id === concept.id)?.name}</td>
                                                            <td>{concept.percentage}%</td>
                                                            <td className="p-0">
                                                                <Button
                                                                    icon="Close" title="Eliminar concepto de la fase"
                                                                    onClick={() => { handleDeleteConceptFromStage(concept) }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </>
                    </div>
                </div>
            </div>

            <div className="col-md-12 d-flex justify-content-center mt-3">
                <Button type="submit" color='secondary' isLight onClick={formikStages.handleSubmit}>Guardar y añadir otra fase</Button>
            </div>
        </form>
    )
}

export default BudgetStageForm;