import { Fragment, useCallback, useContext, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import Spinner from "../../../components/bootstrap/Spinner";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { ProjectService } from "../../../services/projects/projectService";
import { SubsidiesApiResponse } from "../../../type/subsidy-type";
import StatusDropdown from "../../../components/StatusDropdown";
import CreateModalSubsidy from "../subsidy-create/createModal";
import SubsidyFilters from "./subsidy-options/SubsidyFilters";

export interface ISubsidyFilters {
    active?: number;
}

const subsidyFilters: ISubsidyFilters = {
    active: 1
};

const SubsidyList = () => {

    const { userCan } = useContext(PrivilegeContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(subsidyFilters);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const projectService = new ProjectService();
        const response = await projectService.getSubsidies(filters);
        return response.getResponseData() as SubsidiesApiResponse;
    }, [filters]));

    const toggleSubsidyStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = await (await new ProjectService().toggleSubsidyStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                toast.success(status ? 'Se ha activado el usuario' : 'Se ha desactivado el usuario');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new ProjectService()).deleteSubsidy(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Subvención eliminada correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar la subvención");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            setSelectedItem(null);
            setShow(false);
            setModalOpen(false);
            refetch();
            toast.success(message);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage error={error} />;

        if (data) {
            let apiData = data as SubsidiesApiResponse;

            return (
                <CustomTable
                    title="Subvenciones"
                    data={apiData ? apiData.subsidies : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as SubsidiesApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre de la convocatoria",
                            keyValue: "name",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-center" onClick={() => {
                                        setSelectedItem(element);
                                        setModalOpen(true);
                                        setShow(true);
                                    }}>
                                        {element.name}
                                    </div>
                                )
                            },

                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <div className="text-start">
                                            {element.description || "-"}
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            className: (userCan("list", "companies") ? "text-center" : "d-none"),
                            isVisible: (userCan("list", "companies") ? true : false),
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.company.name || "-"}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Estado",
                            keyValue: "active",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={element.id}>
                                        <StatusDropdown
                                            disabled={changingStatus.includes(element.id)}
                                            itemId={element.id}
                                            status={element.active}
                                            change={toggleSubsidyStatus}
                                        />
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Editar",
                                buttonType: 'icon',
                                icon: "Edit",
                                additionalClasses: `text-primary ${userCan("edit", "subsidy") ? "" : "d-none"}`,
                                description: "Editar subvención",
                                callback: (item: any) => {
                                    if (!userCan("edit", "subsidy")) return;
                                    setSelectedItem(item);
                                    setModalOpen(true);
                                    setShow(false);
                                },
                            },
                            {
                                title: "Eliminar",
                                buttonType: 'icon',
                                icon: "Delete",
                                additionalClasses: `text-danger ${userCan("delete", "subsidy") ? "" : "d-none"}`,
                                description: "Eliminar subvención",
                                callback: (item: any) => {
                                    if (!userCan("delete", "subsidy")) return;
                                    handleConfirmationAlert({
                                        title: "Eliminar Subvención",
                                        text: "¿Está seguro que desea eliminar la Subvención?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        }
                                    })
                                },
                            },
                            {
                                title: "Sin permisos",
                                buttonType: 'icon',
                                icon: "Block",
                                additionalClasses: `text-danger ${(userCan('edit', 'subsidy') || userCan('delete', 'subsidy')) ? 'd-none' : ''}`,
                                description: "No tiene permisos para editar ni eliminar",
                                callback: (item: any) => { },
                            }
                        ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Subvenciones</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="primary"
                        title="Crear Subvención"
                        icon="Add"
                        isLight
                        className={userCan("create", "subsidy") ? "" : "d-none"}
                        onClick={() => {
                            if (!userCan("create", "subsidy")) return;
                            setModalOpen(true);
                            setSelectedItem(null);
                            setShow(false);
                        }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <SubsidyFilters filters={filters} updateFilters={updateFilters} updatePageSize={updatePageSize} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card>
                    <CardBody className="table-responsive">
                        <Fragment>{getContent()}</Fragment>
                    </CardBody>
                </Card>
            </Page>
            {modalOpen && (<CreateModalSubsidy isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />)}
        </Fragment>
    );
};

export default SubsidyList;