import { FC, useCallback, useEffect, useRef, useState } from "react";
import { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { Project } from "../../../type/project-type";
import useFetch from "../../../hooks/useFetch";
import { ClientService } from "../../../services/clients/clientService";
import SearchableSelect from "../../../components/selector/SearchableSelect";
import { UserService } from "../../../services/users/userService";
import { UserApiResponse } from "../../../type/user-type";
import { ProjectService } from "../../../services/projects/projectService";
import Spinner from "../../../components/bootstrap/Spinner";
import { FormControl } from "react-bootstrap";
import CustomSearchSelect from "../../../components/selector/CustomSearchSelect";

interface GeneralProjectInfoProps {
    budgetData: any;
    formik: any;
    verifyClass: (inputStageID: any) => "" | "is-invalid";
    showErrors: (inputStageID: any) => JSX.Element;
    mode: string;
}

const GeneralProjectInfo: FC<GeneralProjectInfoProps> = ({ budgetData, formik, verifyClass, showErrors, mode }) => {

    const timeoutRef = useRef<any>(null);

    const [clientSearch, setClientSearch] = useState('');
    const [clientOptions, setClientOptions] = useState<any>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isEndPickerEnabled, setIsEndPickerEnabled] = useState<boolean>(false);

    const getClientsOptions = async (id?: string) => {
        const response = (await ((new ClientService).getClients({ limit: 999999999 }))).response;
        if (response?.status === 200) {
            const options = response?.data?.data?.clients?.map((item: { id: number; name: string, firstName: string, lastName: string, clientContactPerson: any }) => (
                {
                    value: item.id,
                    label: item.name
                        + (item.firstName ? ' ' + item.firstName : '') + (item.lastName ? ' ' + item.lastName : '')
                        + (item.clientContactPerson ? ' (' + item.clientContactPerson.name + (item.clientContactPerson?.firstName ? (' ' + item.clientContactPerson.firstName) : '') + (item.clientContactPerson?.lastName ? (' ' + item.clientContactPerson.lastName) : '') + ')' : ''),
                }
            ));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setClientOptions(options);
        }
    };

    const [users] = useFetch(useCallback(async () => {
        const response = (await (new UserService).getUsers());
        return response.getResponseData() as UserApiResponse;
    }, []));

    const [subsidies] = useFetch(useCallback(async () => {
        const response = (await (new ProjectService).getSubsidies());
        return response.getResponseData() as UserApiResponse;
    }, []));

    const getUsersList = () => {
        if (users !== null) {
            return users.users.map((user: { id: string, name: string, firstName: string, lastName: string }) => {
                return {
                    value: user.id,
                    label: user.name + (user.firstName ? " " + user.firstName : "") + (user.lastName ? " " + user.lastName : ""),
                    isSelected: user.id === formik.values.planner,
                }
            })
        }
        return [];
    };

    const getSubsidiesList = () => {
        if (subsidies !== null) {
            return subsidies.subsidies.map((subsidy: { id: string, name: string }) => {
                return {
                    value: subsidy.id,
                    label: subsidy.name,
                    isSelected: subsidy.id === formik.values.subsidy,
                }
            })
        }
        return [];
    };

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("project.startDate", e.target.value);
        formik.setFieldValue("projectStartDate", e.target.value);
        const selectedStartDate = new Date(e.target.value);
        setStartDate(selectedStartDate);
        setIsEndPickerEnabled(true); // Habilita el segundo input al seleccionar una fecha de inicio
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("project.deliveryDate", e.target.value);
        formik.setFieldValue("projectDeliveryDate", e.target.value);
        const selectedEndDate = new Date(e.target.value);
        setEndDate(selectedEndDate);
    };

    function formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = `${(date.getMonth() + 1)}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Obtener los valores seleccionados por defecto en los select
    const plannerSelected = getUsersList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const subsidySelected = getSubsidiesList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const defaultPlannerValue = plannerSelected[0] ? { value: plannerSelected[0].value, label: plannerSelected[0].label } : null;
    const defaultSubsidyValue = subsidySelected[0] ? { value: subsidySelected[0].value, label: subsidySelected[0].label } : null;

    useEffect(() => {
        if (budgetData !== null) {
            formik.setFieldValue("company", budgetData.company?.id);
            formik.setFieldValue("budget", budgetData.id);
        }
    }, [budgetData]);

    useEffect(() => {
        if (formik.values.project) {
            setStartDate(new Date(formik.values.project.startDate));
            setEndDate(new Date(formik.values.project.deliveryDate));
        }
    }, [formik.values.project]);

    // Cuando se cambia el valor de clientSearch, se ejecuta el timeout para obtener los clientes
    useEffect(() => {
        if (clientSearch !== '' && clientSearch.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getClientsOptions();
            }, 500);
            return () => { clearTimeout(timeoutRef.current) }
        }
    }, [clientSearch]);

    // Obtener los valores seleccionados por defecto en los select
    useEffect(() => {
        if (formik.values.surveyor) {
            formik.values.surveyor && getClientsOptions(formik.values.surveyor);
        }
    }, [formik.values.surveyor]);
    useEffect(() => {
        if (formik.values.constructionManager) {
            formik.values.constructionManager && getClientsOptions(formik.values.constructionManager);
        }
    }, [formik.values.constructionManager]);

    const getGeneralInfo = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <CardHeader className="rounded-1 mb-0">
                        <CardLabel icon="DocumentScanner" iconColor="primary">
                            <CardTitle>Información general del proyecto</CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="row">
                        <FormGroup requiredInputLabel label="Proyectista" className="col-md-2">
                            <SearchableSelect
                                isSearchable
                                name='planner'
                                defaultValue={defaultPlannerValue}
                                options={getUsersList()}
                                onChange={(e: any) => {
                                    formik.setFieldValue("planner", e.value);
                                }}
                                placeholder="proyectista "
                                classname={verifyClass("planner")}
                            />
                            {showErrors("planner")}
                        </FormGroup>

                        <FormGroup label="Aparejador" className="col-md-3">
                            <CustomSearchSelect
                                isClearable
                                id='surveyor-select'
                                options={clientOptions}
                                onInputChange={(e: any) => { setClientSearch(e) }}
                                onChangeSingle={(e: any) => {
                                    formik.setFieldValue('surveyor', e?.value || '');
                                }}
                                defaultValue={clientOptions.find((option: any) => option.value === formik.values.surveyor) || null}
                            />
                            {showErrors("surveyor")}
                        </FormGroup>

                        <FormGroup label="Jefe de obra" className="col-md-3">
                            <CustomSearchSelect
                                isClearable
                                id='constructionManager-select'
                                options={clientOptions}
                                onInputChange={(e: any) => { setClientSearch(e) }}
                                onChangeSingle={(e: any) => {
                                    formik.setFieldValue('constructionManager', e?.value || '');
                                }}
                                defaultValue={clientOptions.find((option: any) => option.value === formik.values.constructionManager) || null}
                            />
                            {showErrors("constructionManager")}
                        </FormGroup>

                        <FormGroup label="Subvención" className="col-md-2">
                            <SearchableSelect
                                isClearable
                                isSearchable
                                name='subsidy'
                                defaultValue={defaultSubsidyValue}
                                options={getSubsidiesList()}
                                onChange={(e: any) => {
                                    formik.setFieldValue("subsidy", e?.value || '');
                                }}
                                placeholder="subvención "
                                classname={verifyClass("subsidy")}
                            />
                            {showErrors("subsidy")}
                        </FormGroup>

                        <FormGroup label="CIF" className="col-md-2">
                            <Input
                                id="cif"
                                value={formik.values.project.cif}
                                onChange={(e: any) => {
                                    formik.setFieldValue("project.cif", e.target.value);
                                    formik.setFieldValue("cif", e.target.value);
                                }}
                                className={verifyClass("cif")}
                            />
                            {showErrors("cif")}
                        </FormGroup>
                    </CardBody>
                </div>
            </div>
        )
    };

    const getAdditionalData = () => {
        return (
            <div className="row mt-3">
                <div className="col-md-12">
                    <CardHeader className="rounded-1 mb-0">
                        <CardLabel icon="Info" iconColor="primary">
                            <CardTitle>Datos del proyecto</CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody>
                        <div className="row">
                            <div className="col-md-8">
                                <>
                                    <div className="row">
                                        <FormGroup requiredInputLabel label="Dirección" className="col-md-6">
                                            <Input
                                                id="adrress"
                                                value={formik.values.project?.address}
                                                onChange={(e: any) => {
                                                    formik.setFieldValue("project.address", e.target.value);
                                                    formik.setFieldValue("projectAddress", e.target.value);
                                                }}
                                                className={verifyClass("projectAddress")}
                                            />
                                            {showErrors("projectAddress")}
                                        </FormGroup>

                                        <FormGroup requiredInputLabel label="Población" className="col-md-3">
                                            <Input
                                                id="population"
                                                value={formik.values.project.population}
                                                onChange={(e: any) => {
                                                    formik.setFieldValue("project.population", e.target.value);
                                                    formik.setFieldValue("projectPopulation", e.target.value);
                                                }}
                                                className={verifyClass("projectPopulation")}
                                            />
                                            {showErrors("projectPopulation")}
                                        </FormGroup>

                                        <FormGroup requiredInputLabel label="Provincia" className="col-md-3">
                                            <Input
                                                id="province"
                                                value={formik.values.project.province}
                                                onChange={(e: any) => {
                                                    formik.setFieldValue("project.province", e.target.value);
                                                    formik.setFieldValue("projectProvince", e.target.value);
                                                }}
                                                className={verifyClass("projectProvince")}
                                            />
                                            {showErrors("projectProvince")}
                                        </FormGroup>
                                    </div>

                                    <div className="row mt-2">
                                        <FormGroup requiredInputLabel label="Fecha de inicio de obra" className="col-md-3">
                                            <FormControl
                                                type="date"
                                                id="startDate"
                                                value={startDate ? formatDate(startDate) : ''}
                                                onChange={handleStartDateChange}
                                                className={verifyClass("projectStartDate")}
                                            />
                                            {showErrors("projectStartDate")}
                                        </FormGroup>

                                        <FormGroup requiredInputLabel label="Fecha de fin de obra" className="col-md-3">
                                            <FormControl
                                                type="date"
                                                id="deliveryDate"
                                                value={endDate ? (endDate instanceof Date ? formatDate(endDate) : '') : ''}
                                                onChange={handleEndDateChange}
                                                min={startDate ? (startDate instanceof Date ? formatDate(startDate) : '') : ''}
                                                disabled={mode === 'Crear' ? !isEndPickerEnabled : false}
                                                className={verifyClass("projectDeliveryDate")}
                                            />
                                            {showErrors("projectDeliveryDate")}
                                        </FormGroup>

                                        <FormGroup requiredInputLabel label="Año construcción" className="col-md-3">
                                            <Input
                                                type="number"
                                                id="constructionYear"
                                                value={formik.values.project.constructionYear}
                                                onChange={(e: any) => {
                                                    formik.setFieldValue("project.constructionYear", e.target.value);
                                                    formik.setFieldValue("projectConstructionYear", e.target.value);
                                                }}
                                                className={"w-75 " + verifyClass("projectConstructionYear")}
                                            />
                                            {showErrors("projectConstructionYear")}
                                        </FormGroup>

                                        <FormGroup requiredInputLabel label="Código postal" className="col-md-2">
                                            <Input
                                                type="number"
                                                id="pc"
                                                value={formik.values.project.pc}
                                                onChange={(e: any) => {
                                                    formik.setFieldValue("project.pc", e.target.value);
                                                    formik.setFieldValue("projectPC", e.target.value);
                                                }}
                                                className={verifyClass("projectPC")}
                                            />
                                            {showErrors("projectPC")}
                                        </FormGroup>
                                    </div>

                                    <div className="row mt-2">
                                        <FormGroup requiredInputLabel label="Referencia catastral" className="col-md-3">
                                            <Input
                                                id="cad_ref"
                                                value={formik.values.project.cad_ref}
                                                onChange={(e: any) => {
                                                    formik.setFieldValue("project.cad_ref", e.target.value);
                                                    formik.setFieldValue("projectCadRef", e.target.value);
                                                }}
                                                className={verifyClass("projectCadRef")}
                                            />
                                            {showErrors("projectCadRef" as keyof Project)}
                                        </FormGroup>

                                        <FormGroup requiredInputLabel label="PEM" className="col-md-2">
                                            <Input
                                                type="number"
                                                id="pem"
                                                value={formik.values.project.pem}
                                                onChange={(e: any) => {
                                                    formik.setFieldValue("project.pem", e.target.value);
                                                    formik.setFieldValue("projectPEM", e.target.value);
                                                }}
                                                className={verifyClass("projectPEM")}
                                            />
                                            {showErrors("projectPEM")}
                                        </FormGroup>
                                    </div>

                                    {mode === 'Editar' && (
                                        <div className="row mt-2">
                                            {formik.values.project.gmu !== null &&
                                                <FormGroup label="Nº Expediente GMU" className="col-md-3">
                                                    <Input
                                                        id="gmu"
                                                        value={formik.values.project.gmu}
                                                        onChange={(e: any) => {
                                                            formik.setFieldValue("project.gmu", e.target.value);
                                                            formik.setFieldValue("projectGMU", e.target.value);
                                                        }}
                                                        className={verifyClass("projectGMU")}
                                                    />
                                                    {showErrors("projectGMU" as keyof Project)}
                                                </FormGroup>
                                            }

                                            {formik.values.project.licenseNumber !== null &&
                                                <FormGroup label="Nº Licencia" className="col-md-3">
                                                    <Input
                                                        id="licenseNumber"
                                                        value={formik.values.project.licenseNumber}
                                                        onChange={(e: any) => {
                                                            formik.setFieldValue("project.licenseNumber", e.target.value);
                                                            formik.setFieldValue("projectLicenseNumber", e.target.value);
                                                        }}
                                                        className={verifyClass("projectLicenseNumber")}
                                                    />
                                                    {showErrors("projectLicenseNumber" as keyof Project)}
                                                </FormGroup>
                                            }

                                            {formik.values.project.use !== null &&
                                                <FormGroup label="Nº Expediente Uso" className="col-md-3">
                                                    <Input
                                                        id="use"
                                                        value={formik.values.project.use}
                                                        onChange={(e: any) => {
                                                            formik.setFieldValue("project.use", e.target.value);
                                                            formik.setFieldValue("projectUse", e.target.value);
                                                        }}
                                                        className={verifyClass("projectUse")}
                                                    />
                                                    {showErrors("projectUse" as keyof Project)}
                                                </FormGroup>
                                            }

                                            {formik.values.project.bailNumberRefund !== null &&
                                                <FormGroup label="Nº Devolución Fianza" className="col-md-3">
                                                    <Input
                                                        id="bailNumberRefund"
                                                        value={formik.values.project.bailNumberRefund}
                                                        onChange={(e: any) => {
                                                            formik.setFieldValue("project.bailNumberRefund", e.target.value);
                                                            formik.setFieldValue("projectBailNumberRefund", e.target.value);
                                                        }}
                                                        className={verifyClass("projectBailNumberRefund")}
                                                    />
                                                    {showErrors("projectBailNumberRefund" as keyof Project)}
                                                </FormGroup>
                                            }
                                        </div>
                                    )}
                                </>
                            </div>

                            <div className="col-md-4">
                                <FormGroup label="Descripción">
                                    <textarea
                                        id='description'
                                        value={formik.values.project.description}
                                        onChange={(e: any) => {
                                            formik.setFieldValue("project.description", e.target.value);
                                            formik.setFieldValue("projectDescription", e.target.value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        className={verifyClass('projectDescription')}
                                        rows={10}
                                        style={{
                                            borderRadius: '10px',
                                            boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                                            backgroundColor: '#f8f9fa',
                                            width: '100%',
                                            padding: '15px',
                                            border: '0'
                                        }}
                                    />
                                    {showErrors("projectDescription")}
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </div>
            </div>
        )
    };

    return (
        (mode === 'Editar' && (!defaultPlannerValue))
            ? <div className="text-center"><Spinner /></div>
            : <>
                {getGeneralInfo()}
                {getAdditionalData()}
            </>
    )
}

export default GeneralProjectInfo;