import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const BALANCE_ENDPOINT = '/balance';

export class BalanceService extends RestServiceConnection {

    getDailyBalances = async (from: string, to: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BALANCE_ENDPOINT + '/get-daily-balances',
            data: { from, to },
        }, true) as AxiosResponse;
        return this;
    }

    updateDailyBalance = async (date: string, quantity: number) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BALANCE_ENDPOINT + '/update-daily-balance',
            data: { date, quantity },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}