import { FC, useCallback, useContext, useEffect, useState } from 'react';
import AsyncImg from '../../../components/AsyncImg';
import Button from '../../../components/bootstrap/Button';
import UserWithAccessModal from './UserWithAccessModal';
import useFetch from '../../../hooks/useFetch';
import { ProjectService } from '../../../services/projects/projectService';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectsApiResponse } from '../../../type/project-type';
import { toast } from 'react-toastify';
import { PrivilegeContext } from '../../../components/priviledge/PriviledgeProvider';
import { authMenu } from '../../../menu';
import { handleConfirmationAlert } from '../../../utils/ConfirmationAlert';

export interface User {
    id: number;
    name: string;
    lastName: string;
    profileImg: string;
}

interface UsersWithAccessProps {
    users: any[];
}

export const UsersWithAccess: FC<UsersWithAccessProps> = ({ users }) => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { userCan } = useContext(PrivilegeContext);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const [participants, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await (new ProjectService).getProjectParticipants(id);
        return response.getResponseData() as ProjectsApiResponse;
    }, [id]));

    const _handleRemoveUser = async (userId: any) => {
        /* handleConfirmationAlert({
            title: 'Eliminar usuario',
            text: '¿Está seguro que desea eliminarlo del proyecto?',
            icon: 'warning',
            onConfirm: async () => { */
        try {
            const response = (await (new ProjectService).removeParticipant(id, userId)).getResponseData();
            if (response.success) {
                refetch();
            } else {
                response.data.errors.map((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error('Error al asignar el usuario al proyecto');
        }
    }
    /* });
}; */

    return (
        <div className="user-circles-container ms-3 d-flex align-items-center">
            {participants?.map((participant: any, index: number) => (
                <div
                    key={participant.id + index} className='user-circle'
                    onClick={() => navigate(`${authMenu.users.path}/${participant.id}/perfil`)}
                >
                    <div title={participant.name + ' ' + (participant.lastName || '')}>
                        <AsyncImg
                            id={participant.profileImg}
                            height="40px" width="40px" styles="avatar-content user-photo rounded" defaultAvatarSize={40}
                            closeBtn={userCan('edit', 'project') && !users.map((user: any) => user).includes(participant.id)}
                            closeBtnColor='light'
                            onClick={() => userCan('edit', 'project') && _handleRemoveUser(participant.id)}
                        />
                    </div>
                </div>
            ))}

            {userCan('edit', 'project') && <Button color='secondary' isLink icon='Add' className="ms-3" onClick={setOpenModal} />}

            {openModal && <UserWithAccessModal open={openModal} setOpen={setOpenModal} refetch={refetch} />}
        </div>
    );
};