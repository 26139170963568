import React, { useCallback, useContext, useEffect, useState } from "react";
import useFilters, { FilterOptions, FilterOrders } from "../../../../hooks/useFilters";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import useFetch from "../../../../hooks/useFetch";
import { IncomeService } from "../../../../services/incomes/incomeService";
import { IncomesApiResponse } from "../../../../type/income-type";
import { changeStorageViewMode } from "../../../../redux/schedulerSlice";
import moment from "moment";

type IncomesProviderProps = {
    children: React.ReactNode,
    defaultFilters?: any,
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
}

type IncomesContextData = {
    viewMode: "Mes" | "Semana" | "Día",
    changeViewMode: (view: "Mes" | "Semana" | "Día") => void,
    filters: FilterOptions | any,
    updateFilters: (filters: any) => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    isLoading: boolean,
    setIsLoading: (loading: boolean) => void,
    fromDate: string,
    setFromDate: (date: string) => void,
    toDate: string,
    setToDate: (date: string) => void,
    incomesList: any,
    refetchIncomes: () => void,
}

const IncomesContext: React.Context<IncomesContextData> = React.createContext<IncomesContextData>({
    viewMode: 'Día',
    changeViewMode: (view: "Mes" | "Semana" | "Día") => { },
    filters: {} as FilterOptions | any,
    updateFilters: (filters: any) => { },
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    isLoading: false,
    setIsLoading: (loading: boolean) => { },
    fromDate: '',
    setFromDate: (date: string) => { },
    toDate: '',
    setToDate: (date: string) => { },
    incomesList: null,
    refetchIncomes: () => { },
});

const IncomesProvider: React.FC<IncomesProviderProps> = ({ children, defaultFilters, defaultOrders = [] as FilterOrders, defaultPage = 1, defaultPageSize = 999999 }) => {

    //  Redux connection
    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.scheduler.viewMode);
    const incomeFilters = useSelector((state: RootState) => state.scheduler.incomeFilters);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>(incomeFilters?.estimated_entry_date?.from || moment().subtract(2, 'months').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<string>(incomeFilters?.estimated_entry_date?.to || moment().add(2, 'months').format('YYYY-MM-DD'));


    const [incomesList, loadingIncomes, incomesError, refetchIncomes] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) return null;
        setIsLoading(true);
        const response = await (new IncomeService).getIncomes(filters);
        setIsLoading(false);
        return response.getResponseData() as IncomesApiResponse;
    }, [filters]));

    const changeViewMode = (view: "Mes" | "Semana" | "Día") => {
        dispatch(changeStorageViewMode(view));
    };

    /**
     * Update the view mode filter when the view mode from the redux store changes
     */
    useEffect(() => {
        if (viewMode) {
            switch (viewMode) {
                case 'Mes':
                    updateFilters({ range_mode_view: 'month' });
                    break;
                case 'Semana':
                    updateFilters({ range_mode_view: 'week' });
                    break;
                case 'Día':
                    updateFilters({ range_mode_view: 'day' });
                    break;
                default:
                    break;
            }
        }
    }, [viewMode]);

    /* useEffect(() => {
        updateFilters({ estimated_entry_date: { from: fromDate, to: toDate } });
    }, [fromDate, toDate]); */

    useEffect(() => {
        if (!filters.filter_filters?.estimated_entry_date.from || !filters.filter_filters?.estimated_entry_date.to) {
            updateFilters({ estimated_entry_date: { from: fromDate, to: toDate } });
        }
    }, [incomeFilters]);

    return (
        <IncomesContext.Provider value={{
            viewMode,
            changeViewMode,
            filters,
            updateFilters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,
            isLoading,
            setIsLoading,
            fromDate,
            setFromDate,
            toDate,
            setToDate,
            incomesList,
            refetchIncomes,
        }}>
            {children}
        </IncomesContext.Provider>
    );
}

export { IncomesProvider, IncomesContext };

export function useIncomes() {
    return useContext(IncomesContext);
}