import { useFormik } from "formik";
import { FC, Fragment, useContext, useState } from "react";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import AsyncImg from "../../components/AsyncImg";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyService } from "../../services/companies/organizationService";
import { toast } from "react-toastify";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Page from "../../layout/Page/Page";
import IBAN from 'iban';
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import classNames from "classnames";
import moment from "moment";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    companyData?: CompanyForm;
    logo?: any;
}

export interface CompanyForm {
    cif: string;
    name: string;
    address: string;
    bankAccount: string;
    legalText: string;
    initialBankBalance: number;
    initialBankBalanceDate: string;
    logo?: Document;
}

const companyInitialValues: CompanyForm = {
    cif: '',
    name: '',
    address: '',
    bankAccount: '',
    legalText: '',
    initialBankBalance: 0,
    initialBankBalanceDate: moment().format('YYYY-MM-DD'),
}

const cifRegex = /^[ABCDEFGHJKLMNPQSUVW]{1}[0-9]{7}[0-9A-J]{1}$/;

const OrganizationEditSchema = yup.object({
    cif: yup.string().matches(cifRegex, 'Introduce un formato de cif válido').nullable().required('Campo obligatorio'),
    name: yup.string().min(1, 'Demasiado Corto').max(50, 'Demasiado Largo').required('Campo obligatorio'),
    address: yup.string().required('Campo obligatorio'),
    bankAccount: yup.string().test('bankAccount', 'Formato de IBAN no válido', (value: any) => IBAN.isValid(value)),
    legalText: yup.string().required('Campo obligatorio'),
    initialBankBalance: yup.number().required('Campo obligatorio'),
    initialBankBalanceDate: yup.date().required('Campo obligatorio'),
});

const CompanyForm: FC<CreateFormProps> = ({ isLoading, submit, companyData, logo }) => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = useContext(PrivilegeContext);

    const mode = companyData ? 'Editar' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<any>(null);

    const formik = useFormik({
        initialValues: companyData ? companyData : companyInitialValues,
        validationSchema: OrganizationEditSchema,
        onSubmit: values => submit(values)
    });

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const organizationService = new CompanyService();
            const response = await organizationService.editOrganizationImg(id, file);

            const responseData = response.getResponseData();

            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Logo actualizado');
                }, 100);
            } else {
                toast.error(responseData.message);
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará el logo de la organización',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const organizationService = new CompanyService();
                    const response = await organizationService.deleteOrganizationImg(id);

                    const responseData = response.getResponseData();

                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la imagen");
                }
            }
        })
    };

    const verifyClass = (inputFieldID: keyof CompanyForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof CompanyForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row">
                    {(mode === 'Editar' && userCan('image', 'companies'))
                        ? (
                            <div className={"col-xl-2"}>
                                <Card stretch={false} className={'mx-2'}>
                                    <CardHeader className='rounded-1 mb-0'>
                                        <CardTitle>Logo</CardTitle>
                                    </CardHeader>
                                    <CardBody className={'text-center'}>
                                        <div className={'text-center pb-4'}>
                                            {selectedImage
                                                ? <img width={'100%'} height={'100%'} src={selectedImage} alt="selected" className='mx-auto d-block img-fluid mb-3 rounded' />
                                                : logo
                                                    ? <AsyncImg isBackground height="100%" width="100%" styles="avatar-content" id={logo.id} />
                                                    : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                                            }
                                        </div>

                                        <Input type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                            autoComplete='photo' placeholder={"Cambiar imagen"} />
                                    </CardBody>
                                    <CardFooter className={'justify-content-center'}>
                                        <Button color='dark' isLight icon='Delete' onClick={deleteImage}>Eliminar imagen</Button>
                                    </CardFooter>
                                </Card>
                            </div>
                        )
                        : <></>
                    }

                    <div className={classNames({ "col-xl-10": userCan('image', 'companies'), "col-xl-12": (mode === 'Editar' && !userCan('image', 'companies')) })}>
                        <Card stretch={false} className={'mx-2'}>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardTitle>Información</CardTitle>
                            </CardHeader>
                            <CardBody className="row d-flex justify-content-between">
                                <div className="col-md-6">
                                    <div>
                                        <div className="row g-4">
                                            <FormGroup requiredInputLabel label='CIF' className='col-md-4'>
                                                <Input id='cif' onChange={formik.handleChange} value={formik.values.cif} onBlur={formik.handleBlur} className={verifyClass('cif')} />
                                                {showErrors('cif')}
                                            </FormGroup>
                                            <FormGroup requiredInputLabel label='Nombre' className='col-md-8'>
                                                <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                                {showErrors('name')}
                                            </FormGroup>
                                            <FormGroup requiredInputLabel label='Cuenta Bancaria' className='col-md-4'>
                                                <Input id='bankAccount' onChange={formik.handleChange} value={formik.values.bankAccount} onBlur={formik.handleBlur} className={verifyClass('bankAccount')} />
                                                {showErrors('bankAccount')}
                                            </FormGroup>
                                            <FormGroup requiredInputLabel label='Saldo Inicial (€)' className='col-md-4'>
                                                <Input id='initialBankBalance' onChange={formik.handleChange} value={formik.values.initialBankBalance} onBlur={formik.handleBlur} className={verifyClass('initialBankBalance')} />
                                                {showErrors('initialBankBalance')}
                                            </FormGroup>
                                            <FormGroup requiredInputLabel label='Fecha del Saldo Inicial' className='col-md-4'>
                                                <Input type='date' id='initialBankBalanceDate' onChange={formik.handleChange} value={formik.values.initialBankBalanceDate} onBlur={formik.handleBlur} className={verifyClass('initialBankBalanceDate')} />
                                                {showErrors('initialBankBalanceDate')}
                                            </FormGroup>
                                            <FormGroup requiredInputLabel label='Dirección' className='col-md-12'>
                                                <Input id='address' onChange={formik.handleChange} value={formik.values.address} onBlur={formik.handleBlur} className={verifyClass('address')} />
                                                {showErrors('address')}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <FormGroup requiredInputLabel label='Texto Legal' className='col-md-12'>
                                        <textarea
                                            id='legalText'
                                            onChange={formik.handleChange}
                                            rows={8}
                                            value={formik.values.legalText}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('legalText')}
                                            style={{
                                                borderRadius: '10px',
                                                boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                                                backgroundColor: '#f8f9fa',
                                                width: '100%',
                                                padding: '15px',
                                                border: '0'
                                            }}
                                        />
                                        {showErrors('legalText')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </form>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate("/organizaciones", { replace: true })}
                    ></Button>
                    <SubheaderSeparator />
                    <CardTitle>{mode} organización</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner /> : `${mode} Organización`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                {getContent()}
            </Page>
        </Fragment>
    )
}

export default CompanyForm;