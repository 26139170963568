import React, { useEffect, useRef, useState } from 'react';
import { createStaticRanges, DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import locale from 'date-fns/locale/es';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export type RangeDate = {
    startDate: string | null,
    endDate: string | null
}
type LiteralRanges = 'this month' | 'this week' | 'this year' | 'this quarter' | 'custom';

type Props = {
    defaultSelection?: LiteralRanges,
    customSelection?: any,
    maxDate?: Date,
    onChangeDate?: (range: RangeDate) => void,
    color?: string,
    active?: boolean,
    customClass?: string,
    inputError?: boolean,
    setIsOpen?: (isOpen: boolean) => void,
    horientation?: 'horizontal' | 'vertical',
    months?: number,
    showStaticRanges?: boolean,
}

const CustomDateRangePicker: React.FC<Props> = ({
    defaultSelection = 'this month',
    customSelection, maxDate,
    onChangeDate,
    color = '#f07493',
    active, customClass, inputError,
    setIsOpen,
    horientation = 'horizontal',
    months = 2,
    showStaticRanges = true
}) => {

    const inputRangeReference = useRef(null);
    const [showPicker, setShowPicker] = useState(false);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [dateRanges, setDateRanges] = useState<Range[]>([
        {
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('day').subtract(1, 'days').toDate(),
            color: color,
            key: 'selection',
        }
    ]);
    const defaultMinDate = moment().subtract(2, 'year').toDate();
    const defaultMaxDate = moment().add(2, 'year').toDate();
    const inputRanges: any = [];

    useEffect(() => {
        initDateRangePickerEvents();
    }, []);

    // notify change date when user closes datetimepicker
    useEffect(() => {
        if (showPicker === false && endDate && startDate) {
            notifyDateChanged();
        }
    }, [showPicker]);


    // Notify data changed
    const notifyDateChanged = () => {
        if (onChangeDate !== undefined) {
            onChangeDate({
                startDate: startDate,
                endDate: endDate,
            })
        }
    };

    const handleRanges = (ranges: RangeKeyDict) => {
        const { selection } = ranges;

        if (selection) {
            setDateRanges([selection]);
            setStartDate(selection.startDate !== undefined ? moment(selection.startDate?.getTime()).format('YYYY-MM-DD') : null);
            setEndDate(selection.endDate !== undefined ? moment(selection.endDate?.getTime()).format('YYYY-MM-DD') : null);
        }
    };

    const selectDateByLiteralRange = (literalRange: LiteralRanges) => {
        let startDate = new Date();
        let endDate = new Date();
        if (literalRange === "this year") {
            startDate = moment().startOf('year').toDate();
            endDate = moment().startOf('month').toDate();
        }
        if (literalRange === "this quarter") {
            startDate = moment().startOf('quarter').toDate();
            endDate = moment().endOf('day').toDate();
        }
        if (literalRange === "this month") {
            startDate = moment().startOf('month').toDate();
            endDate = moment().endOf('day').toDate();
        }
        if (literalRange === "this week") {
            startDate = moment().startOf('week').toDate();
            endDate = moment().endOf('day').toDate();
        }
        if (literalRange === "custom") {
            if (customSelection !== null) {
                startDate = customSelection.startDate;
                endDate = customSelection.endDate;
            } else {
                startDate = moment().startOf('month').toDate();
                endDate = moment().endOf('day').toDate();
            }
        }
        setDateRanges([{
            startDate,
            endDate,
            color: color,
            key: 'selection'
        }])
        setStartDate(moment(startDate).format('YYYY-MM-DD'));
        setEndDate(moment(endDate).format('YYYY-MM-DD'));
    };

    /**
     * Event listeners.
    */
    const initDateRangePickerEvents = () => {
        // close when click outside of daterangepicker
        window.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;
            const clickInsideDateRangePicker = target.closest('.date_range_picker');
            if (clickInsideDateRangePicker === null) {
                setShowPicker(false);
                setIsOpen && setIsOpen(false);
            }
        })
        // open dropdown window when focus on input type text.
        if (inputRangeReference.current !== null) {
            const dropdownElement = inputRangeReference.current as HTMLElement;
            dropdownElement.addEventListener('click', (event) => {
                event.stopPropagation();
                setShowPicker(true);
                setIsOpen && setIsOpen(true);
            })
        }
        // if prop default selection is defined, set de default date selected.
        if (defaultSelection !== undefined) {
            selectDateByLiteralRange(defaultSelection);
        }
    };

    const inputValue = () => {
        if (startDate !== null && endDate !== null) {
            return startDate + " - " + endDate;
        }
        return "";
    };

    const isSelectedRange = (range: Range, startDateExpected: Date, endDateExpected: Date): boolean => {
        if (range.startDate !== undefined && range.endDate !== undefined) {
            let startDateSelected = new Date(range.startDate);
            let endDateSelected = new Date(range.endDate);
            if (
                startDateSelected.getTime() === startDateExpected.getTime()
                &&
                endDateSelected.getTime() === endDateExpected.getTime()
            ) {
                return true;
            }
        }
        return false;
    };

    const staticRanges = createStaticRanges([
        {
            label: 'Este año',
            isSelected: (range: any) => {
                return isSelectedRange(
                    range,
                    moment().startOf('year').toDate(),
                    moment().endOf('day').toDate()
                );
            },
            range: () => ({
                startDate: moment().startOf('year').toDate(),
                endDate: moment().endOf('day').toDate(),
            })
        },
        /* {
            label: 'Este cuatrimestre',
            isSelected: (range: any) => {
                return isSelectedRange(
                    range,
                    moment().startOf('quarter').toDate(),
                    moment().endOf('day').toDate()
                );
            },
            range: () => ({
                startDate: moment().startOf('quarter').toDate(),
                endDate: moment().endOf('day').toDate(),
            })
        }, */
        {
            label: 'Este mes',
            isSelected: (range: any) => {
                return isSelectedRange(
                    range,
                    moment().startOf('month').toDate(),
                    moment().endOf('day').toDate()
                );
            },
            range: () => ({
                startDate: moment().startOf('month').toDate(),
                endDate: moment().endOf('day').toDate(),
            }),
        },
        {
            label: 'Esta semana',
            isSelected: (range: any) => {
                return isSelectedRange(
                    range,
                    moment().startOf('week').add(1, 'day').toDate(),
                    moment().endOf('day').toDate()
                );
            },
            range: () => ({
                startDate: moment().startOf('week').add(1, 'day').toDate(),
                endDate: moment().endOf('day').toDate(),
            })
        },
    ]);

    const getShowClass = () => {
        if (showPicker === true)
            return 'd-block'
        else
            return 'd-none'
    };

    const getInputStyle = () => {
        if (active != undefined && active === true) {
            return { backgroundColor: '#477995', color: 'white' }
        } else {
            return {}
        }
    };

    return (
        <div className='w-100'>
            <input
                id={'dateRange'}
                ref={inputRangeReference}
                lang='es'
                autoComplete='off'
                value={inputValue()}
                onChange={() => { }}
                placeholder="Seleccionar rango de fechas"
                style={getInputStyle()}
                className={`form-control form-control-solid ${inputError !== undefined && inputError == true ? 'is-invalid border-danger' : ''} ${customClass !== undefined ? customClass : ''}`}
            />

            <div className={'date_range_picker position-relative ' + getShowClass()} style={{ zIndex: '20', maxWidth: '100%' }} id="date_range_picker">
                <DateRangePicker
                    locale={locale}
                    minDate={defaultMinDate}
                    maxDate={maxDate ? maxDate : defaultMaxDate}
                    weekStartsOn={1}
                    moveRangeOnFirstSelection={false}
                    months={months}
                    onChange={handleRanges}
                    staticRanges={showStaticRanges ? staticRanges : []}
                    inputRanges={inputRanges}
                    ranges={dateRanges}
                    direction={horientation}
                    className='shadow-lg zindex-modal position-absolute end-0'
                />
            </div>
        </div>
    )
}

export default CustomDateRangePicker;