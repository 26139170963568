import { useState } from 'react';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { Dropdown, FormControl } from 'react-bootstrap';
import { useDashboard } from '../providers/DashboardProvider';

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const formattedStartDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`;
const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

const DashBoardFilters = () => {

  const { updateFilters, dashboardDates, setDashboardDates, viewMode } = useDashboard();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedOption, setSelectedOption] = useState(viewMode);
  const options = ['Mes', 'Día', 'Semana'];

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedStartDate = new Date(e.target.value);
    setStartDate(selectedStartDate);
    const formattedStartDate = formatDate(selectedStartDate);
    const formattedEndDate = endDate ? formatDate(endDate) : '';
    setDashboardDates({ ...dashboardDates, startDate: formattedStartDate, endDate: formattedEndDate });
    updateFilters({ between_dates: { start_date: formattedStartDate, end_date: formattedEndDate } });
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = new Date(e.target.value);
    setEndDate(selectedEndDate);
    const formattedStartDate = startDate ? formatDate(startDate) : '';
    const formattedEndDate = formatDate(selectedEndDate);
    setDashboardDates({ ...dashboardDates, startDate: formattedStartDate, endDate: formattedEndDate });

    updateFilters({ between_dates: { start_date: formattedStartDate, end_date: formattedEndDate } });
  };

  const handleDropdownSelect = (selectedOption: any) => {
    setSelectedOption(selectedOption);

    // Lógica para actualizar las fechas según la opción seleccionada
    const currentDate = new Date();
    let newStartDate;
    let newEndDate;

    if (selectedOption === 'Día') {
      newStartDate = currentDate;
      newEndDate = currentDate;
    } else if (selectedOption === 'Semana') {
      newStartDate = new Date(currentDate);
      newEndDate = new Date(currentDate);
      newStartDate.setDate(currentDate.getDate() - 7);
    } else if (selectedOption === 'Mes') {
      newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    } else {

      newStartDate = currentDate;
      newEndDate = currentDate;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    const formattedStartDate = formatDate(newStartDate);
    const formattedEndDate = formatDate(newEndDate);
    setDashboardDates({ startDate: formattedStartDate, endDate: formattedEndDate });
    updateFilters({ between_dates: { start_date: formattedStartDate, end_date: formattedEndDate } });
  };

  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = `${(date.getMonth() + 1)}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div className='row'>
      <FormGroup className='d-flex align-items-center col'>
        <Dropdown onSelect={handleDropdownSelect}>
          <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ paddingRight: '10px', paddingLeft: '10px', marginLeft: 'auto', marginRight: '-60px' }}>
            {selectedOption}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {options.map((option) => (
              <Dropdown.Item key={option} eventKey={option}>
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </FormGroup>

      <FormGroup className='d-flex align-items-center col'>
        <label htmlFor="startDate" className='mr-2 nowrap font-weight-bold'>Fecha Inicial</label>
        <FormControl
          type="date"
          id="startDate"
          value={startDate ? formatDate(startDate) : formattedStartDate}
          onChange={handleStartDateChange}
          max={endDate ? formatDate(endDate) : formattedEndDate}
        />
      </FormGroup>

      <FormGroup className='d-flex align-items-center col'>
        <label htmlFor="endDate" className='mr-2 nowrap font-weight-bold'>Fecha Final</label>
        <FormControl
          type="date"
          id="endDate"
          value={endDate ? formatDate(endDate) : formattedEndDate}
          onChange={handleEndDateChange}
          min={startDate ? formatDate(startDate) : formattedStartDate}
        />
      </FormGroup>
    </div>
  );
}

export default DashBoardFilters;