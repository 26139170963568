import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import Page from "../../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../../components/ErrorMessage";
import { CustomTable } from "../../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import useFilters from "../../../../hooks/useFilters";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import { CostType, CostTypesApiResponse } from "../../../../type/cost-type";
import CreateModalCostType from "../cost-type-create/createModal";
import Spinner from "../../../../components/bootstrap/Spinner";
import CostTypeFilters from "./cost-type-options/CostTypeFilters";
import { CostService } from "../../../../services/costs/costService";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import classNames from "classnames";
import Icon from "../../../../components/icon/Icon";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import { SketchPicker } from "react-color";

export interface ICostTypeFilters {
    active?: number;
}

const costTypeFilters: ICostTypeFilters = {
    active: 1
};

const CostTypeList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const costService = new CostService();

    const [costTypesTree, setCostTypesTree] = useState<any[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);
    const [visiblePickerId, setVisiblePickerId] = useState(null);
    const [colors, setColors] = useState([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(costTypeFilters);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        filters.limit = 99999999999;
        const filtersCopy = JSON.parse(JSON.stringify(filters));
        filtersCopy.filter_filters = null;
        const response = await costService.getCostTypes(filtersCopy);
        return response.getResponseData() as CostTypesApiResponse;
    }, [filters]));

    const handleColorChange = async (itemId: string, color: any) => {
        try {
            const response = await (await costService.changeCostTypeColor(itemId, color.hex)).getResponseData();
            if (response.success) {
                setColors(prevColors => ({
                    ...prevColors,
                    [itemId]: color.hex
                }));
                setVisiblePickerId(null);
                toast.success('Color actualizado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (e) {
            toast.error('Error al actualizar el color');
        }
    };

    const toggleColorPicker = (itemId: any) => {
        setVisiblePickerId(prevId => (prevId === itemId ? null : itemId));
    };

    /**
     * Set order of cost type
     */
    const _setOrder = async (id: string, direction: 'up' | 'down') => {
        const apiCall = direction === 'up' ? costService.decreaseCostTypeOrder(id) : costService.increaseCostTypeOrder(id);
        const response = await (await apiCall).getResponseData();
        if (response.success) {
            toast.success('Orden actualizado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (costService.deleteCostType(id))).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Tipo de coste eliminado correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar tipo de coste");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            refetch();
            setTimeout(() => {
                setSelectedItem(null);
                setShow(false);
                setModalOpen(false);
                toast.success(message);
            }, 100);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    /**
     * Build tree structure for costs types list
     */
    const buildTree = (costTypes: any, parentId: any) => {
        let level = [];
        for (let i = 0; i < costTypes.length; i++) {
            if (costTypes[i].parent?.id === parentId) {
                let element = costTypes[i];
                let subchildren = buildTree(costTypes, costTypes[i].id);
                element.children = subchildren;
                level.push(element);
            }
        }
        return level;
    };

    /**
     * FILTER TREE ARRAY BY SEARCH_TEXT
     * @param costTypes TREE array of costtypes
     * @param searchName text to search
     * @returns filtered costtypes array
     */
    const filterByNameAndDescription = (costTypes: any, searchText: string) => {
        return costTypes.reduce((filtered: any[], item: { name: string; description: string, children: string | any[]; }) => {
            const searchLower = searchText.toLowerCase();
            const itemMatches = item.name.toLowerCase().includes(searchLower) || (item.description && item.description.toLowerCase().includes(searchLower));
            // Check if the item name or description matches the search criteria
            if (itemMatches) {
                // If it matches, add the entire item to the filtered results
                filtered.push(item);
            } else if (item.children && item.children.length > 0) {
                // Otherwise, check its children
                const filteredChildren = filterByNameAndDescription(item.children, searchText);
                if (filteredChildren.length > 0) {
                    // If any children match, add the item with only those children
                    filtered.push({ ...item, children: filteredChildren });
                }
            }
            return filtered;
        }, []);
    };

    /**
     * FILTER TREE ARRAY BY PARENT ID
     * @param costTypes TREE array of costtypes
     * @param parentId parent id to filter
     * @returns filtered costtypes array
     */
    const filterByParentId = (costTypes: any, parentId: string) => {
        return costTypes.reduce((filtered: any[], item: { parent: { id: string; }; children: string | any[]; }) => {
            // Check if the item has the specified parentId
            if (item.parent && item.parent.id === parentId) {
                // If it matches, add the entire item to the filtered results
                filtered.push(item);
            } else if (item.children && item.children.length > 0) {
                // Otherwise, check its children
                const filteredChildren = filterByParentId(item.children, parentId);
                if (filteredChildren.length > 0) {
                    // If any children match, add the item with only those children
                    filtered.push({ ...item, children: filteredChildren });
                }
            }
            return filtered;
        }, []);
    };

    /**
     * FILTER TREE ARRAY BY PARENTS EXISTENCE
     * @param costTypes TREE array of costtypes
     * @param parentsId parents id to filter
     * @returns filtered costtypes array
     */
    const filterByParentExistence = (costTypes: any, hasParent: boolean) => {
        return costTypes.reduce((filtered: any[], item: { parent: null; children: string | any[]; }) => {
            // Check if the item matches the parent existence criteria
            if ((hasParent && item.parent !== null) || (!hasParent && item.parent === null)) {
                // If it matches, add the entire item to the filtered results
                filtered.push(item);
            } else if (item.children && item.children.length > 0) {
                // Otherwise, check its children
                const filteredChildren = filterByParentExistence(item.children, hasParent);
                if (filteredChildren.length > 0) {
                    // If any children match, add the item with only those children
                    filtered.push({ ...item, children: filteredChildren });
                }
            }
            return filtered;
        }, []);
    };

    /**
     * Update costs tree when costs types change
     */
    useEffect(() => {
        let tree = [];
        if (data && data.costsTypes && data.costsTypes.length > 0) {
            tree = buildTree(data.costsTypes, undefined);
            setCostTypesTree(tree);

            // FILTER TREE ARRAY BY SEARCH_TEXT
            if (filters.filter_filters?.search_text) {
                tree = filterByNameAndDescription(tree, filters.filter_filters.search_text);
                setCostTypesTree(tree);
            }
            // FILTER TREE BY PARENT ID
            if (filters.filter_filters?.parent) {
                tree = filterByParentId(tree, filters.filter_filters.parent);
                setCostTypesTree(tree);
            }
            // FILTER TREE BY PARENTS EXISTENCE
            if (filters.filter_filters?.parents !== undefined) {
                tree = filterByParentExistence(tree, !filters.filter_filters.parents);
                setCostTypesTree(tree);
            }
        }
        if (data && data.costsTypes) setIsLoading(false);
    }, [data, filters]);

    /**
     * Update colors state
     */
    useEffect(() => {
        if (data && data.costsTypes) {
            const colors = data.costsTypes.reduce((acc: any, item: any) => {
                acc[item.id] = item.color;
                return acc;
            }, {});
            setColors(colors);
        }
    }, [data]);

    const getContent = () => {
        if (error) return <ErrorMessage error={error} />;
        if (isLoading) return <Spinner isSmall />;

        if (data) {
            const ids = data.costsTypes.map((item: CostType) => item.id);

            return (
                <CustomTable
                    title="Tipo de Costes"
                    data={costTypesTree ? costTypesTree : null}
                    expandedRowIds={ids}
                    pagination={false}
                    treeSource={true}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            className: "text-start",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer fw-bold text-start align-content-center"
                                        style={{ marginLeft: `${element.level * 20}px`, color: element.color }}
                                        onClick={() => {
                                            setSelectedItem(element);
                                            setModalOpen(true);
                                            setShow(true);
                                        }}>
                                        {element.level > 0 ? '↳' : ''}{element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: '',
                            keyValue: 'setOrder',
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="w-50">
                                        <div className="d-flex justify-content-center">
                                            <Tooltips title="Subir de posición">
                                                <Button
                                                    type={"button"}
                                                    isLight isLink
                                                    onClick={() => {
                                                        if (item.typeOrder === 1) return;
                                                        _setOrder(item.id, 'up');
                                                    }}
                                                    className={classNames({ 'cursor-na': (item.typeOrder === 1) }, 'w-auto')}
                                                >
                                                    {(item.typeOrder > 1) && <Icon icon='KeyboardArrowUp' color="dark" size='2x' />}
                                                </Button>
                                            </Tooltips>
                                            <Tooltips title="Bajar de posición">
                                                <Button
                                                    type={"button"}
                                                    isLight isLink
                                                    onClick={() => {
                                                        // si es el último dentro de su nivel no se puede bajar
                                                        if (data.costsTypes.filter((cost: CostType) => cost.parent?.id === item.parent?.id).length === item.typeOrder) return;
                                                        _setOrder(item.id, 'down');
                                                    }}
                                                    className={classNames({ 'cursor-na': (data.costsTypes.filter((cost: CostType) => cost.parent?.id === item.parent?.id).length === item.typeOrder) }, 'w-auto')}
                                                >
                                                    {(data.costsTypes.filter((cost: CostType) => cost.parent?.id === item.parent?.id).length !== item.typeOrder) && <Icon icon='KeyboardArrowDown' color="dark" size='2x' />}
                                                </Button>
                                            </Tooltips>
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Posición',
                            keyValue: 'type_order',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {item.typeOrder}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Color",
                            keyValue: "color",
                            className: "text-center",
                            render: (item: any) => {
                                return (
                                    <div className="d-flex justify-content-center" key={item.id}>
                                        <div
                                            onClick={() => toggleColorPicker(item.id)}
                                            style={{
                                                backgroundColor: colors[item.id],
                                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                                cursor: "pointer",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                            className="rounded-circle mr-3 p-3"
                                        >
                                            <div
                                                className="rounded-circle"
                                                style={{ backgroundColor: colors[item.id], width: "5px", height: "5px" }}
                                            />
                                        </div>
                                        {visiblePickerId === item.id && (
                                            <SketchPicker
                                                color={colors[item.id]}
                                                onChange={(color) => handleColorChange(item.id, color)}
                                            />
                                        )}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Mostrar en selector",
                            keyValue: "displayOnSelect",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.displayOnSelect ? "Sí" : "No"}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <div className="text-start">
                                            {element.description || "-"}
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            className: (userCan("list", "companies") ? "text-center" : "d-none"),
                            isVisible: (userCan("list", "companies") ? true : false),
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.company.name || "-"}
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            buttonType: 'icon',
                            icon: "Edit",
                            additionalClasses: `text-primary ${userCan("admin_costs", "costs") ? "" : "d-none"}`,
                            description: "Editar tipo de coste",
                            callback: (item: any) => {
                                if (!userCan("admin_costs", "costs")) return;
                                setSelectedItem(item);
                                setModalOpen(true);
                                setShow(false);
                            },
                        },
                        {
                            title: "Eliminar",
                            buttonType: 'icon',
                            icon: "Delete",
                            additionalClasses: `text-danger ${userCan("admin_costs", "costs") ? "" : "d-none"}`,
                            description: "Eliminar Tipo de Coste",
                            callback: (item: any) => {
                                if (!userCan("admin_costs", "costs")) return;
                                handleConfirmationAlert({
                                    title: "Eliminar Tipo de Coste",
                                    text: "¿Está seguro que desea eliminar el tipo de coste?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                        {
                            title: "Sin permisos",
                            buttonType: 'icon',
                            icon: "Block",
                            additionalClasses: `text-danger ${(userCan('admin_costs', 'costs')) ? 'd-none' : ''}`,
                            description: "No tiene permisos para editar ni eliminar",
                            callback: (item: any) => { },
                        }
                    ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Tipos de Costes</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="primary"
                        title="Crear Tipo de Coste"
                        icon="Add"
                        isLight
                        className={userCan("admin_costs", "costs") ? "" : "d-none"}
                        onClick={() => {
                            if (!userCan("admin_costs", "costs")) return;
                            setModalOpen(true);
                            setSelectedItem(null);
                            setShow(false);
                        }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <CostTypeFilters filters={filters} updateFilters={updateFilters} updatePageSize={updatePageSize} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card>
                    <CardBody className="table-responsive">
                        {isLoading
                            ? <div className="w-100 text-center"><Spinner /></div>
                            : <Fragment>{getContent()}</Fragment>
                        }
                    </CardBody>
                </Card>
            </Page>
            {modalOpen && (<CreateModalCostType isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />)}
        </Fragment>
    );
};

export default CostTypeList;