import { useContext } from "react";
import { PrivilegeContext, permissionGroup } from "../components/priviledge/PriviledgeProvider";

interface Permission {
    action: string;
    group: permissionGroup;
}

export function CheckAccessAndLog(menu: any, access?: Permission[]): boolean {

    const { userCan } = useContext(PrivilegeContext);
    let hasPermission = null;
    let count = 0;

    if (access) {
        access.forEach((permission) => {
            if (!userCan(permission.action, permission.group)) {
                hasPermission = false;
            }
        });
        if (hasPermission !== null && !hasPermission) {
            return false
        };
    }
    for (const key in menu) {
        if (menu.hasOwnProperty(key)) {
            const menuItem = menu[key];
            if (menuItem.access && userCan(menuItem.access.action, menuItem.access.group)) {
                count++;
            }
        }
    }
    return count > 0;
}