import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ClientService } from "../../../services/clients/clientService";
import ClientForm from "../ClientsForm";
import 'react-toastify/dist/ReactToastify.css';

const CreateClient = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const handleCreation = async (values: any) => {
		setLoading(true);
		try {
			const clientData = {
				client: {
					name: values.name == "" ? null : values.name,
					category: values.clientCategory == "" ? null : values.clientCategory,
					origin: values.clientOrigin == "" ? null : values.clientOrigin,
					first_name: values.firstName == "" ? null : values.firstName,
					last_name: values.lastName == "" ? null : values.lastName,
					address: values.address == "" ? null : values.address,
					cifDni: values.cifNif == "" ? null : values.cifNif,
					population: values.population == "" ? null : values.population,
					province: values.province == "" ? null : values.province,
					pc: values.pc == "" ? null : values.pc,
					user: values.user == "" ? null : values.user,
					classification: null,
					note: values.note == "" ? null : values.note,
					email: values.email == "" ? null : values.email,
				},
				contactPerson: {
					contactPerson: values.clientContactPerson,
					name: values.cpname,
					first_name: values.cpfirst_name == "" ? null : values.cpfirst_name,
					last_name: values.cplast_name == "" ? null : values.cplast_name,
					nif: values.cpnif == "" ? null : values.cpnif,
					telephone: values.telephone,
					telephoneAux: values.telephoneAux == "" ? null : values.telephoneAux,
					email: values.cpemail == "" ? null : values.cpemail,
					position: values.cpposition == "" ? null : values.cpposition,
				}
			}
			const response = await (await (new ClientService()).createClient(clientData)).getResponseData();
			if (response.success) {
				navigate('/contactos', { replace: true });
				setTimeout(() => {
					toast.success('Cliente creado correctamente');
				}, 100);
			} else if (response.data.errors) {
				response.data.errors.forEach((error: any) => {
					toast.error(error.message);
				});
			} else {
				setError(response.message);
				toast.error(response.message);
			}
		} catch (error: any) {
			setError(error.message);
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	return <ClientForm submit={handleCreation} isLoading={loading} />
}

export default CreateClient;