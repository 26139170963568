import { Fragment, useCallback, useContext, useState } from "react";
import { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import CreateModalSerie from "../create-serie/createModal";
import SeriesFilters from "./series-options/SeriesFilters";
import { SerieService } from "../../../services/series/serieService";
import { SeriesApiResponse } from "../../../type/serie-type";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

export interface ISeriesFilters {
    active?: number;
}

const seriesFilters: ISeriesFilters = {
    active: 1
};

const SeriesList = () => {

    const { userCan } = useContext(PrivilegeContext);

    const serieService = new SerieService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(seriesFilters);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [show, setShow] = useState(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await serieService.listSeries(filters);
        return response.getResponseData() as SeriesApiResponse;
    }, [filters]));

    const handleDelete = async (id: string) => {
        try {
            const response = await (await serieService.deleteSerie(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Serie eliminada correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar la serie");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            setSelectedItem(null);
            setShow(false);
            setModalOpen(false);
            refetch();
            toast.success(message);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage error={error} />;

        if (data) {
            let apiData = data as SeriesApiResponse;

            return (
                <CustomTable
                    title="Series"
                    loading={loading}
                    data={apiData ? apiData.series : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as SeriesApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-center" onClick={() => {
                                        setSelectedItem(element);
                                        setModalOpen(true);
                                        setShow(true);
                                    }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Acrónimo",
                            keyValue: "acronym",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <div className="text-start">
                                            {element.acronym || "-"}
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <>{element.description || "-"}</>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Editar",
                                buttonType: 'icon',
                                icon: "Edit",
                                additionalClasses: `text-primary ${userCan("edit", "budget_series") ? "" : "d-none"}`,
                                description: "Editar serie",
                                callback: (item: any) => {
                                    if (!userCan("edit", "budget_series")) return;
                                    setSelectedItem(item);
                                    setModalOpen(true);
                                    setShow(false);
                                },
                            },
                            {
                                title: "Eliminar",
                                buttonType: 'icon',
                                icon: "Delete",
                                additionalClasses: `text-danger ${userCan("delete", "budget_series") ? "" : "d-none"}`,
                                description: "Eliminar serie",
                                callback: (item: any) => {
                                    if (!userCan("delete", "budget_series")) return;
                                    handleConfirmationAlert({
                                        title: "Eliminar serie",
                                        text: "¿Está seguro que desea eliminar la serie?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        }
                                    })
                                },
                            },
                            {
                                title: "Sin permisos",
                                buttonType: 'icon',
                                icon: "Block",
                                additionalClasses: `text-danger ${(userCan('edit', 'budget_series') || userCan('delete', 'budget_series')) ? 'd-none' : ''}`,
                                description: "No tiene permisos para editar ni eliminar",
                                callback: (item: any) => { },
                            }
                        ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <Card stretch>
                <div className="row m-4 d-flex justify-content-between">
                    <SubHeaderLeft>
                        <CardTitle>Series</CardTitle>
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <SeriesFilters filters={filters} updateFilters={updateFilters} updatePageSize={updatePageSize} resetFilters={resetFilters} />
                        <Button
                            color="primary"
                            title="Crear serie"
                            icon="Add"
                            isLight
                            className={`${userCan("create", "budget_series") ? "" : "d-none"}`}
                            onClick={() => {
                                if (!userCan("create", "budget_series")) return;
                                setModalOpen(true);
                                setSelectedItem(null);
                                setShow(false);
                            }}
                        />
                    </SubHeaderRight>
                </div>
                <CardBody className="table-responsive">
                    <Fragment>{getContent()}</Fragment>
                </CardBody>
            </Card>

            {modalOpen && <CreateModalSerie isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />}
        </Fragment>
    );
};

export default SeriesList;