import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const IncomesWrapper = () => {
    return (
        <Fragment>
            <ToastContainer />
            <Outlet />
        </Fragment>
    )
}

export default IncomesWrapper;