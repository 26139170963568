import { Fragment, useCallback, useContext, useRef, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import StatusDropdown from "../../../components/StatusDropdown";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { ClientsApiResponse } from "../../../type/client-type";
import AsyncImg from "../../../components/AsyncImg";
import { ClientService } from "../../../services/clients/clientService";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { ContactService } from "../../../services/clients/contactService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ClientMenu } from "../../../menu";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import ClientFilters from "./clients-options/ClientFilters";
import ClassificationsDropdown from "../../../components/dropdowns/ClassificationsDropdown";

export interface IClientFilters {
  company?: string;
  active?: number;
  clientContactPerson?: string;
  clientCategory?: string;
  classification?: string;
  province?: string;
}

const ContactsList = () => {

  const token = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const fileInputRef = useRef<HTMLInputElement>(null);  // asocia el input file a un ref para poder hacerlo con un boton

  const clientService = new ClientService();
  const contactService = new ContactService();

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFiltersPR();

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await clientService.getClients(filters);
    return response.getResponseData() as ClientsApiResponse;
  }, [filters]));

  const handleDelete = async (id: string) => {
    try {
      const response = await (await new ClientService().deleteClient(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success("Cliente eliminado");
        }, 100);
      } else {
        toast.error(response.message || "Error al eliminar el cliente");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  // Importar contactos desde excel
  const handleImport = async (e: React.ChangeEvent<any>) => {
    try {
      const selectedFile = e.target.files && e.target.files[0];

      const values = {
        file: selectedFile,
        company: token?.company,
      };

      const response = (await contactService.importContacts(values)).getResponseData();
      if (response.success) {
        toast.success('Contactos importados correctamente');
        refetch();
      } else {
        response.data.errors.forEach((error: any) => {
          toast.error(error);
        });
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  // Exportar contactos a excel
  const handleExport = async () => {
    try {
      const response = await contactService.exportContacts({ ...filters, limit: 999999999 });
      if (response) {
        const fileData = response.getResponseData();
        const blob = new Blob([fileData]);
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'listado_contactos.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error('Error al exportar los contactos');
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const toggleClientStatus = async (id: string, status: boolean, toggleStatus: Function) => {
    try {
      setChangingStatus([...changingStatus, id]);
      const response = await (await new ClientService().toggleClientStatus(id, status)).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success(status ? "Se ha activado el cliente" : "Se ha desactivado el cliente");
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toast.error(response.message);
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
      toast.error(error.message);
    }
  };

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data) {
      let apiData = data as ClientsApiResponse;
      return (
        <CustomTable
          data={apiData.clients ? apiData.clients : null}
          loading={loading}
          defaultLimit={filters.limit || 50}
          defaultOrder={filters.filter_order || undefined}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: (apiData as ClientsApiResponse) ? apiData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "",
              keyValue: "profileImg",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className="user-avatar">
                      <AsyncImg
                        id={element.profileImg ? element.profileImg.id : null}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre/RS",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-primary">
                    {
                      element.name !== null ? (
                        <a href={`${ClientMenu.contacts.path}/${element.id}/perfil`}>
                          {element.name || '-'} {element.firstName || ''} {element.lastName || ''}
                        </a>
                      ) : '-'
                    }
                  </div>
                );
              },
            },
            {
              name: "NIF / CIF",
              keyValue: "cifdni",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return <>{element.cifNif || "-"}</>;
              },
            },
            {
              name: "Provincia",
              keyValue: "province",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return <>{element.province || "-"}</>;
              },
            },
            {
              name: "Población",
              keyValue: "population",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return <>{element.population || "-"}</>;
              },
            },
            {
              name: "Contacto",
              keyValue: "contactPerson",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <>
                    {
                      element.clientContactPerson?.name ? (
                        element.clientContactPerson?.name ? `${element.clientContactPerson?.name}  ${element.clientContactPerson?.firstName || ""} ${element.clientContactPerson?.lastName || ""}` : '-'
                      ) : '-'
                    }
                  </>
                );
              },
            },
            {
              name: "Teléfono del contacto",
              keyValue: "contactPhone",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return <>{element.clientContactPerson?.telephone || "-"}</>;
              },
            },
            {
              name: "Categoria",
              keyValue: "category",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return <>{element.clientCategory?.name || "-"}</>;
              },
            },
            {
              name: "Organización",
              keyValue: "company",
              className: userCan("list", "companies") ? "d-bolck text-center" : "d-none",
              cellClassName: "text-center",
              isVisible: userCan("list", "companies") ? true : false,
              render: (element: any) => {
                return <>{element.company?.name || "-"}</>;
              },
            },
            {
              name: "Clasificación",
              keyValue: "classification",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center" key={element.id}>
                    <ClassificationsDropdown
                      itemId={element.id}
                      status={element.classification}
                      statuses={[
                        { id: "Potencial", name: "Potencial", color: "#d4e3eb" },
                        { id: "Posible", name: "Posible", color: "#863d40" },
                        { id: "Consolidado", name: "Consolidado", color: "#428f61" },
                      ]}
                    />

                    {/* 
                    <StatusesDropdown
                        onChange={() => { refetch && refetch() }}
                        key={item.id + "-status"}
                        itemId={item.id}
                        statuses={projectStatuses}
                        status={
                            item.projectStatus != null
                                ? item.projectStatus
                                : null
                        }
                        additionalInfo={
                            item.projectStatusDate
                                ? moment(item.projectStatusDate.date).format("DD/MM/yyyy HH:mm")
                                : undefined
                        }
                        orientedTo={"project"}
                    />
                    */}
                  </div>
                );
              },
            },
            {
              name: "Estado",
              keyValue: "active",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center" key={element.id}>
                    <StatusDropdown
                      className="fs-6"
                      disabled={changingStatus.includes(element.id)}
                      itemId={element.id}
                      status={element.active}
                      change={toggleClientStatus}
                    />
                  </div>
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
          ]}
          actions={[
            {
              title: "Editar",
              buttonType: "icon",
              icon: "Edit",
              iconPath: "/media/icons/duotune/general/gen055.svg",
              additionalClasses: `text-primary ${userCan("edit", "clients") ? "" : "d-none"}`,
              description: "Editar perfil de cliente",
              callback: (item: any) => {
                if (userCan("edit", "clients")) navigate(`${item.id}/edicion`);
              },
            },
            {
              title: "Eliminar",
              buttonType: "icon",
              icon: "Delete",
              iconPath: "/media/icons/duotune/general/gen027.svg",
              additionalClasses: "text-danger",
              description: "Eliminar cliente",
              callback: (item: any) => {
                if (userCan("delete", "clients")) {
                  handleConfirmationAlert({
                    title: "Eliminar Cliente",
                    text: "¿Está seguro que desea eliminar el cliente?",
                    icon: "warning",
                    onConfirm: () => {
                      handleDelete(item.id);
                    },
                  })
                }
              },
            },
          ]}
        />
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Contactos</CardTitle>
          <SubheaderSeparator />
          <Button
            color="primary"
            title="Crear Cliente"
            icon="Add"
            isLight
            className={userCan("create", "clients") ? "" : "d-none"}
            onClick={() => { if (userCan("create", "clients")) navigate("creacion") }}
          />

          {/* <Button
                        color="light"
                        isLight
                        title="Importar excel"
                        onClick={() => fileInputRef.current?.click()}
                    >Importar</Button>

                    {isUploading && <Spinner color={"primary"} />} */}

          <Button
            color="light"
            isLight
            title="Exportar excel"
            className={userCan("list", "clients") ? "" : "d-none"}
            onClick={userCan("list", "clients") ? handleExport : undefined}
          >Exportar</Button>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImport}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <ClientFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} name='Contacto' classification />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card>
          <CardBody className="table-responsive">
            <Fragment>{getContent()}</Fragment>
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default ContactsList;