import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { ProjectService } from "../../../services/projects/projectService";
import { Link } from "react-router-dom";
import { ProjectsApiResponse } from "../../../type/project-type";
import { StatusService } from "../../../services/status/statusService";
import { Status } from "../../../type/status-type";
import { CustomTable } from "../../../components/table/CustomTable";
import ErrorMessage from "../../../components/ErrorMessage";
import { FinancesMenu } from "../../../menu";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import StatusesDropdown from "../../../components/dropdowns/StatusesDropdown";
import moment from "moment";
import useFilters from "../../../hooks/useFilters";

const LastProjects = () => {

  const { filters, updateFilterOrder } = useFilters({}, [], 1, 5);

  const projectService = new ProjectService();

  const [projectStatuses, setProjectStatuses] = useState<Status[]>([]);

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await projectService.listProjects(filters);
    return response.getResponseData() as ProjectsApiResponse;
  }, [filters]));

  const getStatusList = async (orientedTo: string): Promise<Status[] | undefined> => {
    //if (!userCan("down_status", "statuses")) return;
    const response = await (await (new StatusService()).listStatuses({ filter_filters: { oriented_to: orientedTo }, limit: 999999 })).getResponseData();
    if (response.success) {
      return response.data.statuses;
    } else {
      return [];
    }
  }

  // Carga los estados de los proyectos
  const fetchStatuses = async () => {
    let projectStatusList = await getStatusList("project");
    setProjectStatuses(projectStatusList !== undefined ? projectStatusList : []);
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  const getContent = () => {
    if (loading) return <div className="text-center"><Spinner isGrow /></div>;

    if (error) return <ErrorMessage error={error} />;

    if (data && data.projects.length > 0) {
      let apiData = data as ProjectsApiResponse;

      return (
        <CustomTable
          data={apiData ? apiData.projects : null}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "Estado",
              keyValue: "currentStatus",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (item: any) => {
                return (
                  <div className="p-0" key={item.id}>
                    <StatusesDropdown
                      onChange={() => { refetch() }}
                      key={item.id + "-status"}
                      itemId={item.id}
                      statuses={projectStatuses}
                      status={
                        item.projectStatus != null
                          ? item.projectStatus
                          : null
                      }
                      additionalInfo={
                        item.projectStatusDate
                          ? moment(item.projectStatusDate?.date).format("DD/MM/yyyy HH:mm")
                          : undefined
                      }
                      orientedTo={"project"}
                    />
                  </div>
                );
              },
            },
            {
              name: "Código",
              keyValue: "code_identifier",
              className: "text-center",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="text-primary text-center cursor-pointer fw-bold" >
                    <a href={`${FinancesMenu.project.path}/${element.id}/vista`}>
                      {element.codeIdentifier}
                    </a>
                  </div>
                )
              },
            },
            {
              name: 'Dirección',
              keyValue: 'address',
              sortable: true,
              sortColumn: updateFilterOrder,
            }
          ]}
        />
      );
    }

    return <div className="text-center">No hay proyectos</div>;
  };

  return (
    <Card stretch>
      <CardHeader>
        <CardTitle className="text-center">Últimos Proyectos</CardTitle>
      </CardHeader>
      <CardBody>
        <>{getContent()}</>
      </CardBody>
      <CardFooter className="d-flex justify-content-center">
        <>{(data?.projects?.length > 0) && <Link className="link" to={FinancesMenu.project.path} style={{ color: '#ed291f', textDecoration: 'none' }}>Ver todos</Link>}</>
      </CardFooter>
    </Card>
  );
};

export default LastProjects;