import { Fragment } from "react"
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"

const RoleWrapper = () => {

    return (
        <Fragment>
            <ToastContainer />
            <Outlet />
        </Fragment>
    )
}

export default RoleWrapper