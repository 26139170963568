import { createSlice } from "@reduxjs/toolkit";

export interface ProjectState {
    activeParentTab: string;
    activeChildTab: string;
}

export const initialState: ProjectState = {
    activeParentTab: 'info',
    activeChildTab: 'info',
};

const documentTypeSlice = createSlice({
    name: 'documentType_view',
    initialState,
    reducers: {
        changeParentActiveTab: (state, action) => {
            state.activeParentTab = action.payload;
        },
        changeChildActiveTab: (state, action) => {
            state.activeChildTab = action.payload;
        },
    },
});

export const { changeParentActiveTab, changeChildActiveTab } = documentTypeSlice.actions;

export default documentTypeSlice.reducer;