/**
 * Generate weeks for the given year and return an array of weeks
 */
export const generateWeeksByYear = (year: number) => {
    const isLeap = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    const daysInYear = isLeap ? 366 : 365; const weeks = [];
    const startDate = new Date(year, 0, 1);
    // 1st January of the given year
    for (let i = 0; i < daysInYear; i += 7) {
        let weekStart = new Date(year, 0, 1 + i);
        let weekEnd = new Date(year, 0, 1 + i + 6);
        // Ensure the week end does not exceed the year
        if (weekEnd.getFullYear() > year) {
            weekEnd = new Date(year, 11, 31);
        }
        weeks.push([weekStart.toISOString().slice(0, 10), weekEnd.toISOString().slice(0, 10)]);
        if (weekEnd.getDate() === 31 && weekEnd.getMonth() === 11) {
            break;
        }
    }
    return weeks;
};

/**
 * Generate weeks between the given start and end date and return an array of weeks
 */
export const generateWeeksBetweenDates = (startDate: Date, endDate: Date) => {
    const weeks = [];

    // Calculate the difference in time between the two dates
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    for (let i = 0; i < diffDays + 1; i += 7) {
        let weekStart = new Date(startDate);
        weekStart.setDate(startDate.getDate() + i);
        let weekEnd = new Date(startDate);
        weekEnd.setDate(startDate.getDate() + i + 6);
        if (weekEnd > endDate) {
            weekEnd = new Date(endDate);
        }
        weeks.push([weekStart.toISOString().slice(0, 10), weekEnd.toISOString().slice(0, 10)]);
        if (weekEnd.getDate() === 31 && weekEnd.getMonth() === 11) {
            break;
        }
    }

    return weeks;
};

/**
 * Generate months for the given year and return an array of months
 */
export const generateMonthsByYear = (year: number) => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        const monthStart = new Date(year, i, 1);
        const monthEnd = new Date(year, i + 1, 0);
        months.push([monthStart.toISOString().slice(0, 10), monthEnd.toISOString().slice(0, 10)]);
    }
    return months;
};

/**
 * Generate months between the given start and end date and return an array of months
 */
export const generateMonthsBetweenDates = (startDate: Date, endDate: Date) => {
    const months = [];

    // Calculate the number of months between the two dates
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const diffMonths = (endYear - startYear) * 12 + endMonth - startMonth + 1;

    for (let i = 0; i < diffMonths + 1; i++) {
        const monthStart = new Date(startYear, startMonth + i, 1);
        const monthEnd = new Date(startYear, startMonth + i + 1, 0);
        if (monthEnd > endDate) {
            monthEnd.setMonth(endDate.getMonth());
            monthEnd.setDate(endDate.getDate());
        }
        months.push([monthStart.toISOString().slice(0, 10), monthEnd.toISOString().slice(0, 10)]);
    }

    return months;
};