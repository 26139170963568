import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

const CATEGORIES_ENDPOINT = '/clients';

export class CategoryService extends RestServiceConnection {

    listCategories = async (filter?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CATEGORIES_ENDPOINT + '/list-category',
            data: filter ? filter : {},
        }, true) as AxiosResponse;
        return this;
    }

    getCategoryById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CATEGORIES_ENDPOINT + '/get-category',
            data: {
                category: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createCategory = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CATEGORIES_ENDPOINT + '/create-category',
            data: {
                name: data.name,
                company: data.company,
                description: data.description ? data.description : null,
            }
        }, true) as AxiosResponse;
        return this;
    }

    editCategory = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CATEGORIES_ENDPOINT + '/edit-category',
            data: {
                category: data.category,
                name: data.name,
                company: data.company,
                description: data.description ? data.description : null,
            }
        }, true) as AxiosResponse;
        return this;
    }

    deleteCategory = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CATEGORIES_ENDPOINT + '/delete-category',
            data: {
                category: id
            }
        }, true) as AxiosResponse;
        return this;
    }

}