import React, { useCallback } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import useFetch from '../../../hooks/useFetch';
import { StatusService } from '../../../services/status/statusService';
import { StatusApiResponse } from '../../../type/status-type';
import { useFormik } from 'formik';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { toast } from 'react-toastify';

interface ProjectStatusModalProps {
    isOpen: boolean;
    setIsOpen: (...args: boolean[]) => unknown;
    onChange: (() => void) | undefined;
    statusId: string | undefined;
    projectId: string;
    setCurrentStatus: any;
    isLoading: boolean;
    setIsLoading: (...args: boolean[]) => unknown;
}

const ProjectStatusModal: React.FC<ProjectStatusModalProps> = ({ isOpen, setIsOpen, onChange, statusId, projectId, setCurrentStatus, isLoading, setIsLoading }) => {

    const { handleErrors } = useHandleErrors();

    const [data] = useFetch(useCallback(async () => {
        const response = await (new StatusService).getStatusById(statusId as string);
        return response.getResponseData() as StatusApiResponse;
    }, [statusId]));

    const _toggleStatus = async (values: any) => {
        setIsLoading(true);
        const service = new StatusService().toggleProjectStatus(values);

        try {
            let response = await (await service).getResponseData();

            if (response && response.success) {
                onChange && onChange();
                setCurrentStatus(response.data);
                setTimeout(() => {
                    setIsOpen(false);
                    toast.success('Estado cambiado correctamente');
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al cambiar el estado');
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            project: projectId,
            status: statusId,
            comments: '',
            fileNumberGmu: null,
            licenseNumber: null,
            fileNumberUse: null,
            bailNumberRefund: null,
        },
        validate: (values) => {
            const errors: any = {};

            if (!values.fileNumberGmu && data?.generateFileNumberGMU) {
                errors.fileNumberGmu = 'El número de GMU es obligatorio';
                toast.error('El número de GMU es obligatorio');
            }

            if (!values.licenseNumber && data?.generateLicenseNumber) {
                errors.licenseNumber = 'El número de licencia es obligatorio';
                toast.error('El número de licencia es obligatorio');
            }

            if (!values.fileNumberUse && data?.generateFileNumberUse) {
                errors.fileNumberUse = 'El número de expediente de uso es obligatorio';
                toast.error('El número de expediente de uso es obligatorio');
            }

            if (!values.bailNumberRefund && data?.generateBailNumberRefund) {
                errors.bailNumberRefund = 'El número de devolución de fianza es obligatorio';
                toast.error('El número de devolución de fianza es obligatorio');
            }

            return errors;
        },
        onSubmit: (values) => { _toggleStatus(values) },
    });

    // @ts-ignore
    const verifyClass = (inputFieldID) => { return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? "is-invalid" : ""; };

    // @ts-ignore
    const showErrors = (inputFieldID) => {
        // @ts-ignore
        return formik.touched[inputFieldID] && formik.errors[inputFieldID] ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={'md'} titleId='Nuevo estado'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='change_project_status'>{'Cambiar el estado del proyecto'}</ModalTitle>
            </ModalHeader>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <ModalBody className='px-5'>
                    <div className='row g-4'>
                        {data?.generateFileNumberGMU &&
                            <FormGroup id='fileNumberGmu' label='Nº Expediente GMU:' className={verifyClass("fileNumberGmu") + "col-md-12"} requiredInputLabel>
                                <Input name='fileNumberGmu' value={formik.values.fileNumberGmu || ''} onChange={formik.handleChange} />
                                {showErrors("fileNumberGmu")}
                            </FormGroup>
                        }

                        {data?.generateLicenseNumber &&
                            <FormGroup id='licenseNumber' label='Nº Licencia:' className={verifyClass("licenseNumber") + "col-md-12"} requiredInputLabel>
                                <Input name='licenseNumber' value={formik.values.licenseNumber || ''} onChange={formik.handleChange} />
                                {showErrors("licenseNumber")}
                            </FormGroup>
                        }

                        {data?.generateFileNumberUse &&
                            <FormGroup id='fileNumberUse' label='Nº Expediente Uso:' className={verifyClass("fileNumberUse") + "col-md-12"} requiredInputLabel>
                                <Input name='fileNumberUse' value={formik.values.fileNumberUse || ''} onChange={formik.handleChange} />
                                {showErrors("fileNumberUse")}
                            </FormGroup>
                        }

                        {data?.generateBailNumberRefund &&
                            <FormGroup id='bailNumberRefund' label='Nº Devolución Fianza:' className={verifyClass("bailNumberRefund") + "col-md-12"} requiredInputLabel>
                                <Input name='bailNumberRefund' value={formik.values.bailNumberRefund || ''} onChange={formik.handleChange} />
                                {showErrors("bailNumberRefund")}
                            </FormGroup>
                        }
                    </div>

                    <FormGroup label="Comentario:" className="mt-2 mb-2">
                        <textarea id='comments' value={formik.values.comments || ''} onChange={formik.handleChange} rows={5} className='form-control' />
                    </FormGroup>
                </ModalBody>

                <ModalFooter className='px-4 pb-4 d-flex'>
                    <Button color='secondary' type="submit" icon="Save" className={'d-block'} isDisable={isLoading}>
                        Cambiar estado
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default ProjectStatusModal;