import Page from "../../layout/Page/Page";
import SeriesList from "../series/series-list/SeriesList";
import TaxesList from "../taxes/taxes-list/TaxesList";

const SeriesTaxesLists = () => {
    return (
        <Page container="fluid">
            <div className="row">
                <div className="col-md-6 m-0">
                    <SeriesList />
                </div>

                <div className="col-md-6 m-0">
                    <TaxesList />
                </div>
            </div>
        </Page>
    )
}

export default SeriesTaxesLists;