import ReactApexChart from 'react-apexcharts';
import { bottom } from '@popperjs/core';
import Card, { CardBody, CardHeader, CardTitle } from '../bootstrap/Card';

interface ChartDataItem {
  id: string;
  name: string;
  count: number;
  color: string;
}

interface PieDonutChartBoxProps {
  title: string;
  chartData: ChartDataItem[];
}

const PieDonutChartBox: React.FC<PieDonutChartBoxProps> = ({ title, chartData }) => {

  const series = chartData ? chartData.map(item => item.count || 0) : [];

  const options = {
    labels: chartData ? chartData.map(item => item.name || '') : [],
    colors: chartData ? chartData.map(item => item.color || '') : [],
    legend: {
      position: bottom,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number, opts: any) {
        return `${val.toFixed(2)}%`;
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
  };

  return (
    <Card stretch>
      <CardHeader>
        <CardTitle className="text-center">{title}</CardTitle>
      </CardHeader>

      <CardBody>
        {chartData && chartData.length > 0 ? (
          <ReactApexChart
            type="donut"
            height={300}
            options={options}
            series={series}
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <p>No hay datos disponibles en este rango de fechas.</p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default PieDonutChartBox;