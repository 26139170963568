import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import ErrorMessage from "../../../components/ErrorMessage";
import { ClientMenu } from "../../../menu";
import { ClientService } from "../../../services/clients/clientService";
import { Client } from "../../../type/client-type";
import ClientTab from "./ClientTabs";
import { CardTitle } from "../../../components/bootstrap/Card";

const ClientProfile: FC = () => {

    const navigate = useNavigate();
    const { id = "" } = useParams<{ id: string }>();

    const fetchClient = useCallback(async () => {
        const clientService = new ClientService();
        const response = await clientService.getClientById(id as string);
        return response.getResponseData() as Client;
    }, []);

    const [data, loading, error] = useFetch(fetchClient);

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>;

        if (error) return <ErrorMessage />;

        if (data) return <ClientTab clientData={data} />;
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    />
                    <SubheaderSeparator />
                    <CardTitle>Perfil del cliente</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="brand"
                        icon="Edit"
                        onClick={() => navigate(`${ClientMenu.clients.path}/${id}/edicion`, { replace: true })}
                    >
                        Editar
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="xxl">
                <Fragment>{getContent()}</Fragment>
            </Page>
        </Fragment>
    );
};

export default ClientProfile;